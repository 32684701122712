import React, { useState } from 'react'
import classes from './SumModal.module.scss'
import { AppButton, AppInput, InputType, ModalProps, Spacer } from "../common";
import { Overlay } from "react-bootstrap";
import { OverlayProps } from "react-bootstrap/Overlay";
import { DOMContainer } from "@restart/ui/useWaitForDOMRef";
import { VirtualElement } from "@restart/ui/usePopper";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Modal from "../common/Modal";
import { isMobile } from "react-device-detect";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const SumModal: React.FC<SumModalProps & ModalProps> = (props) => {
  return <Modal
    {...props}
    title={'Sum'}>
    <SumModalContent {...props}/>
  </Modal>
}

interface SumOverlayProps {
  target: DOMContainer<HTMLElement | VirtualElement>
}

export const SumOverlay: React.FC<SumModalProps & SumOverlayProps & Partial<OverlayProps>> = (props) => {

  const {
    onHide,
    show,
    target,
  } = props

  return <Overlay
    rootClose
    onHide={onHide}
    show={show}
    target={target}
    placement='bottom-start'>
    <div>
      <SumModalContent {...props}/>
    </div>
  </Overlay>

}

interface SumModalProps {
  initialFrom?: number
  initialTo?: number
  onSave: (from: number | undefined, to: number | undefined) => void
  onDelete: VoidFunction
  onHide?: (e: any) => void
  show: boolean
}

const SumModalContent: React.FC<SumModalProps> = (props) => {

  const {
    initialFrom,
    initialTo,
    onSave,
    onDelete,
    onHide
  } = props

  const [from, setFrom] = useState(initialFrom)
  const [to, setTo] = useState(initialTo)
  const [fromError, setFromError] = useState(false)
  const [toError, setToError] = useState(false)

  const onSaveClick = (e: any) => {

    if (to && from && from > to) {
      setToError(true)
      return
    }

    onSave(from, to)
    onHide && onHide(e)

  }

  const onDeleteClick = (e: any) => {
    setFrom(undefined)
    setTo(undefined)
    onDelete && onDelete()
    onHide && onHide(e)
  }

  const onFromChange = (v: string) => {
    setFromError(false)
    setFrom(parseInt(v))
  }

  const onToChange = (v: string) => {
    setToError(false)
    setTo(parseInt(v))
  }

  return <div className={isMobile ? classes.SumModalMobile : classes.SumModal}>

    <div className={classes.InputsContainer}>

      <div className={'flex-1'}>
        <AppInput
          type={InputType.money}
          onChange={onFromChange}
          initialMoneyValue={initialFrom}
          placeholder={'From'}
          error={fromError}/>
      </div>

      <Spacer width={10}/>

      <div className={'flex-1'}>
        <AppInput
          type={InputType.money}
          onChange={onToChange}
          initialMoneyValue={initialTo}
          placeholder={'To'}
          error={toError}/>
      </div>

    </div>

    <Spacer height={isMobile ? 30 : 10}/>

    <div className={'d-flex'}>

      <AppButton
        onClick={onDeleteClick}
        title={'Clear'}
        icon={faTrashAlt}
        className={classes.SumModalButton}
        danger/>

      <Spacer width={10}/>

      <AppButton
        onClick={onSaveClick}
        title={'Done'}
        icon={faCheck}
        className={classes.SumModalButton}/>

    </div>

  </div>

}


