import {getEvent, lightUpdateEvent, updateEvent} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import useSWRMutation from "swr/mutation";
import { Event } from "../services/api/models";

export default function useEvent(eventId: string) {
  const {data, error, isLoading} = useSWR(['getEvent', eventId], getEvent)
  return {
    event: data,
    eventLoading: isLoading,
    eventError: error
  }
}

export const useUpdateEvent = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, Event>('updateEvent', updateEvent)
  return {
    updateEvent: trigger,
    updatingEvent: isMutating,
    error
  }
}

export const useLightUpdateEvent = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, Event>('updateEventLight', lightUpdateEvent)
  return {
    lightUpdateEvent: trigger,
    lightUpdatingEvent: isMutating,
    lightUpdateEventError: error
  }
}

export const refreshEvent = async (eventId: string, event?: Event) => {
  await mutate(['getEvent', eventId], event)
}
