import React, {useEffect, useMemo, useState} from 'react'
import {useOrganizers} from "../../hooks/useOrganizers"
import {refreshRedirectRules, useRedirectRules} from "../../hooks/useRedirectRules"
import useEvents from "../../hooks/useEvents"
import classes from "./RedirectRulesView.module.scss"
import {ViewHeader} from "../common/ViewHeader"
import {useTranslation} from "react-i18next"
import {AddButton, ModalProps, Spacer} from "../common"
import {Organizer, RedirectRule, WristbandBatch, Event} from "../../services/api/models"
import {useWristbandBatches} from "../../hooks/useWristbandBatches"
import {AppImage} from "../common/AppImage"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown, faChevronUp, faBoxOpen, faBriefcase} from "@fortawesome/free-solid-svg-icons"
import {faImage, faTrashAlt} from "@fortawesome/free-regular-svg-icons"
import {useKeyboardVisibility} from "../../hooks/useKeyboardVisibility"
import Modal from "../common/Modal"
import {SearchBox} from "../common/SearchBox"
import {numFormat} from "../../util/format_util"
import {capitalize} from "lodash"
import {createRedirectRule, deleteRedirectRule, updateRedirectRule} from "../../services/api/api"
import {showError, showInfo, showSuccess} from "../../util/toast_util"
import Spinner from "../common/Spinner"
import {ConfirmModal} from "../common/ConfirmModal"
import {AccountPlaceholderView} from "../account/AccountPlaceholderView"
import {isDesktop, isMobile} from "react-device-detect"
import {LongPress} from "../common/LongPress"
import {sameNonNull} from "../../util/string_util"

export const RedirectRulesView = () => {

  const {rules, rulesLoading} = useRedirectRules()
  const {t} = useTranslation()
  const [showAddRuleItem, setShowAddRuleItem] = useState(false)

  const sortedRules = useMemo(() => {
    return rules?.sort((r1, r2) => (r1.createdAt ?? '') > (r2.createdAt ?? '') ? 1 : -1)
  }, [rules])

  const onAddRuleClick = () => {
    setShowAddRuleItem(true)
  }

  return <div className={classes.RedirectRulesView}>
    <ViewHeader title={t('redirect_rules')}/>

    {sortedRules?.map(r => <RuleItem rule={r} key={r.id ?? ''}/>)}

    {(!rules || rules.length == 0) && !rulesLoading && !showAddRuleItem && <AccountPlaceholderView
      buttonTitle={t('add_my_first_rule')}
      onClick={onAddRuleClick}/>}

    {showAddRuleItem && <RuleItem
      key={'new'}
      onRuleCreated={() => setShowAddRuleItem(false)}
      onRuleCancelled={() => setShowAddRuleItem(false)}/>}

    {sortedRules && sortedRules.length > 0 && !showAddRuleItem &&
      <AddButton title={t('add_rule')} onClick={onAddRuleClick}/>}

    {isMobile && <Spacer height={20}/>}

  </div>
}

//
interface RuleItemProps {
  // if the rule is not presented
  // means that we are creating a new rule
  rule?: RedirectRule
  onRuleCreated?: VoidFunction
  onRuleCancelled?: VoidFunction
  key: string
}

const RuleItem = (props: RuleItemProps) => {
  const {rule, key, onRuleCreated, onRuleCancelled} = props
  const {organizers} = useOrganizers()
  const {events} = useEvents()
  const {batches} = useWristbandBatches()
  const {rules} = useRedirectRules()
  const {t} = useTranslation()
  const [showFromModal, setShowFromModal] = useState(false)
  const [showToModal, setShowToModal] = useState(false)
  const [fromEvent, setFromEvent] = useState<Event>()
  const [fromBatch, setFromBatch] = useState<WristbandBatch>()
  const [toEvent, setToEvent] = useState<Event>()
  const [toOrganizer, setToOrganizer] = useState<Organizer>()
  const [savingRule, setSavingRule] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    if (rule) {
      const {fromEventId, fromBatchCode, toOrganizerCode, toEventId} = rule
      setFromEvent(events?.find(e => fromEventId && e.id == fromEventId))
      setFromBatch(batches?.find(b => fromBatchCode && b.code == fromBatchCode))
      setToEvent(events?.find(e => toEventId && e.id == toEventId))
      setToOrganizer(organizers?.find(o => toOrganizerCode && o.code == toOrganizerCode))
    }
  }, [rule, batches, events, organizers]);

  useEffect(() => {
    if (showFromModal || showToModal || showDeleteModal) {
      setShowDeleteButton(false)
    }
  }, [showFromModal, showToModal, showDeleteModal]);

  const leftContent = () => {
    return <div className={classes.SideLeft} onClick={() => setShowFromModal(true)}>
      {fromEvent && <EventInfo
        event={fromEvent}/>}

      {fromBatch && <BatchInfo
        batch={fromBatch}
        organizer={organizers?.find(o => o.id == fromBatch?.organizerID)}/>}

      {!fromEvent && !fromBatch && <div className={classes.PlaceholderContainer}>
        <div className={classes.PlaceholderText}>{t('from')}</div>
      </div>}

    </div>
  }

  const rightContent = () => {
    return <div className={classes.SideRight} onClick={() => setShowToModal(true)}>
      {toEvent && <EventInfo event={toEvent}/>}
      {toOrganizer && <OrganizerInfo organizer={toOrganizer}/>}

      {!toEvent && !toOrganizer && <div className={classes.PlaceholderContainer}>
        <div className={classes.PlaceholderText}>{t('to')}</div>
      </div>}
    </div>
  }

  const onFromEventSelect = async (event: Event) => {
    setFromEvent(event)
    if (rule?.id) {
      await updateRule({
        ...rule,
        fromBatchCode: '',
        fromEventId: event.id
      })
    } else if (toEvent) {
      await createRule({
        fromEventId: event.id,
        toEventId: toEvent.id
      })
    } else if (toOrganizer) {
      showInfo(t('select_to_event_now'))
      setToOrganizer(undefined)
    }
  }

  const onFromBatchSelect = async (batch: WristbandBatch) => {
    setFromBatch(batch)
    if (rule?.id) {
      await updateRule({
        ...rule,
        fromBatchCode: batch.code,
        fromEventId: ''
      })
    } else if (toEvent) {
      await createRule({
        fromBatchCode: batch.code,
        toEventId: toEvent.id
      })
    } else if (toOrganizer) {
      await createRule({
        fromBatchCode: batch.code,
        toOrganizerCode: toOrganizer.code
      })
    }
  }

  const onToEventSelect = async (event: Event) => {
    setToEvent(event)
    if (rule?.id) {
      await updateRule({
        ...rule,
        toEventId: event.id,
        toOrganizerCode: ''
      })
    } else if (fromEvent) {
      await createRule({
        fromEventId: fromEvent.id,
        toEventId: event.id
      })
    } else if (fromBatch) {
      await createRule({
        fromBatchCode: fromBatch.code,
        toEventId: event.id
      })
    }
  }

  const onToOrganizerSelect = async (organizer: Organizer) => {
    setToOrganizer(organizer)
    if (rule?.id) {
      await updateRule({
        ...rule,
        toEventId: '',
        toOrganizerCode: organizer.code,
      })
    } else if (fromEvent) {
      showInfo(t('select_from_batch_now'))
      setFromEvent(undefined)
    } else if (fromBatch) {
      await createRule({
        fromBatchCode: fromBatch.code,
        toOrganizerCode: organizer.code
      })
    }
  }

  const isRuleAlreadyExists = (rule: RedirectRule) => {
    return rules?.find(r => {
      return (sameNonNull(r.fromEventId, rule.fromEventId) && sameNonNull(r.toEventId, rule.toEventId)) ||
        (sameNonNull(r.fromBatchCode, rule.fromBatchCode) && sameNonNull(r.toEventId, rule.toEventId)) ||
        (sameNonNull(r.fromBatchCode, rule.fromBatchCode) && sameNonNull(r.toOrganizerCode, rule.toOrganizerCode))
    })
  }

  const isRuleRedirectEventToItself = (rule: RedirectRule) => {
    return sameNonNull(rule.fromEventId, rule.toEventId)
  }

  const ruleWithThisBatchAlreadyExists = (rule: RedirectRule) => {
    return rules?.find(r => r.id != rule.id && sameNonNull(r.fromBatchCode, rule.fromBatchCode))
  }

  const isOppositeRuleExists = (rule: RedirectRule) => {
    return rules?.find(r => sameNonNull(r.toEventId, rule.fromEventId) && sameNonNull(r.fromEventId, rule.toEventId))
  }

  const updateRule = async (rule: RedirectRule) => {

    if (isRuleAlreadyExists(rule)) {
      showError(t('rule_already_exists'))
      refreshRedirectRules()
      return
    }

    if (isRuleRedirectEventToItself(rule)) {
      showError(t('rule_can_not_redirect_event_to_itself'))
      refreshRedirectRules()
      return
    }

    if (ruleWithThisBatchAlreadyExists(rule)) {
      showError(t('rule_with_this_batch_already_exists'))
      refreshRedirectRules()
      return
    }

    if (isOppositeRuleExists(rule)) {
      showError(t('opposite_rule_exists_message'))
      refreshRedirectRules()
      return
    }

    try {
      setSavingRule(true)
      await updateRedirectRule(rule)
      await refreshRedirectRules()
      showSuccess(t('rule_updated'))
    } catch (e) {
      showError(t('can_not_save_rule', {error: e}))
    } finally {
      setSavingRule(false)
    }
  }

  const createRule = async (rule: RedirectRule) => {

    if (isRuleAlreadyExists(rule)) {
      showError(t('rule_already_exists'))
      onRuleCancelled && onRuleCancelled()
      return
    }

    if (isRuleRedirectEventToItself(rule)) {
      showError(t('rule_can_not_redirect_event_to_itself'))
      onRuleCancelled && onRuleCancelled()
      return
    }

    if (ruleWithThisBatchAlreadyExists(rule)) {
      showError(t('rule_with_this_batch_already_exists'))
      onRuleCancelled && onRuleCancelled()
      return
    }

    if (isOppositeRuleExists(rule)) {
      showError(t('opposite_rule_exists_message'))
      onRuleCancelled && onRuleCancelled()
      return
    }

    try {
      setSavingRule(true)
      await createRedirectRule(rule)
      await refreshRedirectRules()
      showSuccess(t('rule_created'))
    } catch (e: any) {
      showError(t('can_not_create_rule', {error: e.toString()}))
    } finally {
      setSavingRule(false)
      onRuleCreated && onRuleCreated()
    }
  }

  const deleteRule = async (rule: RedirectRule) => {
    try {
      setSavingRule(true)
      await deleteRedirectRule(rule)
      await refreshRedirectRules()
      showSuccess(t('rule_deleted'))
    } catch (e: any) {
      showError(t('can_not_delete_rule', {error: e.toString()}))
    } finally {
      setSavingRule(false)
      onRuleCreated && onRuleCreated()
    }
  }

  const onConfirmDeleteClick = async () => {
    if (rule?.id) {
      await deleteRule(rule)
    } else {
      onRuleCancelled && onRuleCancelled()
    }
  }

  const onMouseEnter = () => {
    setShowDeleteButton(true)
  }

  const onMouseLeave = () => {
    setShowDeleteButton(false)
  }

  const mobileContent = () => {
    return <>

      {leftContent()}

      <div className={classes.Divider}></div>

      {rightContent()}
    </>
  }

  const desktopContent = () => {
    return <>

      {leftContent()}

      <div className={classes.Divider}></div>

      {rightContent()}
    </>
  }

  return <LongPress onLongPress={() => setShowDeleteModal(true)} isActive={isMobile}>
    <div
      className={classes.RuleItem}
      key={key}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>

      {savingRule && <div className={classes.SpinnerContainer}>
        <Spinner/>
      </div>}

      {!savingRule && <>

        {isDesktop ? desktopContent() : mobileContent()}

        {showFromModal && <FromToModal
          key={'from'}
          side={'from'}
          otherSideType={toEvent ? 'event' : (toOrganizer ? 'organizer' : undefined)}
          onFromEventSelect={onFromEventSelect}
          onFromBatchSelect={onFromBatchSelect}
          opened={showFromModal}
          onClose={() => setShowFromModal(false)}/>}

        {showToModal && <FromToModal
          key={'to'}
          side={'to'}
          otherSideType={fromEvent ? 'event' : (fromBatch ? 'batch' : undefined)}
          onToEventSelect={onToEventSelect}
          onToOrganizerSelect={onToOrganizerSelect}
          opened={showToModal}
          onClose={() => setShowToModal(false)}/>}

        {isDesktop && <div
          className={classes.DeleteButtonContainer}
          style={{opacity: showDeleteButton ? 1 : 0}}
          onClick={() => {
            if (rule?.id) {
              setShowDeleteModal(true)
              setShowDeleteButton(false)
            } else {
              onRuleCancelled && onRuleCancelled()
            }
          }}>
          <FontAwesomeIcon icon={faTrashAlt} className={classes.DeleteButton}/>
        </div>}

        <ConfirmModal
          title={t('are_you_sure')}
          onClose={() => setShowDeleteModal(false)}
          opened={showDeleteModal}
          onConfirm={onConfirmDeleteClick}
          confirmTitle={capitalize(t('delete'))}
          confirmIcon={faTrashAlt}
          dangerConfirm>
          <div className={classes.DeleteModalText}>{t('are_you_sure_to_delete_the_redirect_rule', {
            from: fromEvent?.name ?? fromBatch?.code,
            to: toEvent?.name ?? toOrganizer?.code,
          })}</div>
        </ConfirmModal>

      </>}

    </div>
  </LongPress>
}

//
interface FromToModalProps {
  key: string
  side: 'from' | 'to'
  // the type that is selected on the other side
  // we will show on this side the possible type
  // for example if from=event, we can show to=event only
  otherSideType: 'event' | 'batch' | 'organizer' | undefined
  onFromEventSelect?: (event: Event) => void
  onFromBatchSelect?: (batch: WristbandBatch) => void
  onToEventSelect?: (event: Event) => void
  onToOrganizerSelect?: (organizer: Organizer) => void
}

export const FromToModal: React.FC<FromToModalProps & ModalProps> = (props) => {
  const {
    opened,
    onClose,
    key,
    side,
    otherSideType,
    onFromEventSelect,
    onToEventSelect,
    onFromBatchSelect,
    onToOrganizerSelect
  } = props
  const {organizers} = useOrganizers()
  const {batches} = useWristbandBatches()
  const {events} = useEvents()
  const {rules} = useRedirectRules()
  const [query, setQuery] = useState<string>()
  const {keyboardVisible} = useKeyboardVisibility()
  const {t} = useTranslation()
  const [fromType, setFromType] = useState<'event' | 'batch'>(otherSideType == 'organizer' ? 'batch' : 'event')
  const [toType, setToType] = useState<'event' | 'organizer'>('event')
  const [showFromSelect, setShowFromSelect] = useState(false)
  const [showToSelect, setShowToSelect] = useState(false)

  const contains = (text?: string, search?: string) => {
    return text?.toLowerCase().includes(search?.toLowerCase() ?? '')
  }

  const filteredOrgs = useMemo(() => {
    return organizers?.filter(o => {
      return contains(o.name, query) || contains(o.code, query) || contains(o.description, query)
    })
  }, [organizers, query])

  const filteredEventsFrom = useMemo(() => {
    const myRules = rules ?? []
    return events?.filter(e =>
      contains(e.name, query) && !myRules.some(r => sameNonNull(r.fromEventId, e.id) || sameNonNull(r.toEventId, e.id)))
  }, [events, query, rules])

  const filteredEventsTo = useMemo(() => {
    const myRules = rules ?? []
    return events?.filter(e => contains(e.name, query) && !myRules.some(r => sameNonNull(r.fromEventId, e.id)))
  }, [events, query, rules])

  const filteredBatches = useMemo(() => {
    return batches?.filter(b => contains(b.code, query) && !(rules ?? []).some(r => sameNonNull(r.fromBatchCode, b.code)))
  }, [batches, query, rules])

  const controlItem = (title: string, isActive: boolean, onClick: VoidFunction) => {
    isActive = false;
    return <div
      className={isActive ? classes.ItemActive : classes.Item}
      onClick={onClick}>

      <div className={classes.Title}>{title}</div>

    </div>
  }

  const fromControl = () => {
    return <>
      <div
        className={classes.Selector}
        onClick={() => {
          if (otherSideType != 'organizer') {
            setShowFromSelect(!showFromSelect)
          }
        }}>
        {fromType == 'event' ? t('from_event') : t('from_batch')}

        {otherSideType != 'organizer' && <FontAwesomeIcon
          icon={showFromSelect ? faChevronUp : faChevronDown}
          className={classes.SelectorIcon}/>}

      </div>

      {showFromSelect && <div className={classes.Control}>
        {controlItem(t('from_event'), fromType == 'event', () => {
          setFromType('event')
          setShowFromSelect(false)
        })}

        {controlItem(t('from_batch'), fromType == 'batch', () => {
          setFromType('batch')
          setShowFromSelect(false)
        })}
      </div>}
    </>
  }

  const toControl = () => {
    return <>
      <div className={classes.Selector} onClick={() => {
        if (otherSideType != 'event') {
          setShowToSelect(!showToSelect)
        }
      }}>

        {toType == 'event' ? t('to_event') : t('to_organizer')}

        {otherSideType != 'event' && <FontAwesomeIcon
          icon={showToSelect ? faChevronUp : faChevronDown}
          className={classes.SelectorIcon}/>}

      </div>

      {showToSelect && <div className={classes.Control}>
        {controlItem(t('to_event'), toType == 'event', () => {
          setToType('event')
          setShowToSelect(false)
        })}
        {controlItem(t('to_organizer'), toType == 'organizer', () => {
          setToType('organizer')
          setShowToSelect(false)
        })}
      </div>}
    </>
  }

  const fromContent = () => {
    return <>

      {fromControl()}

      <Spacer height={10}/>

      <SearchBox onSearchChange={setQuery}/>

      <Spacer height={10}/>

      <div className={classes.ObjectsList} style={{maxHeight: keyboardVisible ? 200 : 400}}>
        {fromType == 'event' && filteredEventsFrom?.map(e => <div>
          <div
            className={classes.ItemCard}
            onClick={() => {
              onClose()
              onFromEventSelect && onFromEventSelect(e)
            }}>
            <EventInfo event={e} key={e.id}/>
          </div>
          <Spacer height={10}/>
        </div>)}

        {fromType == 'batch' && filteredBatches?.map(b => <div>
          <div
            className={classes.ItemCard}
            onClick={() => {
              onClose()
              onFromBatchSelect && onFromBatchSelect(b)
            }}>
            <BatchInfo batch={b} organizer={organizers?.find(o => o.id == b?.organizerID)} key={b.id}/>
          </div>
          <Spacer height={10}/>
        </div>)}
      </div>

    </>
  }

  const toContent = () => {
    return <>

      {toControl()}

      <Spacer height={10}/>

      <SearchBox onSearchChange={setQuery}/>

      <Spacer height={10}/>

      <div className={classes.ObjectsList} style={{maxHeight: keyboardVisible ? 200 : 400}}>
        {toType == 'event' && filteredEventsTo?.map(e => <div>
            <div
              className={classes.ItemCard}
              onClick={() => {
                onClose()
                onToEventSelect && onToEventSelect(e)
              }}>
              <EventInfo event={e} key={e.id}/>
            </div>
            <Spacer height={10}/>
          </div>
        )}
        {toType == 'organizer' && filteredOrgs?.map(o => <div>
          <div
            className={classes.ItemCard}
            onClick={() => {
              onClose()
              onToOrganizerSelect && onToOrganizerSelect(o)
            }}>
            <OrganizerInfo organizer={o} key={o.id}/>
          </div>
          <Spacer height={10}/>
        </div>)}
      </div>

    </>
  }

  return <Modal
    opened={opened}
    onClose={onClose}
    title={side == 'from' ? t('select_from') : t('select_to')}>
    <div className={classes.CreateRuleModal} key={key}>

      {side == 'from' && fromContent()}

      {side == 'to' && toContent()}

    </div>
  </Modal>

}

//

interface BatchInfoProps {
  batch?: WristbandBatch
  organizer?: Organizer
}

const BatchInfo = (props: BatchInfoProps) => {
  const {batch, organizer} = props
  return <div className={classes.Info}>

    <div className={classes.ImageContainer}>
      <div className={classes.ImagePlaceholder}>
        <FontAwesomeIcon icon={faBoxOpen} className={classes.ImageIcon}/>
      </div>
    </div>

    <div className={classes.BatchInfoContent}>
      <div className={classes.Title}>{batch?.code}</div>
      <div className={classes.Subtitle}>{numFormat(batch?.amount ?? 0)}</div>
      <div className={classes.Subtitle}>{organizer?.name}</div>
    </div>

  </div>
}

//

interface EventInfoProps {
  event?: Event
  onClick?: VoidFunction
}

const EventInfo = (props: EventInfoProps) => {
  const {event, onClick} = props
  const {imageUrl, imageStorageKey} = event ?? {}
  return <div className={classes.Info} onClick={onClick}>
    <div className={classes.ImageContainer}>
      {(imageUrl || imageStorageKey)
        ? <AppImage src={imageUrl} storageKey={imageStorageKey} className={classes.InfoImage}/>
        : <div className={classes.ImagePlaceholderEvent}>
          <FontAwesomeIcon icon={faImage}/>
        </div>}
    </div>
    <div className={classes.Title}>{event?.name}</div>
  </div>

}

//

interface OrganizerInfoProps {
  organizer?: Organizer
}

const OrganizerInfo = (props: OrganizerInfoProps) => {
  const {name, description, code} = props.organizer ?? {}
  return <div className={classes.Info}>

    <div className={classes.ImageContainer}>
      <div className={classes.ImagePlaceholder}>
        <FontAwesomeIcon icon={faBriefcase} className={classes.ImageIcon}/>
      </div>
    </div>

    <div className={classes.BatchInfoContent}>
      <div className={classes.Title}>{name}</div>
      {description && <div className={classes.Title}>{description}</div>}
      <div className={classes.Subtitle}>{code}</div>
    </div>

  </div>
}
