import React from 'react'
import classes from './AdminMenuView.module.scss'
import {Link, useLocation} from "react-router-dom";
import {MenuItem} from "../common/MenuItem";
import {
  faCircleUser,
  faGear,
  faTicket,
  faMoneyCheckDollar,
  faKey,
  faCreditCard,
  faHeartPulse,
  faDiamondTurnRight,
  faRing,
  faBoxOpen,
  faBuilding,
  faRoad,
  faBriefcase
} from "@fortawesome/free-solid-svg-icons";
import {ViewHeader} from "../common/ViewHeader";
import {isMobile} from "react-device-detect";
import {ReactComponent as WristbandIconActive} from "../../images/wristband_new_thin_active.svg";
import {getIdToken} from "../../services/api/api";
import {showSuccess} from "../../util/toast_util";
import { useTranslation } from 'react-i18next';

const AdminMenuView: React.FC = () => {

  const {pathname} = useLocation()
  const {t} = useTranslation()

  const onTokenClick = async () => {
    const token = await getIdToken()
    await navigator.clipboard.writeText(token);
    showSuccess('Token copied to clipboard')
  }

  return <div className={classes.AdminMenuView}>

    <ViewHeader title={'Admin'} showBackButton={isMobile}/>

    <div className={classes.MenuItems}>

      <Link to={'invites'}>
        <MenuItem
          selected={pathname == '/admin/invites'}
          title={'Invites'}
          faIcon={faTicket}/>
      </Link>

      <Link to={'system'}>
        <MenuItem
          selected={pathname == '/admin/system'}
          title={'System settings'}
          faIcon={faGear}/>
      </Link>

      <Link to={'transactions'}>
        <MenuItem
          selected={pathname == '/admin/transactions'}
          title={'Transactions'}
          faIcon={faMoneyCheckDollar}/>
      </Link>

      <Link to={'wristbands'}>
        <MenuItem
          selected={pathname == '/admin/wristbands'}
          title={'Wristbands'}
          faIcon={faRing}
          iconActive={WristbandIconActive}/>
      </Link>

      <Link to={'users'}>
        <MenuItem
          selected={pathname == '/admin/users'}
          title={'Users'}
          faIcon={faCircleUser}/>
      </Link>

      <Link to={'adyen'}>
        <MenuItem
          selected={pathname == '/admin/adyen'}
          title={'Adyen'}
          faIcon={faCreditCard}/>
      </Link>

      <Link to={'health'}>
        <MenuItem
          selected={pathname == '/admin/health'}
          title={t('system_health')}
          faIcon={faHeartPulse}/>
      </Link>

      <Link to={'redirect-rules'}>
        <MenuItem
          selected={pathname == '/admin/redirect-rules'}
          title={t('redirect_rules')}
          faIcon={faDiamondTurnRight}/>
      </Link>

      <Link to={'organizers'}>
        <MenuItem
          selected={pathname == '/admin/organizers'}
          title={t('organizers')}
          faIcon={faBriefcase}/>
      </Link>

      <Link to={'integrators'}>
        <MenuItem
          selected={pathname == '/admin/integrators'}
          title={t('integrators')}
          faIcon={faBuilding}/>
      </Link>

      <Link to={'batches'}>
        <MenuItem
          selected={pathname == '/admin/batches'}
          title={t('wristband_batches')}
          faIcon={faBoxOpen}/>
      </Link>

      <Link to={'roadmap'}>
        <MenuItem
          selected={pathname == '/admin/roadmap'}
          title={t('roadmap')}
          faIcon={faRoad}/>
      </Link>

      <MenuItem
        onClick={onTokenClick}
        title={t('token')}
        faIcon={faKey}
        noBorder/>

    </div>
  </div>

}

export default AdminMenuView
