import React, {useCallback, useEffect, useMemo, useState} from 'react'
import classes from './EventEditView.module.scss'
import './EventEditView.scss'
import {AddButton, AppButton, AppDateInput, AppInput, Spacer} from "../common"
import {Link, useNavigate, useParams} from "react-router-dom"
import useEvent, {refreshEvent, useUpdateEvent, useLightUpdateEvent} from "../../hooks/useEvent"
import {formatDate} from "../../util/date_util"
import {showError, showSuccess} from "../../util/toast_util"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faCancel,
  faCheck,
  faSquareCheck,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons"
import {faSquare} from "@fortawesome/free-regular-svg-icons"
import {isMobile} from "react-device-detect"
import {Location, RegistrationType, Event, GalleryItem, CardFundingType, Organizer} from "../../services/api/models"
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons"
import {LocationSelector} from "../common/LocationSelector"
import {addOrReplaceAtIndex, removeElementAt} from "../../util/array_util"
import {differentLocations} from "../../util/location_util"
import {toggleValueInArray} from "../../util/string_util"
import classnames from "classnames"
import {useDropzone} from "react-dropzone"
import {getGoogleMapStaticImage, uploadFile} from "../../services/api/api"
import SpinnerSmall from "../common/SpinnerSmall"
import {GalleryView} from "./GalleryView"
import {
  isFacebookUrl,
  isImageFile,
  isImageUrl,
  isInstagramUrl,
  isTwitterUrl,
  isUrlValid,
} from "../../util/validation_util"
import Modal from "../common/Modal"
import Spinner from "../common/Spinner"
import useUser from "../../hooks/useUser"
import QuillEditor from "react-quill"
import "react-quill/dist/quill.snow.css"
import imageCompression from 'browser-image-compression';
import {maxGalleryImageSizeMb} from "../../config/constants";
import {useOrganizers} from "../../hooks/useOrganizers";
import {useOrganizer} from "../../hooks/useOrganizer";
import {SelectOrganizerModal} from "../common/SelectOrganizerModal";
import {useTranslation} from "react-i18next";
import {AppImage} from "../common/AppImage";

export function EventEditView() {

  const {eventId} = useParams() as any
  const {isClientAdmin, isBillfoldAdmin, isAdminLoading} = useUser()
  const {event, eventLoading} = useEvent(eventId)
  const navigate = useNavigate()
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [name, setName] = useState<string>()
  const [imageUrl, setImageUrl] = useState<string>()
  const [imageStorageKey, setImageStorageKey] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [facebookUrl, setFacebookUrl] = useState<string>()
  const [instagramUrl, setInstagramUrl] = useState<string>()
  const [twitterUrl, setTwitterUrl] = useState<string>()
  const [facebookUrlError, setFacebookUrlError] = useState(false)
  const [twitterUrlError, setTwitterUrlError] = useState(false)
  const [instagramUrlError, setInstagramUrlError] = useState(false)
  const [locations, setLocations] = useState<Location[]>()
  const [gallery, setGallery] = useState<GalleryItem[]>()
  const [locationErrors, setLocationErrors] = useState<Record<number, boolean>>({})
  const [startDate, setStartDate] = useState<Date>()
  const [finishDate, setFinishDate] = useState<Date>()
  const {updateEvent, updatingEvent} = useUpdateEvent()
  const {lightUpdateEvent} = useLightUpdateEvent()
  const [registrationTypes, setRegistrationTypes] = useState<RegistrationType[] | undefined>([])
  const [cardFundingTypes, setCardFundingTypes] = useState<CardFundingType[] | undefined>([])
  const [updatingRegistrationType, setUpdatingRegistrationType] = useState(false)
  const [updatingCardFundingTypes, setUpdatingCardFundingTypes] = useState(false)
  const [updatingShowToUsers, setUpdatingShowToUsers] = useState(false)
  const [uploadingImages, setUploadingImages] = useState(false)

  useEffect(() => {
    if (event) {
      setName(event.name ?? '')
      setImageUrl(event.imageUrl ?? '')
      setImageStorageKey(event.imageStorageKey ?? '')
      setDescription(event.description ?? '')
      setLocations(event.locations)
      setGallery(event.gallery)
      setRegistrationTypes(event.registrationTypes)
      setFacebookUrl(event.facebookUrl)
      setInstagramUrl(event.instagramUrl)
      setTwitterUrl(event.twitterUrl)
      setCardFundingTypes(event.allowedCardFundingTypes)
      if (event.startDate) {
        setStartDate(new Date(Date.parse(event.startDate)))
      }
      if (event.finishDate) {
        setFinishDate(new Date(Date.parse(event.finishDate)))
      }
      refreshShowSaveButton()
    }
  }, [event])

  useEffect(() => {
    refreshShowSaveButton()
  }, [name, imageUrl, imageStorageKey, description, startDate, finishDate, locations, twitterUrl, facebookUrl, instagramUrl])

  useEffect(() => {
    setFacebookUrlError(false)
  }, [facebookUrl])

  useEffect(() => {
    setTwitterUrlError(false)
  }, [twitterUrl])

  useEffect(() => {
    setInstagramUrlError(false)
  }, [instagramUrl])

  useEffect(() => {
    if (isAdminLoading) {
      return
    }

    if (!isClientAdmin && !isBillfoldAdmin) {
      navigate('/')
    }
  }, [isClientAdmin, isBillfoldAdmin])

  const preUploadMapsImages = async (locations: Location[]): Promise<Location[]> => {
    for (const l of locations) {
      if (!l.id) {
        const file = await getGoogleMapStaticImage(l.address, l.latitude, l.longitude)
        l.mapImageStorageKey = await uploadFile(eventId, file)
      }
    }
    return locations
  }

  const onSave = async () => {

    if (facebookUrl && !isFacebookUrl(facebookUrl)) {
      setFacebookUrlError(true)
      showError('Facebook url is not valid')
      return
    }

    if (twitterUrl && !isTwitterUrl(twitterUrl)) {
      setTwitterUrlError(true)
      showError('Twitter url is not valid')
      return
    }

    if (instagramUrl && !isInstagramUrl(instagramUrl)) {
      setInstagramUrlError(true)
      showError('Instagram url is not valid')
      return
    }

    try {

      let hasWrongLocation = false
      locations?.forEach((l, i) => {

        if (!l.city) {
          showError('Selected location has no city defined')
        }

        if (!l.country) {
          showError('Selected location has no country defined')
        }

        if (!l.latitude) {
          showError('Selected location has no latitude defined')
        }

        if (!l.longitude) {
          showError('Selected location has no longitude defined')
        }

        if (!l.city || !l.country || !l.latitude || !l.longitude || !l.address) {
          hasWrongLocation = true
          setLocationErrors({
            ...locationErrors,
            [i]: true
          })
        }
      })

      if (hasWrongLocation) {
        return
      }

      // todo here we need to upload google maps images

      setUploadingImages(true)
      const locationsWithUpdatedImages = await preUploadMapsImages(locations ?? [])

      const updatedEvent = await updateEvent({
        ...event,
        name,
        imageUrl,
        imageStorageKey,
        // description: editor?.getContent() ?? '',
        description: description,
        locations,
        gallery,
        registrationTypes,
        twitterUrl,
        facebookUrl,
        instagramUrl,
        startDate: formatDate(startDate),
        finishDate: formatDate(finishDate)
      })
      showSuccess('Event updated')
      setShowSaveButton(false)
      refreshEvent(eventId, updatedEvent)
    } catch (e) {
      console.log(`onSave - error: ${e}`)
      showError(e)
    } finally {
      setUploadingImages(false)
    }
  }

  const onBackClick = () => {
    navigate(-1)
  }

  const onLocationDeleteClick = (index: number) => {
    if (!locations) {
      return
    }
    setLocations(removeElementAt(locations, index))
  }

  const onAddLocationClick = () => {
    setLocations([
      ...locations ?? [],
      {
        address: '',
        city: '',
        country: '',
        latitude: 0,
        longitude: 0
      }
    ])
  }

  const onLocationSelected = (location: Location, index: number) => {
    setLocationErrors({
      ...locationErrors,
      [index]: false
    })
    setLocations(addOrReplaceAtIndex(locations ?? [], index, location))
  }

  const onRegistrationTypeClick = async (type: RegistrationType) => {

    if (!event) {
      return
    }

    const newTypes = toggleValueInArray(registrationTypes, type)

    const newRegistrationTypes = toggleValueInArray(registrationTypes, type)
    if (newRegistrationTypes?.length == 0) {
      showError('At least one type must be selected')
      return
    }

    setRegistrationTypes(newRegistrationTypes)

    try {
      setUpdatingRegistrationType(true)
      const updatedEvent = await updateEvent({
        ...event,
        registrationTypes: newRegistrationTypes,
      })
      showSuccess('Registration type updated')
      await refreshEvent(eventId, updatedEvent)
    } catch (e) {
      console.log(`onSave - error: ${e}`)
      showError(e)
    } finally {
      setUpdatingRegistrationType(false)
    }
  }

  const onCardFundingTypeClick = async (type: CardFundingType) => {

    try {

      let newTypes
      if (cardFundingTypes?.includes(type)) {
        newTypes = cardFundingTypes.filter(t => t != type)
      } else {
        newTypes = [...(cardFundingTypes ?? []), type]
      }
      setCardFundingTypes(newTypes)

      setUpdatingCardFundingTypes(true)
      const updatedEvent = await lightUpdateEvent({
        ...event,
        allowedCardFundingTypes: newTypes,
      })
      showSuccess('Card funding type updated')
      await refreshEvent(eventId, updatedEvent)
    } catch (e) {
      console.log(`onCardFundingTypeClick - error: ${e}`)
      showError(e)
    } finally {
      setUpdatingCardFundingTypes(false)
    }
  }

  const onShowToUsersClick = async () => {

    if (!event) {
      return
    }

    const newShowToUsers = !event.showToUsers

    try {
      setUpdatingShowToUsers(true)
      const updatedEvent = await updateEvent({
        ...event,
        showToUsers: newShowToUsers,
      })
      await refreshEvent(eventId, updatedEvent)
      showSuccess('Show to users updated')
    } catch (e) {
      console.log(`onShowToUsersClick - error: ${e}`)
      showError(e)
    } finally {
      setUpdatingShowToUsers(false)
    }
  }

  const editorView = () => {


    return <QuillEditor
      className={classes.QuillEditor}
      theme="snow"
      value={description?.replaceAll('\\n', '\n')}
      modules={{
        toolbar: {
          container: [
            [{header: [2, false]}],
            [
              "bold",
              "italic",
              "underline",
              {list: "ordered"},
              {list: "bullet"},
              "link",
              "clean"
            ],
          ],
        },
        clipboard: {
          matchVisual: true,
        },
      }}
      onChange={v => {
        console.log(`onChange - v: `, v)
        setDescription(v)

      }}
    />
  }

  const registrationTypesControl = () => {
    const wristbandsAllowed = registrationTypes?.includes('wristband')
    const wristbandsOnly = wristbandsAllowed && registrationTypes?.length == 1
    const qrsAllowed = registrationTypes?.includes('qr')
    const qrsOnly = qrsAllowed && registrationTypes?.length == 1

    return <div
      className={updatingRegistrationType ? classes.RegistrationTypesControlDisabled : classes.RegistrationTypesControl}>
      <div
        className={classnames(
          classes.Item,
          {
            [classes.ItemActive]: wristbandsAllowed,
            [classes.ItemDisabled]: wristbandsOnly,
          }
        )}
        onClick={() => onRegistrationTypeClick('wristband')}>
        <div className={classes.Title}>Wristband registration</div>
        <FontAwesomeIcon
          icon={wristbandsAllowed ? faSquareCheck : faSquare}
          className={wristbandsAllowed ? classes.CheckboxIconActive : classes.CheckboxIcon}/>
      </div>
      <div className={classes.Divider}/>
      <div
        className={classnames(
          classes.Item,
          {
            [classes.ItemActive]: qrsAllowed,
            [classes.ItemDisabled]: qrsOnly,
          }
        )}
        onClick={() => onRegistrationTypeClick('qr')}>
        <div className={classes.Title}>QR registration</div>
        <FontAwesomeIcon
          icon={qrsAllowed ? faSquareCheck : faSquare}
          className={qrsAllowed ? classes.CheckboxIconActive : classes.CheckboxIcon}/>
      </div>
    </div>
  }

  const cardFundingTypesControl = () => {
    const creditAllowed = cardFundingTypes?.includes('credit')
    const debitAllowed = cardFundingTypes?.includes('debit')

    return <div
      className={updatingCardFundingTypes
        ? classes.RegistrationTypesControlDisabled
        : classes.RegistrationTypesControl}>

      <div
        className={classnames(
          classes.Item,
          {[classes.ItemActive]: creditAllowed}
        )}
        onClick={() => onCardFundingTypeClick('credit')}>
        <div className={classes.Title}>Credit</div>
        <FontAwesomeIcon
          icon={creditAllowed ? faSquareCheck : faSquare}
          className={creditAllowed ? classes.CheckboxIconActive : classes.CheckboxIcon}/>
      </div>

      <div className={classes.Divider}/>
      <div
        className={classnames(
          classes.Item,
          {
            [classes.ItemActive]: debitAllowed,
          }
        )}
        onClick={() => onCardFundingTypeClick('debit')}>
        <div className={classes.Title}>Debit</div>
        <FontAwesomeIcon
          icon={debitAllowed ? faSquareCheck : faSquare}
          className={debitAllowed ? classes.CheckboxIconActive : classes.CheckboxIcon}/>
      </div>
    </div>
  }

  const showToUsersControl = () => {
    return <div
      className={updatingShowToUsers ? classes.RegistrationTypesControlDisabled : classes.RegistrationTypesControl}>
      <div
        onClick={onShowToUsersClick}
        className={classnames(
          classes.Item,
          {[classes.ItemActive]: event?.showToUsers}
        )}>
        <div className={classes.Title}>Show event to users</div>
        {updatingShowToUsers ? <SpinnerSmall/> : <FontAwesomeIcon
          icon={event?.showToUsers ? faSquareCheck : faSquare}
          className={event?.showToUsers ? classes.CheckboxIconActive : classes.CheckboxIcon}/>}
      </div>
    </div>
  }

  const refreshShowSaveButton = () => {

    if (!event) {
      setShowSaveButton(false)
      return
    }

    const oldDescription = (event.description ?? '').replaceAll('\\n', '\n')

    setShowSaveButton(
      name != event.name
      || (imageUrl ?? '') != (event.imageUrl ?? '')
      || description != oldDescription
      || twitterUrl != event.twitterUrl
      || facebookUrl != event.facebookUrl
      || instagramUrl != event.instagramUrl
      || formatDate(startDate) != event.startDate
      || formatDate(finishDate) != event.finishDate
      || differentLocations(locations, event.locations))
  }

  return <div className={classes.EventEditView}>
    <div className={classes.Header}>
      <FontAwesomeIcon icon={faArrowLeft} onClick={onBackClick} className={classes.BackButton}/>

      <h1>{event?.name ?? 'Edit event'}</h1>
    </div>

    <Spacer height={10}/>

    <MainImageView event={event}/>

    <Spacer height={4}/>

    <div className={classes.Hint}>
      Use images 2000x1000px, check <Link to={'/event-image-guideline'} className={classes.Link}>UI guideline</Link>
    </div>

    <Spacer height={20}/>

    {event && <GalleryView event={event}/>}

    <Spacer height={10}/>

    {editorView()}

    <SectionHeader title={'Dates'}/>

    <div className={classes.DatesWrapper}>

      <div className={classes.DateInput}>
        <AppDateInput
          initialDate={startDate}
          placeholder={'Start date'}
          onChange={setStartDate}
          loading={eventLoading}
          allowFuture
        />
      </div>

      <Spacer width={10}/>

      <div className={classes.DateInput}>
        <AppDateInput
          initialDate={finishDate}
          placeholder={'Finish date'}
          onChange={setFinishDate}
          loading={eventLoading}
          allowFuture
        />
      </div>
    </div>

    <SectionHeader title={'Locations'}/>

    {locations?.map((l, i) => <div key={l.address}>
      <LocationSelector
        key={l.address}
        placeholder={`Location #${i + 1}`}
        initialLocation={l}
        error={locationErrors[i]}
        onChange={(l) => onLocationSelected(l, i)}
        hoverTrailing={<div
          className={classes.LocationDeleteButton}
          onClick={() => onLocationDeleteClick(i)}>
          <FontAwesomeIcon icon={faTrashAlt}/>
        </div>}/>
      {i != locations.length - 1 && <Spacer height={10}/>}
    </div>)}

    <Spacer height={10}/>

    <AddButton title={'Add location'} onClick={onAddLocationClick}/>

    <SectionHeader title={'Social links'}/>

    <AppInput
      key={'facebook'}
      initialValue={facebookUrl}
      placeholder={'Facebook'}
      onChange={setFacebookUrl}
      loading={eventLoading}
      error={facebookUrlError}
    />

    <Spacer height={10}/>

    <AppInput
      initialValue={instagramUrl}
      placeholder={'Instagram'}
      onChange={setInstagramUrl}
      loading={eventLoading}
      error={instagramUrlError}
    />

    <Spacer height={10}/>

    <AppInput
      initialValue={twitterUrl}
      placeholder={'Twitter'}
      onChange={setTwitterUrl}
      loading={eventLoading}
      error={twitterUrlError}
    />

    <SectionHeader title={'Registration types'}/>

    {registrationTypesControl()}

    <SectionHeader title={'Card funding types'}/>

    {cardFundingTypesControl()}

    <SectionHeader title={'Advanced'}/>

    <AppInput
      initialValue={event?.platformAccountBillfoldId}
      placeholder={'Platform account'}
      disabled
    />

    <Spacer height={10}/>

    {event && <OrganizerControl event={event}/>}

    {/*<AppInput*/}
    {/*  initialValue={event?.organizer ?? '...'}*/}
    {/*  placeholder={'Organizer'}*/}
    {/*  disabled*/}
    {/*/>*/}

    <Spacer height={10}/>

    {showToUsersControl()}

    <Spacer height={30}/>

    {showSaveButton && <div className={classes.SaveButtonContainer}>
      <AppButton
        title={'Save'}
        icon={faCheck}
        onClick={onSave}
        progress={updatingEvent || uploadingImages}
        className={isMobile ? 'w-100' : ''}
      />
    </div>}
  </div>
}

interface MainImageViewProps {
  event?: Event | null
}

export const MainImageView = (props: MainImageViewProps) => {

  const {event} = props
  const {imageUrl, imageStorageKey} = event ?? {}
  const {updateEvent, updatingEvent} = useUpdateEvent()
  const [uploading, setUploading] = useState(false)
  const [showUrlModal, setShowUrlModal] = useState(false)
  const [url, setUrl] = useState('')
  const [urlError, setUrlError] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const onDrop = useCallback(async (files: File[]) => {
    if (files.length == 0 || files.length > 1) {
      console.log(`onDrop - only one file is accepted`)
      showError('Only one file is accepted')
      return
    }

    if (!isImageFile(files[0].name)) {
      showError('Only image files are accepted for the main image')
      return
    }

    try {
      setUploading(true)

      let file = files[0]
      // let fileUrl = ''
      let fileStorageKey = ''
      if (isImageFile(file.name)) {
        const compressedFile = await imageCompression(file, {maxSizeMB: maxGalleryImageSizeMb})
        fileStorageKey = await uploadFile(event?.id ?? '', compressedFile)
      } else {
        fileStorageKey = await uploadFile(event?.id ?? '', file)
      }

      const updatedEvent: Event = {
        ...event,
        imageStorageKey: fileStorageKey,
      }

      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      console.log(`onDrop - error: ${e}`)
      showError('Error uploading file')
    } finally {
      setUploading(false)
    }
  }, [event])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const onSetUrlClickInner = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setShowUrlModal(true)
  }

  const emptyContent = () => {
    return <div
      {...!showUrlModal && getRootProps()}
      className={isDragActive ? classes.MainImageEmptyActive : classes.MainImageEmpty}>

      {!showUrlModal && <input {...getInputProps()} />}

      {!uploading && !updatingEvent && <div className={classes.Title}>
        <div>Drop file here</div>
        <div>or <a onClick={onSetUrlClickInner}>set the url</a></div>
      </div>}

      {(uploading || updatingEvent) && <SpinnerSmall/>}

      {showUrlModal && <Modal
        title={'Set the url'}
        opened={showUrlModal}
        onClose={() => setShowUrlModal(false)}>

        <AppInput
          placeholder={'Url'}
          onChange={v => {
            setUrlError(false)
            setUrl(v)
          }}
          error={urlError}
        />

        <Spacer height={10}/>

        <AppButton
          title={'Set url'}
          onClick={onSaveUrlPressed}
          className={classes.SaveUrlButton}
          progress={updatingEvent}
        />

      </Modal>}

    </div>
  }

  const fullContent = () => {

    if (!imageUrl && !imageStorageKey) {
      return <div/>
    }

    return <div className={classes.MainImageFull}>

      {(imageUrl || imageStorageKey) && <div className={classes.Image}>
        <AppImage src={imageUrl} storageKey={imageStorageKey} className={classes.Image}/>
      </div>}

      <div className={classes.DeleteButton} onClick={() => setShowDeleteConfirmation(true)}>
        <FontAwesomeIcon icon={faTrashAlt}/>
      </div>

      {showDeleteConfirmation && !updatingEvent && <div className={classes.DeleteConfirmationModal}>
        <div>Delete main image?</div>

        <Spacer height={20}/>

        <AppButton
          title={'Yes'}
          onClick={onDeleteClick}
          icon={faCheck}/>

        <Spacer height={10}/>

        <AppButton
          title={'No'}
          onClick={() => setShowDeleteConfirmation(false)}
          icon={faCancel}
          secondary/>

      </div>}

      {updatingEvent && <div className={classes.DeletingCover}>
        <Spinner/>
      </div>}

    </div>
  }

  const onSaveUrlPressed = async (e: any) => {

    if (!url) {
      showError('Please enter an url')
      setUrlError(true)
      return
    }

    if (!isUrlValid(url)) {
      showError('Please enter a valid url')
      setUrlError(true)
      return
    }

    if (!isImageUrl(url)) {
      showError('Only images are supported')
      setUrlError(true)
      return
    }

    try {

      const updatedEvent = {
        ...event,
        imageUrl: url,
      }

      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      console.log(`onDrop - error: ${e}`)
      showError('Error uploading file')
    }
  }

  const onDeleteClick = async () => {

    try {

      setShowDeleteConfirmation(false)

      const updatedEvent = {
        ...event,
        imageUrl: '',
        imageStorageKey: ''
      }

      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      showError('Error deleting gallery item')
    }

  }

  return <>
    {(imageUrl || imageStorageKey) ? fullContent() : emptyContent()}
  </>
}

// ---
interface SectionHeaderProps {
  title: string
}

const SectionHeader = (props: SectionHeaderProps) => {
  return <div className={classes.SectionHeader}>{props.title}</div>
}

// ---

interface OrganizerControlProps {
  event: Event
}

const OrganizerControl = (props: OrganizerControlProps) => {
  const {event} = props
  const {organizer, organizerLoading} = useOrganizer(event.organizerID ?? '')
  const [showModal, setShowModal] = useState(false)
  const {updateEvent, updatingEvent} = useUpdateEvent()
  const {t} = useTranslation()

  const onSelected = async (o: Organizer) => {
    try {
      const updatedEvent = {
        ...event,
        organizerID: o.id,
      }
      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)

    } catch (e) {
      console.log(`onSelected - error: ${e}`)
      showError('Error updating event')
    }
  }

  return <>
    <div className={updatingEvent ? classes.OrganizerControlLoading : classes.OrganizerControl}>
      <div>
        <div className={classes.OrganizerHeader}>{t('organizer')}</div>
        <div className={classes.Title}>{organizer?.name ?? '...'}</div>
      </div>
      {/*<Spacer/>*/}
      {/*{updatingEvent ? <Spinner secondary/> : <FontAwesomeIcon icon={faChevronDown} className={classes.ArrowIcon}/>}*/}
    </div>

    <SelectOrganizerModal
      onSelected={onSelected}
      opened={showModal}
      onClose={()=>setShowModal(false)}/>
  </>
}
