import {findUsers, deleteUserData} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import useSWRMutation from "swr/mutation";

export const useUsers = (email: string) => {
  const {data, error, isLoading} = useSWR(['findUsers', email], findUsersFetcher)

  return {
    users: data,
    usersLoading: isLoading,
    userLoadingError: error,
  }
}

export const useDeleteUserData = () => {
  const {trigger, isMutating, error} = useSWRMutation<boolean, string, string, {email: string}>('deleteUserData', deleteUserMutator)
  return {
    deleteUser: trigger,
    deletingUser: isMutating,
    deleteUserError: error
  }
}

export const refreshUsers = async (email: string) => {
  await mutate(['findUsers', email])
}

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const findUsersFetcher = async (params: any) => {
  const [,email] = params
  return await findUsers(email)
}

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const deleteUserMutator = async (url: string, params: { arg: {email: string} }) => {
  return await deleteUserData(params.arg.email)
}


