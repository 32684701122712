import React, { useEffect, useRef, useState } from 'react'
import classes from './DateControl.module.scss'
import { isDesktop, isMobile } from "react-device-detect";
import { DateFilterButton } from "./DateFilterButton";
import { FilterButton } from "./FilterButton";
import {
  minusDay,
  minusMonth,
  minusWeek,
  minusYear,
  periodTitle,
  plusDay, plusMonth, plusWeek, plusYear, rangeTypeFromDates
} from "../../util/date_util";
import { PeriodModalMobile, PeriodOverlay, RangeType } from "../account/PeriodModal";

interface DateControlProps {
  dateFrom?: Date
  dateTo?: Date
  onDatesChanged: (dateFrom: Date | undefined, dateTo: Date | undefined) => void
}

export const DateControl: React.FC<DateControlProps> = (props) => {
  const {dateFrom, dateTo, onDatesChanged} = props
  const [dateModalOpened, setDateModalOpened] = useState(false)
  const [targetDiv, setTargetDiv] = useState(null)
  const target = useRef(null)
  const [dateFromInner, setDateFromInner] = useState<Date>()
  const [dateToInner, setDateToInner] = useState<Date>()
  const [rangeType, setRangeType] = useState<RangeType>()

  useEffect(() => {

    if (dateFrom) {
      setDateFromInner(new Date(dateFrom))
    }

    if (dateTo) {
      setDateToInner(new Date(dateTo))
    }

    setRangeType(rangeTypeFromDates(dateFrom, dateTo))

  }, [dateFrom, dateTo])

  useEffect(() => {
    console.log(`onDatesChanged - from: ${dateFromInner}, to: ${dateToInner}`)
    onDatesChanged(dateFromInner, dateToInner)
  }, [dateFromInner, dateToInner])

  const onDateClick = (e: any) => {
    setDateModalOpened(!dateModalOpened)
    setTargetDiv(e.target)
  }

  const onDateSave = (type: RangeType | undefined, from: Date | undefined, to: Date | undefined) => {
    setDateFromInner(from)
    setDateToInner(to)
    setRangeType(type)
    setDateModalOpened(false)
  }

  const onDateDelete = () => {
    setDateFromInner(undefined)
    setDateToInner(undefined)
    setRangeType(undefined)
  }

  const onDateLeftClick = () => {
    setDateModalOpened(false)
    switch (rangeType) {
      case RangeType.today:
        setDateFromInner(minusDay(dateFromInner))
        setDateToInner(minusDay(dateToInner))
        break
      case RangeType.yesterday:
        setDateFromInner(minusDay(dateFromInner))
        setDateToInner(minusDay(dateToInner))
        break
      case RangeType.week:
        setDateFromInner(minusWeek(dateFromInner))
        setDateToInner(minusWeek(dateToInner))
        break
      case RangeType.month:
        setDateFromInner(minusMonth(dateFromInner))
        setDateToInner(minusMonth(dateToInner))
        break
      case RangeType.year:
        setDateFromInner(minusYear(dateFromInner))
        setDateToInner(minusYear(dateToInner))
        break
      case RangeType.range:
      default:
        // do nothing
        break

    }
  }

  const onDateRightClick = () => {
    setDateModalOpened(false)
    switch (rangeType) {
      case RangeType.today:
        setDateFromInner(plusDay(dateFromInner))
        setDateToInner(plusDay(dateToInner))
        break
      case RangeType.yesterday:
        setDateFromInner(plusDay(dateFromInner))
        setDateToInner(plusDay(dateToInner))
        break
      case RangeType.week:
        setDateFromInner(plusWeek(dateFromInner))
        setDateToInner(plusWeek(dateToInner))
        break
      case RangeType.month:
        setDateFromInner(plusMonth(dateFromInner))
        setDateToInner(plusMonth(dateToInner))
        break
      case RangeType.year:
        setDateFromInner(plusYear(dateFromInner))
        setDateToInner(plusYear(dateToInner))
        break
      case RangeType.range:
      default:
        // do nothing
        break

    }
  }

  return <div className={classes.DateControl}>

    <div ref={target}>
      {isDesktop && <DateFilterButton
        opened={dateModalOpened}
        dateFrom={dateFromInner}
        dateTo={dateToInner}
        rangeType={rangeType}
        onLeftClick={onDateLeftClick}
        onRightClick={onDateRightClick}
        onClick={onDateClick}/>}

      {isMobile && <FilterButton
        // todo srsly? why?
        // dateFrom={dateFromInner}
        // dateTo={dateToInner}
        opened={dateModalOpened}
        onClick={onDateClick}
        title={periodTitle(rangeType, dateFromInner, dateToInner)}/>}

    </div>

    <PeriodOverlay
      rootClose
      onHide={() => setDateModalOpened(false)}
      show={isDesktop && dateModalOpened}
      target={targetDiv}
      initialRangeType={rangeType}
      initialFrom={dateFromInner}
      initialTo={dateToInner}
      onSave={onDateSave}
      onDelete={onDateDelete}
    />

    {isMobile && dateModalOpened && <PeriodModalMobile
      onHide={() => setDateModalOpened(false)}
      show={dateModalOpened}
      onSave={onDateSave}
      onDelete={onDateDelete}
      initialFrom={dateFromInner}
      initialTo={dateToInner}
      initialRangeType={rangeType}
      onClose={() => setDateModalOpened(false)}
      opened={dateModalOpened}/>}

  </div>
}
