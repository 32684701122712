import {Event, Location} from '../services/api/models'
import {elementsEqual} from "./array_util";
import {DateTime} from "luxon";
import {formatDate, sameDay, sameMonth} from "./date_util";
import {deduplicateLocationsByCity} from "./location_util";

export const locationTitle = (event?: Event): string | undefined => {

  if (!event){
    return ''
  }

  const {locations} = event
  if (!locations || !locations.length) {
    return 'Place to be confirmed'
  }

  if (locations.length == 1) {
    return `${locations[0].city}, ${locations[0].country}`
  }

  const citiesAndCountries = locations.map(l => `${l.city}, ${l.country}`)
  if (elementsEqual(citiesAndCountries)) {
    return `${locations[0].city}, ${locations[0].country}`
  }

  if (elementsEqual(locations.map(l => l.country))) {
    return `${locations.map(l => l.city).join(', ')}, ${locations[0].country}`
  }

  return citiesAndCountries.join('; ')

}

export const isBraintree = (event: Event): boolean => {
  return event.paymentAccountType == 'braintree'
}

export const wristbandRegistrationOnly = (event?: Event | null): boolean => {
  const types = event?.registrationTypes ?? []
  return types.length == 1 && types.includes('wristband')
}

export const qrRegistrationOnly = (event?: Event | null): boolean => {
  const types = event?.registrationTypes ?? []
  return types.length == 1 && types.includes('qr')
}

export const dates = (startDate: string | undefined, finishDate: string | undefined): string => {
  if (!startDate || !finishDate) {
    return ''
  }
  const dateFrom = DateTime.fromSQL(startDate ?? '').toJSDate()
  const dateTo = DateTime.fromSQL(finishDate ?? '').toJSDate()

  if (sameDay(dateFrom, dateTo)) {
    return formatDate(dateFrom, 'MMM d') ?? 'Unknown date'
  }

  if (sameMonth(dateFrom, dateTo)) {
    return `${formatDate(dateFrom, 'MMM d')}-${formatDate(dateTo, 'd')}`
  }

  return `${formatDate(dateFrom, 'MMM d')} - ${formatDate(dateTo, 'MMM d')}`

}

export const notFinishedYet = (event: Event): boolean => {
  const date = event.finishDate
  if (!date) {
    return false
  }
  return DateTime.fromSQL(date).plus({days: 1}) > DateTime.now()
}

export const countriesFromEvents = (events?: Event[]): string[] => {
  if (!events){
    return []
  }
  return [...new Set(events.flatMap(e => e.locations ?? []).map(l => l.country))]
}

export const locationsFromEvents = (events?: Event[]): Location[] => {
  if (!events){
    return []
  }
  const combinedLocations: Location[] = events.flatMap(e => e.locations ?? [])
  return deduplicateLocationsByCity(combinedLocations)
}
