import React, {useEffect} from 'react'
import classes from "./GooglePayButton.module.scss"
import {showError} from "../../util/toast_util"
import {googlePayCompletePayment} from "../../services/api/api"
import {adyenCountryCode, adyenCurrency} from "../../config/constants"

interface GooglePayButtonProps {
  onPaymentComplete: (transactionId: string, currency: string, amountInCents: number) => void
  // doing this because conditional rendering doesn't work for Google Pay button for some reason;
  onCompletingStart: VoidFunction
  onCompletingEnd: VoidFunction
}

export const GooglePayButton = (props: GooglePayButtonProps) => {

  const {onPaymentComplete, onCompletingStart, onCompletingEnd} = props

  useEffect(() => {
    const loadGooglePay = () => {
      const paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: process.env.REACT_APP_ENV == 'prod' ? 'PRODUCTION' : 'TEST'
      })

      const paymentDataRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
              "type": "PAYMENT_GATEWAY",
              "parameters": {
                "gateway": "adyen",
                "gatewayMerchantId": process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT
              }
            }
          },
        ],
        merchantInfo: {
          merchantId: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT,
          merchantName: 'Billfold',
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: '0.00',
          currencyCode: adyenCurrency,
          countryCode: adyenCountryCode,
        },
      }

      const container = document.getElementById('google-pay-button')
      if (container) {
        container.innerHTML = ''
        container.appendChild(paymentsClient.createButton({
          onClick: () => onGooglePayButtonClick(paymentsClient, paymentDataRequest),
        }))

        const child = container.firstChild as HTMLElement
        if (child) {
          child.style.width = '100%';
        }
      }

    }

    const onGooglePayButtonClick = async (paymentsClient: any, paymentDataRequest: any) => {
      try {
        onCompletingStart()
        const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest)
        const {token} = paymentData.paymentMethodData.tokenizationData

        const reference = 'Billfold card authorization'

        const response = await googlePayCompletePayment(token, adyenCurrency, 0, reference)
        onPaymentComplete && onPaymentComplete(response.transactionId, adyenCurrency, 0)
      } catch (e: any) {
        console.log(`onGooglePayButtonClick - error: ${e}`)
        showError(e.toString())
      } finally {
        onCompletingEnd()
      }
    }

    if (window.google) {
      console.log(`useEffect - window has google`)
      loadGooglePay()
    }
  }, [])

  return <div className={classes.GooglePayButton} id={'google-pay-button'}/>
}
