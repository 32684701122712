import {
  addEventToFavorite,
  getFavoriteEvents,
  removeEventFromFavorite,
} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import {Event} from "../services/api/models";
import useSWRMutation from "swr/mutation";
import {isBraintree, notFinishedYet} from "../util/event_util";

export const useFavoriteEvents = () => {
  const {data, error, isLoading} = useSWR('getFavoriteEvents', getFavoriteEvents)
  const events = data?.filter(e => notFinishedYet(e) && e.registrationAllowed && !isBraintree(e) && e.showToUsers)
  return {
    favoriteEvents: events,
    favoriteEventsLoading: isLoading,
    favoriteEventsError: error
  }
}

export const refreshFavoriteEvents = async () => {
  await mutate('getFavoriteEvents')
}

export const useAddToFavorite = () => {
  const {
    trigger,
    isMutating,
    error
  } = useSWRMutation<any, any, any, Event>('addToFavorite', addToFavoriteFetcher)
  return {
    addToFavorite: trigger,
    addingToFavorite: isMutating,
    addToFavoriteError: error
  }
}

export const useRemoveFromFavorite = () => {
  const {
    trigger,
    isMutating,
    error
  } = useSWRMutation<any, any, any, Event>('removeFromFavorite', removeFromFavoriteFetcher)
  return {
    removeFromFavorite: trigger,
    removingFromFavorite: isMutating,
    removeFromFavoriteError: error
  }
}

//
const addToFavoriteFetcher = async (url: string, data: { arg: Event }) => {
  const event = data.arg
  return addEventToFavorite(event.id ?? '')
}

const removeFromFavoriteFetcher = async (url: string, data: { arg: Event }) => {
  const event = data.arg
  return removeEventFromFavorite(event.id ?? '')
}



