import React, { PropsWithChildren } from "react"
import Modal, { ModalProps } from "./Modal";
import classes from './ConfirmModal.module.scss'
import { AppButton } from "./AppButton";
import { faCheck, faClose, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Spacer } from "./Spacer";
import {useTranslation} from "react-i18next";

export interface ConfirmModalProps {
  confirmTitle?: string
  confirmIcon?: IconDefinition
  confirmDisabled?: boolean
  onConfirm: VoidFunction
  declineTitle?: string
  declineIcon?: IconDefinition
  dangerConfirm?: boolean
}

export const ConfirmModal: React.FC<ConfirmModalProps & ModalProps & PropsWithChildren> = (props) => {

  const {
    confirmTitle,
    onConfirm,
    declineTitle,
    onClose,
    declineIcon,
    confirmIcon,
    confirmDisabled,
    dangerConfirm
  } = props
  const {t} = useTranslation()

  const onConfirmClick = () => {
    onClose()
    onConfirm()
  }

  return <Modal {...props}>

    {props.children}

    <div className={classes.ConfirmModal}>
      <div className={classes.Buttons}>
        <AppButton
          secondary={dangerConfirm}
          className={classes.Button}
          danger={!dangerConfirm}
          title={declineTitle ?? t('cancel')}
          onClick={onClose}
          icon={declineIcon ?? faClose}/>

        <Spacer width={10}/>

        <AppButton
          className={classes.Button}
          danger={dangerConfirm}
          title={confirmTitle ?? t('ok')}
          onClick={onConfirmClick}
          disabled={confirmDisabled}
          icon={confirmIcon ?? faCheck}/>

      </div>
    </div>

  </Modal>
}
