import React from 'react'
import classes from "./AgeVerificationView.module.scss";
import { AccountSectionTitle } from "./AccountSectionTitle";
import { ClearVerificationView } from "../common/ClearVerificationView";

export const AgeVerificationView = () => {

  return <div className={classes.AgeVerificationView}>

    <AccountSectionTitle title={'Age verification'}/>

    <ClearVerificationView
      placeholder={'Date of birth'}
      redirectToPage={'/account/age-verification'}
      disableDateSelecting/>

  </div>
}
