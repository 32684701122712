import React, {useEffect, useRef, useState} from 'react'
import classes from './Header.module.scss'
import Logo from '../../../images/logo.svg'
import Star from '../../../images/star.svg'
import StarActive from '../../../images/star_active.svg'
import Heart from '../../../images/heart.svg'
import HeartActive from '../../../images/heart_active.svg'
import Wristband from '../../../images/wristband.svg'
import WristbandActive from '../../../images/wristband_active.svg'
import FlagUk from '../../../images/flag_uk.svg'
import FlagEs from '../../../images/flag_es.svg'
import FlagBr from '../../../images/flag_br.svg'
import GlobeRegular from '../../../images/globe_regular.svg'
import CircleUserRegular from '../../../images/circle_user_regular.svg'
import classnames from 'classnames'
import {Overlay} from "react-bootstrap"
import {AppButton, Spacer, useAppContext} from "../../common"
import useUser, {clearUser} from "../../../hooks/useUser"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {faArrowRightFromBracket, faUserTie, faChevronDown} from "@fortawesome/free-solid-svg-icons"
import {faCircleUser} from "@fortawesome/free-regular-svg-icons"
import SpinnerSmall from "../../common/SpinnerSmall"
import {Menu} from "../../common/Menu"
import {useTranslation} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Header() {

  // todo it will be cool to perform all the search on all the screens
  // from the generic header search box
  const {t, i18n} = useTranslation()
  const {locale, setLocale} = useAppContext()
  const {user, userLoading, isBillfoldAdmin} = useUser()
  const navigate = useNavigate()
  const [accountMenuOpened, setAccountMenuOpened] = useState(false)
  const [langMenuOpened, setLangMenuOpened] = useState(false)
  const [target, setTarget] = useState(null)
  const [targetLang, setTargetLang] = useState(null)
  const ref = useRef(null)
  const refLang = useRef(null)
  const {pathname} = useLocation()

  const handleAvatarClick = (e: any) => {
    setAccountMenuOpened(!accountMenuOpened)
    setTarget(e.target)
  }

  const handleLangClick = (e: any) => {
    setLangMenuOpened(!langMenuOpened)
    setTargetLang(e.target)
  }

  const handleLogoutClick = async () => {
    await clearUser()
    navigate('auth')
  }

  const avatar = () => {

    if (!user) {
      return <div/>
    }

    return <div className={classes.Avatar} onClick={handleAvatarClick} ref={ref}>

      <img src={CircleUserRegular} alt={'CircleUserRegular'}/>

      <Overlay
        rootClose
        onHide={() => setAccountMenuOpened(false)}
        show={accountMenuOpened}
        target={target}
        placement='bottom-end'
        container={ref}
        containerPadding={16}>
        <div style={{zIndex: 999}}>
          <Menu
            items={[
              {
                title: t('account'),
                linkTo: '/account',
                icon: faCircleUser,
              },
              ...(isBillfoldAdmin) ? [{
                title: t('admin'),
                linkTo: '/admin',
                icon: faUserTie,
              }] : [],
              {
                title: t('logout'),
                onClick: handleLogoutClick,
                icon: faArrowRightFromBracket,
              }
            ]}/>
        </div>
      </Overlay>

    </div>
  }

  const languageIcon = () => {

    return <div className={classes.LangController} onClick={handleLangClick} ref={refLang}>

      <img src={GlobeRegular}/>

      <Overlay
        rootClose
        onHide={() => setLangMenuOpened(false)}
        show={langMenuOpened}
        target={targetLang}
        placement='bottom-end'
        container={refLang}
        containerPadding={16}>
        <div style={{zIndex: 999}}>
          <Menu
            items={[
              {
                title: 'English',
                iconImage: FlagUk,
                onClick: () => {
                  setLocale('en')
                  i18n.changeLanguage('en')
                },
              },
              {
                title: 'Español',
                iconImage: FlagEs,
                onClick: () => {
                  setLocale('es')
                  i18n.changeLanguage('es')
                },
              },
              {
                title: 'Português',
                iconImage: FlagBr,
                onClick: () => {
                  setLocale('pt')
                  i18n.changeLanguage('pt')
                },
              },
            ]}/>
        </div>
      </Overlay>

    </div>
  }

  const locationFilterIcon = () => {
    return <div className={classes.LocationFilter}>
      <div className={classes.LocationFilterTitle}>location city</div>
      <FontAwesomeIcon icon={faChevronDown} className={classes.LocationFilterIcon}/>
    </div>
  }

  return <div className={classes.Header}>
    <div className={classes.HeaderContent}>
      <Link to={'/'}>
        <img src={Logo} className={classes.Logo}/>
      </Link>

      <div className={classes.TabsContainer}>

        <Link to={'/'}>
          <Tab
            title={t('events')}
            icon={Star}
            iconActive={StarActive}
            selected={pathname == '/' || pathname.startsWith('/event')}
          />
        </Link>

        <Link to={'/wishlist'}>
          <Tab
            title={t('wishlist')}
            icon={Heart}
            iconActive={HeartActive}
            selected={pathname.startsWith('/wishlist')}/>
        </Link>

        <Link to={'/wristbands'}>
          <Tab
            title={t('wristbands')}
            icon={Wristband}
            iconActive={WristbandActive}
            selected={pathname.startsWith('/wristbands')}/>
        </Link>

      </div>

      {userLoading ? <SpinnerSmall/> : user ? avatar() : <AppButton
        title={'Sign up'}
        className={classes.RegisterButton}
        onClick={() => navigate('/auth')}/>}

      <Spacer width={16}/>

      {languageIcon()}

    </div>
  </div>
}

interface TabProps {
  title: string
  icon: string
  iconActive: string
  selected: boolean
}

const Tab: React.FC<TabProps> = ({title, icon, iconActive, selected}) => {
  return <div className={classnames({[classes.Selected]: selected})}>
    <div className={selected ? classes.HeaderTabSelected : classes.HeaderTab}>
      <img src={selected ? iconActive : icon} alt={title} className={classes.HeaderTabIcon}/>
      <div>{title}</div>
    </div>
  </div>
}
