import React, {useEffect, useState} from 'react'
import classes from './PaymentMethodInfoPage.module.scss'
import {AddButton, AddCardModal, AppButton, Spacer} from "../common";
import {CardItem} from "../account/CardItem";
import useCards from "../../hooks/useCards";
import {showError} from "../../util/toast_util";
import {Card, StripePaymentMethod} from "../../services/api/models";
import PaymentMethodsModal from "../common/PaymentMethodsModal";
import {isEmpty} from "../../util/array_util";
import {AddCardForm} from "../common/AddCardForm";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useEvent from "../../hooks/useEvent";

interface PaymentMethodInfoPage {
  onContinue: (card: Card) => void
}

export default function PaymentMethodInfoPage(props: PaymentMethodInfoPage) {
  const {onContinue} = props
  const {t} = useTranslation()
  const {eventId} = useParams() as any
  const {event} = useEvent(eventId)
  const {cards, cardsLoading} = useCards(event?.platformAccountBillfoldId, event?.paymentAccountType)
  const [selectedCard, setSelectedCard] = useState<Card>()
  const [showChooseCardModal, setShowChooseCardModal] = useState(false)
  const [showAddCardModal, setShowAddCardModal] = useState(false)
  const [showAddCardInfo, setShowAddCardInfo] = useState(false)
  const [showAddFirstCardInfo, setShowAddFirstCardInfo] = useState(false)

  useEffect(() => {
    if (!cardsLoading && isEmpty(cards)) {
      setShowAddFirstCardInfo(true)
    }
  }, [cards, cardsLoading])

  useEffect(() => {

    // case where all cards have been deleted
    if (!cards || !cards.length) {
      setSelectedCard(undefined)
      return
    }

    // case when we first load all the cards,
    // and we need to select the card first time
    if (!selectedCard && cards && cards.length > 0) {
      setSelectedCard(cards[0])
    }
  }, [cards])

  const onContinueClick = () => {

    if (!selectedCard) {
      showError(t('select_the_card_please'))
      return
    }

    onContinue(selectedCard)
  }

  const onChooseCardModalClose = () => {
    setShowChooseCardModal(false)
  }

  const onAddCardModalClose = () => {
    setShowAddCardModal(false)
  }

  const onCardSelect = (card: Card) => {
    setSelectedCard(card)
  }

  const onAddCardClick = () => {
    setShowAddCardInfo(true)
  }

  const onCardAdded = (card: Card) => {
    setShowAddCardInfo(false)
    onCardSelect(card)
  }

  const onFirstCardAdded = (card: Card) => {
    setShowAddFirstCardInfo(false)
    setSelectedCard(card)
    onContinue(card)
  }

  return <div className={classes.PaymentMethodInfoPage}>
    <h2 className={classes.Title}>{(showAddCardInfo || showAddFirstCardInfo) ? t('add_card') : t('choose_the_card')}</h2>

    {cardsLoading && <div className={'mb-3'}>
      <CardItem loading/>
    </div>}

    {!showAddCardInfo && !showAddFirstCardInfo && <div>
      <div>
        {(cards ?? []).map(c => <div className={'mb-3'}>
          <CardItem
            card={c}
            selected={c.id == selectedCard?.id}
            onClick={() => onCardSelect(c)}/>
        </div>)}

        <AddButton title={t('add_card')} onClick={onAddCardClick}/>

      </div>
    </div>}

    {showAddFirstCardInfo && <div className={'w-100'}>
      <AddCardForm
        shortButton
        event={event ?? undefined}
        onCardAdded={onFirstCardAdded}
        buttonTitle={t('continue')}
        buttonsBottomFixed={isMobile}/>
    </div>}

    {showAddCardInfo && <div className={'w-100'}>
      <AddCardForm
        onCardAdded={onCardAdded}
        event={event ?? undefined}
        shortButton
        buttonTitle={t('add_card')}
        showCancelButton
        onCancelClick={() => setShowAddCardInfo(false)}
        buttonsBottomFixed={isMobile}/>

      <Spacer height={100}/>
    </div>}

    {!showAddCardInfo && !showAddFirstCardInfo && <AppButton
      className={isMobile ? classes.ContinueButtonMobile : classes.ContinueButton}
      onClick={onContinueClick}
      title={t('continue')}/>}

    {showChooseCardModal && event && <PaymentMethodsModal
      event={event}
      onClose={onChooseCardModalClose}
      opened={showChooseCardModal}
      selectedCard={selectedCard}
      onChange={onCardSelect}
    />}

    {showAddCardModal && <AddCardModal
      onClose={onAddCardModalClose}
      opened={showAddCardModal}
    />}
  </div>
}
