import React from "react";
import classes from "./FilterButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface FilterButtonProps {
  title?: string
  onClick?: (e: any) => void
  opened?: boolean
}

export const FilterButton: React.FC<FilterButtonProps> = (props) => {
  const {title, onClick, opened} = props

  return <div className={classes.FilterButton} onClick={onClick}>
    {title}

    <FontAwesomeIcon
      icon={!opened ? faChevronDown : faChevronUp}
      className={classes.FilterButtonIcon}/>

  </div>
}
