import { Location } from "../services/api/models";
import { trimCommas } from "./string_util";

export const getLocationFromGooglePlace = (place: google.maps.places.PlaceResult): Location => {
  const {name, formatted_address, geometry} = place

  const streetNumber = getShortValue(place, 'street_number')
  const street = getShortValue(place, 'route')
  const postalCode = getShortValue(place, 'postal_code')
  const city = getShortValue(place, 'locality') || getShortValue(place, 'sublocality')
  const country = getShortValue(place, 'country')
  let address
  if (!name || !formatted_address) {
    address = trimCommas(`${street} ${streetNumber}, ${city}, ${country}, ${postalCode}`)
  } else if (!name || formatted_address?.includes(name)) {
    address = formatted_address
  } else {
    address = `${name}, ${formatted_address}`
  }

  return {
    city: city ?? '',
    country: country ?? '',
    address: address,
    latitude: geometry?.location?.lat() ?? 0,
    longitude: geometry?.location?.lng() ?? 0,
  }
}

export const differentLocations = (locations1?: Location[], locations2?: Location[]): boolean => {
  const l1 = [...locations1 ?? []]
  const l2 = [...locations2 ?? []]
  const addresses1 = l1.map(l => l.address)
  const addresses2 = l2.map(l => l.address)
  return l1.length != l2.length
    || addresses1.some(a => !addresses2.includes(a))
    || addresses2.some(a => !addresses1.includes(a))
}

export const locationHashCity =  (location: Location): string => {
  return `${location.city},${location.country}`
}

export const deduplicateLocationsByCity = (locations: Location[]): Location[] => {
  const map : Record<string, Location> = {}

  locations.forEach(location => {
    map[locationHashCity(location)] = location
  })

  return Object.values(map)
}

//
const getShortValue = (place: google.maps.places.PlaceResult, type: string): string | undefined => {
  return place.address_components?.find(c => c.types.includes(type))?.short_name ?? ''
}
