import React, {useState} from 'react'
import classes from './DonePage.module.scss'
import {ReactComponent as CheckBigGreenWhite} from '../../images/check_big_green_white.svg'
import {AddButton, AppButton, Spacer} from "../common"
import {Link, useLocation, useParams} from "react-router-dom"
import {Event, Wristband} from "../../services/api/models"
import QRCode from "qrcode.react"
import Modal from "../common/Modal"
import {ReactComponent as ShareIcon} from '../../images/share.svg'
import {useTranslation} from "react-i18next"

interface DonePageProps {
  event: Event
  wristband: Wristband
  onAddWristbandClick: () => void
  onDonePressed?: VoidFunction
}

export default function DonePage(props: DonePageProps) {
  const {wristband, onAddWristbandClick, onDonePressed} = props

  const {t} = useTranslation()
  const {eventId} = useParams()
  const [showQrModal, setShowQrModal] = useState(false)
  const location = useLocation()

  const onShareClick = async () => {

    // if (!navigator.share) {
    //   console.log('Web Share API is not supported in this browser.')
    // }
    // try {
    //   await navigator.share({
    //     title: 'Share Title',
    //     text: 'Check out this amazing content!',
    //     url: window.location.href,
    //   })
    //   console.log('Shared successfully')
    // } catch (error) {
    //   console.error('Error sharing:', error)
    // }

  }

  return <div className={classes.DonePage}>

    <Spacer/>

    <CheckBigGreenWhite className={classes.CheckIcon}/>

    <Spacer height={36}/>

    <h1>
      <p>{t('congratulations_you_have_successfully_registered')}</p>
    </h1>

    <Spacer height={24}/>

    <AddButton title={t('add_one_more_wristband')} onClick={onAddWristbandClick}/>

    <Spacer height={50}/>

    <Spacer/>

    <AppButton title={t('done')} onClick={onDonePressed}/>

    {/*todo get rid of code duplication*/}
    <Modal
      opened={showQrModal}
      onClose={() => setShowQrModal(false)}
      title={'How it works'}
      titleTrailing={<ShareIcon onClick={onShareClick} className={classes.ShareIcon}/>}
      backgroundColor={'#EFEFF0'}>

      <div className={classes.QrModal}>

        <div className={classes.QrCodeContainer}>
          <QRCode value={wristband?.billfoldId ?? ''} size={200}/>
        </div>

        <Spacer height={20}/>

        <div className={classes.Explanation}>
          {t('qr_code_explanation')}
        </div>

        <Spacer height={10}/>

        <Link to={`/wristbands/${wristband?.id}`} state={{from: location}}>
          <AppButton
            title={t('go_to_wristband_page')}
            className={classes.GoToWristbandPage}
            transparent/>
        </Link>

        <Spacer height={10}/>

      </div>

    </Modal>

  </div>
}
