import React from 'react'
import classes from './Footer.module.scss'
import { Spacer } from "../common";
import { Link } from "react-router-dom";
import { version } from "../../config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faHeartPulse } from "@fortawesome/free-solid-svg-icons";
import { isBrowser, isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import useUser from "../../hooks/useUser";

export default function Footer() {
  const {t, i18n} = useTranslation()
  const {isBillfoldAdmin} = useUser()

  return <div className={classes.Footer} style={{height: isMobile ? 'auto' : undefined}}>

    {isMobile && <div className={classes.FooterContentMobile}>

      <a href={'https://billfoldpos.com/privacy-policy'}>
        <div> Privacy Policy</div>
      </a>

      <Spacer height={10}/>

      <a href={'https://billfoldpos.com'}>
        <div>© 2024 Billfold LLC</div>
      </a>

      <Spacer height={10}/>

      <div>v{version}</div>

      <Spacer height={10}/>

      {isBillfoldAdmin && <Link to={'/admin/health'}>
        <div className={classes.FooterContent}>
          <div>{t('system_health')}</div>
          <Spacer width={4}/>
          <FontAwesomeIcon icon={faHeartPulse}/>
        </div>
      </Link>}

      {isBillfoldAdmin && <Spacer height={10}/>}

      {isBillfoldAdmin && <a href={'https://billfoldpos.com'}>
        <div className={classes.FooterContent}>

          <div>API</div>
          <Spacer width={4}/>
          <FontAwesomeIcon icon={faCode}/>
        </div>
      </a>}

      {isBillfoldAdmin && <Spacer height={10}/>}

    </div>}

    {isBrowser && <div className={classes.FooterContent}>

      <a href={'https://billfoldpos.com'}>
        <div>© 2024 Billfold LLC</div>
      </a>

      <Spacer/>

      {isBillfoldAdmin && <Link to={'/admin/health'}>
        <div className={classes.FooterContent}>
          <FontAwesomeIcon icon={faHeartPulse}/>
          <Spacer width={4}/>
          <div>{t('system_health')}</div>
        </div>
      </Link>}

      {isBillfoldAdmin && <div className={classes.FooterDivider}>|</div>}

      {isBillfoldAdmin && <Link to={process.env.REACT_APP_ADMIN_DOCS_URL ?? ''}>
        <div className={classes.FooterContent}>
          <div>API</div>
          <Spacer width={4}/>
          <FontAwesomeIcon icon={faCode}/>

        </div>
      </Link>}

      {isBillfoldAdmin && <div className={classes.FooterDivider}>|</div>}

      <div>v{version}-{process.env.REACT_APP_ENV}</div>

      <div className={classes.FooterDivider}>|</div>

      <a href={'https://billfoldpos.com/privacy-policy'}>
        <div>{t('privacy_policy_and_terms_of_use')}</div>
      </a>
    </div>}

  </div>
}
