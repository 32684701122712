import React, {useMemo} from 'react'
import classes from "./HealthView.module.scss";
import {useSystemHealth} from "../../hooks/useSystemHealth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../common/Spinner";
import {Spacer} from "../common";
import {ReactComponent as HeartPulse} from "../../images/heart_pulse.svg";
import {ReactComponent as HeartError} from "../../images/heart_circle_exclamation.svg";
import {useTranslation} from "react-i18next";
import {ViewHeader} from "../common/ViewHeader";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

export default function HealthView() {

  const {t} = useTranslation()
  const {systemHealth, systemHealthLoading} = useSystemHealth()
  const {apiKeysValid, socialUrlsValid, lastCheckTime} = systemHealth ?? {}
  const navigate = useNavigate()

  const systemOk = useMemo(() => {
    return apiKeysValid && socialUrlsValid
  }, [systemHealth])

  const line = (isOk: boolean, messageValid: string, messageInvalid: string) => {
    return <div className={classes.Line}>
      <FontAwesomeIcon
        icon={isOk ? faCheck : faXmark}
        className={isOk ? classes.LineIcon : classes.LineIconError}/>
      <Spacer width={10}/>
      <div className={isOk ? classes.LineMessage : classes.LineMessageError}>
        {isOk ? messageValid : messageInvalid}
      </div>
    </div>
  }

  const minutesSinceLastCheck = useMemo(() => {
    if (!lastCheckTime) {
      return 0
    }
    const now = new Date()
    const lastCheck = new Date(lastCheckTime)

    return Math.floor((now.getTime() - lastCheck.getTime()) / 60000)
  }, [lastCheckTime, systemHealth])

  return <div className={classes.HealthView}>

    {isMobile && <ViewHeader
      title={t('system_health')}
      showBackButton
      onBackClick={() => navigate(-1)}/>}

    <Spacer height={10}/>

    <div className={classes.HealthViewContent}>

      {systemHealthLoading && <div className={classes.SpinnerContainer}>
        <Spinner/>
      </div>}

      {!systemHealthLoading && <div className={classes.InfoContainer}>

        <Spacer height={120}/>

        {systemOk && <div className={classes.Success}>
          <HeartPulse className={classes.Icon}/>
        </div>}

        {!systemOk && <div className={classes.Error}>
          <HeartError className={classes.IconError}/>
        </div>}

        <Spacer height={20}/>

        <div className={classes.Title}>
          {systemOk ? t('billfold_works_normally') : t('something_wrong')}
        </div>

        {systemOk && <div className={classes.TitleSecondary}>
          {minutesSinceLastCheck == 0
            ? t('checked_a_few_seconds_ago') :
            (minutesSinceLastCheck == 1
              ? t('checked_one_minute_ago')
              : t('checked_minutes_ago', {minutes: minutesSinceLastCheck}))}
        </div>}

        {!systemOk && <div className={classes.TitleSecondary}>
          {t('we_are_informed_and_working_on_it')}
        </div>}

        <Spacer height={60}/>

        <div>
          {line(!!apiKeysValid, t('api_keys_are_valid'), t('api_keys_are_expired'))}
          <Spacer height={6}/>
          {line(!!socialUrlsValid, t('social_urls_are_valid'), t('social_urls_are_wrong'))}
        </div>

        <Spacer height={100}/>

      </div>}

    </div>
  </div>

}
