import {getSystemHealth} from '../services/api/api'
import useSWR from 'swr'

export const useSystemHealth = () => {
  const {data, error, isLoading} = useSWR('getSystemHealth', getSystemHealth)
  return {
    systemHealth: data,
    systemHealthLoading: isLoading,
    systemHealthError: error
  }
}
