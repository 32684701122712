import React, {PropsWithChildren, useEffect, useMemo} from 'react'
import classes from './SelectEventModal.module.scss'
import Modal, {ModalProps} from "../common/Modal";
import useEvents from "../../hooks/useEvents";
import {EventItemSmallOld} from "./CheckinExternalView";
import {Spacer} from "../common";
import {Event, Organizer} from "../../services/api/models";
import {useKeyboardVisibility} from "../../hooks/useKeyboardVisibility";
import useUser from "../../hooks/useUser";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {notFinishedYet} from "../../util/event_util";

interface SelectEventModalProps {
  onSelected: (event: Event) => void
  organizer?: Organizer
}

export const SelectEventModal: React.FC<SelectEventModalProps & ModalProps> = (props) => {
  const [searchParams] = useSearchParams()
  const {opened, onClose, onSelected, organizer} = props
  const {events: regularEvents} = useEvents()
  const [search, setSearch] = React.useState('')
  const {keyboardVisible} = useKeyboardVisibility()
  const {isClientAdmin, isBillfoldAdmin} = useUser()
  const {t} = useTranslation()

  const events = useMemo(
    () => regularEvents?.filter((e) => isClientAdmin || isBillfoldAdmin ? true : e.showToUsers),
    [regularEvents]
  )

  const filteredEvents = useMemo(() => {
    // const organizer = searchParams?.get('organizer') ?? ''
    const isAdmin = isClientAdmin || isBillfoldAdmin
    return events?.filter(e => {
      const notFinished = notFinishedYet(e)
      const visible = e.showToUsers
      const orgFit = !organizer || e.organizerID == organizer.id
      const nameFit = e.name?.toLowerCase().includes(search.toLowerCase())
      return nameFit && orgFit && ((visible && notFinished) || isAdmin)
    })
  }, [events, search, searchParams])

  return <Modal
    opened={opened}
    onClose={onClose}
    title={t('select_event')}>
    <div className={classes.SelectEventModal}>
      {/*<SearchBox onSearchChange={(v) => setSearch(v)}/>*/}
      <Spacer height={10}/>
      <div className={classes.EventsList} style={{maxHeight: keyboardVisible ? 200 : 400}}>
        {filteredEvents?.map(e => <EventItemSmallOld
          event={e}
          onClick={(event) => {
            onSelected(event)
            onClose()
          }}
          darkBackground/>)}
      </div>
    </div>
  </Modal>


}
