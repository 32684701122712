import { Wristband, Event, Card } from "../../services/api/models";
import React, {useEffect, useMemo, useState} from "react";
import classes from "./WristbandItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faLock } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import useCards from "../../hooks/useCards";
import { Shimmer, Shimmers } from "../common";
import classnames from "classnames";
import { moneyFormat } from "../../util/format_util";
import { ReactComponent as WristbandIcon } from "../../images/wristband_icon.svg";
import { ReactComponent as QrIcon } from "../../images/qr_icon.svg";
import {hasPaymentMethodId} from "../../util/card_util";
import {isQrType} from "../../util/wristband_util";
import {useTranslation} from "react-i18next";
import {PaymentMethodInfo} from "./PaymentMethodInfo";

interface WristbandItemProps {
  wristband?: Wristband
  shimmer?: boolean
  event?: Event
}

export const WristbandItem: React.FC<WristbandItemProps> = ({wristband, shimmer, event}) => {

  const {wristbandNumber, wristbandOwnerName, balance, status, registrationType} = wristband ?? {}
  const {cards, cardsLoading} = useCards()
  const {t} = useTranslation()
  const [card, setCard] = useState<Card>()

  useEffect(() => {
    if (cards && cards.length > 0) {
      setCard(
        cards?.find(c => hasPaymentMethodId(c, wristband?.paymentMethodID)) ??
        cards?.find(c => hasPaymentMethodId(c, wristband?.stripePaymentMethodID))
      )
    }
  }, [cards])

  const isQr = useMemo(() => wristband && isQrType(wristband), [wristband])

  const blocked = status == 'blocked'

  const cardInfo = useMemo(() => {
    return <>

      {cardsLoading && <div className={'placeholder-glow'}>
        <div className={'placeholder'}/>
      </div>}

      {!cardsLoading && !blocked && card && <PaymentMethodInfo card={card} small/>}

      {!cardsLoading && !blocked && !card && <div className={classes.NoCard}>
        <FontAwesomeIcon icon={faCreditCard} className={classes.Icon}/>
        <div>{t('no_card')}</div>
      </div>}

      {!cardsLoading && blocked && <div className={classes.NoCard}>
        <FontAwesomeIcon icon={faLock} className={classes.Icon}/>
        <div>Blocked</div>
      </div>}
    </>
  }, [card, cardsLoading, blocked, cards])

  return <div>

    {wristband && <div className={classes.WristbandItem}>

      {isQr && <div className={classes.QrIconContainer}>
        <QrIcon className={classes.WristbandIcon}/>
      </div>}

      {!isQr && <WristbandIcon className={classes.WristbandIcon}/>}

      <div className={classes.LeftContent}>
        <div className={classes.WristbandTitle}>{wristbandOwnerName}</div>
        {!isQr && <div className={classes.WristbandSubtitle}>{wristbandNumber}</div>}
        {isQr && <div className={classes.WristbandSubtitle}>{t('activation_required')}</div>}
      </div>

      {!isQr && <div className={classes.RightContent}>
        <div className={classnames(!!balance ? classes.Sum : classes.Sum)}>
          {moneyFormat(balance ?? 0, event?.paymentAccountCurrency)}
        </div>
        {cardInfo}
      </div>}

      {isQr && <div className={classes.ActionTitle}>{t('activate')}</div>}

    </div>}

    {shimmer && <Shimmers>
      <div className={classnames(classes.WristbandItem, 'w-100')}>

        <div className={classes.LeftContent}>
          <div>
            <Shimmer width={80}/>
          </div>
          <div className={'mt-2'}>
            <Shimmer width={90}/>
          </div>
        </div>

        <div className={classes.RightContent}>
          <div>
            <Shimmer width={110}/>
          </div>
          <div className={'mt-2'}>
            <Shimmer width={90}/>
          </div>
        </div>

        <Shimmer width={20} height={20} borderRadius={10}/>
      </div>
    </Shimmers>}

  </div>
}
