import React, { useEffect, useMemo, useRef, useState } from 'react'
import classes from './HistoryView.module.scss'
import classnames from "classnames"
import { faRotate } from "@fortawesome/free-solid-svg-icons"
import { BalanceTransaction } from "../../services/api/models"
import { AppButton, Spacer } from "../common"
import { AccountSectionTitle } from "./AccountSectionTitle"
import { SumModal, SumOverlay } from "./SumModal"
import { isDesktop, isMobile } from "react-device-detect"
import { formatDate, sameDay } from "../../util/date_util"
import { useSearchParams } from "react-router-dom";
import useTransactions from "../../hooks/useTransactions";
import { paramsToObject, setValue } from "../../util/search_params_util";
import { moneyFormat } from "../../util/format_util";
import useEvent from "../../hooks/useEvent";
import Spinner from "../common/Spinner";
import { AccountPlaceholderView } from "./AccountPlaceholderView";
import { ReactComponent as HistoryIcon } from "../../images/history.svg";
import { isEmpty, isNotEmpty } from "../../util/array_util";
import { isRefund, transactionReason } from "../../util/transaction_util";
import { TransactionSourceIcon } from "../common/TransactionSourceIcon";
import { TransactionDetailsModal } from "./TransactionDetailsModal";
import { FilterButton } from "../common/FilterButton";
import { DateControl } from "../common/DateControl";
import {useTranslation} from "react-i18next";

export default function HistoryView() {

  const paramsFromParams = () => {
    return {
      amountFrom: amountFrom == undefined ? undefined : parseInt(amountFrom),
      amountTo: amountTo == undefined ? undefined : parseInt(amountTo),
      dateFrom: !dateFrom ? undefined : dateFrom,
      dateTo: !dateTo ? undefined : dateTo
    }
  }

  const [params, setParams] = useSearchParams()
  const {amountFrom, amountTo, dateFrom, dateTo} = paramsToObject(params) as {
    dateFrom?: string,
    dateTo?: string,
    amountFrom?: string,
    amountTo?: string,
  }
  const {
    transactions,
    size,
    setSize,
    hasMore,
    transactionsLoading
  } = useTransactions(paramsFromParams())
  const {t} = useTranslation()
  const [sumModalOpened, setSumModalOpened] = useState(false)
  const [targetDiv, setTargetDiv] = useState(null)
  const target = useRef(null)
  const [amountFromInner, setAmountFromInner] = useState<number>()
  const [amountToInner, setAmountToInner] = useState<number>()
  const [dateFromInner, setDateFromInner] = useState<Date>()
  const [dateToInner, setDateToInner] = useState<Date>()

  useEffect(() => {
    // todo
    if (dateFrom) {
      setDateFromInner(new Date(dateFrom))
    }
    if (dateTo) {
      setDateToInner(new Date(dateTo))
    }
    if (amountFrom) {
      setAmountFromInner(parseInt(amountFrom))
    }
    if (amountTo) {
      setAmountFromInner(parseInt(amountTo))
    }
  }, [])

  useEffect(() => {

    let newParams = new URLSearchParams(params.toString());
    newParams = setValue(newParams, 'dateFrom', formatDate(dateFromInner))
    newParams = setValue(newParams, 'dateTo', formatDate(dateToInner))
    newParams = setValue(newParams, 'amountFrom', amountFromInner?.toString())
    newParams = setValue(newParams, 'amountTo', amountToInner?.toString())

    setParams(newParams)

  }, [amountFromInner, amountToInner])

  const onDatesChanged = (from: Date | undefined, to: Date | undefined) => {

    const fromDifferent = (!from && dateFrom) || (from && !dateFrom) || (from && dateFrom && !sameDay(from, new Date(dateFrom)))
    const toDifferent = (!to && dateTo) || (to && !dateTo) || (to && dateTo && !sameDay(to, new Date(dateTo)))

    if (fromDifferent || toDifferent) {
      let newParams = new URLSearchParams(params.toString());
      newParams = setValue(newParams, 'dateFrom', formatDate(from))
      newParams = setValue(newParams, 'dateTo', formatDate(to))
      setParams(newParams)
    }
  }

  const onSumClick = (e: any) => {
    console.log(`onSumClick - ${sumModalOpened}`)
    setSumModalOpened(!sumModalOpened)
    setTargetDiv(e.target)
  }

  const onSumSave = (from: number | undefined, to: number | undefined) => {
    setAmountFromInner(from)
    setAmountToInner(to)
  }

  const onSumDelete = () => {
    setAmountFromInner(undefined)
    setAmountToInner(undefined)
  }

  const sumTitle = () => {
    if (!amountFromInner && !amountToInner) {
      return 'Sum'
    }

    if (amountFromInner && !amountToInner) {
      return `From $${amountFromInner}`
    }

    if (!amountFromInner && amountToInner) {
      return `Up to $${amountToInner}`
    }

    if (amountFromInner && amountToInner) {
      return `$${amountFromInner}-${amountToInner}`
    }

    return 'Sum'
  }

  const onLoadMoreClick = async () => {
    await setSize(size + 1)
  }

  const filtered = useMemo(() => {
    return dateFrom != undefined
      || dateTo != undefined
      || amountFrom != undefined
      || amountTo != undefined
  }, [dateFrom, dateTo, amountFrom, amountTo])

  return <div className={classes.HistoryView}>

    <AccountSectionTitle title={t('history')} previousRoute={'/account'}/>

    {/*todo get back it later once we will have the orders endpoint*/}
    {/*<div className={classes.HistoryTabs}>
      <div
        className={classnames(classes.HistoryTab, {[classes.HistoryTabSelected]: historyTab == 0})}
        onClick={() => setHistoryTab(0)}>
        Orders
      </div>
      <div
        className={classnames(classes.HistoryTab, {[classes.HistoryTabSelected]: historyTab == 1})}
        onClick={() => setHistoryTab(1)}>
        Payments
      </div>
    </div>*/}

    {(isNotEmpty(transactions) || filtered) && <div className={classes.Controls}>
      <div className={classes.ControlsContent}>

        <FilterButton title={t('events')}/>

        <DateControl
          dateFrom={dateFrom ? new Date(dateFrom) : undefined}
          dateTo={dateTo ? new Date(dateTo) : undefined}
          onDatesChanged={onDatesChanged}/>

        <div>
          <div ref={target} onClick={onSumClick}>
            <FilterButton
              title={sumTitle()}
              opened={sumModalOpened}/>
          </div>

          <SumOverlay
            rootClose
            onHide={() => {
              setSumModalOpened(false)
            }}
            show={isDesktop && sumModalOpened}
            target={targetDiv}
            initialFrom={amountFromInner}
            initialTo={amountToInner}
            onSave={onSumSave}
            onDelete={onSumDelete}
          />
        </div>

      </div>
    </div>}

    <div>
      {transactions?.map(t => <TransactionItem key={JSON.stringify(t)} transaction={t}/>)}
    </div>

    {transactionsLoading && <div className={classes.LoaderContainer}>
      <Spinner/>
    </div>}

    {isEmpty(transactions) && !transactionsLoading && <AccountPlaceholderView
      title={filtered ? t('no_transactions_fit_this_filter') : t('you_have_no_transactions')}
      icon={HistoryIcon}/>}

    {isNotEmpty(transactions) && hasMore && !transactionsLoading && <AppButton
      className={classes.LoadMoreButton}
      transparent
      title={'Load more'}
      icon={faRotate}
      onClick={onLoadMoreClick}/>}

    {isNotEmpty(transactions) && hasMore && transactionsLoading && <Spinner/>}

    {isMobile && sumModalOpened && <SumModal
      onHide={() => setSumModalOpened(false)}
      show={sumModalOpened}
      onSave={onSumSave}
      onDelete={onSumDelete}
      initialFrom={amountFromInner}
      initialTo={amountToInner}
      onClose={() => setSumModalOpened(false)}
      opened={sumModalOpened}/>}

  </div>
}

interface TransactionItemProps {
  transaction: BalanceTransaction
}

const TransactionItem: React.FC<TransactionItemProps> = ({transaction}) => {
  const {event} = useEvent(transaction.eventID)
  const {amount, createdAt, type} = transaction
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const refund = useMemo(() => isRefund(type), [type])

  const onTransactionDetailsModalClose = () => {
    setShowDetailsModal(false)
  }

  return <>

    <div className={classes.TransactionItem} onClick={() => setShowDetailsModal(true)}>
      <div>
        <div className={classes.TransactionTitleContainer}>
          <TransactionSourceIcon transaction={transaction}/>
          <div className={classes.TransactionTitle}>{transactionReason(transaction)}</div>
        </div>
        <div className={classes.TransactionSubtitle}>
          {event?.name ?? 'Unknown event'}
        </div>
      </div>
      <Spacer/>
      <div style={{textAlign: 'end'}}>
        <div
          className={classnames(
            classes.TransactionSum,
            {[classes.Red]: amount < 0 && !refund},
            {[classes.Green]: amount > 0 && !refund},
          )}>
          {refund ? '' : (amount > 0 ? '+' : '-')}{moneyFormat(Math.abs(amount), event?.paymentAccountCurrency)}
        </div>
        <div className={classes.TransactionSubtitle}>
          {formatDate(createdAt, 'd MMM')}
        </div>
      </div>
    </div>

    {showDetailsModal && <TransactionDetailsModal
      transaction={transaction}
      opened={showDetailsModal}
      onClose={onTransactionDetailsModalClose}
      event={event}/>}
  </>
}
