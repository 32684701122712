import React from 'react'
import ReactDOM from "react-dom/client"
import { enableMapSet } from 'immer'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import App from "./App"
import './i18n'

enableMapSet()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
