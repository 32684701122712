import React, {PropsWithChildren, useEffect} from "react"
import {Modal as BModal} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import classes from "./Modal.module.scss";
import {useKeyboardVisibility} from "../../hooks/useKeyboardVisibility";
import {setStyle} from "../../util/document_util";
import {isMobile} from "react-device-detect";

export interface ModalProps {
  opened: boolean
  onClose: VoidFunction
  title?: string
  className?: string
  bodyWidth?: number
  onBackClick?: VoidFunction
  titleTrailing?: React.ReactNode
  backgroundColor?: string
  /**
   * Full screen modal should not be used with input fields
   * Because we are moving the modal when keyboard becomes visible
   * ad if we will move the full-screen dialog it will be bad UX
   */
  fullScreen?: boolean
}

const Modal: React.FC<ModalProps & PropsWithChildren> = (props) => {

  const {
    opened,
    onClose,
    title,
    children,
    className,
    onBackClick,
    bodyWidth,
    titleTrailing,
    backgroundColor,
    fullScreen,
  } = props

  const {keyboardVisible, keyboardHeight} = useKeyboardVisibility()

  useEffect(() => {

    if (!opened){
      return
    }

    if (fullScreen) {
      makeFullScreen()
    } else {
      updateModalPosition()
    }
  }, [opened])

  useEffect(() => {

    if (!opened){
      return
    }

    if (fullScreen) {
      makeFullScreen()
    } else {
      updateModalPosition()
    }
  }, [keyboardVisible])

  const updateModalPosition = () => {
    console.log(`updateModalPosition`)
    // todo fix it: The problem is we are updating all modals at once.
    // need to add IDS to all the modals and update only the one that is opened
    // but how to do it?
    // explanation: we have a lot of modals over the app
    // every modal change the same root element ('modal' or 'modal-content') on it's own way
    // this is a problems - aa lot of modals, all changing one element
    // dso the current solution is make this changes ONLY if the modal is OPENED.
    // kind of works, but I am not sure. So only the opened dialog changing the element.
    // only one modal at a time.
    // setStyle(document, 'modal', keyboardVisible ? `bottom: ${keyboardHeight}px;` : 'bottom: 0 !important;')
  }

  const makeFullScreen = () => {
    console.log(`makeFullScreen`)
    const maxSizeStyle = 'height: 100% !important; max-height: 100% !important; width: 100% !important; max-width: 100% !important;'
    setStyle(document, 'modal-dialog', maxSizeStyle)
    setStyle(document, 'modal-content', maxSizeStyle)
    if (isMobile) {
      setStyle(document, 'modal', 'display: block; height: 100% !important;')
    }
  }

  return <BModal
    show={opened}
    onHide={onClose}
    className={className}>

    <div className={fullScreen ? classes.ModalFullScreen : classes.Modal} style={{backgroundColor}}>

      {title && <div className={classes.ModalHeader}>
        {onBackClick ?
          <FontAwesomeIcon icon={faArrowLeft} className='close-button' onClick={onBackClick}/> :
          <div className={'back-button-stub'}/>}
        {/*add here to keep title centered*/}
        <div style={{opacity: 0}}>{titleTrailing}</div>
        <div className={classes.ModalTitle}>{title}</div>
        {titleTrailing}
        <div className='close-button' onClick={onClose}/>
      </div>}

      <div className={fullScreen ? classes.ModalBodyFullScreen : classes.ModalBody} style={{width: `${bodyWidth} !important`}}>
        {children}
      </div>

    </div>

  </BModal>
}

export default Modal
