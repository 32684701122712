import { PaymentMethodType, Card, Wristband } from "../../services/api/models";
import React, { useEffect, useState } from "react";
import { PaymentMethodIcon } from "../common/PaymentMethodIcon";
import { ReactComponent as Linked } from "../../images/linked.svg";
import { Spacer } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import useSWRMutation from "swr/mutation";
import { stripeDeleteCard } from "../../services/api/api";
import { showError, showSuccess } from "../../util/toast_util";
import SpinnerSmall from "../common/SpinnerSmall";
import useCards, { refreshCards } from "../../hooks/useCards";
import Modal from "../common/Modal";
import { AppButton } from "../common";
import classes from "./CardItem.module.scss";
import Warning from "../common/Warning";
import { useWristbands } from "../../hooks/useWristbands";
import { WristbandItem } from "../common/WristbandItem";
import { faChevronRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Shimmer, Shimmers } from "../common";
import { PaymentMethodInfo } from "../common/PaymentMethodInfo";
import {isNotEmpty} from "../../util/array_util";
import {parse} from "../../util/object_util";
import {hasPaymentMethodId} from "../../util/card_util";
import {useTranslation} from "react-i18next";

interface CardItemProps {
  card?: Card
  paymentMethodType?: PaymentMethodType
  showLinked?: boolean
  showArrow?: boolean
  showDelete?: boolean
  onClick?: VoidFunction
  selected?: boolean
  loading?: boolean
}

export const CardItem: React.FC<CardItemProps> = (props) => {

  const {card, showLinked, showDelete, onClick, selected, loading, showArrow} = props
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const {trigger: deleteTrigger, isMutating} = useSWRMutation<any, any, any, any>(
    'stripe/deletePaymentMethod',
    stripeDeleteCard
  )

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const {wristbands} = useWristbands()
  const [wristbandsUsingThisCard, setWristbandsUsingThisCard] = useState<Wristband[]>()
  const {cards} = useCards()
  const {t} = useTranslation()

  useEffect(() => {
    if (wristbands && card && !wristbandsUsingThisCard) {
      setWristbandsUsingThisCard(wristbands.filter(w => hasPaymentMethodId(card, w.stripePaymentMethodID) || hasPaymentMethodId(card, w.paymentMethodID)))
    }
  }, [wristbands, card])

  const onDeleteClick = () => {
    setShowConfirmDeleteModal(true)
  }

  const onConfirmDeleteClick = async () => {

    if (!card) {
      showError('Payment method not found')
      return
    }

    try {
      await deleteTrigger({paymentMethodId: card.id})
      handleConfirmDeleteModalClosed()
      showSuccess('Card deleted')
      refreshCards(cards?.filter(c => c.id != card.id))
    } catch (e) {
      showError(e)
    }

  }

  const onMouseEnter = () => {
    //setShowDeleteButton(true)
  }

  const onMouseLeave = () => {
    //setShowDeleteButton(false)
  }

  const handleConfirmDeleteModalClosed = () => {
    setShowConfirmDeleteModal(false)
  }

  const warningMessage = () => {
    const amount = wristbandsUsingThisCard?.length ?? 0
    return amount > 1
      ? `${amount} wristbands will loose cards. Set them a new card.`
      : `1 wristband will loose the card. Set it a new card.`
  }

   const platformAccountInfo = () => {
    const info = parse(card?.paymentMethodIds ?? '{}')
     return <div className={classes.PlatformAccountInfo}>
       {Object.keys(info).join(', ')}
     </div>
   }

  return <div>
    {!loading && <div
      className={classes.CardItem}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>

      <PaymentMethodInfo card={card}/>

      <Spacer/>

      {process.env.REACT_APP_ENV == 'staging' && <div>{platformAccountInfo()}</div>}

      <Spacer width={8}/>

      {showLinked && <div className={classes.Linked}>
        <Linked className={classes.Icon}/>
        {t('linked')}
      </div>}

      {showArrow && <FontAwesomeIcon icon={faChevronRight} className={classes.Arrow}/>}

      {showDelete && showDeleteButton && !isMutating && <FontAwesomeIcon
        icon={faTrashAlt}
        onClick={onDeleteClick}
        className={classes.DeleteIcon}/>}

      {isMutating && <SpinnerSmall/>}

      {!isMutating && selected && <FontAwesomeIcon
        icon={faCheckCircle}
        className={classes.SelectedIcon}/>}

      {showConfirmDeleteModal && <Modal
        opened={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClosed}
        title={'Are you sure to delete this card?'}>

        <div className={classes.DeleteModal}>

          <div className={classes.CardInfo}>
            <PaymentMethodIcon card={card}/>
            <Spacer width={2}/>
            <div className={classes.CardNumber}>
              {card?.last4}
            </div>
          </div>

          {isNotEmpty(wristbandsUsingThisCard) && <div>
            <Spacer height={10}/>
            <Warning title={warningMessage()}/>
            <Spacer height={10}/>
          </div>}

          {card && wristbandsUsingThisCard?.map(w => <div>
            <WristbandItem
              wristband={w}/>
            <Spacer height={10}/>
          </div>)}

          <div className={classes.ButtonsContainer}>
            <AppButton
              title={'Cancel'}
              onClick={handleConfirmDeleteModalClosed}
              className={classes.ConfirmDeleteButton}
              secondary/>

            <div className={'me-3'}/>

            <AppButton
              title={'Delete'}
              icon={faTrashAlt}
              onClick={onConfirmDeleteClick}
              className={classes.ConfirmDeleteButton}
              progress={isMutating}
              danger/>
          </div>
        </div>
      </Modal>}

    </div>}

    {loading && <div className={classes.CardItemShimmer}>
      <Shimmers className={'w-100'}>
        <Shimmer width={40}/>
        <Spacer width={10}/>
        <Shimmer width={120}/>
        <Spacer/>
        {showLinked && <Shimmer width={40}/>}
      </Shimmers>
    </div>}
  </div>
}

