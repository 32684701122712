import React, { useEffect, useState } from 'react'
import classes from './ClearVerificationView.module.scss'
import { Spacer } from "./Spacer";
import { AppInput } from "./AppInput";
import { AppDateInput } from "./AppDateInput";
import { AppButton } from "./AppButton";
import { faCalendarCheck, } from "@fortawesome/free-regular-svg-icons";
import { faBaby, faCheck, faClose, faClock } from "@fortawesome/free-solid-svg-icons";
import useUser, {useUpdateUser} from "../../hooks/useUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoModal from ".//InfoModal";
import { formatDate, minutesAgo, over21 as over21Util, sameDay } from "../../util/date_util";
import { ReactComponent as ClearIcon } from "../../images/clear_icon.svg";
import { showError } from "../../util/toast_util";
import { defaultAgeVerificationValidTime } from "../../config/constants";
import useSystemSettings from "../../hooks/useSystemSettings";
import { formatTime } from "../../util/time_util";
import { DateTime } from "luxon";
import { ClearButton } from "./ClearButton";
import { clearUrl } from "../../util/clear_util";
import { useSearchParams } from "react-router-dom";

interface ClearVerificationViewProps {
  placeholder?: string
  showSaveDateButton?: boolean
  disableDateSelecting?: boolean
  redirectToPage: string
}

export const ClearVerificationView: React.FC<ClearVerificationViewProps> = (props) => {
  const {placeholder, showSaveDateButton, disableDateSelecting, redirectToPage} = props
  const {user} = useUser()
  const {updateUser, updatingUser} = useUpdateUser()
  const {settings} = useSystemSettings()
  const {ageVerificationValidTime} = settings ?? {}
  const {verifiedOver21, verifiedOver21Time, dateOfBirth} = user ?? {}
  const [showTooYoungModal, setShowTooYoungModal] = useState(false)
  const [showVerificationExpiredModal, setShowVerificationExpiredModal] = useState(false)
  const [dateOfBirthLocal, setDateOfBirthLocal] = useState<Date>()
  const [showDateOfBirthInput, setShowDateOfBirthInput] = useState(false)
  const [secondsBeforeExpiration, setSecondsBeforeExpiration] = useState<number>()
  const [searchParams] = useSearchParams()

  const verified = verifiedOver21 != undefined

  const expired = minutesAgo(verifiedOver21Time) > (ageVerificationValidTime ?? defaultAgeVerificationValidTime)

  const over21 = verifiedOver21 == true && !expired

  const under21 = verifiedOver21 == false && !expired

  useEffect(() => {
    if (verifiedOver21Time && settings && user) {
      const validMinutes = ageVerificationValidTime ?? defaultAgeVerificationValidTime
      const expirationTime = DateTime.fromISO(verifiedOver21Time).plus({minutes: validMinutes})
      setSecondsBeforeExpiration(Math.floor(expirationTime.diffNow('seconds').seconds))
    }
  }, [settings, verifiedOver21Time, user])

  useEffect(() => {
    if (!!secondsBeforeExpiration && secondsBeforeExpiration > 0) {
      const intervalId = setInterval(() => {
        if (secondsBeforeExpiration > 0) {
          setSecondsBeforeExpiration(secondsBeforeExpiration - 1);
        } else {
          setSecondsBeforeExpiration(0);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [secondsBeforeExpiration]);

  useEffect(() => {
    if (dateOfBirth) {
      setDateOfBirthLocal(new Date(dateOfBirth))
    }
  }, [user])

  const onSaveDateClick = async () => {

    if (!dateOfBirthLocal) {
      return
    }

    try {
      await updateUser({
        ...user,
        dateOfBirth: formatDate(dateOfBirthLocal)
      })
    } catch (e) {
      console.log(`onSaveDateClick - error: ${e}`)
      showError(e)
    }

  }

  const onCancelClick = () => {
    setShowDateOfBirthInput(false)
  }

  const counter = () => {
    return <div
      className={classes.Counter}>{!!secondsBeforeExpiration && formatTime(secondsBeforeExpiration)}</div>
  }

  return <div className={classes.ClearVerificationView}>

    {!showDateOfBirthInput && verified && <div>

      {expired
        && <AppInput
          disabled
          colored
          warning
          placeholder={'Age verification'}
          initialValue={'Age verification is expired'}
          trailing={<FontAwesomeIcon
            icon={faClock}
            className={classes.ExpiredIcon}
            onClick={() => setShowVerificationExpiredModal(true)}/>}/>}

      {over21 && <AppInput
        disabled
        colored
        placeholder={'Age verification'}
        valid
        initialValue={'Over 21, verified by CLEAR®'}
        trailing={secondsBeforeExpiration && counter()}/>}

      {under21 && <AppInput
        disabled
        colored
        placeholder={'Age verification'}
        error
        initialValue={'Under 21, verified by CLEAR®'}
        trailing={<div className={'d-flex'}>
          {secondsBeforeExpiration && counter()}
          <Spacer width={10}/>
          <FontAwesomeIcon
            icon={faBaby}
            className={classes.TooYoungIcon}
            onClick={() => setShowTooYoungModal(true)}/>
        </div>}/>}

    </div>}

    {showDateOfBirthInput && <div>
      <AppDateInput
        placeholder={placeholder ?? 'Guest date of birth'}
        onChange={setDateOfBirthLocal}
        error={!!dateOfBirthLocal && !over21Util(dateOfBirthLocal)}
        valid={!!dateOfBirthLocal && over21Util(dateOfBirthLocal)}
        initialDate={dateOfBirthLocal}
        infoTitle={'You have to be 21 or older'}
        infoMessage={'This event sells alcoholic beverages, we must be sure you are of legal age'}/>
    </div>}

    <Spacer height={10}/>

    {searchParams.has('ageValidationError') && <div className={classes.ErrorContainer}>
      {searchParams.get('ageValidationError')}
    </div>}

    {!showDateOfBirthInput && (!verified || under21 || expired) && <div>
      <ClearButton
        under21={under21}
        expired={expired}
        redirectToPage={redirectToPage}
        verified={verified}
      />
    </div>}

    <Spacer height={10}/>

    {showDateOfBirthInput && <div className={classes.ExplainMessageContainer}>

      If you set the date of birth manually, you will have to show your ID at the
      entrance. For the frictionless experience and fast pass, please verify your age by CLEAR®
      for free.

      <Spacer height={10}/>

      {!!dateOfBirthLocal
      && !sameDay(new Date(dateOfBirth ?? ''), dateOfBirthLocal)
      && over21Util(dateOfBirthLocal)
      && showSaveDateButton
        ? <div className={'d-flex'}>
          <AppButton
            danger
            icon={faClose}
            title={'Cancel'}
            onClick={onCancelClick}
            disabled={updatingUser}/>

          <Spacer width={10}/>

          <AppButton
            icon={faCheck}
            title={'Save'}
            onClick={onSaveDateClick}
            progress={updatingUser}/>
        </div>
        : <a href={clearUrl(redirectToPage)}>
          <AppButton
            transparent
            className={'w-100'}
            iconComponent={<div className={classes.ClearIconBlue}>
              <ClearIcon className={classes.ClearIconBlue}/>
            </div>}
            title={verifiedOver21 == false ? 'Re-verify age with CLEAR®' : 'Verify age with CLEAR®'}
          />
        </a>}
    </div>}

    {!disableDateSelecting && !showDateOfBirthInput && (!verified || under21 || expired) &&
      <AppButton
        transparent
        className={'w-100'}
        icon={faCalendarCheck}
        title={!!dateOfBirth ? 'Or use your date of birth' : 'Or set the date of birth manually'}
        onClick={() => setShowDateOfBirthInput(true)}/>}

    {showTooYoungModal && <InfoModal
      opened={showTooYoungModal}
      onClose={() => setShowTooYoungModal(false)}
      title={'You have to be 21 or older'}
      message={'This event sells alcoholic beverages, so we must be sure you are of legal age.'}/>}

    {showVerificationExpiredModal && <InfoModal
      opened={showVerificationExpiredModal}
      onClose={() => setShowVerificationExpiredModal(false)}
      title={'Age verification is expired'}
      message={`${!!verifiedOver21Time ? `Last time you passed verification on ${formatDate(verifiedOver21Time, 'MMM dd')} at ${formatDate(verifiedOver21Time, 'hh:mm')}.\n\n` : ''}According to security rules, age verification is valid for an hour. After that, you need to go through verification again.`}/>}

  </div>
}
