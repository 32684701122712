import {Card, User} from "../services/api/models";
import {parse} from "./object_util";

const brandNamesMap : Record<string, string>= {
  'visa': 'visa',
  'mastercard': 'mc',
  'amex': 'amex',
  'discover': 'dc',
  'diners': 'din',
  'jcb': 'jcb',
  'unionpay': 'cup',
  'maestro': 'maestro',
  'cartes_bancaires': 'cartesbancaires',
  'sepa_debit': 'sepadirectdebit',
  'ideal': 'ideal',
  'paypal': 'paypal',
  'alipay': 'alipay',
  'wechat': 'wechatpay',
  'apple_pay': 'applepay',
  'google_pay': 'googlepay',
}

export const hasPlatformId = (card?: Card, platformAccountBillfoldId?: string, paymentAccountType?: string): boolean => {
  if (!card || !card.paymentMethodIds || !platformAccountBillfoldId) {
    return false
  }

  const paymentMethodIds = parse(card.paymentMethodIds ?? '{}')

  switch(paymentAccountType) {
    case 'stripe':
      return !!paymentMethodIds[platformAccountBillfoldId]
    default:
      return !!paymentMethodIds[`${paymentAccountType}_${platformAccountBillfoldId}`]
  }
}

export const hasPaymentMethodId = (card?: Card, paymentMethodId?: string): boolean => {
  if (!card || !card.paymentMethodIds || !paymentMethodId) {
    return false
  }

  const paymentMethodIds = parse(card.paymentMethodIds ?? '{}')
  return Object.values(paymentMethodIds).includes(paymentMethodId)
}

export const getPaymentMethodId = (card?: Card, platformAccountBillfoldId?: string, paymentAccountType?: string): string | undefined => {
  if (!card || !card.paymentMethodIds || !platformAccountBillfoldId) {
    return undefined
  }

  const paymentIds = parse(card.paymentMethodIds)

  switch(paymentAccountType) {
    case 'stripe':
      return paymentIds[platformAccountBillfoldId]
    default:
      return paymentIds[`${paymentAccountType}_${platformAccountBillfoldId}`]
  }

}

export const sameBrand = (brand1: string | undefined, brand2: string | undefined) => {
  if (!brand1 || !brand2) {
    return false
  }
  return brand1 == brand2 || brandNamesMap[brand1] == brand2 || brandNamesMap[brand2] == brand1
}

export const normalizeBrand = (brand: string | undefined) => {
  if (!brand) {
    return ''
  }

  for (const [key, value] of Object.entries(brandNamesMap)) {
    if (brand == value) {
      return key
    }
  }

  return brand
}
