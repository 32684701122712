import React, {useEffect, useState} from 'react'
import Modal, {ModalProps} from "../common/Modal";
import {t} from "i18next";
import {OptIn} from "../../services/api/models";
import {Checkbox} from "../common/Checkbox";
import classnames from "classnames";
import classes from "./OptInsModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {OptInResult} from "../../services/api/api";
import {AppButton, Spacer} from "../common";
import {isNotEmpty} from "../../util/string_util";

interface OptInsModalProps {
  onOptInsConfirmed: (optIns: OptInResult[]) => void
  optIns: OptIn[]
}

export const OptInsModal: React.FC<OptInsModalProps & ModalProps> = (props) => {
  const {opened, onClose, className, onOptInsConfirmed, optIns} = props
  const [optInsChecks, setOptInsChecks] = useState<Record<string, boolean>>({})
  const [optInsErrors, setOptInsErrors] = useState<Record<string, boolean>>({})
  const [optInsResults, setOptInsResults] = useState<OptInResult[]>([])

  useEffect(() => {
    if (optIns) {
      const checks: Record<string, boolean> = {}
      const errors: Record<string, boolean> = {}
      optIns.forEach(o => {
        checks[o.id ?? ''] = false
        errors[o.id ?? ''] = false
      })
      setOptInsChecks(checks)
      setOptInsErrors(errors)
    }
  }, [optIns])

  useEffect(() => {
    setOptInsResults(
      optIns?.map(o => ({
        id: o.billfoldId ?? '',
        version: o.version ?? 0,
        accepted: optInsChecks[o.id ?? ''],
      })) ?? []
    )
  }, [optInsChecks])

  const onContinueClick = () => {
    let hasOptInError = false
    const errors = {...optInsErrors}
    for (const o of optIns ?? []) {
      if (o.required && o.visible && !optInsChecks[o.id ?? '']) {
        errors[o.id ?? ''] = true
        hasOptInError = true
      }
    }
    setOptInsErrors(errors)
    if (hasOptInError) {
      return
    }

    onOptInsConfirmed(optInsResults)

  }

  return <Modal
    opened={opened}
    onClose={onClose}
    className={className}
    title={t('agree_to_opt_ins')}>

    <div className={classes.OptInsModal}>

      {optIns?.filter(o => o.visible)?.map(o => <div>
        <Checkbox
          checked={optInsChecks[o.id ?? '']}
          onChange={(v) => {
            if (v) {
              setOptInsErrors({
                ...optInsErrors,
                [o.id ?? '']: false
              })
            }
            setOptInsChecks({
              ...optInsChecks,
              [o.id ?? '']: v
            })
          }}
          error={optInsErrors[o.id ?? '']}>

          {o.text} {isNotEmpty(o.fileUrl) && <a
          href={o.fileUrl}
          target={'_blank'}
          className={classnames(
            classes.TermsLink,
            {
              [classes.TermsLinkError]: optInsErrors[o.id ?? ''],
            }
          )}>
          {t('read_more')}
        </a>}

          {isNotEmpty(o.fileUrl) && <FontAwesomeIcon
            icon={faUpRightFromSquare}
            className={classnames(
              classes.TermsIcon,
              {
                [classes.TermsIconError]: optInsErrors[o.id ?? ''],
              }
            )}/>}

        </Checkbox>

        <Spacer height={6}/>

      </div>)}

      <Spacer height={20}/>

      <AppButton
        title={t('continue')}
        onClick={onContinueClick}
        className={classes.ContinueButton}/>
    </div>
  </Modal>
}
