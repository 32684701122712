import React from "react";
import useEvent from "../../hooks/useEvent";
import {WristbandItem} from "./WristbandItem";
import {Link} from "react-router-dom";
import {AddButton} from "./AddButton";
import {Wristband} from "../../services/api/models";
import classes from './EventWristbandsView.module.scss'
import {Shimmer, Shimmers} from "./Shimmer";
import {useTranslation} from "react-i18next";
import {RegistrationItem} from "./RegistrationItem";
import {AddRegistrationButton} from "./AddRegistrationButton";
import {isDesktop} from "react-device-detect";

interface EventWristbands {
  eventId?: string
  wristbands?: Wristband[]
  loading?: boolean
  linkPrefix?: string
  isQr?: boolean
}

export default function EventWristbandsView(props: EventWristbands) {
  const {eventId, wristbands, loading, linkPrefix, isQr} = props
  const {event} = useEvent(eventId ?? '')
  const {t} = useTranslation()

  return <div className={classes.EventWristbandsView}>

    {!loading && <div>
      <div className={'mb-3'}>
        <h2 className={classes.EventWristbandsViewTitle}>{event?.name}</h2>
      </div>

      {!isQr && wristbands?.map(w => <div className={'mb-3'}>
        <Link to={`${linkPrefix ? `${linkPrefix}/` : ''}${w.id}`}>
          <WristbandItem key={w.id} wristband={w} event={event}/>
        </Link>
      </div>)}

      {isQr && <div className={classes.RegistrationItemsGrid}>
        {wristbands?.map(w =>
          <RegistrationItem
            wristband={w}
            event={event}
            key={w.id}
            aspectRatio={isDesktop ? 1.5 : undefined}/>
        )}
        {event && <AddRegistrationButton
          event={event}
          aspectRatio={isDesktop ? 1.5 : undefined}/>}
      </div>}

      {!isQr && <Link to={`/event/${eventId}/checkin`}>
        <AddButton title={t('add_guest')}/>
      </Link>}

    </div>}

    {loading && <Shimmers>
      <div className={'w-100'}>
        <div className={'mb-3'}>
          <Shimmer width={100}/>
        </div>
        {[...Array(6)].map((_) => <div className={'mb-3'}>
          <WristbandItem shimmer/>
        </div>)}
        <div className={'mt-4 d-flex justify-content-center'}>
          <Shimmer width={200} height={36}/>
        </div>
      </div>
    </Shimmers>}

  </div>
}
