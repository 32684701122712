import {Col, Row} from "react-bootstrap";
import classes from "./TableView.module.scss";
import {Link} from "react-router-dom";
import {isDesktop, isMobile} from "react-device-detect";
import React, {useState} from "react";
import {isNotEmpty} from "../../util/array_util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSadCry, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {ConfirmModal} from "./ConfirmModal";
import {useTranslation} from "react-i18next";

export type TableItem = {
  values: Array<string | undefined>
  mobileValue?: string
  linkTo?: string
}

interface TableViewProps {
  columns: number[]
  headers: string[]
  mobileHeader: string
  items: TableItem[]
  placeholderText: string
}

export const TableView = (props: TableViewProps) => {
  const {columns, headers, items, mobileHeader, placeholderText} = props

  return <div className={classes.TableView}>

    {isNotEmpty(items) && <div className={classes.Table}>

      {isDesktop && <Row className={classes.Header}>
        {columns.map((c,i) => <Col xs={c}>{headers[i]}</Col>)}
      </Row>}

      {isMobile && <Row className={classes.Header}>
        <Col xs={12}>{mobileHeader}</Col>
      </Row>}

      {items?.map(i => <ItemView item={i} columns={columns}/>)}

    </div>}

    {items?.length === 0 && <div className={classes.PlaceholderContainer}>
      <FontAwesomeIcon icon={faFaceSadCry} className={classes.PlaceholderIcon}/>
      <div className={classes.PlaceholderTitle}>{placeholderText}</div>
    </div>}
  </div>
}

// todo finish show delete button in a generic way
interface ItemViewProps {
  columns: number[]
  item: TableItem
  onConfirmDeleteClick?: () => void
  confirmDeleteMessage?: string
  deleting?: boolean
  showDeleteButton?: boolean
}

const ItemView = (props: ItemViewProps) => {
  const {columns, item, onConfirmDeleteClick, confirmDeleteMessage} = props
  const {t} = useTranslation()
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onDeleteClick = async () => {
    setShowDeleteButton(false)
    setShowDeleteModal(true)
  }

  const onHover = () => {
    if (showDeleteModal) {
      return
    }
    setShowDeleteButton(true)
  }

  const onLeave = () => {
    setShowDeleteButton(false)
  }

  const onDeleteModalClose = () => {
    setShowDeleteModal(false)
  }

  const onMobileClick = () => {
    setShowDeleteModal(true)
  }

  const browserContent = () => {
    return <Row
      className={classes.Item}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}>
      {columns.map((c,i) => <Col xs={c}>{item.values[i]}</Col>)}

      {/*{deletingInvite && <SpinnerSmall/>}*/}
      {/**/}
      {/*{!deletingInvite && showDeleteButton && <FontAwesomeIcon*/}
      {/*  icon={faTrashAlt}*/}
      {/*  onClick={onDeleteClick}*/}
      {/*  className={classes.DeleteButton}/>}*/}
    </Row>
  }

  const mobileContent = () => {
    return <Row className={classes.Item} onClick={onMobileClick}>
      <Col xs={12}>
        <div className={classes.MobileEmail}>{item.mobileValue}</div>
      </Col>
    </Row>
  }

  return <Link to={item.linkTo ?? ''}>
    {isDesktop ? browserContent() : mobileContent()}

    {showDeleteModal && <ConfirmModal
      title={t('are_you_sure')}
      onClose={onDeleteModalClose}
      opened={showDeleteModal}
      onConfirm={() => onConfirmDeleteClick && onConfirmDeleteClick()}
      confirmTitle={t('delete')}
      confirmIcon={faTrashAlt}
      dangerConfirm>
      <div className={classes.DeleteModalText}>{confirmDeleteMessage}</div>
    </ConfirmModal>}

  </Link>

}
