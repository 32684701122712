import React, {useState} from 'react'
import classes from './OrderDetailsView.module.scss'
import {ViewHeader} from "../common/ViewHeader";
import {moneyFormatTwoDigits} from "../../util/format_util";
import {PaymentMethodIcon} from "../common/PaymentMethodIcon";
import {PaymentMethodType, Card} from "../../services/api/models";
import {formatDate} from "../../util/date_util";
import {Spacer} from "../common";

interface OrderDetailsViewProps {

}

export const TransactionDetailsView: React.FC<OrderDetailsViewProps> = (props) => {

  const [newLook, setNewLook] = useState()

  const tableInfoRow = (title: string, qty: number, tax: number, price: number, total: number) => {
    return <div className={classes.TableRow}>
      <div className={classes.ColumnTitle}>{title}</div>
      <div className={classes.ColumnValue}>{qty}</div>
      <div className={classes.ColumnValue}>{!tax ? '-' : `${tax}%`}</div>
      <div className={classes.ColumnValue}>{!price ? '-' : moneyFormatTwoDigits(price)}</div>
      <div className={classes.ColumnTotal}>{!total ? '-' : moneyFormatTwoDigits(total)}</div>
    </div>
  }

  const tableResultRow = (title: string, total: number) => {
    return <div className={classes.TableRow}>
      <div className={classes.ColumnTitle}>{title}</div>
      <div className={classes.ColumnTotal}>{!total ? '-' : moneyFormatTwoDigits(total)}</div>
    </div>
  }

  const tableTotalRow = (title: string, total: number) => {
    return <div className={classes.TableHeader}>
      <div className={classes.ColumnTitle}>{title}</div>
      <div className={classes.ColumnTotal}>{!total ? '-' : moneyFormatTwoDigits(total)}</div>
    </div>
  }

  const paymentInfoRow = (
    card: Card | null,
    methodType: PaymentMethodType | null,
    date: Date,
    total: number
  ) => {
    return <div className={classes.TableRow}>
      <div className={classes.ColumnTitle}>
        <div className={classes.CardInfo}>
          <div className={'me-1'}>
            <PaymentMethodIcon
              card={card ?? undefined}
              paymentMethodType={methodType ?? undefined}/>
          </div>
          <div className={classes.CardNumber}>
            {card?.last4 ?? 'Cash'}
          </div>
        </div>
      </div>
      <div className={classes.ColumnValue}>{formatDate(date)}</div>
      <div className={classes.ColumnTotal}>{!total ? '-' : moneyFormatTwoDigits(total)}</div>
    </div>
  }

  return <div className={classes.OrderDetails}>

    <ViewHeader title={'Order #JUAA736GTS'} showBackButton/>

    <div className={classes.TimeInfo}>
      <div className={classes.TimeInfoTitle}>
        Order time:
      </div>
      <div className={classes.TimeInfoValue}>
        Thu, 04 Feb 2021 14:50
      </div>
    </div>

    <div className={classes.InfoContainer}>
      <div className={classes.InfoTitle}>
        <h1>Burger shot vendor</h1>
      </div>
      <div className={classes.TableHeader}>
        <div className={classes.ColumnTitle}>Title</div>
        <div className={classes.ColumnValue}>Quantity</div>
        <div className={classes.ColumnValue}>Sales tax</div>
        <div className={classes.ColumnValue}>Price</div>
        <div className={classes.ColumnValue}>Total</div>
      </div>

      <div className={classes.DividerBold}/>

      {tableInfoRow('Fanta', 1, 0, 5, 5)}
      {tableInfoRow('Finlandia Bloody Mary', 1, 0, 320, 320)}
      {tableInfoRow('E-On Black Power', 4, 0, 200, 200)}
      {tableInfoRow('E-On Almond Rush', 1, 0, 200, 200)}
      {tableInfoRow('Finlandia Black Currant', 1, 0, 260, 260)}

      <div className={classes.Divider}/>

      {tableResultRow('Discount', 0)}
      {tableResultRow('Subtotal', 1585)}
      {tableResultRow('Donation', 0)}
      {tableResultRow('Service fee', 0)}
      {tableResultRow('Tip', 237.75)}
      {tableResultRow('Sales tax', 0)}

      <div className={classes.DividerBold}/>

      {tableTotalRow('Total', 1822.75)}

    </div>

    <Spacer height={20}/>

    <div className={classes.InfoContainer}>

      <div className={classes.TableHeader}>
        <div className={classes.ColumnTitle}>Payment method</div>
        <div className={classes.ColumnValue}>Transaction time</div>
        <div className={classes.ColumnValue}>Amount</div>
      </div>

      <div className={classes.DividerBold}/>

      {paymentInfoRow(null, PaymentMethodType.CASH, new Date(), 120)}
      {paymentInfoRow(
        {
          last4: '1111',
          brand: 'visa'
        },
        null,
        new Date(),
        120
      )}

    </div>

    <Spacer height={60}/>

  </div>
}
