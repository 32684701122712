import React, {PropsWithChildren} from 'react'
import Modal from "./Modal";
import {AddCardForm} from "./AddCardForm";
import {useTranslation} from "react-i18next";

interface AddCardModalProps {
  opened: boolean
  onClose: () => void
}

export const AddCardModal: React.FC<AddCardModalProps & PropsWithChildren> = (props) => {

  const {opened, onClose} = props
  const {t} = useTranslation()

  return <Modal
    opened={opened}
    onClose={onClose}
    title={t('add_card')}>
    {/*<AddCardForm onCardAdded={onClose}/>*/}
    <AddCardForm onCardAdded={onClose}/>
  </Modal>
}
