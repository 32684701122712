import React, {CSSProperties, MouseEventHandler, PropsWithChildren, ReactNode} from 'react'
import classes from "../login/LoginView.module.scss";
import {Button} from "react-bootstrap";
import classnames from "classnames";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import SpinnerSmall from "./SpinnerSmall";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface AppButtonProps {
  transparent?: boolean
  danger?: boolean
  secondary?: boolean
  title?: string
  onClick?: MouseEventHandler | undefined
  className?: string
  progress?: boolean
  icon?: IconDefinition
  iconComponent?: ReactNode
  link?: string
  disabled?: boolean
  outline?: boolean
  fontColor?: string
  style?: CSSProperties | undefined;
}

export const AppButton: React.FC<AppButtonProps & PropsWithChildren> = (props) => {
  const {
    transparent,
    danger,
    title,
    children,
    onClick,
    className,
    progress,
    icon,
    secondary,
    disabled,
    outline,
    iconComponent,
    fontColor,
    style
  } = props

  return <Button
    className={classnames(
      classes.ActionButton,
      {[classes.TransparentButton]: transparent},
      {[classes.DangerButton]: danger},
      {[classes.SecondaryButton]: secondary},
      {[classes.OutlineButton]: outline},
      {[classes.Danger]: transparent && danger},
      className,
    )}
    onClick={onClick}
    disabled={disabled}
    style={{...style, color: fontColor}}>

    {progress && <SpinnerSmall/>}

    {!progress && icon && <div style={{paddingTop: 0}}>
      <FontAwesomeIcon icon={icon} className={classes.ActionButtonIcon}/>
    </div>}

    {!progress && iconComponent}

    {!progress && title}

    {!progress && children}

  </Button>
}
