import React, {useEffect, useState} from 'react'
import classes from "./AccountInfoView.module.scss";
import {AppButton, AppDateInput, AppInput, InputType} from "../common";
import useUser, {refreshUser, useUpdateUser} from "../../hooks/useUser";
import {showError, showSuccess} from "../../util/toast_util";
import {useSWRConfig} from "swr";
import {dateLocal, formatDate, sameDay} from "../../util/date_util";
import {AccountSectionTitle} from "./AccountSectionTitle";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {isValidPhoneNumber} from "libphonenumber-js";
import {isNotEmpty} from "../../util/string_util";
import {useTranslation} from "react-i18next";
import {countryCodeUS} from "../../config/constants";

export default function AccountInfoView() {
  const {t} = useTranslation()
  const {user, userLoading} = useUser();
  const {updateUser, updatingUser, updateUserError} = useUpdateUser();
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>()
  const {mutate} = useSWRConfig()

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName ?? '')
      setLastName(user.lastName ?? '')
      setAddress(user.address ?? '')
      setPhoneNumber(user.phoneNumber ?? '')
      if (user.dateOfBirth) {
        setDateOfBirth(dateLocal(user.dateOfBirth))
      }
    }
  }, [user])

  useEffect(() => {
    setPhoneNumberError(false)
  }, [phoneNumber])

  useEffect(() => {
    if (!user) {
      setShowSaveButton(false)
      return
    }

    setShowSaveButton(firstName != (user.firstName ?? '')
      || lastName != (user.lastName ?? '')
      || address != (user.address ?? '')
      || phoneNumber != (user.phoneNumber ?? '')
      || !sameDay(dateOfBirth, new Date(user.dateOfBirth ?? '')))

  }, [user, firstName, lastName, address, phoneNumber, dateOfBirth])

  const onFirstNameChange = (name: string) => {
    setFirstName(name)
  }

  const onLastNameChange = (name: string) => {
    setLastName(name)
  }

  const onAddressChange = (address: string) => {
    setAddress(address)
  }

  const onPhoneNumberChange = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber)
  }

  const onDateOfBirthChange = (dateOfBirth: Date) => {
    setDateOfBirth(dateOfBirth)
  }

  const onSave = async () => {
    try {

      if (isNotEmpty(phoneNumber) && !isValidPhoneNumber(phoneNumber)) {
        showError(t('phone_number_is_invalid'))
        setPhoneNumberError(true)
        return
      }

      await updateUser({
        ...user ?? {},
        firstName,
        lastName,
        address,
        phoneNumber,
        dateOfBirth: formatDate(dateOfBirth)
      })

      showSuccess(t('account_updated'))
      setShowSaveButton(false)
      refreshUser()
    } catch (e) {
      console.log(`onSave - error: ${e}`)
      showError(e)
    }
  }

  return <div className={classes.AccountInfoView}>

    <AccountSectionTitle title={t('account_info')}/>

    <AppInput
      initialValue={firstName}
      placeholder={t('first_name')}
      onChange={onFirstNameChange}
      loading={userLoading}
    />

    <div className={'mb-3'}/>

    <AppInput
      initialValue={lastName}
      placeholder={t('last_name')}
      onChange={onLastNameChange}
      loading={userLoading}
    />

    <div className={'mb-3'}/>

    <AppDateInput
      initialDate={dateOfBirth}
      placeholder={t('date_of_birth')}
      onChange={onDateOfBirthChange}
      loading={userLoading}
    />

    <div className={'mb-3'}/>

    <AppInput
      initialValue={phoneNumber}
      placeholder={t('phone_number')}
      onChange={onPhoneNumberChange}
      loading={userLoading}
      type={InputType.phone}
      freshStartPrefix={`${countryCodeUS} `}
      error={phoneNumberError}
      valid={isValidPhoneNumber(phoneNumber)}
    />

    <div className={'mb-3'}/>

    <AppInput
      initialValue={address}
      placeholder={t('address')}
      onChange={onAddressChange}
      loading={userLoading}
    />

    <div className={'mb-3'}/>

    <AppInput
      initialValue={user?.email}
      placeholder={t('email')}
      disabled
      loading={userLoading}
    />

    <div className={'mb-3'}/>

    {showSaveButton && <AppButton
      title={t('save')}
      icon={faCheck}
      onClick={onSave}
      progress={updatingUser}
      className={classes.SaveButton}
    />}

  </div>
}
