import React, {useEffect, useMemo, useState} from 'react'
import classes from './AccountWristbandsView.module.scss'
import {Wristband} from "../../services/api/models";
import EventWristbandsView from "../common/EventWristbandsView";
import {AccountSectionTitle} from "./AccountSectionTitle";
import {ReactComponent as WristbandIcon} from "../../images/wristband_new_thin.svg";
import {AccountPlaceholderView} from "./AccountPlaceholderView";
import {useWristbands} from "../../hooks/useWristbands";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {isQrType} from "../../util/wristband_util";

interface AccountWristbandsViewProps {
  isQr?: boolean
  key?: string
}

export const AccountWristbandsView = (props: AccountWristbandsViewProps) => {

  const {isQr, key} = props
  const [eventsWristbands, setEventsWristbands] = useState<Map<string, Wristband[]>>()
  const {wristbands: originalWristbands} = useWristbands()
  const {t} = useTranslation()

  const wristbands = useMemo(() => {
    return originalWristbands.filter(w => isQrType(w) == !!isQr)
  }, [originalWristbands])

  useEffect(() => {
    if (wristbands && wristbands.length > 0 && !eventsWristbands) {
      const eventsWristbands = new Map<string, Wristband[]>()
      for (const w of wristbands) {
        const {eventID} = w
        eventsWristbands.set(eventID ?? '', [...(eventsWristbands.get(eventID ?? '') ?? []), w])
      }
      setEventsWristbands(eventsWristbands)
    }
  }, [wristbands])

  return <div className={classes.AccountWristbandsView} key={key}>
    <AccountSectionTitle title={isQr ? t('online_registrations') : t('active_wristbands')}/>

    {[...eventsWristbands?.entries() ?? []].map(e => <EventWristbandsView
      key={e[0]}
      eventId={e[0]}
      wristbands={e[1]}
      isQr={isQr}
      linkPrefix={isMobile ? '/account/wristband' : 'wristband'}/>)}

    {(!wristbands || !wristbands?.length) && <AccountPlaceholderView
      buttonTitle={t('register_my_first_wristband')}
      linkTo={'/'}
      icon={WristbandIcon}/>}

  </div>

}
