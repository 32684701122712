import {
  Event,
  BalanceTransaction,
  Invite,
  ClearUserInfo,
  SystemSettings,
  GalleryItem,
  OptIn,
  StripeCustomer,
  StripePaymentMethod,
  PlatformAccount,
  Card,
  SystemHealth,
  BFPaymentsCard,
  Organizer,
  RedirectRule,
  WristbandBatch,
  Integrator,
  Charity
} from './models'
import {Auth} from "aws-amplify"
import {AutoTopUpConfig, User, Wristband} from "./models"
import {
  CardTypeNotSupportedError,
  CreditCardNotSupportedError,
  DebitCardNotSupportedError,
  NeedAdminPermissionError,
  UnauthorizedError,
  UserNotConfirmedError,
} from "./errors"
import JSON5 from 'json5'
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth"
import {DateTime} from "luxon"
import {formatDate} from "../../util/date_util"
import {parse, stringify} from "../../util/object_util"
import {getBillfoldErrorMessage} from "../../util/billfold_error_util"
import {clearRedirectUrl} from "../../util/clear_util"
import {capitalize} from "../../util/string_util"
import Bugsnag from '@bugsnag/js'
import {qrRegistrationOnly, wristbandRegistrationOnly} from "../../util/event_util"
import {API} from 'aws-amplify'
import {Stripe, StripeElements} from "@stripe/stripe-js"
import {normalizeBrand, sameBrand} from "../../util/card_util"
import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios'
import {adyenCurrency, maxRetryCount} from "../../config/constants"
import {delay} from 'src/util/promise_util'
import collectBrowserInfo from "../../util/adyen/browser"
import { Storage } from 'aws-amplify'

const paymentApi = axios.create(
  {
    baseURL: process.env.REACT_APP_BILLFOLD_PAYMENTS_API_URL,
    headers: {
      'content-type': 'application/json',
    }
  }
)

paymentApi.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    const message = error.response?.data?.error?.message
    if (message) {
      return Promise.reject(message)
    } else {
      return Promise.reject(error)
    }
  }
)

paymentApi.interceptors.request.use(
  async (request: InternalAxiosRequestConfig) => {
    const token = await getIdToken()
    request.headers.setAuthorization(`Bearer ${token}`)
    return request
  },
)

export type OptInResult = {
  id: string
  version: number
  accepted: boolean
}

type EqFilter = {
  field: string
  value: string
}

export interface GetWristbandsParams {
  nextToken?: string
}

export interface WristbandsPageData {
  wristbands: Wristband[]
  nextToken?: string
}

export interface GetTransactionsParams {
  dateFrom?: string
  dateTo?: string
  amountFrom?: number
  amountTo?: number
  nextToken?: string
}

export interface TransactionsPageData {
  transactions: BalanceTransaction[]
  nextToken?: string
}

export interface GetEventsParams {
  dateFrom?: string
  dateTo?: string
  search?: string
  nextToken?: string
}

export interface EventsPageData {
  events: Event[]
  nextToken?: string
}

export const login = async (url: string, data: any): Promise<User | null> => {
  console.log(`login - url:  ${url}`)
  console.log(`login - data: ${JSON.stringify(data)}`)
  try {
    const {email, password} = data.arg
    await Auth.signIn({
      username: email.toLowerCase(),
      password: password,
    });
    const user = await getCurrentUser()
    await createStripeCustomerIfNeedTo(user)
    return user
  } catch (error: any) {
    switch (error.code) {
      case 'UserNotConfirmedException':
        console.log(`login - error: UserNotConfirmedException`)
        throw new UserNotConfirmedError()
      default:
        console.log(`login - error: ${error}`)
        throw error
    }
  }
}

export const loginWithGoogle = async (url: string, data: any) => {
  const {redirect} = data.arg
  console.log(`loginWithGoogle - redirect: ${redirect}`)
  await Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: `redirect=${redirect},socialProvider=google`
  })
}

export const loginWithFacebook = async (url: string, data: any) => {
  const {redirect} = data.arg
  console.log(`loginWithFacebook - redirect: ${redirect}`)
  await Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook,
    customState: `redirect=${redirect},socialProvider=facebook`
  })
}

export const forgotPassword = async (url: string, data: any): Promise<any> => {
  const {email} = data.arg
  return Auth.forgotPassword(email)
}

export const forgotPasswordSubmit = async (url: string, data: any): Promise<any> => {
  const {email, code, newPassword} = data.arg
  return Auth.forgotPasswordSubmit(email, code, newPassword)
}

export const confirmUser = async (url: string, data: any): Promise<boolean> => {
  console.log(`confirmUser`)
  const {email, password, code} = data.arg
  await Auth.confirmSignUp(email.toLowerCase(), code)
  // todo check why, write comment
  await Auth.signIn(email, password)
  const authUser = await Auth.currentAuthenticatedUser()
  await createUser(authUser.username, email)
  return true
}

export const resendCode = async (url: string, data: any): Promise<boolean> => {
  console.log(`resendCode`)
  const {email} = data.arg
  await Auth.resendSignUp(email.toLowerCase())
  return true
}

export const register = async (url: string, data: any): Promise<boolean> => {
  console.log(`register - signing up...`)
  const {email, password} = data.arg

  /*const existingUser = await findUser(email)

  console.log(`register - existingUser: ${existingUser}`)

  if (existingUser && existingUser.authUserID?.includes('google')) {
    throw 'User already exists, try to login with Google.'
  }

  if (existingUser && existingUser.authUserID?.includes('facebook')) {
    throw 'User already exists, try to login with Facebook.'
  }*/

  // removed this for now
  // because the new big Lousiana event is open for everyone
  //
  // if user is not in the invite list - throw an error
  // const invite = await getInvite(email)
  // if (!invite) {
  //   throw new UserNotInvitedError()
  // }

  console.log(`register - before signup`)

  const {user} = await Auth.signUp({
    username: email.toLowerCase(),
    password: password,
  });

  console.log(`register - after signup`)

  console.log(JSON.stringify(user));
  return true
}

export const deleteAuthUser = async () => {
  await Auth.deleteUser()
}

export const resendVerificationCode = async () => {

}

export const getUser = async () => {
  return await Auth.currentAuthenticatedUser()
}

export const getProfile = async (): Promise<User> => {
  return await getCurrentUser()
}

export const isClientAdmin = async (): Promise<boolean> => {
  const accessToken = (await Auth.currentSession()).getAccessToken()
  const groups = accessToken.payload["cognito:groups"]
  return (groups ?? []).includes('client-admins')
}

export const isBillfoldAdmin = async (): Promise<boolean> => {
  const accessToken = (await Auth.currentSession()).getAccessToken()
  const groups = accessToken.payload["cognito:groups"]
  return (groups ?? []).includes('billfold-admins')
}

export const getUserBalanceDetails = async () => {
}

export const getBalanceDetails = async (wristbandId: string) => {
}

export const getFavoriteEvents = async (): Promise<Event[]> => {

  const events = await requestPublic(`
    query MyQuery {
      listEvents(limit: 1000) {
        items {
          billfoldEventId
          registrationAllowed
          createdAt
          description
          featured
          finishDate
          id
          imageUrl
          imageStorageKey
          name
          startDate
          updatedAt
          pinForPurchases
          registrationTypes
          paymentAccountType
          paymentAccountCurrency
          platformAccountUuid
          platformAccountBillfoldId
          facebookUrl
          instagramUrl
          twitterUrl
          locations
          showToUsers
          organizerID
          allowedCardFundingTypes
          wristbandsFileUrl
          checkWristbandNumberInFile
          charityEnabled
          charityID
          gallery {
            items {
              id
              imageUrl
              imageStorageKey
              videoUrl
              videoStorageKey
              videoThumbnailUrl
              videoThumbnailStorageKey
              position
            }
          }
        }
        nextToken
      }
    }
  `);

  const newEvents: Event[] = events.listEvents.items.map((e: any) => ({
    ...e,
    locations: e.locations?.map((l: string) => JSON5.parse(l)),
  }))

  const user = await getCurrentUser()
  return newEvents.filter(e => user.favoriteEventIds?.includes(e.id ?? '')) ?? []

}

export const getEvents = async (params: GetEventsParams[]): Promise<EventsPageData> => {
  try {

    const [, getParams] = params

    const {
      dateFrom,
      dateTo,
      search,
      nextToken
    } = getParams

    const nextTokenString = !nextToken ? '' : `, nextToken: "${nextToken}"`

    const query = search ?? ''
    const filterObject = {
      startDate: {
        ge: dateFrom ?? '1970-01-01',
      },
      finishDate: {
        le: dateTo ?? '3000-01-01',
      },
      registrationAllowed: {
        eq: true
      },
      or: [
        {name: {contains: query}},
        {name: {contains: query.toLowerCase()}},
        {name: {contains: query.toUpperCase()}},
        {name: {contains: capitalize(query)}},
        {description: {contains: query}},
        {description: {contains: query.toLowerCase()}},
        {description: {contains: query.toUpperCase()}},
        {description: {contains: capitalize(query)}},
      ],
    }

    // todo get rid of list events this way
    // it will lead to bug if have > 100 events
    const result = await requestPublic(`
      query MyQuery {
        listEvents(
          filter: ${stringify(filterObject)}
          ${nextTokenString}
        ) {
          items {
            billfoldEventId
            registrationAllowed
            createdAt
            description
            featured
            finishDate
            id
            imageUrl
            imageStorageKey
            imageStorageKey
            name
            startDate
            updatedAt
            pinForPurchases
            registrationTypes
            paymentAccountType
            paymentAccountCurrency
            platformAccountUuid
            platformAccountBillfoldId
            facebookUrl
            instagramUrl
            twitterUrl
            locations
            showToUsers
            organizerID
            allowedCardFundingTypes
            wristbandsFileUrl
            checkWristbandNumberInFile
            charityEnabled
            charityID
            gallery {
              items {
                id
                imageUrl
                imageStorageKey
                videoUrl
                videoStorageKey
                videoThumbnailUrl
                videoThumbnailStorageKey
                position
              }
            }
          }
          nextToken
        }
      }
    `);

    const newEvents: Event[] = result.listEvents.items.map((e: any) => ({
      ...e,
      locations: e.locations?.map((l: string) => JSON5.parse(l)),
      gallery: e.gallery.items
    }))

    newEvents.sort((e1: Event, e2: Event) => (e1.startDate ?? '').localeCompare(e2.startDate ?? '') ?? 0)

    return {
      events: newEvents,
      nextToken: result.listEvents.nextToken
    }

  } catch (e: any) {
    console.log(`getEvents - error: ${e?.stack}`)
    return {
      events: [],
    }
  }
}

export const getWristbands = async (): Promise<Wristband[]> => {

  const user = await getCurrentUser();
  if (!user) {
    return []
  }

  return getList<Wristband>(
    'wristbandsByWristbandUserID',
    [
      'createdAt',
      'emailToSendReceipt',
      'eventID',
      'guid',
      'id',
      'billfoldId',
      'pinCode',
      'spendingLimit',
      'stripePaymentMethodID',
      'paymentMethodID',
      'topUpAmount',
      'wristbandNumber',
      'wristbandOwnerEmail',
      'wristbandOwnerName',
      'wristbandOwnerPhoneNumber',
      'wristbandUserID',
      'balance',
      'status',
      'purchasesWithoutPin',
      'registrationType'
    ],
    undefined,
    {
      field: 'wristbandUserID',
      value: user.id ?? ''
    }
  )
}

export const getEvent = async (params: string[]): Promise<Event | undefined> => {
  const [, eventId] = params
  const result = await requestPublic(`
    query MyQuery {
      getEvent(id: "${eventId}") {
        billfoldEventId
        registrationAllowed
        createdAt
        description
        featured
        finishDate
        id
        imageUrl
        imageStorageKey
        name
        startDate
        updatedAt
        pinForPurchases
        registrationTypes
        paymentAccountType
        paymentAccountCurrency
        platformAccountUuid
        platformAccountBillfoldId
        facebookUrl
        instagramUrl
        twitterUrl
        locations
        showToUsers
        organizerID
        allowedCardFundingTypes
        wristbandsFileUrl
        checkWristbandNumberInFile
        charityEnabled
        charityID
        gallery {
          items {
            id
            imageUrl
            imageStorageKey
            videoUrl
            videoStorageKey
            videoThumbnailUrl
            videoThumbnailStorageKey
            position
          }
        }
      }
    }
  `)

  const event = result.getEvent

  if (!event) {
    return undefined
  }

  return {
    ...event,
    locations: event.locations?.map((l: string) => JSON5.parse(l)),
    gallery: event.gallery.items
  }
}

export const updateEvent = async (url: string, data: any): Promise<Event> => {

  const event: Event = data.arg

  // delete all gallery items
  const currentItems = await getList<GalleryItem>('listGalleryItems', ['id', 'imageUrl'], {
    field: 'eventID',
    value: event.id ?? ''
  })
  await Promise.all(currentItems.map(i => remove('deleteGalleryItem', i.id ?? '')))

  // create new given items
  await Promise.all((event.gallery ?? []).map(i => addGalleryItem({
    ...i,
    eventID: event.id ?? ''
  })))

  if (event.description) {
    event.description = event.description.replace(/\n/g, "\\n")
  }

  return await update<Event>(
    'updateEvent',
    event.id ?? '',
    {
      ...event,
      locations: event.locations?.map(l => JSON.stringify(l)),
      // remove it from the object, otherwise GraphQL doesn't understand it
      gallery: undefined
    },
    [
      'billfoldEventId',
      'registrationAllowed',
      'createdAt',
      'description',
      'featured',
      'finishDate',
      'pinForPurchases',
      'registrationTypes',
      'paymentAccountType',
      'paymentAccountCurrency',
      'platformAccountUuid',
      'platformAccountBillfoldId',
      'id',
      'imageUrl',
      'imageStorageKey',
      'name',
      'startDate',
      'updatedAt',
      'showToUsers',
      'organizerID',
      'allowedCardFundingTypes',
      'wristbandsFileUrl',
      'checkWristbandNumberInFile',
      'charityEnabled',
      'charityID'
    ]
  )
}

/**
 * Use it to update very light data.
 * No locations, no gallery items
 */
export const lightUpdateEvent = async (url: string, data: any): Promise<Event> => {

  const event: Event = data.arg

  if (event.description) {
    event.description = event.description.replace(/\n/g, "\\n")
  }

  return await update<Event>(
    'updateEvent',
    event.id ?? '',
    {
      ...event,
      locations: undefined,
      gallery: undefined
    },
    [
      'billfoldEventId',
      'registrationAllowed',
      'createdAt',
      'description',
      'featured',
      'finishDate',
      'pinForPurchases',
      'registrationTypes',
      'paymentAccountType',
      'paymentAccountCurrency',
      'platformAccountUuid',
      'platformAccountBillfoldId',
      'id',
      'imageUrl',
      'imageStorageKey',
      'name',
      'startDate',
      'updatedAt',
      'showToUsers',
      'organizerID',
      'allowedCardFundingTypes',
      'wristbandsFileUrl',
      'checkWristbandNumberInFile',
      'charityEnabled',
      'charityID'
    ]
  )
}

export const getSetupIntentSecret = async (platformAccountBillfoldId: string, stripeCustomerId: string): Promise<string> => {
  console.log(`getSetupIntentSecret - account: ${platformAccountBillfoldId}, customer: ${stripeCustomerId}`)

  const response = await requestPublic(`
    query MyQuery {
      stripeCreateSetupIntent(
        stripeCustomerId: "${stripeCustomerId}",
        platformAccountId: "${platformAccountBillfoldId}"
      )
    }
  `)

  const {stripeCreateSetupIntent} = response
  if (!stripeCreateSetupIntent) {
    throw 'stripeCreateSetupIntent is null';
  }

  return stripeCreateSetupIntent

}

export const addEventToFavorite = async (eventId: string) => {
  console.log(`addEventToFavorite`)
  const user = await getCurrentUser()
  if (user.favoriteEventIds?.includes(eventId)) {
    return
  }

  user.favoriteEventIds = [...user.favoriteEventIds ?? [], eventId]
  await updateUser(user)
}

export const removeEventFromFavorite = async (eventId: string) => {

  const user = await getCurrentUser()
  if (!user.favoriteEventIds?.includes(eventId)) {
    return
  }

  user.favoriteEventIds = (user.favoriteEventIds ?? []).filter(id => id != eventId)
  await updateUser(user)
}

export const getOptIns = async (eventId: string): Promise<OptIn[]> => {
  const result = await requestPublic(`
    query MyQuery {
      listOptIns(filter: {eventId: {eq: "${eventId}"}}) {
        items {
          id
          billfoldId
          visible
          version
          updatedAt
          text
          required
          fileUrl
          eventId
          createdAt
          billfoldEventId
        }
      }
    }
  `)

  return result.listOptIns?.items ?? []

}

export const getCharity = async (charityId: string): Promise<Charity | undefined> => {
  const result = await requestPublic(`
    query MyQuery {
      getCharity(id: "${charityId}") {
        billfoldId
        createdAt
        description
        id
        updatedAt        
      }
    }
  `)

  return result.getCharity

}

export const initMockData = async () => {
}

export const clearAll = async () => {
}

export const getCurrentUser = async (): Promise<User> => {
  const authUser = await Auth.currentAuthenticatedUser()

  if (!authUser) {
    throw new UnauthorizedError()
  }

  const response = await request(`
    query MyQuery {
      usersByAuthUserID(authUserID: "${authUser.username}") {
        items {
          id
        }
      }
    }
  `)
  const user = response.usersByAuthUserID.items[0]

  if (!user) {
    throw new Error('User can not be found')
  }

  const userWithData = await request(`
    query MyQuery {
      getUser(id: "${user?.id}") {
        authUserID
        createdAt
        email
        emailConfirmed
        fcmToken
        id
        linkedPaymentMethodId
        stripeCustomerID
        paymentCustomerIds
        updatedAt
        autoTopUpConfig
        verifiedOver21
        verifiedOver21Time
        address
        avatarUrl
        dateOfBirth
        firstName
        lastName
        phoneNumber
        favoriteEventIds
      }
    }
  `)

  return userWithData.getUser

}

export const getCurrentUserOrNull = async (): Promise<User | undefined> => {
  try {
    return getCurrentUser()
  } catch (e) {
    return undefined
  }
}

export const getIdToken = async (): Promise<string> => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

export const getWristband = async (params: string[]): Promise<Wristband> => {
  const [, wristbandId] = params
  return await get<Wristband>(
    'getWristband',
    wristbandId,
    [
      'createdAt',
      'emailToSendReceipt',
      'eventID',
      'guid',
      'id',
      'billfoldId',
      'pinCode',
      'spendingLimit',
      'stripePaymentMethodID',
      'paymentMethodID',
      'topUpAmount',
      'wristbandNumber',
      'wristbandOwnerEmail',
      'wristbandOwnerName',
      'wristbandOwnerPhoneNumber',
      'wristbandUserID',
      'balance',
      'status',
      'purchasesWithoutPin',
      'registrationType'
    ]
  )
}

export const getWristbandByNumberForEvent = async (params: string[]): Promise<Wristband | undefined> => {
  const [, wristbandNumber, eventId] = params

  const data = await request(`
    query MyQuery {
      wristbandsByWristbandNumber(wristbandNumber: "${wristbandNumber}", filter: {eventID: {eq: "${eventId}"}}) {
        items {
          balance
          billfoldId
          createdAt
          emailToSendReceipt
          eventID
          guid
          id
          newsSubscription
          pinCode
          purchasesWithoutPin
          spendingLimit
          status
          stripePaymentMethodID
          paymentMethodID
          topUpAmount
          updatedAt
          wristbandNumber
          wristbandOwnerEmail
          wristbandOwnerName
          wristbandOwnerPhoneNumber
          wristbandUserID
          registrationType
        }
      }
    }
  `)

  const items = data.wristbandsByWristbandNumber.items
  if (!items || items.length === 0) {
    return undefined
  }

  return items[0]
}

export const updateWristband = async (event: Event, wristband: Wristband, cardId: string): Promise<Wristband> => {
  console.log(`updateWristband - wristband: `, wristband)

  const user = await getCurrentUser();
  const {name, registrationAllowed, billfoldEventId} = event

  if (!name) {
    throw 'Name is missing on event'
  }

  if (!registrationAllowed) {
    throw 'Update wristband to this event is not allowed'
  }

  const clientToken = await getIdToken()

  if (wristband) {
    delete wristband.createdAt
    delete wristband.updatedAt
    delete wristband.balance
    wristband.wristbandOwnerPhoneNumber = (wristband.wristbandOwnerPhoneNumber ?? '').replace(' ', '')
  }

  try {
    const {updateWristband} = await requestPublic(`
      query MyQuery {
        updateWristband(
          input: {
            clientToken: "${clientToken}",
            userId: "${user.id}",
            eventId: "${event?.id}",
            billfoldEventId: "${billfoldEventId}",
            cardId: "${cardId}",
            wristband: ${stringify(wristband)}
          }
        ) 
      } 
    `)
    const updatedWristbandId = updateWristband as string

    if (!updatedWristbandId) {
      throw 'Updated wristband is NULL';
    }

    return get<Wristband>(
      'getWristband',
      updatedWristbandId,
      [
        'createdAt',
        'emailToSendReceipt',
        'eventID',
        'guid',
        'id',
        'pinCode',
        'spendingLimit',
        'stripePaymentMethodID',
        'paymentMethodID',
        'topUpAmount',
        'wristbandNumber',
        'wristbandOwnerEmail',
        'wristbandOwnerName',
        'wristbandOwnerPhoneNumber',
        'wristbandUserID',
        'balance',
        'status',
        'purchasesWithoutPin',
        'registrationType'
      ]
    )

  } catch (e: any) {
    console.log(`updateWristband - error: ${e}`)
    console.log(`updateWristband - error: ${JSON.stringify(e.response?.errors)}`)
    throw new Error(getErrorMessage(e))
  }

}

export const getAutoTopUpConfig = async (): Promise<AutoTopUpConfig> => {
  const user = await getCurrentUser();
  return {}
}

export const setAutoTopUpConfig = async (url: string, data: any) => {
  const {config} = data.arg
  if (!config) {
    throw 'Config is missing'
  }
  // todo implement it
}

export const deleteAutoTopUpConfig = async (url: string, data: any) => {
  const {configId} = data.arg

  if (!configId) {
    throw 'Config id is missing'
  }

  const user = await getCurrentUser()
  await remove<AutoTopUpConfig>('deleteAutoTopUpConfig', configId)
  await update<User>('updateUser', user.id ?? '', {userAutoTopUpConfigId: ''})

}

export const disableAutoTopUp = () => {
}

export const topUp = (paymentMethodId: string, amount: number) => {
}

export const updateUser = async (user: User): Promise<User> => {
  console.log(`updateUser`)

  // Delete object fields, keep the primitives only
  // because we can't update it via updateUser graphql query
  delete user.wristbands
  delete user.autoTopUpConfig
  delete user.favoriteEvents
  delete user.watchedStories
  delete user.userBalanceDetails
  return await update<User>('updateUser', user.id ?? '', {...user})
}

/**
 * Used by admin only in dev env only
 */
export const createBalanceTransaction = async (transaction: BalanceTransaction): Promise<BalanceTransaction | null> => {

  if (process.env.REACT_APP_ENV != 'dev') {
    throw new Error('Balance transactions can be created on DEV only')
  }

  if (!(await isBillfoldAdmin())) {
    throw new Error('Balance transactions can be created by admin only')
  }

  try {
    return create('createBalanceTransaction', transaction);
  } catch (e) {
    console.log(`createBalanceTransaction - error: ${e}`)
    return null
  }

}

export const getBalanceTransactions = async (params: GetTransactionsParams[]): Promise<TransactionsPageData> => {

  try {

    const [, getParams] = params

    const {
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      nextToken
    } = getParams

    const nextTokenString = !nextToken ? '' : `, nextToken: "${nextToken}"`

    const user = await getCurrentUser()

    const createdAtObject = {
      between: [
        dateFrom ?? '2020-01-01',
        dateTo ?? formatDate(DateTime.now().plus({days: 1}).toJSDate())
      ]
    }

    const filterObject = {
      amount: {
        ge: amountFrom ?? -1000000000,
        le: amountTo ?? 1000000000
      },
    }

    const result = await request(`
      query MyQuery {
        listBalanceTransactionsByUser(
          userID: "${user.id}", 
          createdAt: ${stringify(createdAtObject)}, 
          filter: ${stringify(filterObject)}
          ${nextTokenString}
        ) {
          items {
            id
            billfoldId
            createdAt
            updatedAt
            amount
            currency
            type
            purpose
            paymentMethodID
            wristbandID
            eventID
            userID
          }
          nextToken
        }
      }
    `);

    return {
      transactions: result.listBalanceTransactionsByUser.items,
      nextToken: result.listBalanceTransactionsByUser.nextToken
    }

  } catch (e: any) {
    console.log(`getBalanceTransactions - error: ${e?.stack}`)
    return {
      transactions: [],
    }
  }

}

// todo move this to the server
// it's not good the client app has all invited emails
export const getInvites = async (): Promise<Invite[]> => {
  await checkAdmin()
  const invites = await getList<Invite>('listInvites', ['id', 'name', 'email', 'createdAt', 'accepted'])
  invites.sort((i1, i2) => i1.email.localeCompare(i2.email))
  return invites
}

export const getInvite = async (email: string): Promise<Invite | null> => {
  return getFirstOrNull<Invite>('listInvites', ['id', 'name', 'email', 'createdAt', 'accepted'], {
    field: 'email',
    value: email
  })
}

export const deleteInvite = async (url: string, data: any): Promise<Invite> => {
  await checkAdmin()
  const inviteId: string = data.arg
  return await remove<Invite>('deleteInvite', inviteId)
}

export const addInvite = async (url: string, data: any): Promise<Invite | null> => {
  await checkAdmin()
  const email = data.arg
  return await addInviteInner(email)
}

export const addInvites = async (url: string, data: any) => {
  await checkAdmin()
  const emails = data.arg
  for (const e of emails) {
    await addInviteInner(e)
  }
}

export const getOrders = (
  query: string,
  pageNumber: number,
  pageSize: number,
  dateFrom: Date,
  dateTo: Date,
  amountFrom: number,
  amountTo: number,
) => {
}

export const getClearUserInfo = async (authCode: string): Promise<ClearUserInfo> => {
  const user = await getCurrentUser()
  const clearUrl = clearRedirectUrl()
  console.log(`getClearUserInfo - clearRedirectUrl: ${clearUrl}`)
  const result = await request(`
    query MyQuery {
      getClearUserInfo(
        input: {
          email: "${user.email}",
          authCode: "${authCode}",
          redirectUri: "${clearUrl}",
        }
      ) { 
        over21
        phone
      }
    } 
  `)
  return result.getClearUserInfo
}

export const getSystemSettings = async (): Promise<SystemSettings | undefined> => {
  const response = await requestPublic(`
    query getSettings {
      listSystemSettings {
        items {
          id
          eventsLastSyncTime
          paymentTxsLastSyncTime
          balanceTxsLastSyncTime
          wristbandsLastSyncTime
          maintenance
          ageVerificationValidTime
        }
      }
    }
  `)

  const list = response.listSystemSettings?.items ?? []
  return list.length == 0 ? undefined : list[0]
}

export const getSystemHealth = async (): Promise<SystemHealth | undefined> => {

  const response = await request(`
    query getHealth{
      listSystemHealths {
        items {
          id
          apiKeysValid
          socialUrlsValid
          lastCheckTime
        }
      }
    }
  `)

  const healths = response.listSystemHealths?.items

  if (!healths || healths.length == 0) {
    return undefined
  }

  return healths[0]


}

export const updateSystemSettings = async (url: string, data: any): Promise<SystemSettings | null> => {
  const {settings} = data.arg
  console.log(`updateSystemSettings - settings: ${JSON.stringify(settings)}`)
  return update<SystemSettings>(
    'updateSystemSettings',
    settings?.id ?? '',
    settings,
    [
      'id',
      'eventsLastSyncTime',
      'paymentTxsLastSyncTime',
      'balanceTxsLastSyncTime',
      'wristbandsLastSyncTime',
      'maintenance',
      'ageVerificationValidTime',
    ]
  )
}

/**
 * Uploads file to the Amplify storage and returns the storage key
 *
 * @param eventId id of the event the file is related to
 * @param file the file itself
 * @return AWS Amplify storage key
 */
export const uploadFile = async (eventId: string, file: File) : Promise<string> => {
  try {
    const fileName = `event_file_${eventId}_${DateTime.now().toFormat('yyyy-MM-dd_hh-mm-ss')}`

    const result = await Storage.put(
      `event-files/${fileName}`,
      file,
      {
        contentType: file.type,
        level: 'public'
      }
    )

    // return Storage.get(result.key, { level: 'public' })
    return result.key

  } catch (error) {
    console.log(`uploadFile - Error uploading file: ${error}`)
    return ''
  }

}

export const downloadFile = async (fileUrl: string) => {
    console.log(`downloadFile`)
    try {
      const result = await axios.get(fileUrl)
      console.log(`downloadFile - result: `, result)
      return result.data
    } catch (e) {
      console.log(`getCountryCode - error: ${e}`)
      return ''
    }

}

function createFileFromBuffer(arrayBuffer: ArrayBuffer, fileName: string, fileType: string): File {
  const blob = new Blob([arrayBuffer], {type: fileType})
  return new File([blob], fileName)
}

export const getGoogleMapStaticImage = async (address: string, latitude: number, longitude: number): Promise<File> => {
  const url = 'https://maps.googleapis.com/maps/api/staticmap' +
    `?center=${latitude},${longitude}` +
    '&zoom=17' +
    '&scale=2' +
    '&size=640x640' +
    `&markers=color:red%7C${latitude},${longitude}` +
    `&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

  console.log(`getGoogleMapStaticImage - ${url}`)

  const res = await axios.get(url, {responseType: 'arraybuffer'});
  return createFileFromBuffer(
    res.data as ArrayBuffer,
    `location_${latitude}_${longitude}_${formatDate(new Date(), 'yyyy-MM-dd-hh-mm-ss')}.png`,
    'image/png'
  )
}

export const getPlatformAccounts = async (): Promise<PlatformAccount[]> => {
  return getList<PlatformAccount>(
    'listPlatformAccounts',
    [
      'id',
      'billfoldId',
      'uuid',
      'countryCode',
      'type'
    ],
  )
}

export const createUser = async (authUserId: string, email: string, firstName?: string, lastName?: string): Promise<User> => {
  const existingUser = await findUser(email)

  if (existingUser) {
    throw 'User exists'
  }

  const user = await create<User>(
    'createUser',
    {
      email: email,
      emailConfirmed: true,
      authUserID: authUserId,
      firstName: firstName,
      lastName: lastName
    },
    ['id', 'email', 'paymentCustomerIds']
  )

  await createStripeCustomerIfNeedTo(user)

  return user

}

export const findUser = async (email: string): Promise<User | null> => {
  const items = await findUsers(email)
  return items == null || items?.length == 0 ? null : items[0]
}

export const findUsers = async (email: string): Promise<User[] | null> => {
  console.log(`findUsers - email: ${email}`)

  if (email.length == 0) {
    return []
  }

  const response = await request(`
    query MyQuery {
      usersByEmail(email: "${email}") {
        items {
          id
          email
          authUserID
          paymentCustomerIds
        }
      }
    }
  `)

  console.log(`findUsers - response: `, response)

  return response.usersByEmail.items
}

export const deleteUserData = async (email: string): Promise<boolean> => {
  console.log(`deleteUserData - ${email}`)

  // get user with all wristbands and transactions
  const response = await request(`
    query MyQuery {
      usersByEmail(email: "${email}") {
        items {
          id
          owner
          authUserID
          firstName
          lastName
          dateOfBirth
          phoneNumber
          wristbands {
            items {
              id
            }
          }
          balanceTransactions {
            items {
              id
            }
          }
        }
      }
    }
  `)

  const user = response.usersByEmail.items[0]

  if (user == null) {
    return true
  }

  const wristbands = user.wristbands.items
  await Promise.all(wristbands?.map((w: any) => request(`
    mutation MyMutation {
      deleteWristband(input: {id: "${w.id}"}) {
        id
      }
    }
  `)))

  const transactions = user.balanceTransactions.items
  await Promise.all(transactions?.map((t: any) => request(`
    mutation MyMutation {
      deleteBalanceTransaction(input: {id: "${t.id}"}) {
        id
      }
    }
  `)))

  await request(`
    mutation MyMutation {
      deleteUser(input: {id: "${user.id}"}) {
        id
      }
    }
  `)

  return true
}

export const stripeCreateCustomer = async (email: string): Promise<Record<string, StripeCustomer>> => {
  console.log(`stripeCreateCustomer - email: ${email}`)
  try {
    const response = await request(`
      query MyQuery {
        stripeCreateCustomer(email: "${email}")
      } 
    `)
    console.log(`stripeCreateCustomer - response: `, response)
    return JSON.parse(response.stripeCreateCustomer) as Record<string, StripeCustomer>
  } catch (e) {
    console.log(`stripeCreateCustomer - error:`, e)
    throw e
  }
}

export const stripeGetCustomer = async (): Promise<StripeCustomer> => {
  console.log(`stripeGetCustomer`)
  const user = await getCurrentUser()
  // todo fix it later
  const response = await request(`
    query MyQuery {
      stripeGetCustomer(customerId: "")
    } 
 `)
  return JSON.parse(response.stripeGetCustomer) as StripeCustomer
}

export const stripeUpdateCustomerName = async (platformAccountId: string, customerId: string, name: string): Promise<StripeCustomer> => {
  console.log(`stripeUpdateCustomerName`)
  const response = await request(`
    query MyQuery {
      stripeUpdateCustomerName(
        platformAccountId: "${platformAccountId}", 
        customerId: "${customerId}", 
        name: "${name}"
      )
    } 
  `)
  return JSON.parse(response.stripeUpdateCustomerName) as StripeCustomer
}

export const getCards = async (): Promise<Card[]> => {
  const user = await getCurrentUser()
  const response = await request(`
    query MyQuery {
      cardsByUserID(userID: "${user.id}") {
        items {
          userID
          userCardsId
          updatedAt
          paymentMethodIds
          owner
          last4
          id
          createdAt
          brand
        }
      }
    }
  `)
  console.log(`getCards - response: `, response)
  return response.cardsByUserID.items
}

export const getCard = async (cardId: string): Promise<Card> => {
  const response = await request(`
    query MyQuery {
      getCard(id: "${cardId}") {
        brand
        createdAt
        id
        last4
        owner
        paymentMethodIds
        userCardsId
        userID
      }
    }
  `)
  console.log(`getCard - response: `, response)
  return response.getCard
}

export const stripeAddCard = async (
  elements: StripeElements,
  stripe: Stripe,
  cardholderName: string,
  platformAccountId: string,
  paymentCustomerId: string,
  allowedCardFundingTypes?: string[],
): Promise<Card> => {

  // const {elements, stripe, cardholderName} = data.arg

  if (!stripe) {
    throw Error('No stripe object')
  }

  if (!elements) {
    throw Error('No elements object')
  }

  const user = await getCurrentUser()

  await stripeUpdateCustomerName(platformAccountId, paymentCustomerId ?? '', cardholderName)
  console.log(`addCard - stripe customer updated with name: ${cardholderName}`)

  const {setupIntent, error} = await (stripe as Stripe).confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        expand: ['payment_method'],
        return_url: 'http://localhost:3000/account',
        payment_method_data: {
          billing_details: {
            name: cardholderName,
          }
        }
      },
    }
  );

  if (error) {
    console.log(`addCard - error: `, error)
    throw error
  }

  if (!setupIntent) {
    throw 'Setup intent is empty'
  }

  const {status, payment_method} = setupIntent

  switch (status) {
    case 'canceled':
      throw 'Add card cancelled';
    case 'requires_confirmation':
    case 'requires_action':
    case 'processing':
      return {}
    case 'requires_payment_method':
      throw 'Payment requires payment method';
    case 'succeeded':

      const stripeCard = (payment_method as StripePaymentMethod)?.card
      const stripeMethodId = (payment_method as StripePaymentMethod)?.id

      const funding = stripeCard?.funding ?? ''
      const types = allowedCardFundingTypes ?? []
      if (types.length > 0 && !types.includes(funding)) {
        switch (funding) {
          case 'debit':
            throw new DebitCardNotSupportedError()
          case 'credit':
            throw new CreditCardNotSupportedError()
          default :
            throw new CardTypeNotSupportedError()
        }
      }

      const cards = await getCards()
      const existingCard = cards.find(c => c.last4 == stripeCard?.last4 && c.brand == stripeCard?.brand)
      let resultCard

      if (existingCard) {
        // case if we have the same card
        const oldIds = parse(existingCard.paymentMethodIds ?? '{}')
        console.log(`stripeAddCard - oldIds: `, oldIds)

        // handling only cases when we have NOT this card for this platform account already
        // otherwise do nothing here
        if (!oldIds[platformAccountId]) {
          const newIds = {
            ...oldIds,
            [platformAccountId]: stripeMethodId,
          }
          console.log(`stripeAddCard - newIds: `, newIds)
          const newIdsString = stringify(newIds).replaceAll('"', '\\"')
          resultCard = (await request(`
            mutation MyMutation {
              updateCard(input: {paymentMethodIds: "${newIdsString}", id: "${existingCard.id}"}){
                userID
                userCardsId
                updatedAt
                paymentMethodIds
                owner
                last4
                id
                createdAt
                brand
              }
            }
          `)).updateCard
        } else {
          resultCard = existingCard
        }
      } else {
        // case if we have NO such card yet, so creating a fresh new one
        resultCard = await createCard({
          brand: normalizeBrand(stripeCard?.brand),
          last4: stripeCard?.last4,
          userID: user?.id ?? '',
          paymentMethodIds: stringify({[platformAccountId]: stripeMethodId})
        })
      }

      return resultCard
    default:
      throw `Error during adding the card: ${setupIntent.status}`
  }
}

export const adyenAddCard = async (
  encryptedCardNumber: string,
  encryptedExpiryMonth: string,
  encryptedExpiryYear: string,
  encryptedSecurityCode: string,
  holderName: string,
  paymentAccountType: string,
  platformAccountId: string,
  userId: string,
): Promise<Card | undefined> => {

  const response = await paymentApi.post(
    'cards',
    {
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
      holderName,
      // todo implement getting keyUuid based on platformAccountId
      // todo stop keep platform accounts being connected to Billfold server
      // stop syncing, keep it separately
      platformAccountKeyUuid: process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID ?? '',
      currency: adyenCurrency,
      merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
      customerId: userId,
    }
  )
  const adyenCard = response.data

  if (!adyenCard) {
    throw new Error('Can not create Adyen card')
  }

  const cards = await getCards()
  const existingCard = cards.find(c => c.last4 == adyenCard.last4 && sameBrand(c.brand, adyenCard.brand))
  let resultCard
  const idKey = `${paymentAccountType}_${platformAccountId}`

  if (existingCard) {
    // case if we have the same card
    const oldIds = parse(existingCard.paymentMethodIds ?? '{}')
    console.log(`stripeAddCard - oldIds: `, oldIds)

    // handle only cases when we have NO this card for this platform account already
    // otherwise do nothing here
    if (!oldIds[idKey]) {
      const newIds = {
        ...oldIds,
        [idKey]: adyenCard.paymentMethodId,
      }
      const newIdsString = stringify(newIds).replaceAll('"', '\\"')
      resultCard = (await request(`
        mutation MyMutation {
          updateCard(input: {paymentMethodIds: "${newIdsString}", id: "${existingCard.id}"}){
            userID
            userCardsId
            updatedAt
            paymentMethodIds
            owner
            last4
            id
            createdAt
            brand
          }
        }
      `)).updateCard
    } else {
      resultCard = existingCard
    }
  } else {
    // case if we have NO such card yet, so creating a fresh new one
    resultCard = await createCard({
      brand: normalizeBrand(adyenCard?.brand),
      last4: adyenCard?.last4,
      userID: adyenCard?.customerId,
      paymentMethodIds: stringify({[idKey]: adyenCard?.paymentMethodId}),
      holderName: adyenCard.holderName,
      expiryMonth: adyenCard.expiryMonth,
      expiryYear: adyenCard.expiryYear,
    })
  }

  return resultCard

}

export const adyenGetCards = async (
  userId: string,
  platformAccountKeyUuid: string,
  isProd?: boolean
): Promise<BFPaymentsCard[]> => {

  const response = await paymentApi.get(
    'cards',
    {
      params: {
        platformAccountKeyUuid,
        merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
        customerId: userId,
        isProd: isProd
      }
    }
  )

  return response.data

}

export const adyenMakePayment = async (
  cardId: string,
  amount: number,
  // wristbandNumber: string,
  // eventId: string,
  userId: string,
  reference: string,
  browserInfo: any,
): Promise<any> => {

  console.log(`adyenMakePayment - browserInfo: `, browserInfo)

  const response = await paymentApi.post(
    'payments',
    {
      currency: adyenCurrency,
      amount: amount,
      reference: reference,
      returnUrl: process.env.REACT_APP_ADYEN_RETURN_URL ?? '',
      merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
      paymentMethodId: cardId,
      customerId: userId,
      browserInfo: browserInfo,
      // wristbandNumber: wristbandNumber,
      // eventI: eventId,
    }
  )
  return response.data

}

export const getAdyenSession = async (
  customerId: string,
  // paymentMethodId: string,
  reference: string,
  amount: number,
): Promise<any> => {
  const response = await paymentApi.post(
    'payments/adyen-session',
    {
      currency: adyenCurrency,
      amount: amount,
      reference: reference,
      returnUrl: "localhost:3000/adyen-callback",
      merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
      platformAccountKeyUuid: process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID ?? '',
      // paymentMethodId: paymentMethodId,
      customerId: customerId,
    }
  )
  return response.data
}

export const applePayGetSession = async () => {
  const response = await paymentApi.post('payments/apple-pay-session', {
    merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
    platformAccountKeyUuid: process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID_PROD ?? '',
    countryCode: process.env.REACT_APP_ADYEN_COUNTRY_CODE ?? '',
    isProd: true,
  })
  return response.data
}

export const applePayCompletePayment = async (
  paymentData: string,
  currency: string,
  amountInCents: number,
  reference: string
) => {

  // export interface BFApplePayCompletePaymentRequest {
  //   merchantAccount: string
  //   platformAccountKeyUuid: string
  //   paymentData: any
  //   currency: string
  //   amount: number
  //   reference: string
  //   returnUrl: string
  //   isProd?: boolean
  // }

  const user = await getCurrentUser()

  const response = await paymentApi.post('payments/apple-pay-complete-payment', {
    merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
    platformAccountKeyUuid: process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID_PROD ?? '',
    paymentData: paymentData,
    currency: currency,
    amount: amountInCents,
    reference: reference,
    returnUrl: process.env.REACT_APP_ADYEN_RETURN_URL ?? '',
    customerId: user.id,
    isProd: true,
  })
  return response.data
}

export const googlePayCompletePayment = async (
  paymentData: string,
  currency: string,
  amountInCents: number,
  reference: string
) => {

  const user = await getCurrentUser()

  const response = await paymentApi.post('payments/google-pay-complete-payment', {
    merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
    platformAccountKeyUuid: process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID ?? '',
    paymentData: paymentData,
    currency: currency,
    amount: amountInCents,
    reference: reference,
    returnUrl: process.env.REACT_APP_ADYEN_RETURN_URL ?? '',
    customerId: user.id,
    browserInfo: collectBrowserInfo(),
  })

  return response.data
}

export const adyenMakeRefund = async (
  originalTxId: string,
  currency: string,
  amount: number,
  options?: { isProd: boolean }
) => {
  const {isProd} = options ?? {}
  const response = await paymentApi.post(`payments/${originalTxId}/refund`, {
    merchantAccount: process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT ?? '',
    platformAccountKeyUuid: (isProd
      ? process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID_PROD
      : process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID) ?? '',
    currency: currency,
    amount: amount,
    isProd: isProd
  })
  return response.data
}

export const stripeDeleteCard = async (cardId: string): Promise<StripePaymentMethod> => {
  const response = await request(`
    query MyQuery {
      stripeDeleteCard(cardId: "${cardId}")
    } 
  `)
  return JSON.parse(response.stripeDeleteCard) as StripePaymentMethod
}

export const createStripeCustomerIfNeedTo = async (user: User) => {
  console.log(`createStripCustomerIfNeedTo`)
  const {id, email, paymentCustomerIds} = user

  if (paymentCustomerIds) {
    console.log(`createStripCustomerIfNeedTo - paymentCustomerIds already exists, exit`)
    return
  }

  const stripeCustomers = await stripeCreateCustomer(email ?? '');

  console.log(`createStripeCustomerIfNeedTo - stripeCustomers: `, stripeCustomers)
  console.log(`createStripeCustomerIfNeedTo - stripeCustomers keys: `, Object.keys(stripeCustomers))
  // console.log(`createStripeCustomerIfNeedTo - stripeCustomers keys: `, stripeCustomers.keys())
  // console.log(`createStripeCustomerIfNeedTo - stripeCustomers entries: `, stripeCustomers.entries)
  // console.log(`createStripeCustomerIfNeedTo - stripeCustomers entries: `, stripeCustomers.entries())

  const newPaymentCustomerIds: Record<string, string> = {}

  for (const key of Object.keys(stripeCustomers)) {
    console.log(`createStripeCustomerIfNeedTo - iterating over: `, key, stripeCustomers[key])
    newPaymentCustomerIds[key] = stripeCustomers[key].id ?? ''
  }

  const input = stringify(newPaymentCustomerIds).replaceAll('"', '\\"')
  await request(`
    mutation MyMutation {
      updateUser(input: {paymentCustomerIds: "${input}", id: "${id}"}) {
        id
      }
    }
  `)
}

export const registerWristband = async (
  event: Event,
  wristband: Wristband,
  cardId: string,
  optIns?: OptInResult[]
): Promise<Wristband | undefined> => {

  console.log(`registerWristband - wristband: `, wristband)

  const user = await getCurrentUser();

  const {wristbandNumber} = wristband as Wristband
  const {name, registrationAllowed} = event as Event

  if (!name) {
    throw 'Name is missing on event'
  }

  if (!registrationAllowed) {
    throw 'Registration to this event is not allowed'
  }

  if (wristbandRegistrationOnly(event) && !wristbandNumber) {
    throw 'This event allows registration with wristbands only'
  }

  if (qrRegistrationOnly(event) && !!wristbandNumber) {
    throw 'This event allows registration via QR only'
  }

  const clientToken = await getIdToken()

  wristband.wristbandOwnerPhoneNumber = (wristband.wristbandOwnerPhoneNumber ?? '').replaceAll(' ', '')
  wristband.wristbandOwnerEmail = wristband.wristbandOwnerEmail?.toLowerCase()
  delete wristband.billfoldId

  const response = await requestPublic(`
    query MyQuery {
      registerWristband(
        input: {
          clientToken: "${clientToken}",
          userId: "${user.id}",
          eventId: "${event.id}",
          cardId: "${cardId}",
          billfoldEventId: "${event.billfoldEventId}",
          wristband: ${stringify(wristband)},
          optIns: ${stringify(optIns)}          
        } 
      )
    } 
  `)

  const registeredWristband = await getWristband(['', response.registerWristband]);
  if (registeredWristband == null) {
    throw 'Registered wristband is NULL';
  }

  return registeredWristband
}

export const registerWristbandByQr = async (
  wristbandId: string,
  wristbandNumber: string,
  eventId: string
): Promise<Wristband | undefined> => {

  console.log(`registerWristbandByQr - wristbandId: `, wristbandId)

  const clientToken = await getIdToken()

  const response = await requestPublic(`
    query MyQuery {
      registerWristbandByQr(
        wristbandId: "${wristbandId}", 
        wristbandNumber: "${wristbandNumber}",
        eventId: "${eventId}", 
        clientToken: "${clientToken}",
      )
    } 
  `)

  console.log(`registerWristbandByQr - response: `, response.registerWristbandByQr)

  // const registeredWristband = await getWristband(['', response.registerWristband]);
  // if (registeredWristband == null) {
  //   throw 'Registered wristband is NULL';
  // }

  return response.registerWristbandByQr
}

export const createCard = async (card: Card) => {
  console.log(`createCard - card: `, card)
  return create<Card>(
    'createCard',
    card,
    [
      'id',
      'owner',
      'paymentMethodIds',
      'last4',
      'brand',
      'userID',
    ]
  )
}

export const updateCard = async (cardId: string, card: Card) => {
  console.log(`updateCard - card: `, card)
  return update<Card>(
    'updateCard',
    cardId,
    card,
    [
      'id',
      'owner',
      'paymentMethodIds',
      'last4',
      'brand',
      'userID',
    ]
  )
}

export const getOrganizers = async (): Promise<Organizer[]> => {
  const response = await requestPublic(`
    query MyQuery {
      listOrganizers(limit: 1000) {
        items {
          id
          billfoldId
          name
          integratorID
          description
          code
          createdAt
          updatedAt
          discardedAt
        }
      }
    }
  `);
  return response.listOrganizers.items ?? []
}

export const getOrganizer = async (organizerId: string): Promise<Organizer> => {
  const response = await requestPublic(`
    query MyQuery {
      getOrganizer(id: "${organizerId}") {
        id
        billfoldId
        name
        integratorID
        description
        code
        createdAt
        updatedAt
        discardedAt
      }
    }
  `);
  return response.getOrganizer
}

export const getOrganizerByCode = async (code: string): Promise<Organizer> => {
  const response = await requestPublic(`
    query MyQuery {
      listOrganizers(limit: 1000) {
        items {
          id
          billfoldId
          name
          integratorID
          description
          code
          createdAt
          updatedAt
          discardedAt
        }
      }
    }
  `);
  const organizers = response.listOrganizers.items ?? []
  return organizers.find((o: Organizer) => o.code == code)
}

export const getIntegrators = async (): Promise<Integrator[]> => {
  const response = await requestPublic(`
    query MyQuery {
      listIntegrators(limit: 1000) {
        items {
          id
          billfoldId
          name
          createdAt
          updatedAt
          discardedAt
        }
      }
    }
  `);
  return response.listIntegrators.items ?? []
}

export const getIntegrator = async (integratorId: string): Promise<Integrator> => {
  const response = await requestPublic(`
    query MyQuery {
      getIntegrator(id: "${integratorId}") {
        id
        billfoldId
        name
        createdAt
        updatedAt
        discardedAt
      }
    }
  `);
  return response.getIntegrator
}

export const getRedirectRules = async (): Promise<RedirectRule[]> => {
  const response = await requestPublic(`
    query MyQuery {
      listRedirectRules {
        items {
          id
          fromEventId
          fromBatchCode
          toEventId
          toOrganizerCode
          createdAt
        }
      }
    }
  `);
  return response.listRedirectRules.items ?? []
}

export const createRedirectRule = async (rule: RedirectRule): Promise<RedirectRule> => {
  return create<RedirectRule>(
    'createRedirectRule',
    rule,
    [
      'id',
      'fromEventId',
      'fromBatchCode',
      'toEventId',
      'toOrganizerCode'
    ]
  )
}

export const updateRedirectRule = async (rule: RedirectRule): Promise<RedirectRule> => {
  return update<RedirectRule>(
    'updateRedirectRule',
    rule.id ?? '',
    rule,
    [
      'id',
      'fromEventId',
      'fromBatchCode',
      'toEventId',
      'toOrganizerCode'
    ]
  )
}

export const deleteRedirectRule = async (rule: RedirectRule): Promise<RedirectRule> => {
  return remove<RedirectRule>(
    'deleteRedirectRule',
    rule.id ?? ''
  )
}

export const getWristbandBatches = async (): Promise<WristbandBatch[]> => {
  const response = await requestPublic(`
    query MyQuery {
      listWristbandBatches(limit: 1000) {
        items {
          id
          code
          amount
          orderDate
          supplier
          organizerID
        }
      }
    }
  `);
  return response.listWristbandBatches.items ?? []
}

export const getWristbandBatch = async (batchId: string): Promise<WristbandBatch> => {
  const response = await requestPublic(`
    query MyQuery {
      getWristbandBatch(id: "${batchId}") {
        id
        code
        amount
        orderDate
        supplier
        organizerID
      }
    }
  `);
  return response.getWristbandBatch
}

export const getWristbandBatchByCode = async (code: string): Promise<Organizer> => {
  const response = await requestPublic(`
    query MyQuery {
      listWristbandBatches(limit: 1000) {
        items {
          id
          code
          amount
          orderDate
          supplier
          organizerID
        }
      }
    }
  `);
  const organizers = response.listWristbandBatches.items ?? []
  return organizers.find((b: WristbandBatch) => b.code == code)
}

export const createWristbandBatch = async (batch: WristbandBatch): Promise<WristbandBatch> => {
  if (batch.code) {
    batch.code = batch.code.toLowerCase();
  }
  return create<WristbandBatch>(
    'createWristbandBatch',
    batch,
    [
      'id',
      'code',
      'amount',
      'orderDate',
      'supplier',
      'organizerID'
    ]
  )
}

export const updateWristbandBatch = async (batch: WristbandBatch): Promise<WristbandBatch> => {
  return update<WristbandBatch>(
    'updateWristbandBatch',
    batch.id ?? '',
    batch,
    [
      'id',
      'code',
      'amount',
      'orderDate',
      'supplier',
      'organizerID'
    ]
  )
}

export const deleteWristbandBatch = async (batchId: string): Promise<WristbandBatch> => {
  return remove<WristbandBatch>('deleteWristbandBatch', batchId)
}

export const isWristbandNumberAllowed = async (wristbandsFileUrl: string, wristbandNumber: string): Promise<boolean> => {
  const response = await requestPublic(`
    query MyQuery {
      isWristbandNumberAllowed(
        wristbandsFileUrl: "${wristbandsFileUrl}",
        wristbandNumber: "${wristbandNumber}"
      )
    } 
  `)
  return response.isWristbandNumberAllowed == 'true'
}

//
const requestPrivate = async (query: string): Promise<any> => {
  return request(query, true)
}

const requestPublic = async (query: string): Promise<any> => {
  return request(query, false)
}

const request = async (query: string, isPrivate: boolean = true, retryCount = 0): Promise<any> => {
  try {
    // console.log(`request - token: ${await getIdToken()}`)
    const result: any = isPrivate
      ? await API.graphql(
        {
          query,
          authToken: await getIdToken(),
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        }
      )
      : await API.graphql(
        {
          query,
          authMode: 'API_KEY',
        }
      )
    return result.data
  } catch (e: any) {

    if (e.errors?.length > 0 && e.errors[0].message == 'Network Error') {
      console.log(`request - network error`)

      if (retryCount < maxRetryCount) {
        // todo implement retry logic with increasing delay
        console.log(`request - retry in ${retryCount} seconds`)
        await delay(1000 * retryCount)
        return request(query, isPrivate, retryCount + 1)
      }

      throw 'Network Error'
    }

    console.log(`request - error: `, e)
    let message = getErrorMessage(e)
    Bugsnag.notify(
      message,
      async (event) => {
        event.addMetadata('appSync', {query})
        event.addMetadata('stack', {stack: e.stack})
        const user = await getCurrentUserOrNull()
        if (user) {
          event.addMetadata('user', {
            id: user.id,
            email: user.email,
            name: [user.firstName, user.lastName].join(' '),
            phone: user.phoneNumber ?? '',
          })
        }
      },
    )
    throw message
  }
}

const get = async <T>(queryName: string, id: string, fields?: string[]): Promise<T> => {
  const data = await request(`
    query MyQuery {
      ${queryName}(id: "${id}") {
        ${(fields ?? ['id']).join('\n')}
      }
    }
  `)
  return data[queryName];
}

const getList = async <T>(queryName: string, fields?: string[], eqFilter?: EqFilter, parameter?: EqFilter): Promise<T[]> => {

  let filter = ''
  if (eqFilter) {
    const {field, value} = eqFilter
    switch (typeof value) {
      case 'string':
        filter = `filter: {${field}: {eq: "${value}"}}`
        break
      case 'boolean':
      case 'number':
      default:
        filter = `filter: {${field}: {eq: ${value}}}`
        break
    }
  }

  let nextToken = ''
  let allItems: T[] = []

  // Fetch data in a loop while nextToken is not empty
  do {
    const parameterString = !!parameter ? `${parameter.field}: "${parameter.value}"` : ''
    const nextTokenString = !!nextToken ? `nextToken: "${nextToken}"` : ''
    const filterString = filter || nextTokenString || parameterString
      ? `(${[filter, nextTokenString, parameterString].filter(e => !!e).join(',')})`
      : ''

    const data = await request(`
      query MyQuery {
        ${queryName}${filterString} {
          items {
            ${(fields ?? ['id']).join('\n')}
          }
          nextToken
        }
      }
    `)

    const items = data[queryName]?.items ?? []
    allItems = [
      ...allItems,
      ...items
    ]
    nextToken = data[queryName]?.nextToken
  } while (!!nextToken)

  return allItems

}

const getFirstOrNull = async <T>(queryName: string, fields: string[], eqFilter?: EqFilter): Promise<T | null> => {
  const list = await getList<T>(queryName, fields, eqFilter)
  if (!list || !list.length) {
    return null
  }

  return list[0]
}

const create = async <T>(queryName: string, input: T, fields?: string[]): Promise<T> => {
  console.log(`create`)

  // using JSON5 to avoid quotes after stringify

  const data = await request(`
    mutation MyMutation {
      ${queryName}(input: ${JSON5.stringify(input, {quote: '"'})}) {
        ${(fields ?? ['id']).join('\n')}
      }
    }
  `)
  return data[queryName] as T;
}

const update = async <T>(queryName: string, modelId: string, input: any, fields?: string[]): Promise<T> => {
  console.log(`update`)
  delete input['createdAt']
  delete input['updatedAt']

  const data = await request(`
    mutation MyMutation {
      ${queryName}(input: ${JSON5.stringify({...input, id: modelId}, {quote: '"'})}) {
        ${(fields ?? ['id']).join('\n')}
      }
    }
  `)
  return data[queryName] as T;
}

const remove = async <T>(queryName: string, modelId: string): Promise<T> => {
  const data = await request(`
    mutation MyMutation {
      ${queryName}(input: {id: "${modelId}"}) {
        id
      }
    }
  `)
  return data[queryName] as T;
}

const getErrorMessage = (error: any) => {
  const {errors} = error
  return errors?.length ? getBillfoldErrorMessage(errors[0].message) : error.toString()
}

const addInviteInner = async (email: string): Promise<Invite | null> => {
  await checkAdmin()

  const existingInvite = await getFirstOrNull<Invite>('listInvites', ['id'], {
    field: 'email',
    value: email
  })
  if (existingInvite) {
    return null
  }

  return await create<Invite>(
    'createInvite',
    {
      email: email,
      createdAt: new Date(),
      accepted: false,
    },
    [
      'id',
      'name',
      'email',
      'createdAt',
      'accepted',
    ]
  )
}

const checkAdmin = async () => {
  console.log(`checkAdmin`)
  if (!(await isBillfoldAdmin())) {
    throw new NeedAdminPermissionError()
  }
}

const addGalleryItem = async (item: GalleryItem): Promise<GalleryItem> => {
  console.log(`addGalleryItem - item: `, item)
  return await create<GalleryItem>(
    'createGalleryItem',
    {...item},
    [
      'id',
      'imageUrl',
      'imageStorageKey',
      'videoUrl',
      'videoStorageKey',
      'videoThumbnailUrl',
      'videoThumbnailStorageKey',
      'position'
    ]
  )
}

