import React, { useEffect, useState } from 'react'
import classes from './LoginView.module.scss'
import { ReactComponent as CheckIcon } from '../../images/check_big_very.svg'
import classnames from "classnames";
import { AppButton, AppInput, InputType, Spacer } from "../common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faDoorOpen, faKey, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { showError } from "../../util/toast_util";
import {Navigate, useNavigate} from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { useForgotPassword } from "../../hooks/useForgotPassword";
import { isEmpty } from "../../util/string_util";
import { useLogin } from "../../hooks/useLogin";
import useSystemSettings from "../../hooks/useSystemSettings";

export default function ResetPasswordView() {

  const {
    setNewPassword,
    settingNewPassword,
    sendingResetPasswordCode,
    sendResetPasswordCode
  } = useForgotPassword()
  const navigate = useNavigate();
  const {settings} = useSystemSettings()
  const {login, loginInProgress} = useLogin()
  const [showSetPassword, setShowSetPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [newPasswordValue, setNewPasswordValue] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [confirmationCodeError, setConfirmationCodeError] = useState(false)
  const [error, setError] = useState('')
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)

  useEffect(() => {
    setEmailError(false)
    setError('')
  }, [email])

  useEffect(() => {
    setNewPasswordError(false)
    setConfirmPasswordError(false)
    setError('')
  }, [newPasswordValue])

  useEffect(() => {
    setNewPasswordError(false)
    setConfirmPasswordError(false)
    setError('')
  }, [confirmPassword])

  useEffect(() => {
    setConfirmationCodeError(false)
    setError('')
  }, [confirmationCode])

  const onSendResetPasswordCode = async () => {
    if (!email) {
      showError('Enter the email')
      setEmailError(true)
      return
    }

    try {
      await sendResetPasswordCode({
        email,
      })

      setShowSetPassword(true)
    } catch (e: any) {
      console.log(`onSendResetPasswordCode - ${e}`)
      showError(e.message)
      setEmailError(true)
      setConfirmationCodeError(true)
    }

  }

  const onSetNewPassword = async () => {
    if (!confirmationCode) {
      showError('Enter the code')
      setConfirmationCodeError(true)
      return
    }

    if (confirmationCode.length != 6) {
      showError('Code should be 6 digits length')
      setConfirmationCodeError(true)
      return
    }

    if (isEmpty(newPasswordValue)) {
      showError('Enter new password')
      setNewPasswordError(true)
      return
    }

    if (isEmpty(confirmPassword)) {
      showError('Enter password confirmation')
      setConfirmPasswordError(true)
      return
    }

    if (newPasswordValue != confirmPassword) {
      showError('Passwords not match')
      setNewPasswordError(true)
      setConfirmPasswordError(true)
      return
    }

    try {
      await setNewPassword({
        email: email,
        code: confirmationCode,
        newPassword: newPasswordValue,
      })
      setResetPasswordSuccess(true)
    } catch (e: any) {
      console.log(`onConfirmUser - ${e}`)
      showError(e.message)
    }

  }

  const onGoToAppClick = async () => {
    try {
      await login({
        email: email,
        password: newPasswordValue,
      })
      navigate('/')
    } catch (e: any) {
      console.log(`onGoToAppClick - ${e}`)
      showError(e.message)
    }
  }

  const content = () => {
    if (resetPasswordSuccess) {
      return <div className={isMobile ? '' : classes.LoginViewWrapper}>
        <div className={isMobile ? classes.LoginViewMobile : classes.LoginView}>
          <div className={classes.Content}>
            <div className={classes.RegisterSuccessContainer}>
              <Spacer height={200}/>
              <CheckIcon/>
              <div className={classnames(classes.RegisterSuccessTitle, 'mt-5')}>
                {`Password changed!`}
              </div>
              <Spacer height={10}/>
              <AppButton
                className={classnames(classes.LoginButton, 'mt-4')}
                title={'Go to the app'}
                icon={faDoorOpen}
                onClick={onGoToAppClick}
                progress={loginInProgress}/>
            </div>
          </div>
        </div>
      </div>
    }

    if (settings?.maintenance) {
      return <Navigate to='maintenance'/>
    }

    return <div className={isMobile ? '' : classes.LoginViewWrapper}>
      <div className={isMobile ? classes.LoginViewMobile : classes.LoginView}>
        <div className={classes.Content}>

          <Spacer height={200}/>

          <FontAwesomeIcon icon={faKey} size={'2x'} className={'mb-3'}/>

          <div className={classes.Title}>
            {'Reset password'}
          </div>

          <div className={classnames(classes.Subtitle, 'mb-4')}>
            {showSetPassword ? `Enter the code we sent to ${email} and set a new password` : `Enter your email and we will send you reset password code`}
          </div>

          {!showSetPassword && <div>
            <AppInput
              placeholder={'Your email'}
              onChange={setEmail}
              error={emailError}
              type={InputType.email}/>

            <Spacer height={16}/>

            <AppButton
              title={'Send confirmation code'}
              className={classnames(classes.LoginButton, 'w-100')}
              onClick={onSendResetPasswordCode}
              progress={sendingResetPasswordCode}/>

          </div>}

          {showSetPassword && <div>
            <AppInput
              placeholder={'Confirmation code'}
              onChange={setConfirmationCode}
              error={confirmationCodeError}
              maxLength={6}/>
            <Spacer height={10}/>

            <AppInput
              placeholder={'New password'}
              onChange={setNewPasswordValue}
              error={newPasswordError}
              type={InputType.password}/>

            <Spacer height={10}/>

            <AppInput
              placeholder={'Confirm password'}
              onChange={setConfirmPassword}
              error={confirmPasswordError}
              type={InputType.password}/>

            <Spacer height={10}/>

            <AppButton
              title={'Set new password'}
              icon={faCheck}
              className={classnames(classes.LoginButton, 'mt-2', 'w-100')}
              onClick={onSetNewPassword}
              progress={settingNewPassword}/>

            <AppButton
              title={'Resend code'}
              icon={faRefresh}
              className={classnames(classes.LoginButton, 'mt-2', 'w-100')}
              onClick={onSendResetPasswordCode}
              progress={sendingResetPasswordCode}
              transparent/>

          </div>}
        </div>
      </div>
    </div>

  }

  return <div>
    {content()}
  </div>
}
