import useSWRMutation from "swr/mutation";
import { loginWithGoogle } from "../services/api/api";

export const useGoogleLogin = () => {
  const {trigger, isMutating} = useSWRMutation<any, any, any, any>('loginWithGoogle', loginWithGoogle)
  return {
    loginWithGoogle: trigger,
    loginWithGoogleInProgress: isMutating,
  }
}
