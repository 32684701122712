export const clearUrl = (redirectToPage: string) => {
  const clientId = process.env.REACT_APP_CLEAR_CLIENT_ID
  if (!clientId) {
    throw new Error('CLEAR_CLIENT_ID is not set')
  }
  const scope = 'urn:clearme:biographics:over_21'
  //      https://verify.partner.platform.clearme.com/?clientId=bafbe06d-ccce-4fa5-8ba6-efc41402a786&redirectURI=https%3A%2F%2Fwww.clearme.com%2F&state=test-state-value&scope=urn%3Aclearme%3Abiographics%3Aover_21
  //      https://verify.partner.platform.clearme.com/?clientId=bafbe06d-ccce-4fa5-8ba6-efc41402a786&redirectURI=https://staging.billfold-wallet.com/clear/auth&scope=urn:clearme:biographics:over_21&state=/account&response_type=code
  return `https://verify.partner.platform.clearme.com/?clientId=${clientId}&redirectURI=${clearRedirectUrl()}&scope=${scope}&state=${redirectToPage}&response_type=code`
}

export const clearRedirectUrl = () => {

  const redirectPath = process.env.REACT_APP_CLEAR_REDIRECT_URI_PATH ?? ''
  if (!redirectPath.length) {
    throw new Error('CLEAR_REDIRECT_URI_PATH is not set')
  }
  let host = process.env.REACT_APP_HOST ?? ''
  // let host = 'staging.billfold.app' ?? ''
  if (!host.length) {
    throw new Error('APP_HOST is not set')
  }
  if (!host.includes('localhost')) {
    host = `https://${host}`
  }
  return `${host}${redirectPath}`
}
