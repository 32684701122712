import React, {PropsWithChildren} from 'react'
import classes from './Checkbox.module.scss'
import {ReactComponent as CheckboxIcon} from '../../images/checkbox.svg'
import {ReactComponent as CheckboxIconError} from '../../images/checkbox_error.svg'
import {ReactComponent as CheckboxActiveIcon} from '../../images/checkbox_active.svg'
import classnames from "classnames";

interface CheckboxProps {
  title?: string
  checked?: boolean
  onChange?: (checked: boolean) => void
  error?: boolean
}

export const Checkbox: React.FC<CheckboxProps & PropsWithChildren> = ({title, checked, onChange, children, error}) => {

  let Icon = CheckboxIcon
  if (error) {
    Icon = CheckboxIconError
  } else if (checked) {
    Icon = CheckboxActiveIcon
  }

  return <div
    className={classnames(
      classes.Checkbox,
      {
        [classes.CheckboxActive]: checked,
        [classes.CheckboxError]: error,
      }
    )}
    onClick={() => onChange && onChange(!checked)}>
    <div>
    <Icon className={classes.CheckboxIcon}/>
    </div>
    <div className={classes.Content}>
      {title}
      {children}
    </div>

  </div>
}
