import React, {useEffect, useState} from 'react'
import classes from './DonePageModal.module.scss'
import {isMobile} from "react-device-detect";
import {Link, useLocation} from "react-router-dom";
import {AddButton, AppButton, ModalProps, Spacer} from "../common";
import {ReactComponent as CheckBigWhiteGreen} from '../../images/check_big_white_green.svg'
import {Event, Wristband} from '../../services/api/models'
import QRCode from "qrcode.react";
import Modal from "../common/Modal";
import {useTranslation} from "react-i18next";

interface DonePageModalProps {
  event: Event
  wristband: Wristband
  onFinishClick: () => void
  onAddWristbandClick: () => void
}

export const DonePageModal: React.FC<DonePageModalProps & ModalProps> = (props) => {
  const {opened, onClose, className, wristband, onAddWristbandClick, onFinishClick} = props
  const location = useLocation();
  const {t} = useTranslation()

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <Modal
    opened={opened}
    onClose={onClose}
    className={className}
    fullScreen={true}>

    <div className={classes.DonePageModal}>

      <CheckBigWhiteGreen/>

      <Spacer height={20}/>

      <h1>
        {t('congratulations_you_have_successfully_registered')}
      </h1>

      <Spacer height={60}/>

      <div className={classes.FinishButtonContainer}>
        <AppButton
          title={t('done')}
          secondary
          className={classes.FinishButton}
          onClick={() => {
            onClose()
            onFinishClick()
          }}/>
      </div>

    </div>

  </Modal>

}
