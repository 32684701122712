import {User} from "../services/api/models";

export const initials = (user?: User | null) => {
  if (!user) {
    return 'N/A'
  }

  const {email, firstName, lastName} = user

  if (firstName && lastName) {
    return firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase()
  } else if (firstName) {
    return firstName.substring(0, 2).toUpperCase()
  } else if (lastName) {
    return lastName.substring(0, 2).toUpperCase()
  } else {
    return email?.substring(0, 2)?.toUpperCase() ?? ''
  }

}

export const getPaymentCustomerId = (user?: User, platformAccountBillfoldId?: string): string | undefined => {
  if (!user || !user.paymentCustomerIds || !platformAccountBillfoldId) {
    return undefined
  }

  const customerIds = JSON.parse(user.paymentCustomerIds)
  return customerIds[platformAccountBillfoldId]
}
