import {getIntegrator} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import {Integrator} from "../services/api/models";

export const useIntegrator = (integratorId: string) => {
  const {data, error, isLoading} = useSWR(['getIntegrator', integratorId], getIntegratorFetcher)
  return {
    integrator: data,
    integratorLoading: isLoading,
    integratorError: error
  }
}

export const refreshIntegrator = async (integratorId: string, integrator?: Integrator) => {
  await mutate(['getIntegrator', integratorId], integrator)
}

// ---

const getIntegratorFetcher = async (params: any) => {
  const [,integratorId] = params
  return await getIntegrator(integratorId)
}

