import { addInvite, getInvites, deleteInvite, addInvites } from '../services/api/api'
import useSWR, { mutate } from 'swr'
import useSWRMutation from "swr/mutation";

export default function useInvites() {
  const {data, error, isLoading} = useSWR('getInvites', getInvites)
  return {
    invites: data,
    isLoading,
    error
  }
}

export const useAddInvite = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, string>('addInvite', addInvite)
  return {
    addInvite: trigger,
    addingInvite: isMutating,
    error
  }
}

export const useAddInvites = () => {
  const {
    trigger,
    isMutating,
    error
  } = useSWRMutation<any, any, any, string[]>('addInvites', addInvites)
  return {
    addInvites: trigger,
    addingInvites: isMutating,
    error
  }
}

export const useDeleteInvite = () => {
  const {
    trigger,
    isMutating,
    error
  } = useSWRMutation<any, any, any, string>('updateInvite', deleteInvite)
  return {
    deleteInvite: trigger,
    deletingInvite: isMutating,
    error
  }
}

export const refreshInvites = async () => {
  await mutate('getInvites')
}
