import useSWRMutation from "swr/mutation";
import { forgotPassword,  forgotPasswordSubmit} from "../services/api/api";

export const useForgotPassword = () => {
  const sendCodeMutation = useSWRMutation<any, any, any, any>('forgotPassword', forgotPassword)
  const setNewPasswordMutation = useSWRMutation<any, any, any, any>('forgotPasswordSubmit', forgotPasswordSubmit)

  return {
    sendResetPasswordCode: sendCodeMutation.trigger,
    sendingResetPasswordCode: sendCodeMutation.isMutating,
    setNewPassword: setNewPasswordMutation.trigger,
    settingNewPassword: setNewPasswordMutation.isMutating,
  }
}
