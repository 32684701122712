export const getBillfoldErrorMessage = (errorMessage: string): string => {
  switch (errorMessage) {
    case 'customer_id_is_blank':
      return 'Customer ID can’t be blank';
    case 'customer_not_found':
      return 'Customer not found';
    case 'email_is_blank':
      return 'Email can’t be blank';
    case 'event_id_is_blank':
      return 'Event can’t be blank';
    case 'event_not_found':
      return 'Event not found';
    case 'invalid_date_of_birth_format':
      return 'Data must be in the international standard ISO 8601';
    case 'invalid_email_format':
      return 'Email format is invalid';
    case 'invalid_gender':
      return 'Gender is invalid';
    case 'invalid_phone_number':
      return 'Phone number is invalid';
    case 'invalid_pin':
      return 'PIN code is invalid';
    case 'news_subscription_must_be_false_or_null':
      return 'News subscription can be set to true if the email address field is specified';
    case 'payment_gateway_error':
      return 'Some problems when making a request to the payment gateway';
    case 'payment_method_id_is_blank':
      return 'Payment method ID can’t be blank';
    case 'payment_method_not_found':
      return 'Payment method not found';
    case 'purchases_without_pin_is_invalid':
      return 'Value of the parameter "purchases_without_pin" is invalid';
      // return 'The valid value depends on the pin_for_purchases parameter of the event';
    case 'subject_is_blank':
      return 'The sub field in the JWT is missing or empty';
    case 'subject_is_taken':
      return 'The sub field has already been taken by another user';
    case 'vault_is_invalid':
      return 'Vault is invalid Available types: stripe';
    case 'wristband_is_blocked':
      return 'Wristband status is blocked';
    case 'wristband_number_contains_invalid_characters':
      return 'Wristband number contains wrong symbols';
    case 'wristband_number_not_found':
      return 'Wristband number not found in the list of valid numbers';
    case 'wristband_number_taken':
    case 'wristband_number_is_already_taken':
      return 'Wristband number has already been taken';
    case 'id_is_blank':
      return 'Opt-in `id` is blank';
    case 'version_is_blank':
      return 'Opt-in `version` is blank';
    case 'accepted_is_invalid':
      return 'Opt-in `accepted` is invalid';
    case 'opt_in_does_not_exists':
      return 'Combination of opt-in `id` and `version` does not exists';
    default:
      return errorMessage;
  }
}
