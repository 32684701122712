export const parseState = (state: string): Record<string, string> => {
  console.log(`parseState - state: ${state}`)

  const pairs = state.toString().split(',')
  const data: Record<string, string> = {}
  pairs.forEach((p: string) => {
    const chunks = p.split('=')
    if (chunks[1] != 'null' && chunks[1] != 'undefined') {
      data[chunks[0]] = p.substring(chunks[0].length + 1)
    }
  })
  console.log(`parseState - result: `, data)

  return data
}
