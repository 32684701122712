import {dataUrlToFile} from "./file_util";

export const createVideoThumbnail = async (file: File): Promise<File> => {
  const video = document.createElement('video')
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  video.src = URL.createObjectURL(file)
  video.load()
  video.currentTime = 2

  await Promise.all([
    new Promise<void>((resolve) => {
      video.addEventListener('loadedmetadata', () => {
        resolve();
      });
    }),
    new Promise<void>((resolve) => {
      video.addEventListener('loadeddata', () => {
        resolve();
      });
    })
  ])

  canvas.width = video.videoWidth
  canvas.height = video.videoHeight
  context?.drawImage(video, 0, 0, canvas.width, canvas.height)
  const thumbnailDataURL = canvas.toDataURL('image/jpeg')
  return dataUrlToFile(thumbnailDataURL, `${file.name}_thumb.jpg`)

}
