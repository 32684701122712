import useSWRInfinite from 'swr/infinite'
import {
  getBalanceTransactions,
  GetTransactionsParams,
  TransactionsPageData,
} from "../services/api/api";
import { last } from "../util/array_util";

export default function useTransactions(params: GetTransactionsParams) {

  try {

    const getKey = (pageNumber: number, prevData: TransactionsPageData | null) => {
      const {transactions, nextToken} = prevData ?? {}
      if (prevData && !transactions?.length) {
        return null
      }
      return ['getTransactions', {...params, nextToken}]
    }

    const {
      data,
      error,
      isLoading,
      size,
      setSize
    } = useSWRInfinite<TransactionsPageData>(getKey, getBalanceTransactions)

    const isEmpty = data?.[0]?.transactions.length === 0;
    return {
      transactions: data?.flatMap(d => d.transactions),
      hasMore: !!last(data)?.nextToken,
      isEmpty,
      transactionsLoading: isLoading,
      error,
      size,
      setSize
    }
  } catch (e) {
    console.log(`useTransactions - e: ${e}`)
    return {
      transactions: [],
      hasMore: false,
      isEmpty: true,
      transactionsLoading: false,
      error: '',
      size: 0,
      setSize: (index: number) => {
      },
    }
  }
}
