import React, {useEffect, useMemo, useState} from 'react'
import classes from './WristbandDetailsView.module.scss'
import './WristbandDetailsView.scss'
import {showError, showSuccess} from "../../util/toast_util"
import {Link, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom"
import useWristband, {refreshWristband, useRegisterWristbandByQr, useUpdateWristband} from "../../hooks/useWristband"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faChevronRight, faClose, faKey, faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons"
import QRCode from "qrcode.react"
import {Shimmer, Shimmers} from "./Shimmer"
import {CardItem} from "../account/CardItem"
import useCards from "../../hooks/useCards"
import {Card, StripePaymentMethod, Wristband, Event} from "../../services/api/models"
import {AppInput, InputType} from "./AppInput"
import {Spacer} from "./Spacer"
import {AppButton} from "./AppButton"
import PaymentMethodsModal from "./PaymentMethodsModal"
import {isEmailValid} from "../../util/validation_util"
import {moneyFormat} from "../../util/format_util"
import {ViewHeader} from "./ViewHeader"
import PinCodePage from "../registration/PinCodePage"
import {ConfirmModal} from "./ConfirmModal"
import Modal from "./Modal"
import {isDesktop, isMobile} from "react-device-detect"
import {useTranslation} from "react-i18next"
import {getPaymentMethodId, hasPaymentMethodId} from "../../util/card_util"
import PlaceholderView from "./PlaceholderView"
import {getEvent, isWristbandNumberAllowed} from "../../services/api/api"
import {isQrType} from "../../util/wristband_util"
import WristbandNumberPage from "../registration/WristbandNumberPage"
import {ReactComponent as WristbandIcon} from "../../images/wristband_icon.svg"
import {ActivateSuccessPage} from "./ActivateSuccessPage"
import {ReactComponent as CardIcon} from "../../images/credit_card_icon.svg"
import Spinner from "./Spinner"
import {ActivationOptionModal} from "./ActivationOptionModal"
import {SwitchView} from "./SwitchView";
import useEvent from "../../hooks/useEvent";

export function WristbandDetailsView() {
  const {t} = useTranslation()
  const {wristbandId} = useParams() as any
  const {wristband, isLoading} = useWristband(wristbandId)
  const {status} = wristband ?? {}
  const {cards} = useCards()
  const navigate = useNavigate()
  const [wristbandCard, setWristbandCard] = useState<StripePaymentMethod>()
  const [showChooseCardModal, setShowChooseCardModal] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false)
  const {updateWristband, updatingWristband} = useUpdateWristband()
  const [name, setName] = useState<string>()
  const [nameError, setNameError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [emailError, setEmailError] = useState(false)
  const [localSpendingLimit, setLocalSpendingLimit] = useState<number>()
  const [showSpendingLimitInput, setShowSpendingLimitInput] = useState(false)
  const {balance, id, spendingLimit, billfoldId, wristbandNumber, eventID, purchasesWithoutPin} = wristband ?? {}
  const [showChangePinView, setShowChangePinView] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showUnblockModal, setShowUnblockModal] = useState(false)
  /**
   * Using separated state for blocking to show the spinner on the block button only
   */
  const [blocking, setBlocking] = useState(false)
  const [showQrModal, setShowQrModal] = useState(false)
  const [showActivationOptionModal, setShowActivationOptionModal] = useState(false)
  const [cardChanging, setCardChanging] = useState(false)
  const [usePingForPurchasesUpdating, setUsePingForPurchasesUpdating] = useState(false)
  const location = useLocation()
  const from = location.state?.from?.pathname
  const [event, setEvent] = useState<Event>()

  useEffect(() => {
    if (wristband) {
      setName(wristband.wristbandOwnerName)
      setEmail(wristband.wristbandOwnerEmail)
      setPhoneNumber(wristband.wristbandOwnerPhoneNumber)
      setLocalSpendingLimit(wristband.spendingLimit)

      // conditional hooks doesn't work for some reason,
      // so we have to use it in this way.
      // or we need to make a useEventByWristband
      getEvent(['', wristband?.eventID ?? '']).then((event) => {
        setEvent(event)
      })
    }
  }, [wristband])

  useEffect(() => {
    if (cards && cards.length && wristband) {
      const card =
        cards?.find(c => hasPaymentMethodId(c, wristband?.paymentMethodID)) ??
        cards?.find(c => hasPaymentMethodId(c, wristband?.stripePaymentMethodID))
      if (card) {
        setWristbandCard(card)
      }
    }
  }, [cards, wristband])

  useEffect(() => {
    if (name && name.length) {
      setNameError(false)
    }
  }, [name])

  useEffect(() => {
    if (email && email.length) {
      setEmailError(false)
    }
  }, [email])

  useEffect(() => {

    if (!wristband) {
      setShowSaveButton(false)
      return
    }
    setShowSaveButton(name != wristband.wristbandOwnerName
      || email != wristband.wristbandOwnerEmail
      || phoneNumber != wristband.wristbandOwnerPhoneNumber
      || localSpendingLimit != wristband.spendingLimit)

  }, [name, email, phoneNumber, localSpendingLimit, wristband])

  const comeFromRegistration = useMemo(() => {
    return from && from.includes('/event/') && from.includes('/checkin')
  }, [from])

  const onCardClick = () => {
    setShowChooseCardModal(true)
  }

  const onPaymentsMethodClose = () => {
    setShowChooseCardModal(false)
  }

  const onCardChanged = async (card: Card) => {
    console.log(`onCardChanged - card: `, card)
    try {

      setCardChanging(true)

      if (event == null) {
        return
      }

      const newWristband = await updateWristband({
        event: event,
        cardId: card.id ?? '',
        wristband: {
          ...wristband,
          paymentMethodID: getPaymentMethodId(card, event.platformAccountBillfoldId, event.paymentAccountType)
        }
      })

      showSuccess(t('card_changed'))
      refreshWristband(wristbandId, newWristband)
    } catch (e: any) {
      console.log(`onCardChanged - ${e}, ${e.stack}`)
      showError(e)
    } finally {
      setCardChanging(false)
    }
  }

  const onSaveClick = async () => {
    console.log(`onSaveClick`)

    if (event == null) {
      return
    }

    if (!name || !name.length) {
      setNameError(true)
      return
    }

    if (email && email.length && !isEmailValid(email)) {
      showError(t('email_has_wrong_format'))
      setEmailError(true)
      return
    }

    try {
      const newWristband = await updateWristband({
        event: event,
        cardId: wristbandCard?.id ?? '',
        wristband: {
          ...wristband,
          wristbandOwnerName: name,
          wristbandOwnerEmail: email,
          wristbandOwnerPhoneNumber: phoneNumber,
          spendingLimit: localSpendingLimit,
        } as Wristband
      })
      showSuccess(t('wristband_updated'))
      refreshWristband(wristbandId, newWristband)
      setShowSpendingLimitInput(false)
    } catch (e) {
      showError(e)
    }
  }

  const onAddSpendingLimitClick = async () => {
    setShowSpendingLimitInput(true)
  }

  const onCloseSpendingLimitInputClick = async () => {
    setShowSpendingLimitInput(false)
    setLocalSpendingLimit(undefined)
  }

  const onChangePinClick = () => {
    setShowChangePinView(true)
  }

  const onBlockClick = async () => {
    setShowBlockModal(true)
  }

  const onUnblockClick = async () => {
    setShowUnblockModal(true)
  }

  const onPinCodeConfirmed = async (pinCode: string) => {
    if (!wristband) {
      return
    }
    wristband.pinCode = pinCode
    onSaveClick()
    setShowChangePinView(false)

  }

  const onBlockConfirm = async () => {
    if (!wristband || !event) {
      return
    }

    try {
      setBlocking(true)
      const updatedWristband = await updateWristband({
        wristband: {
          ...wristband,
          status: wristband.status == 'blocked' ? 'active' : 'blocked'
        },
        event: event,
        cardId: wristbandCard?.id ?? ''
      })

      if (updatedWristband) {
        const blockedNow = updatedWristband?.status == 'blocked'
        showSuccess(blockedNow ? t('wristband_is_blocked_now') : t('wristband_is_unblocked_now'))
        refreshWristband(updatedWristband?.id ?? '', updatedWristband)
      }
    } catch (e) {
      showError(e)
    } finally {
      setBlocking(false)
    }
  }

  const onQrClick = async () => {

  }

  const onActivateButtonClick = () => {
    setShowActivationOptionModal(true)
  }

  const blocked = status == 'blocked'

  const isQr = useMemo(() => {
    return wristband && isQrType(wristband)
  }, [wristband])

  const qrInfo = () => {
    return <div className={classes.QrInfo}>

      <div className={classes.QrCodeContainer} onClick={() => setShowQrModal(true)}>

        <QRCode
          className={blocked ? classes.QrTransparent : classes.Qr}
          value={billfoldId ?? ''}/>

        {blocked && <div className={classes.BlockIconContainer}>
          <FontAwesomeIcon icon={faLock} className={classes.BlockIcon}/>
        </div>}

      </div>

      {isMobile && <Spacer height={10}/>}

      {isDesktop && <Spacer width={10}/>}

      <div className={classes.HintMessage}>
        {t('wristband_details_hint')}
      </div>

      {isMobile && <Link to={'activate'} className={classes.ActivateButtonWrapper}>
        <AppButton
          title={t('link')}
          className={classes.ActivateButton}
        />
      </Link>}

    </div>
  }

  const wristbandInfo = () => {
    return <div className={classes.WristbandInfo}>

      <div className={classes.BalanceHeader}>{t('balance')}</div>

      <div className={classes.SumContainer}>
        <div className={(balance ?? 0) == 0 ? classes.SumZero : classes.Sum}>
          {moneyFormat(balance ?? 0, event?.paymentAccountCurrency)}
        </div>
      </div>

      <div className={classes.WristbandNumberContainer}>
        <Spacer width={2}/>
        <WristbandIcon className={classes.Icon}/>
        <Spacer width={6}/>
        <div className={classes.WristbandNumber}>{wristbandNumber}</div>
        <Spacer width={8}/>
        <div className={blocked ? classes.StatusBadgeBlocked : classes.StatusBadge}>
          <FontAwesomeIcon
            icon={blocked ? faLock : faCheck}
            className={blocked ? classes.StatusBadgeIconBlocked : classes.StatusBadgeIcon}/>
          <Spacer width={4}/>
          {blocked ? t('blocked') : t('active')}
        </div>
      </div>

      <Spacer height={30}/>

    </div>
  }

  const eventControl = () => {
    return <Link to={`/event/${eventID}`}>
      <div className={classes.EventControl}>
        <div>
          <div className={classes.EventControlHeader}>{t('event')}</div>
          <div className={classes.EventControlTitle}>{event?.name ?? '...'}</div>
        </div>
        <Spacer/>
        <FontAwesomeIcon icon={faChevronRight} className={classes.ArrowIcon}/>
      </div>
    </Link>
  }

  const content = () => {

    if (isLoading) {
      return <div className={classes.WristbandDetailsView}>
        <Shimmers className={'w-100 d-block'}>

          <div className={classes.Header}>
            <Shimmer width={24} height={24} borderRadius={12}/>
            <Spacer width={10}/>
            <Shimmer height={20} width={100}/>
          </div>

          <div className={'d-flex align-items-center'}>

            <Shimmer height={150} width={150}/>

            <Spacer width={20}/>

            <div>

              <div className={classes.SumContainer}>
                <Shimmer height={50} width={100}/>
                <Spacer width={16}/>
                <Shimmer width={24} height={24} borderRadius={12}/>
              </div>

              <Spacer height={10}/>

              <Shimmer width={160}/>
            </div>
          </div>

          <Spacer height={10}/>

          <CardItem loading showLinked/>

          <Spacer height={10}/>

          <AppInput loading/>

          <Spacer height={10}/>

          <AppInput loading/>

          <Spacer height={10}/>

          <AppInput loading/>

          <Spacer height={10}/>

          <AppInput loading/>

          <Spacer height={10}/>

          <AppInput loading/>

          <Spacer height={10}/>

          <AppInput loading/>

        </Shimmers>

      </div>
    }

    if (wristband == null && !isLoading) {
      return <div className={classes.WristbandDetailsView}>
        <PlaceholderView title={t('wristband_not_found')}/>
      </div>
    }

    if (!wristband) {
      return <div/>
    }

    if (showChangePinView) {
      return <div className={classes.WristbandDetailsView}>

        <ViewHeader
          showBackButton
          title={t('change_pin')}
          onBackClick={() => setShowChangePinView(false)}/>

        <Spacer height={20}/>

        <PinCodePage
          changePin
          onCodeConfirmed={onPinCodeConfirmed}
          currentCode={wristband.pinCode}/>

      </div>
    }

    return <div className={classes.WristbandDetailsView}>

      <ViewHeader
        title={t(isQr ? 'name_registration' : 'name_wristband', {name: (wristband?.wristbandOwnerName ?? '').split(' ')[0]})}
        onBackClick={() => {
          if (comeFromRegistration) {
            navigate(`/event/${event?.id}`)
          } else {
            navigate(-1)
          }
        }}
        showBackButton/>

      <Spacer height={10}/>

      {!isQr && wristbandInfo()}

      <Spacer height={10}/>

      {wristbandCard && !cardChanging && <CardItem
        card={wristbandCard}
        showLinked
        onClick={onCardClick}/>}

      {!wristbandCard && !cardChanging && <div
        className={classes.AddCardButton}
        onClick={onCardClick}>
        <CardIcon className={classes.AddCardButtonIcon}/>
        <Spacer width={4}/>
        {t('link_card')}
      </div>}

      {cardChanging && <div className={classes.AddCardButton}>
        <Spinner/>
      </div>}

      {(spendingLimit || showSpendingLimitInput) && <AppInput
        key={'spending_limit'}
        disabled={blocked || !event?.registrationAllowed}
        placeholder={t('spending_limit')}
        initialMoneyValue={localSpendingLimit}
        type={InputType.money}
        currency={event?.paymentAccountCurrency}
        onChange={v => setLocalSpendingLimit(!v ? 0 : parseFloat(v))}
        trailing={!wristband.spendingLimit && <FontAwesomeIcon
          icon={faClose}
          onClick={onCloseSpendingLimitInputClick}
          className={classes.SpendingLimitCloseButton}/>}/>}

      {/*{!spendingLimit && !showSpendingLimitInput && <AppButton
        title={'Add spending limit'}
        icon={faLock}
        transparent
        className={classes.AddSpendingLimitButton}
        onClick={onAddSpendingLimitClick}/>}*/}

      <Spacer height={10}/>

      <AppInput
        key={'name'}
        disabled={blocked || !event?.registrationAllowed}
        placeholder={t('guest_name')}
        initialValue={name}
        onChange={setName}
        error={nameError}/>

      <Spacer height={10}/>

      <AppInput
        key={'email'}
        disabled={blocked || !event?.registrationAllowed}
        placeholder={t('guest_email')}
        initialValue={email}
        onChange={setEmail}
        error={emailError}/>

      <Spacer height={10}/>

      <AppInput
        disabled={blocked || !event?.registrationAllowed}
        key={'phone_number'}
        placeholder={t('guest_phone_number')}
        initialValue={phoneNumber}
        type={InputType.phone}
        onChange={setPhoneNumber}/>

      <Spacer height={10}/>

      {eventControl()}

      {isQr && event && event.pinForPurchases == 'optional' && <div>
        <Spacer height={10}/>
        <SwitchView
          title={t('use_pin_code_for_purchases')}
          onClick={onUsePinForPurchasesClick}
          checked={!purchasesWithoutPin}
          progress={usePingForPurchasesUpdating}
        />
      </div>}

      {!showSaveButton && <div>

        <Spacer height={20}/>

        {!blocked && !blocking && <AppButton
          transparent
          title={t('change_pin')}
          icon={faKey}
          onClick={onChangePinClick}
          progress={updatingWristband}
          className={'w-100'}/>}

        <Spacer height={6}/>

        {!blocked && !isQr && <AppButton
          transparent
          danger
          title={t('block_wristband')}
          icon={faLock}
          onClick={onBlockClick}
          progress={blocking}
          className={'w-100'}/>}

        {blocked && <AppButton
          transparent
          danger
          title={t('unblock_wristband')}
          icon={faLockOpen}
          onClick={onUnblockClick}
          progress={blocking}
          className={'w-100'}/>}
      </div>}

      {showSaveButton && <div className={classes.SaveButtonContainer}>
        <Spacer height={10}/>
        <AppButton
          title={t('save')}
          onClick={onSaveClick}
          progress={updatingWristband}
        />
      </div>}

      {!showSaveButton && isQr && isDesktop && <div>
        <Spacer height={20}/>
        <AppButton
          title={t('link_wristband')}
          className={classes.ActivateButton}
          onClick={onActivateButtonClick}
          iconComponent={<WristbandIcon className={classes.ActivateButtonIcon}/>}
        />
      </div>}

      {!showSaveButton && isQr && isMobile && <AppButton
        onClick={onActivateButtonClick}
        title={t('link_wristband')}
        className={classes.ActivateButton}
        iconComponent={<WristbandIcon className={classes.ActivateButtonIcon}/>}
      />}

      <Spacer height={42}/>

    </div>
  }

  const onUsePinForPurchasesClick = async () => {
    if (!wristband || !event) {
      return
    }

    try {
      setUsePingForPurchasesUpdating(true)

      const updatedWristband = await updateWristband({
        wristband: {
          ...wristband,
          purchasesWithoutPin: !purchasesWithoutPin
        },
        event: event,
        cardId: wristbandCard?.id ?? ''
      })

      if (updatedWristband) {
        showSuccess(t('registration_updated'))
        refreshWristband(updatedWristband?.id ?? '', updatedWristband)
      }
    } catch (e) {
      showError(t('can_not_update_registration'))
    } finally {
      setUsePingForPurchasesUpdating(false)
    }
  }

  return <div className={'w-100'}>

    <Routes>

      <Route path='/activate' element={<WristbandActivateView/>}/>

      <Route path='/activate-kiosk' element={<WristbandActivateKioskView/>}/>

      <Route path='/' element={content()}/>

    </Routes>

    {showChooseCardModal && event && <PaymentMethodsModal
      event={event}
      opened={showChooseCardModal}
      onClose={onPaymentsMethodClose}
      onChange={onCardChanged}
      selectedCard={wristbandCard ?? undefined}/>}

    {showBlockModal && <ConfirmModal
      title={t('are_you_sure')}
      opened={showBlockModal}
      confirmIcon={faLock}
      confirmTitle={t('block')}
      dangerConfirm
      children={<div className={classes.BlockModalMessage}>
        {t('block_wristband_warning')}
      </div>}
      onClose={() => setShowBlockModal(false)}
      onConfirm={onBlockConfirm}/>}

    {showUnblockModal && <ConfirmModal
      title={t('are_you_sure')}
      opened={showUnblockModal}
      confirmIcon={faLockOpen}
      confirmTitle={t('unblock')}
      children={<div className={classes.BlockModalMessage}>
        {t('unblock_wristband_warning')}
      </div>}
      onClose={() => setShowUnblockModal(false)}
      onConfirm={onBlockConfirm}/>}

    {showQrModal && <Modal
      title={''}
      opened={showQrModal}
      onClose={() => setShowQrModal(false)}
      className={classes.TestModal}>

      <div className={classes.QrModal}>

        <Spacer height={isDesktop ? 30 : 50}/>

        <QRCode
          className={classes.QrBig}
          value={billfoldId ?? ''}
          size={300}/>

        <Spacer height={isDesktop ? 0 : 10}/>

      </div>

    </Modal>}

    {showActivationOptionModal && id && <ActivationOptionModal
      opened={showActivationOptionModal}
      onClose={() => setShowActivationOptionModal(false)}
      wristbandId={id}
    />}

  </div>

}

const WristbandActivateView = () => {

  const {wristbandId} = useParams()
  const {wristband} = useWristband(wristbandId ?? '')
  const {event} = useEvent(wristband?.eventID ?? '')
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {registerWristbandByQr, registeringWristbandByQr} = useRegisterWristbandByQr()
  const [success, setSuccess] = useState(false)
  const [checkingWristbandNumber, setCheckingWristbandNumber] = useState(false)

  const onActivateClick = async (wristbandNumber: string, event: Event) => {

    if (event.checkWristbandNumberInFile && event.wristbandsFileUrl) {
      try {
        setCheckingWristbandNumber(true)
        const allowed = await isWristbandNumberAllowed(event.wristbandsFileUrl, wristbandNumber)
        if (!allowed) {
          showError(t('wristband_number_not_allowed_message'))
          return
        }
      } catch (e: any) {
        showError(t('can_not_check_wristband_number'))
        return
      } finally {
        setCheckingWristbandNumber(false)
      }
    }

    try {
      await registerWristbandByQr({
        wristbandNumber: wristbandNumber,
        eventId: wristband?.eventID ?? '',
        wristbandId: wristband?.id ?? '',
      })
      await refreshWristband(wristband?.id ?? '')
      setSuccess(true)
    } catch (e: any) {
      console.log(`onActivateClick - ${e}, ${e.stack}`)
      showError(e)
    }
  }

  const content = () => {

    if (success) {
      return <ActivateSuccessPage onOkClick={() => navigate(`/event/${wristband?.eventID}`)}/>
    }

    if (!event) {
      return <div className={classes.SpinnerContainer}>
        <Spinner/>
      </div>
    }

    return <div className={classes.WristbandActivateView}>

      <ViewHeader title={t('activate_wristband')} showBackButton/>

      <WristbandNumberPage
        onContinue={(number) => onActivateClick(number, event)}
        continueButtonTitle={t('activate')}
        continueButtonIcon={<div className={classes.ActivateButtonIcon}>
          <WristbandIcon className={classes.ActivateButtonIcon}/>
        </div>}
        continueButtonIconDisabled={<div className={classes.ActivateButtonIcon}>
          <WristbandIcon className={classes.ActivateButtonIcon}/>
        </div>}
        continueButtonProgress={registeringWristbandByQr || checkingWristbandNumber}
        showTitle={false}
      />

    </div>
  }

  return content()
}

const WristbandActivateKioskView = () => {

  const {wristbandId} = useParams()
  const {wristband} = useWristband(wristbandId ?? '')
  const {t} = useTranslation()

  return <div className={classes.WristbandActivateKioskView}>

    <ViewHeader title={t('wristband_activation')} showBackButton/>

    <div className={classes.TopSpace}/>

    <div className={classes.Title}>
      {t('wristband_activation_kiosk_title')}
    </div>

    <Spacer height={20}/>

    <div className={classes.KioskQrContainer}>
      <QRCode
        className={classes.KioskQr}
        value={wristband?.billfoldId ?? ''}/>
    </div>

    <Spacer height={20}/>

    <div className={classes.Hint}>
      {t('wristband_activation_kiosk_hint')}
    </div>

  </div>
}
