import React, {ReactNode, useEffect, useState} from 'react'
import classes from './WristbandNumberPage.module.scss'
import {AppButton, AppInput, Spacer} from "../common";
import {Wristband} from "../../services/api/models";
import {showError, showInfo} from "../../util/toast_util";
import {useParams, useSearchParams} from "react-router-dom";
import WristbandBack from "../../images/wristband_back.png";
import {wristbandNumberMaxLength, wristbandNumberMinLength} from "../../config/constants";
import {isMobile} from "react-device-detect";
import {useKeyboardVisibility} from "../../hooks/useKeyboardVisibility";
import useUser from "../../hooks/useUser";
import {useTranslation} from "react-i18next";
import useEvent from "../../hooks/useEvent";
import {downloadFile, isWristbandNumberAllowed} from "../../services/api/api";

interface WristbandNumberPageProps {
  onContinue: (wristbandNumber: string) => void
  continueButtonTitle?: string
  continueButtonIcon?: ReactNode
  continueButtonIconDisabled?: ReactNode
  continueButtonProgress?: boolean
  preFilledWristband?: Wristband
  showTitle?: boolean
}

export default function WristbandNumberPage(props: WristbandNumberPageProps) {
  const {
    onContinue,
    preFilledWristband,
    continueButtonTitle,
    continueButtonIcon,
    continueButtonIconDisabled,
    showTitle,
    continueButtonProgress
  } = props

  const {eventId} = useParams() as any
  const {event} = useEvent(eventId ?? '')

  const [wristbandNumber, setWristbandNumber] = useState('')
  const [wristbandNumberError, setWristbandNumberError] = useState(false)
  const [wristbandNumberValid, setWristbandNumberValid] = useState(false)
  const [checkingWristbandNumber, setCheckingWristbandNumber] = useState(false)
  const {keyboardVisible, keyboardHeight} = useKeyboardVisibility()
  const {user} = useUser()
  const {t} = useTranslation()

  useEffect(() => {
    console.log(`useEffect (WristbandNumberPage) - keyboardVisible: ${keyboardVisible}`)
    if (user?.email == 'airon@tark.pro') {
      showInfo(`keyboardVisible: ${keyboardVisible}`)
    }
  }, [keyboardVisible])

  useEffect(() => {
    if (preFilledWristband) {
      setWristbandNumber(preFilledWristband.wristbandNumber ?? '')
    }
  }, [])

  useEffect(() => {
    setWristbandNumberError(false)
    setWristbandNumberValid(
      wristbandNumber.length >= wristbandNumberMinLength
      && wristbandNumber.length <= wristbandNumberMaxLength
    )
  }, [wristbandNumber])


  const onContinueClick = async () => {

    if (wristbandNumber.length == 0) {
      showError(t('enter_wristband_number'))
      setWristbandNumberError(true)
      return
    }

    if (wristbandNumber.length < wristbandNumberMinLength
      && wristbandNumber.length > wristbandNumberMaxLength) {
      showError(t('wristband_number_should_be_from_to_symbols_length', {
        min: wristbandNumberMinLength,
        max: wristbandNumberMaxLength
      }))
      setWristbandNumberError(true)
      return
    }

    if (event?.checkWristbandNumberInFile && event?.wristbandsFileUrl) {
      try {
        setCheckingWristbandNumber(true)
        const numberAllowed = await isWristbandNumberAllowed(event?.wristbandsFileUrl, wristbandNumber)
        if (!numberAllowed) {
          showError(t('wristband_number_not_allowed_message'))
          setWristbandNumberError(true)
          return
        }
      } catch (e: any) {
        console.log(`onContinueClick - error: ${e.toString()}`)
        showError(t('can_not_check_wristband_number'))
        return
      } finally {
        setCheckingWristbandNumber(false)
      }
    }

    onContinue(wristbandNumber)
  }

  return <div className={classes.WristbandNumberPage}>

    {/* todo finish the css styling*/}

    {(showTitle == undefined || showTitle) && <div className={classes.Title}>
      <h2>{t('enter_wristband_number')}</h2>
    </div>}

    <AppInput
      placeholder={t('wristband_number')}
      onChange={setWristbandNumber}
      uppercase
      error={wristbandNumberError}
      valid={wristbandNumberValid}
      initialValue={wristbandNumber}
      maxLength={wristbandNumberMaxLength}/>

    <Spacer height={20}/>

    <div className={classes.Info}>
      <p>{t('wristband_number_explanation', {
        min: wristbandNumberMinLength,
        max: wristbandNumberMaxLength
      })}</p>
    </div>

    <Spacer height={16}/>

    <div className={classes.WristbandNumberHint}>
      <img src={WristbandBack} alt={'wristband'} className={classes.Image}/>
    </div>

    <Spacer height={40}/>

    <AppButton
      title={continueButtonTitle ?? t('continue')}
      onClick={onContinueClick}
      className={isMobile ? classes.ContinueButtonMobile : classes.ContinueButton}
      disabled={!wristbandNumberValid}
      iconComponent={wristbandNumberValid ? continueButtonIcon : continueButtonIconDisabled}
      style={{bottom: keyboardVisible ? keyboardHeight + 16 : 16}}
      progress={continueButtonProgress || checkingWristbandNumber}
    />

  </div>
}
