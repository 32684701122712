import React, {useState, useEffect, useCallback} from "react"
import {Layout, Layouts, Responsive, WidthProvider} from "react-grid-layout"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import classes from "./GalleryView.module.scss"
import "./EventEditView.scss"
import {Event, GalleryItem} from "../../services/api/models"
import {refreshEvent, useUpdateEvent} from "../../hooks/useEvent"
import {showError} from "../../util/toast_util"
import {uploadFile} from "../../services/api/api"
import {useDropzone} from "react-dropzone"
import SpinnerSmall from "../common/SpinnerSmall"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSave} from "@fortawesome/free-regular-svg-icons"
import classnames from "classnames"
import Modal from "../common/Modal"
import {AppButton, AppInput, Spacer} from "../common"
import {isImageFile, isImageUrl, isUrlValid, isVideoFile, isVideoUrl} from "../../util/validation_util"
import {getThumbnail} from "../../services/api/noembed_api"
import {DateTime} from "luxon"
import {GalleryModal} from "../common/GalleryModal"
import {faPlay} from "@fortawesome/free-solid-svg-icons"
import {createVideoThumbnail} from "../../util/image_util"
import imageCompression from "browser-image-compression"
import {maxGalleryImageSizeMb} from "../../config/constants"
import {AppImage} from "../common/AppImage"

const rows: Record<string, number> = {lg: 3, md: 3, sm: 3, xs: 3, xxs: 2}
const breakpoints = {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}

type WidthType = 'lg' | 'md' | 'sm' | 'xs' | 'xxs'

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface GalleryViewNewProps {
  event: Event
}

export const GalleryView = (props: GalleryViewNewProps) => {

  const {event} = props
  const {updateEvent, updatingEvent} = useUpdateEvent()
  const [layouts, setLayouts] = useState<Layouts>(layoutsFromGallery(event.gallery));
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>(widthType());
  const [mounted, setMounted] = useState(false);
  const [positionsUpdated, setPositionsUpdated] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [pressedItem, setPressedItem] = useState<number>()
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (event) {
      setLayouts(layoutsFromGallery(event.gallery))
    }
  }, [event])

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
  };

  const onLayoutChange = async (layout: any, layouts: any) => {
    setLayouts(layouts)
    return
  };

  const generateLayout = () => {
    return layouts[widthType()].map((item, i) => {
      if (i == layouts[widthType()].length - 1) {
        return <div key={i} className={classnames(classes.Card, 'static')}>
          <GalleryItemView event={event}/>
        </div>
      }
      return <div
        key={i}
        className={classes.Card}
        onClick={() => {
          console.log(`onClick`)
          if (dragging) {
            return
          }
          setPressedItem(i)
          setShowGalleryModal(true)
        }}
        onPointerUp={() => {
          console.log(`onPointerUp`)
          if (dragging) {
            return
          }
          setPressedItem(i)
          setShowGalleryModal(true)
        }}>
        <GalleryItemView
          key={i}
          event={event}
          galleryItem={event?.gallery?.at(i)}
          onDeleteItem={onDeleteItem}
        />
      </div>
    })
  }

  const moveStaticToEnd = (layouts: Layouts): Layouts => {

    // get data according current breakpoint
    const data = layouts[widthType()].filter((i) => i.static != true)

    // remove the static item
    const gallery = event?.gallery?.map((item, i) => {
      return {
        ...item,
        position: position(data[i].x, data[i].y, rows[widthType()])
      }
    })

    gallery?.sort((a, b) => a.position - b.position)
    const galleryWithoutGaps: GalleryItem[] = []
    gallery?.forEach((item, i) => {
      galleryWithoutGaps.push({...item, position: i,})
    })

    // remove any gaps (convert layout to gallery, remove gaps, convert back to layout)
    // add static item to the end
    return layoutsFromGallery(galleryWithoutGaps)
  }

  const maxRows = () => {
    return Math.floor(((event.gallery?.length ?? 0) + 1) / rows[widthType()]) + 1
  }

  const layoutChanged = (layoutList: Layout[]) => {
    return layoutList.length > 0 && layoutList[0].moved != undefined
  }

  const getChangedLayout = (layouts: Layouts): Layout[] => {
    if (layoutChanged(layouts.lg)) {
      return layouts.lg
    } else if (layoutChanged(layouts.md)) {
      return layouts.md
    } else if (layoutChanged(layouts.sm)) {
      return layouts.sm
    } else if (layoutChanged(layouts.xs)) {
      return layouts.xs
    } else if (layoutChanged(layouts.xxs)) {
      return layouts.xxs
    }

    return layouts.xs
  }

  const onSaveClick = async () => {
    try {

      const data = getChangedLayout(layouts)
      const gallery = event?.gallery?.map((item, i) => {
        return {
          ...item,
          position: position(data[i].x, data[i].y, rows[widthType()])
        }
      })

      const updatedEvent = {
        ...event,
        gallery: gallery
      }
      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      console.log(`onLayoutChange - error: ${e}`)
      showError(e)
    } finally {
      setPositionsUpdated(false)
    }
  }

  const onDeleteItem = async (item: GalleryItem) => {

    try {
      const updatedEvent = {
        ...event,
        gallery: event?.gallery
          ?.filter(g => g.id != item?.id)
          ?.map((item, index) => ({...item, position: index})) ?? []
      }

      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      showError('Error deleting gallery item')
    }

  }

  return <div className={classes.Wrapper}>

    <ResponsiveReactGridLayout
      maxRows={maxRows()}
      layouts={layouts}
      measureBeforeMount={false}
      useCSSTransforms={mounted}
      compactType={'horizontal'}
      preventCollision={false}
      onLayoutChange={onLayoutChange}
      onBreakpointChange={onBreakpointChange}
      isResizable={false}
      containerPadding={[0, 0]}
      rowHeight={140}
      cols={rows}
      breakpoints={breakpoints}
      onDrag={_ => setDragging(true)}
      onDragStop={() => {
        console.log(`onDragStop`)
        setPositionsUpdated(true)
        setTimeout(() => setDragging(false), 200)
      }}>
      {generateLayout()}
    </ResponsiveReactGridLayout>

    {updatingEvent && <div className={classes.Cover} onClick={e => e.stopPropagation()}>
      <SpinnerSmall/>
    </div>}

    {positionsUpdated && !updatingEvent && <div>
      <Spacer height={10}/>
      <AppButton title={'Save positions'} icon={faSave} onClick={onSaveClick}/>
    </div>}

    <GalleryModal
      key={DateTime.now().toString()}
      opened={showGalleryModal}
      onClose={() => setShowGalleryModal(false)}
      event={event}
      itemIndex={pressedItem ?? 0}/>

  </div>

};

interface GalleryItemProps {
  event?: Event
  galleryItem?: GalleryItem
  onDeleteItem?: (galleryItem: GalleryItem) => void
}

export const GalleryItemView = (props: GalleryItemProps) => {

  const {galleryItem, event, onDeleteItem} = props
  const {imageUrl, videoUrl, videoThumbnailUrl, imageStorageKey, videoStorageKey, videoThumbnailStorageKey} = galleryItem ?? {}
  const {updateEvent, updatingEvent} = useUpdateEvent()
  const [uploading, setUploading] = useState(false)
  const [showUrlModal, setShowUrlModal] = useState(false)
  const [url, setUrl] = useState('')
  const [urlError, setUrlError] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)

  const onDrop = useCallback(async (files: File[]) => {
    if (files.length == 0 || files.length > 1) {
      console.log(`onDrop - only one file is accepted`)
      showError('Only one file is accepted')
      return
    }

    try {
      setUploading(true)

      const fileName= files[0].name
      const isImage = isImageFile(fileName)
      const isVideo = isVideoFile(fileName)

      if (!isImage && !isVideo) {
        showError('Only image and video files are accepted')
        return
      }

      let videoThumbnailStorageKey = ''
      if (isVideo) {
        const thumbnail = await createVideoThumbnail(files[0])
        videoThumbnailStorageKey = await uploadFile(event?.id ?? '', thumbnail)
      }

      let file = files[0]
      let fileStorageKey = ''
      if (isImageFile(file.name)) {
        const compressedFile = await imageCompression(file, { maxSizeMB: maxGalleryImageSizeMb })
        fileStorageKey = await uploadFile(event?.id ?? '', compressedFile)
      } else {
        fileStorageKey = await uploadFile(event?.id ?? '', file)
      }

      const updatedEvent = {
        ...event,
        gallery: [
          ...event?.gallery ?? [],
          {
            imageStorageKey: isImage ? fileStorageKey : undefined,
            videoStorageKey: isVideo ? fileStorageKey : undefined,
            videoThumbnailStorageKey: isVideo ? videoThumbnailStorageKey : undefined,
            position: event?.gallery?.length ?? 0
          } as GalleryItem
        ]
      }

      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      console.log(`onDrop - error: ${e}`)
      showError('Error uploading file')
    } finally {
      setUploading(false)
    }
  }, [event])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const onSetUrlClickInner = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setShowUrlModal(true)
    // onSetUrlClick && onSetUrlClick(galleryItem)
  }

  const emptyContent = () => {
    return <div
      {...!showUrlModal && getRootProps()}
      className={isDragActive ? classes.GalleryItemEmptyActive : classes.GalleryItemEmpty}>

      {!showUrlModal && <input {...getInputProps()} />}

      {!uploading && !updatingEvent && <div className={classes.Title}>
        <div>Drop file here</div>
        <div>or <a onClick={onSetUrlClickInner}>set the url</a></div>
      </div>}

      {(uploading || updatingEvent) && <SpinnerSmall/>}

      {showUrlModal && <Modal
        title={'Set the url'}
        opened={showUrlModal}
        onClose={() => setShowUrlModal(false)}>

        <AppInput
          placeholder={'Url'}
          onChange={v => {
            setUrlError(false)
            setUrl(v)
          }}
          error={urlError}
        />

        <Spacer height={10}/>

        <AppButton
          title={'Set url'}
          onClick={onSaveUrlPressed}
          className={classes.SaveUrlButton}
          progress={updatingEvent}
        />

      </Modal>}

    </div>
  }

  const fullContent = () => {

    if (!galleryItem) {
      return <div/>
    }

    return <div className={classes.GalleryItemFull}>

      {(imageUrl || imageStorageKey) && <div className={classes.Image}>
        <AppImage src={imageUrl} storageKey={imageStorageKey} className={classes.Image}/>
      </div>}

      {(videoThumbnailUrl || videoThumbnailStorageKey) && <AppImage
        src={videoThumbnailUrl}
        storageKey={videoThumbnailStorageKey}
        className={classes.Image}/>}

      <div className={classes.Position}>
        {(galleryItem?.position ?? 0) + 1}
      </div>

      {videoThumbnailUrl && <FontAwesomeIcon
        icon={faPlay}
        className={classes.PlayButton}/>}

    </div>
  }

  const onSaveUrlPressed = async (e: any) => {

    if (!url) {
      showError('Please enter an url')
      setUrlError(true)
      return
    }

    if (!isUrlValid(url)) {
      showError('Please enter a valid url')
      setUrlError(true)
      return
    }

    try {

      const isImage = isImageUrl(url)
      const isVideo = isVideoUrl(url)

      if (!isImage && !isVideo) {
        showError('Only images and videos are supported')
        return
      }

      const updatedEvent = {
        ...event,
        gallery: [
          ...event?.gallery ?? [],
          {
            videoUrl: isVideo ? url : undefined,
            videoThumbnailUrl: isVideo ? await getThumbnail(url) : '',
            imageUrl: isImage ? url : undefined,
            position: event?.gallery?.length ?? 0,
          }
        ]
      }

      console.log(`onDrop - updatedEvent: ${JSON.stringify(updatedEvent)}`)

      await updateEvent(updatedEvent)
      await refreshEvent(updatedEvent.id ?? '', updatedEvent)
    } catch (e) {
      console.log(`onDrop - error: ${e}`)
      showError('Error uploading file')
    }
  }

  return <>
    {galleryItem ? fullContent() : emptyContent()}
  </>
}

//
const layoutsFromGallery = (gallery?: GalleryItem[]): Layouts => {

  if (gallery == null || gallery.length == 0) {
    return {
      lg: [
        layout(0, 'lg', true),
      ],
      md: [
        layout(0, 'md', true),
      ],
      sm: [
        layout(0, 'sm', true),
      ],
      xs: [
        layout(0, 'xs', true),
      ],
      xxs: [
        layout(0, 'xxs', true),
      ]
    }
  }

  gallery = gallery.sort((i1, i2) => (i1.position ?? 0) < (i2.position ?? 0) ? -1 : 1)

  return {
    lg: [
      ...gallery.map((_, i) => layout(i, 'lg')),
      layout(gallery.length ?? 0, 'lg', true),
    ],
    md: [
      ...gallery.map((_, i) => layout(i, 'md')),
      layout(gallery.length ?? 0, 'md', true),
    ],
    sm: [
      ...gallery.map((_, i) => layout(i, 'sm')),
      layout(gallery.length ?? 0, 'sm', true),
    ],
    xs: [
      ...gallery.map((_, i) => layout(i, 'xs')),
      layout(gallery.length ?? 0, 'xs', true),
    ],
    xxs: [
      ...gallery.map((_, i) => layout(i, 'xxs')),
      layout(gallery.length ?? 0, 'xxs', true),
    ]
  }
}

const layout = (index: number, type: WidthType, isStatic?: boolean): Layout => {
  return {
    x: index % rows[type],
    y: Math.floor(index / rows[type]),
    w: 1,
    h: 1,
    i: `${index}`,
    static: isStatic
  }
}

const position = (x: number, y: number, row: number) => {
  return y * row + x
}

const widthType = (): WidthType => {
  const screenWidth = window.innerWidth
  if (screenWidth >= breakpoints.lg) {
    return 'lg'
  } else if (screenWidth >= breakpoints.md) {
    return 'md'
  } else if (screenWidth >= breakpoints.sm) {
    return 'sm'
  } else if (screenWidth >= breakpoints.xs) {
    return 'xs'
  } else {
    return 'xxs'
  }
}

