import React from 'react'
import classes from "./InfoRow.module.scss";
import classnames from "classnames";
import { PropsWithClassName } from "./PropsWithClassName";

interface InfoRowPros {
  title?: string
  accent?: boolean
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  fontBig?: boolean
  grey?: boolean
  fontWeight?: 'bold' | 'normal' | 400 | 500 | 600 | 700 | 800 | 900
}

export default function InfoRow(props: InfoRowPros & PropsWithClassName) {
  const {icon, title, accent, fontBig, className, grey, fontWeight} = props
  const Icon = icon
  return <div className={classnames(
    classes.InfoRow,
    {[classes.Accent]: accent},
    {[classes.Grey]: grey},
    {[classes.FontBig]: fontBig},
    className
  )}>
    {!!Icon && <Icon className={classes.Icon}/>}
    <div className={classes.InfoTitle} style={{fontWeight}}>
      {title}
    </div>
  </div>
}
