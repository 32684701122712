import React, {useEffect, useState} from 'react'
import classes from './WristbandNumberModal.module.scss'
import Modal, {ModalProps} from "../common/Modal";
import {AppButton, AppInput, Spacer} from "../common";
import WristbandBack from "../../images/wristband_back.png";
import {faSave} from "@fortawesome/free-regular-svg-icons";
import {wristbandNumberMaxLength, wristbandNumberMinLength} from "../../config/constants";
import {showError} from "../../util/toast_util";
import {useTranslation} from "react-i18next";

interface WristbandNumberModalProps {
  wristbandNumber: string
  onNumberChanged: (number: string) => void
}

export const WristbandNumberModal: React.FC<WristbandNumberModalProps & ModalProps> = (props) => {
  const {opened, onClose, className, onNumberChanged} = props
  const [number, setNumber] = useState<string>(props.wristbandNumber)
  const [numberError, setNumberError] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    setNumber(props.wristbandNumber)
  }, [])

  useEffect(() => {
    setNumberError(false)
  }, [number])

  const onSaveClick = () => {
    if (number.length == 0) {
      showError(t(`please_enter_the_wristband_number`))
      setNumberError(true)
      return
    }

    if (number.length < wristbandNumberMinLength || number.length > wristbandNumberMaxLength) {
      showError(t('wristband_number_should_be_from_to_symbols_length', {
        min: wristbandNumberMinLength,
        max: wristbandNumberMaxLength
      }))
      setNumberError(true)
      return
    }

    onNumberChanged(number)
    onClose()

  }

  return <Modal
    opened={opened}
    onClose={onClose}
    className={className}
    title={t('enter_wristband_number')}>

    <div className={classes.WristbandNumberModal}>
      <AppInput
        placeholder={t('wristband_number')}
        initialValue={number}
        onChange={setNumber}
        error={numberError}
        maxLength={wristbandNumberMaxLength}
        uppercase/>

      <Spacer height={16}/>

      <div className={classes.Info}>
        {t('wristband_number_explanation')}
      </div>

      <Spacer height={16}/>

      <div className={classes.WristbandNumberHint}>
        <img src={WristbandBack} alt={'wristband'} className={classes.Image}/>
      </div>

      <Spacer height={40}/>

      <AppButton
        title={t('save')}
        icon={faSave}
        onClick={onSaveClick}
        className={classes.SaveButton}/>

    </div>
  </Modal>

}
