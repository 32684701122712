import React, {useEffect} from 'react'
import classes from './EventImageGuideline.module.scss'
import EventImageInstruction1 from "../../images/event_image_instruction_1.png"
import EventImageInstruction2 from "../../images/event_image_instruction_2.png"
import EventImageInstruction3 from "../../images/event_image_instruction_3.png"
import EventImageGoodExample1 from "../../images/event_image_good_example_1.jpeg"
import EventImageGoodExample2 from "../../images/event_image_good_example_2.jpeg"
import EventImageGoodExample3 from "../../images/event_image_good_example_3.png"
import EventImageBadExample1 from "../../images/event_image_bad_example_1.jpeg"
import EventImageBadExample2 from "../../images/event_image_bad_example_2.png"

import {AppImage} from "../common/AppImage"
import {Spacer} from "../common"
import {ViewHeader} from "../common/ViewHeader"
import useUser from "../../hooks/useUser"
import {useNavigate} from "react-router-dom"

export const EventImageGuideline = () => {

  const {user, isBillfoldAdmin} = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !isBillfoldAdmin) {
      navigate('/')
    }
  }, [user])

  return <div className={classes.EventImageGuideline}>

    <ViewHeader title={'Event image guideline'} showBackButton/>

    <Spacer height={16}/>

    <ul>

      <li>Event main image aspect ratio should be 2:1</li>
      <li>These sizes are ok: 2000x1000, 1000x500</li>
      <li>Minimum size is 1000x500</li>
      <li>Image can looks different on different screens</li>
      <li>Text content must be in the center</li>
      <li>Min. distance from top/bottom is 1/4 of the height</li>
      <li>Min. distance from left/right is 1/4 of the width</li>
      <li>Schema example</li>
      <AppImage src={EventImageInstruction1} className={classes.Image}/>

      <li>How it can be cut on different screens</li>
      <AppImage src={EventImageInstruction2}  className={classes.Image}/>
      <AppImage src={EventImageInstruction3} className={classes.Image}/>

      <li>Good examples</li>
      <AppImage src={EventImageGoodExample1} className={classes.Image}/>
      <AppImage src={EventImageGoodExample2} className={classes.Image}/>
      <AppImage src={EventImageGoodExample3} className={classes.Image}/>

      <li>Bad examples</li>
      <AppImage src={EventImageBadExample1} className={classes.Image}/>
      <AppImage src={EventImageBadExample2} className={classes.Image}/>

    </ul>

  </div>
}
