import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import classes from "./LocationSelector.module.scss";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css"
import {Location} from "../../services/api/models";
import {debounce} from "@mui/material";
import {useFocus} from "../../hooks/useFocus";
import {showError} from "../../util/toast_util";
import {Overlay} from "react-bootstrap";
import {Menu} from "./Menu";
import {DivOrNull} from "../account/AccountView";
import {getLocationFromGooglePlace} from "../../util/location_util";
import {GoogleMap, MarkerF} from "@react-google-maps/api";
import {Spacer} from "./Spacer";

type Prediction = google.maps.places.QueryAutocompletePrediction
type Status = google.maps.places.PlacesServiceStatus

interface LocationSelectorProps {
  placeholder?: string
  error?: boolean
  onChange?: (location: Location) => void
  onMapImageChange?: (address: string, image: File) => void
  initialLocation?: Location
  disabled?: boolean
  loading?: boolean
  trailing?: ReactNode
  hoverTrailing?: ReactNode
}

export const LocationSelector: React.FC<LocationSelectorProps & PropsWithChildren> = (props) => {
  const {
    placeholder,
    error,
    onChange,
    disabled,
    initialLocation,
    trailing,
    hoverTrailing
  } = props

  const {mapImageUrl, address} = initialLocation ?? {}

  const [valueLocal, setValueLocal] = useState<string | undefined>()
  const [inputRef, setInputFocus] = useFocus()
  const [loading, setLoading] = useState(false)
  const overlayContainerRef = useRef(null)
  const [target, setTarget] = useState(null);
  const [overlayOpened, setOverlayOpened] = useState(false)
  const [predictions, setPredictions] = useState<google.maps.places.QueryAutocompletePrediction[]>()
  const [showHoverTrailing, setShowHoverTrailing] = useState(false)
  const [latitude, setLatitude] = useState<number>()
  const [longitude, setLongitude] = useState<number>()
  const ref = useRef<DivOrNull>(null)

  useEffect(() => {
    if (initialLocation) {
      setValueLocal(initialLocation.address)
      setLatitude(initialLocation.latitude)
      setLongitude(initialLocation.longitude)
    }
  }, [initialLocation])

  const getPlacesAutoComplete = async (query: string): Promise<Prediction[]> => {

    return new Promise((resolve, reject) => {
      try {
        const service = new google.maps.places.AutocompleteService();

        service.getQueryPredictions(
          {input: query},
          (predictions: Prediction[] | null, status: Status) => {
            switch (status) {
              case 'OK':
                resolve(predictions ?? [])
                break;
              case 'NOT_FOUND':
                reject(new Error('Place not found'))
                break;
              case 'INVALID_REQUEST':
                reject(new Error('Invalid request'))
                break;
              case 'OVER_QUERY_LIMIT':
                reject(new Error('Limit exceeded'))
                break;
              case 'UNKNOWN_ERROR':
                reject(new Error('Unknown error'))
                break;
              case 'REQUEST_DENIED':
                reject(new Error('Request denied'))
                break;
              case 'ZERO_RESULTS':
                resolve([])
                break;
            }
          }
        );
      } catch (e) {
        reject(e)
      }
    })
  }

  const getPlace = async (placeId: string): Promise<google.maps.places.PlaceResult | null> => {
    const div = ref.current
    if (!div) {
      throw new Error('View is not rendered, can not access Place Service')
    }

    return new Promise((resolve, reject) => {
      try {
        new google.maps.places.PlacesService(div).getDetails(
          {placeId},
          r => resolve(r)
        )
      } catch (e) {
        reject(e)
      }
    })
  }

  const onChangeLocal = (e: any) => {
    setValueLocal(e.target.value)
    onInputChangedDebounced(e)
  }

  const onInputChangedDebounced = useCallback(debounce(async (e: any) => {
    try {
      setLoading(true)
      const places = await getPlacesAutoComplete(e.target.value)
      console.log(`onInputChangedDebounced - places: ${places.length}`)
      setPredictions(places)
      setOverlayOpened(true)
    } catch (e) {
      showError(`Can not load places: ${e}`)
    } finally {
      setLoading(false)
    }
  }, 300), [])

  const onFocus = (e: any) => {
    setTarget(overlayContainerRef.current)
  }

  const onPlaceSelected = async (prediction: Prediction) => {
    setValueLocal(prediction.description)
    setOverlayOpened(false)

    const place = await getPlace(prediction.place_id ?? '')

    if (!place) {
      showError('Can not get the place from API')
      return
    }

    onChange && onChange(getLocationFromGooglePlace(place))
  }

  const onMouseEnter = () => {
    setShowHoverTrailing(true)
  }

  const onMouseLeave = () => {
    setShowHoverTrailing(false)
  }

  return <div className={classes.LocationSelector}>
    <div
      ref={ref}
      className={classes.AppInput}
      style={{paddingTop: 0}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>

      <div className={classes.InputContainer}>

        <div ref={overlayContainerRef}>

          <div className="form-floating">
            <input
              ref={inputRef}
              className={classnames(
                classes.Input,
                {[classes.InputError]: error},
                "form-control"
              )}
              placeholder={placeholder ?? 'Location'}
              id="floatingInput"
              onChange={onChangeLocal}
              value={valueLocal}
              disabled={disabled}
              onFocus={onFocus}/>

            <label htmlFor="floatingInput">{placeholder ?? 'Location'}</label>
          </div>

          <Overlay
            rootClose
            onHide={() => setOverlayOpened(false)}
            show={overlayOpened}
            target={target}
            placement='bottom'
            container={overlayContainerRef}>
            {props => <div {...props} style={{zIndex: 999, position: 'absolute', width: '100%'}}>
              <Menu
                items={predictions?.map(p => ({
                  title: p.description,
                  onClick: () => onPlaceSelected(p)
                })) ?? []}/>
            </div>}

          </Overlay>

        </div>

        <div className={classes.Icons}>

          {trailing}

          {showHoverTrailing && hoverTrailing}

        </div>

      </div>

    </div>

    <Spacer height={10}/>

    <div className={classes.MapWrapper} style={{height: 250, opacity: 1}}>
      {mapImageUrl
        ? <div className={classes.MapImageWrapper}>
          <img className={classes.MapImage} src={mapImageUrl}/>
        </div>
        : <GoogleMap
          mapContainerClassName={classes.Map}
          zoom={16}
          options={{
            disableDefaultUI: true,
          }}
          center={{
            lat: latitude ?? 0,
            lng: longitude ?? 0
          }}>
          <MarkerF
            position={{
              lat: latitude ?? 0,
              lng: longitude ?? 0
            }}>
            test
          </MarkerF>
        </GoogleMap>}

      <a
        href={`https://maps.google.com/?q=${address}`}
        target='_blank'
        className={classes.MapCover}/>
    </div>
  </div>
}
