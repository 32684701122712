import {getRedirectRules} from '../services/api/api'
import useSWR, {mutate} from 'swr'

export const useRedirectRules = () => {
  const {data, error, isLoading} = useSWR('getRedirectRules', getRedirectRules)
  return {
    rules: data,
    rulesLoading: isLoading,
    rulesError: error
  }
}

export const refreshRedirectRules = async () => {
  await mutate('getRedirectRules')
}
