import React, { useMemo, useState } from 'react'
import classes from './TransactionDetailsModal.module.scss'
import { moneyFormatTwoDigits } from "../../util/format_util"
import { BalanceTransaction, BalanceTransactionType, Event } from "../../services/api/models"
import { formatDate } from "../../util/date_util"
import { ModalProps } from "../common"
import Modal from "../common/Modal"
import { isRefund, transactionReason } from "../../util/transaction_util"
import { useCard } from "../../hooks/useCard"
import { WristbandItem } from "../common/WristbandItem"
import useWristband from "../../hooks/useWristband"
import { PaymentMethodInfo } from "../common/PaymentMethodInfo";
import { TransactionSourceIcon } from "../common/TransactionSourceIcon";
import classnames from "classnames";

interface TransactionDetailsModalProps {
  transaction: BalanceTransaction
  event?: Event
}

export const TransactionDetailsModal: React.FC<TransactionDetailsModalProps & ModalProps> = (props) => {

  const {transaction, event} = props
  const {amount, createdAt, paymentMethodID, wristbandID, type} = transaction
  const {card} = useCard(paymentMethodID)
  const {wristband} = useWristband(wristbandID)

  const sourceInfo = () => {
    if (card) {
      return <PaymentMethodInfo card={card}/>
    }

    let source
    switch (transaction.type) {
      case BalanceTransactionType.cashTopUp:
      case BalanceTransactionType.cashRefund:
        source = 'Cash'
        break
      case BalanceTransactionType.bankCardCashTopUp:
      case BalanceTransactionType.bankCardCashRefund:
        source = 'Bank card'
        break
      case BalanceTransactionType.promoCashTopUp:
      case BalanceTransactionType.promoCashRefund:
        source = 'Promo cash'
        break
      case BalanceTransactionType.cryptoTopUp:
      case BalanceTransactionType.cryptoRefund:
        source = 'Crypto'
        break
      default:
        source = 'Unknown source'
        break
    }

    return <div className={classes.SourceInfo}>
      <TransactionSourceIcon transaction={transaction}/>
      {source}
    </div>
  }

  const refund = useMemo(() => isRefund(type), [type])

  return <Modal
    {...props}
    title={transactionReason(transaction)}>

    <div className={classes.TransactionDetailsModal}>

      <div className={classnames(
        classes.Sum,
        {[classes.Red]: amount < 0 && !refund},
        {[classes.Green]: amount > 0 && !refund},
        {[classes.Accent]: refund},
      )}>{refund ? '' : (amount > 0 ? '+' : '-')}{moneyFormatTwoDigits(Math.abs(amount), event?.paymentAccountCurrency)}</div>


      <div className={classes.Date}>{formatDate(createdAt, 'dd MMM yyyy, hh:mm:ss')} </div>

      {sourceInfo()}

      <div className={'w-100 mt-5'}>
        <WristbandItem wristband={wristband} event={event}/>
      </div>
    </div>
  </Modal>
}
