import {getWristbandBatches} from "../services/api/api";
import useSWR, {mutate} from "swr";
import {WristbandBatch} from "../services/api/models";

export const useWristbandBatches = () => {
  const {data, error, isLoading} = useSWR('getWristbandBatches', getWristbandBatches)

  return {
    batches: data,
    batchesLoading: isLoading,
    batchesError: error,
  }
}


export const refreshWristbandBatches = async (batches?: WristbandBatch[]) => {
  await mutate('getWristbandBatches', batches)
}
