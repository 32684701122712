import React, { DetailedHTMLProps, useCallback, useEffect, useState } from 'react'
import classes from './SearchBox.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { debounce } from "@mui/material";

const typingDebounceTime = 300

interface SearchBoxProps {
  onSearchChange: (query: string) => void
  placeholder?: string
  onCloseClick?: () => void
  initialValue?: string
}

export const SearchBox: React.FC<SearchBoxProps & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {
  const {onSearchChange, placeholder, onCloseClick, initialValue} = props

  const [value, setValue] = useState<string>()

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  const onCloseClickInner = () => {
    if (value) {
      console.log(`onCloseClickInner - set value undefined`)
      setValue('')
      onSearchChange('')
    } else {
      onCloseClick?.()
    }
  }

  const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onInputChangedDebounced(e)
  }

  const onInputChangedDebounced = useCallback(debounce(async (e: any) => {
    onSearchChange(e.target.value)
  }, typingDebounceTime), [])

  return <div {...props} className={classnames(classes.SearchBox, props.className)}>
    <FontAwesomeIcon icon={faSearch} className={classes.Icon}/>
    <input
      className={classes.SearchInput}
      placeholder={placeholder ?? 'Search'}
      onChange={onChangeInner}
      value={value}/>
    {(value || onCloseClick) &&
      <FontAwesomeIcon icon={faClose} className={classes.CloseIcon} onClick={onCloseClickInner}/>}
  </div>
}
