import React, {useEffect, useState} from 'react'
import classes from './OrganizersView.module.scss'
import {Spacer} from "../common"
import {isMobile} from "react-device-detect"
import {ViewHeader} from "../common/ViewHeader"
import {useOrganizers} from "../../hooks/useOrganizers"
import {useTranslation} from "react-i18next"
import {TableView} from "../common/TableView";
import {useIntegrators} from "../../hooks/useIntegrators";

export const OrganizersView = () => {

  const {organizers} = useOrganizers()
  const {integrators} = useIntegrators()
  const {t} = useTranslation()

  return <div className={classes.OrganizersView}>

    <ViewHeader title={t('organizers')} showBackButton={isMobile}/>

    <Spacer height={20}/>

    <TableView
      columns={[5,3,4]}
      headers={[t('name'), t('integrator'), t('code')]}
      mobileHeader={t('name')}
      items={organizers?.map(o => ({
        values: [
          o.name,
          (integrators??[]).find(i => o.integratorID == i.id)?.name,
          o.code,
        ],
        mobileValue: o.name,
        linkTo: o.id ?? '',
      })) ?? []}
      placeholderText={t('no_organizers')}/>

  </div>
}
