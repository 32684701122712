import React, {MouseEventHandler, PropsWithChildren} from 'react'
import classes from "./AddButton.module.scss";
import {Button} from "react-bootstrap";
import classnames from "classnames";
import SpinnerSmall from "./SpinnerSmall";
import {ReactComponent as AddIcon} from "../../images/add.svg";

interface AddButtonProps {
  title?: string
  onClick?: MouseEventHandler | undefined
  className?: string
  progress?: boolean
  error?: boolean
  gray?: boolean
  fontColor?: string
}

export const AddButton: React.FC<AddButtonProps & PropsWithChildren> = (props) => {
  const {title, children, onClick, className, progress, error, gray, fontColor} = props
  return <Button
    className={classnames(
      classes.ActionButton,
      classes.TransparentButton,
      className,
      'w-100',
      {[classes.Secondary]: gray},
      {[classes.Danger]: error}
    )}
    onClick={onClick}
    style={{color: `${fontColor} !important`}}>

    {progress && <SpinnerSmall/>}

    {!progress && <div>
      <AddIcon className={classnames(className, classes.AddButtonIcon, {[classes.AddButtonIconDanger]: error})}/>
    </div>}

    {!progress && title}

    {!progress && children}

  </Button>
}
