import {createUser, findUser, createStripeCustomerIfNeedTo} from "../services/api/api";
import {UserRegisteredFromOtherChannelError} from "../services/api/errors";
import {User} from "../services/api/models";

export const useCheckSocialLogin = () => {
  return {handleSignInEvent}
}

const handleSignInEvent = async (data: any): Promise<User> => {
  const authUserId = data.username
  const {
    given_name: firstName,
    family_name: lastName,
    email
  } = data?.signInUserSession?.idToken?.payload ?? {}

  if (!authUserId) {
    console.log(`checkForSocialLogin - reject 1`)
    throw new Error('Social login: auth user id is missing')
  }

  if (!email) {
    console.log(`checkForSocialLogin - reject 2`)
    throw new Error('Social login: email is missing')
  }

  // removed this for now
  // because the new big Lousiana event is open for everyone
  //
  // const invite = await getInvite(email)
  // if (!invite) {
  //   console.log(`checkForSocialLogin - INVITE ERROR!!!`)
  //   throw new UserNotInvitedError()
  // }

  let user = await findUser(email)
  console.log(`checkForSocialLogin - found user: ${user?.email}, ${user?.authUserID}`)
  if (user && user.authUserID != authUserId) {
    throw new UserRegisteredFromOtherChannelError()
  }

  if (!user) {
    console.log(`checkForSocialLogin - creating user...`)
    user = await createUser(authUserId, email, firstName, lastName)
  }

  await createStripeCustomerIfNeedTo(user)

  console.log(`checkForSocialLogin - success!`)
  return user
}
