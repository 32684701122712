import React, {useEffect, useMemo, useState} from 'react'
import classes from './WristbandsView.module.scss'
import EventWristbandsView from "../common/EventWristbandsView";
import {Wristband} from "../../services/api/models";
import { useWristbands } from "../../hooks/useWristbands";
import PlaceholderView from "../common/PlaceholderView";
import { Route, Routes } from "react-router-dom";
import { WristbandDetailsView } from "../common/WristbandDetailsView";
import { Spacer } from '../common';
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

interface WristbandsViewProps {
}

export const WristbandsView = (props: WristbandsViewProps) => {

  // todo create wristbands view to delete code duplication
  const [eventsWristbands, setEventsWristbands] = useState<Map<string, Wristband[]>>()
  const {wristbands, wristbandsLoading} = useWristbands()
  const {t} = useTranslation()

  useEffect(() => {
    if (wristbands && wristbands.length > 0 && !eventsWristbands) {
      const eventsWristbandsInner = new Map<string, Wristband[]>()
      for (const w of wristbands) {
        if (w.registrationType != 'qr') {
          const {eventID} = w
          eventsWristbandsInner.set(eventID ?? '', [...(eventsWristbandsInner.get(eventID ?? '') ?? []), w])
        }
      }
      setEventsWristbands(eventsWristbandsInner)
    }

  }, [wristbands])

  return <Routes>
    <Route path='/:wristbandId/*' element={
      <div className={classes.WristbandsView}>
        <WristbandDetailsView/>
      </div>
    }/>

    <Route path='/' element={
      <div className={classes.WristbandsView}>

        {isMobile && <h1 className={classes.Header}>{t('wristbands')}</h1>}

        {!wristbands && wristbandsLoading && <EventWristbandsView loading/>}

        {wristbands && !wristbands.length && !wristbandsLoading && <PlaceholderView
          title={'You have no wristbands'}/>}

        {wristbands && !!wristbands.length && eventsWristbands && <div style={{marginBottom: 100}}>
          {[...eventsWristbands.entries()].map(e => <EventWristbandsView
            key={e[0]}
            eventId={e[0]}
            wristbands={e[1]}/>)}
        </div>}

        <Spacer height={60}/>

      </div>
    }/>

  </Routes>

}
