import React, { useState } from 'react'
import classes from './CardsView.module.scss'
import useCards from "../../hooks/useCards";
import { CardItem } from "./CardItem";
import { AddButton, AddCardModal } from "../common";
import { AccountSectionTitle } from "./AccountSectionTitle";
import { AccountPlaceholderView } from "./AccountPlaceholderView";
import { ReactComponent as CardIcon } from "../../images/credit_card.svg";
import { isEmpty, isNotEmpty } from "../../util/array_util";
import {useTranslation} from "react-i18next";

export default function CardsView() {
  const {t} = useTranslation()
  const {cards} = useCards()
  const [showCardModal, setShowCardModal] = useState(false)

  const onAddCardPressed = () => {
    setShowCardModal(true)
  }

  return <div className={classes.CardsView}>

    <AccountSectionTitle title={t('cards')}/>

    {isEmpty(cards) && <AccountPlaceholderView
      icon={CardIcon}
      buttonTitle={t('add_my_first_card')}
      onClick={onAddCardPressed}/>}

    {cards?.map(c => <div className={'mt-3'}>
      <CardItem
        key={c.id}
        showDelete
        card={c}/>
    </div>)}

    {isNotEmpty(cards) && <div className={'mt-3'}>
      <AddButton title={t('add_new_card')} onClick={onAddCardPressed}/>
    </div>}

    {showCardModal && <AddCardModal
      onClose={() => setShowCardModal(false)}
      opened={showCardModal}
    />}

  </div>
}
