import React from "react";
import { ReactComponent as GoogleIcon } from "../../images/google.svg";
import classes from "./SocialButton.module.scss";
import { ReactComponent as FacebookIcon } from "../../images/facebook.svg";
import { ReactComponent as SpotifyIcon } from "../../images/spotify.svg";
import Spinner from "../common/Spinner";

interface SocialButtonProps {
  type: 'google' | 'facebook' | 'spotify'
  title: string
  onClick: VoidFunction
  progress?: boolean
}

export default function SocialButton({type, onClick, progress, title}: SocialButtonProps) {

  const icon = () => {
    switch (type) {
      case 'google':
        return <GoogleIcon className={classes.SocialButtonIcon}/>
      case 'facebook':
        return <FacebookIcon className={classes.SocialButtonIcon}/>
      case 'spotify':
        return <SpotifyIcon className={classes.SocialButtonIcon}/>
    }
  }

  const providerName = (): string => {
    switch (type) {
      case 'google':
        return 'Google'
      case 'facebook':
        return 'Facebook'
      case 'spotify':
        return 'Spotify'
    }
  }

  return <div className={classes.SocialButton} onClick={onClick}>
    {!progress && <div className={'d-flex w-100 justify-content-center'}>
      <div className={classes.Icon}>{icon()}</div>
      {title} {providerName()}
    </div>}
    {progress && <div>
      <Spinner/>
    </div>}
  </div>
}
