import classes from "./EventItemSmall.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faImage} from "@fortawesome/free-solid-svg-icons"
import {Shimmer, Spacer} from "../common"
import InfoRow from "../common/InfoRow"
import {dates, locationTitle} from "../../util/event_util"
import React from "react"
import {Event} from "../../services/api/models"
import {ReactComponent as Calendar} from "../../images/calendar_small.svg";
import {ReactComponent as Location} from "../../images/location_small.svg";
import {AppImage} from "../common/AppImage";

interface EventItemSmallProps {
  event?: Event
  onClick?: (e: Event) => void
  selected?: boolean
  shimmer?: boolean
}

export const EventItemSmall = (props: EventItemSmallProps) => {
  const {event, onClick, selected, shimmer} = props
  const {imageUrl, imageStorageKey, name, locations, startDate, finishDate} = event ?? {}

  const platformAccountInfo = () => {
    return <div className={classes.PlatformAccountInfo}>
      {event?.platformAccountBillfoldId}
    </div>
  }

  const shimmerContentOld = () => {
    return <div className={classes.EventItemSmall}>

      <div className={classes.ImagePlaceholder}>
        <Shimmer width={50} height={50}/>
      </div>

      <div className={classes.Info}>

        <Shimmer width={100} height={16}/>

        <Spacer height={3}/>

        <Shimmer width={100} height={10}/>

        <Shimmer width={100} height={10}/>

      </div>

      {process.env.REACT_APP_ENV == 'staging' && platformAccountInfo()}

    </div>
  }

  const shimmerContent = () => {
    return <div className={classes.EventItemSmallShimmer}>
      <Shimmer height={74} width={74} borderRadius={10}/>

      <Spacer width={16}/>

      <div>
        <Shimmer height={20} width={160}/>
        <Spacer height={10}/>
        <Shimmer height={20} width={80}/>
      </div>

    </div>
  }

  const content = () => {
    return <div
      className={selected? classes.EventItemSmallSelected:classes.EventItemSmall}
      onClick={() => event && onClick && onClick(event)}>

      {(imageUrl || imageStorageKey)
        ? <div className={classes.ImageWrapper}>
            <AppImage src={imageUrl} storageKey={imageStorageKey} alt={name} className={classes.Image}/>
          </div>
        : <div className={classes.ImagePlaceholder}>
          <FontAwesomeIcon icon={faImage} className={classes.ImagePlaceholderIcon}/>
        </div>}

      <div className={classes.Info}>

        <div className={classes.ClosestTitle}>{event?.name}</div>

        <Spacer height={3}/>

        <InfoRow
          title={dates(startDate, finishDate)}
          icon={Calendar}
          fontWeight={400}/>

        <InfoRow
          title={locationTitle(event)}
          icon={Location}
          grey={!locations || !locations.length}
          fontWeight={400}/>

      </div>

      {process.env.REACT_APP_ENV == 'staging' && platformAccountInfo()}

    </div>
  }

  return shimmer ? shimmerContent() : content()

}
