import React, {useEffect, useState} from 'react'
import classes from './LoginView.module.scss'
import {ReactComponent as CheckIcon} from '../../images/check_big_very.svg'
import classnames from "classnames";
import {AppButton, AppInput, InputType} from "../common";
import {isEmailValid, passwordAlphaNumeric} from "../../util/validation_util";
import {deleteAuthUser} from "../../services/api/api";
import {
  UserNotConfirmedError,
  UserNotInvitedError,
  UserRegisteredFromOtherChannelError
} from "../../services/api/errors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-regular-svg-icons'
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons'
import {showError, showSuccess} from "../../util/toast_util";
import {
  Link, Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import useUser, {refreshUser} from "../../hooks/useUser";
import {Spacer} from "../common";
import Footer from "../main/Footer";
import {isDesktop, isMobile} from 'react-device-detect';
import SocialButton from "./SocialButton";
import {useGoogleLogin} from "../../hooks/useGoogleLogin";
import {useFacebookLogin} from "../../hooks/useFacebookLogin";
import Spinner from "../common/Spinner";
import {useStorage} from "../../hooks/useStorage";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import PlaceholderView from "../common/PlaceholderView";
import {User} from "../../services/api/models";
import {Auth, Hub} from "aws-amplify";
import Modal from "../common/Modal";
import {useCheckSocialLogin} from "../../hooks/useSocialLogin";
import {parseState} from "../../util/amplify_state_util";
import {useLogin} from "../../hooks/useLogin";
import {useRegister} from "../../hooks/useRegister";
import {useConfirmEmail} from "../../hooks/useConfirmEmail";
import {useIntercom} from "react-use-intercom";
import {Checkbox} from "../common/Checkbox";
import {useTranslation} from "react-i18next";
import Logo from '../../images/logo.svg'
import {AppImage} from "../common/AppImage";
import useSystemSettings from "../../hooks/useSystemSettings";

const socialLoginTimeout = 5000

const supportedBrowsers = [
  {name: 'Google Chrome', pattern: /Chrome/},
  {name: 'Mozilla Firefox', pattern: /Firefox/},
  {name: 'Apple Safari', pattern: /Safari/},
  {name: 'Microsoft Edge', pattern: /Edge/},
  {name: 'Opera', pattern: /Opera|OPR/},
];

export default function LoginView() {

  const [params] = useSearchParams()
  const {settings, settingsLoading} = useSystemSettings()
  const {login, loginInProgress} = useLogin()
  const {register, registerInProgress} = useRegister()
  const {sendCode, sendingCode, resendCode, resendingCode} = useConfirmEmail()
  const {loginWithGoogle} = useGoogleLogin()
  const {loginWithFacebook} = useFacebookLogin()
  const {handleSignInEvent} = useCheckSocialLogin()
  const navigate = useNavigate()
  const {user} = useUser()
  const [showRegister, setShowRegister] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(true)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [confirmationCodeError, setConfirmationCodeError] = useState(false)
  const [error, setError] = useState('')
  const [userIsNotActive, setUserIsNotActive] = useState(false)
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const {
    loginWithGoogleInProgress: storageGoogleInProgress,
    setLoginWithGoogleInProgress,
    loginWithFacebookInProgress: storageFacebookInProgress,
    setLoginWithFacebookInProgress,
  } = useStorage()
  const {pathname} = useLocation()
  const [showNotInvitedModal, setShowNotInvitedModal] = useState(false)
  const [deleteAccountOnNotInvitedModalClose, setDeleteAccountOnNotInvitedModalClose] = useState(false)
  const [redirect, setRedirect] = useState<string>()
  const {boot} = useIntercom()
  const {t} = useTranslation()

  const isSupportedBrowser = supportedBrowsers.some(b => b.pattern.test(navigator.userAgent));

  useEffect(() => {

    if (isDesktop) {
      boot()
    }

    const unsubscribe = Hub.listen(
      'auth',
      async ({payload: {event, data}}) => {
        switch (event) {
          case 'signIn':

            if (!storageGoogleInProgress && !storageFacebookInProgress) {
              return
            }

            handleSignInEvent(data)
              .catch(e => {
                console.log(`checkForSocialLogin - error: ${e}`)
                if (e instanceof UserRegisteredFromOtherChannelError) {
                  console.log(`checkForSocialLogin - error: User registered via other channel`)
                  if (storageGoogleInProgress) {
                    setError(t('user_already_registered_error_1'))
                  } else if (storageFacebookInProgress) {
                    setError(t('user_already_registered_error_2'))
                  } else {
                    setError(t('user_already_registered_error_3'))
                  }
                } else if (e instanceof UserNotInvitedError) {
                  navigate('/auth')
                  setDeleteAccountOnNotInvitedModalClose(true)
                  setShowNotInvitedModal(true)
                } else {
                  console.log(`checkForSocialLogin - error: ${e?.message ?? e?.toString()}`)
                  setError(e?.message ?? e?.toString())
                }
                setLoginWithGoogleInProgress(false)
                setLoginWithFacebookInProgress(false)
              })
              .then(async (user) => {
                if (!user) {
                  return
                }
                console.log(`checkForSocialLogin - user: ${JSON.stringify(user)}`)

                // we set a user with the small delay of 1 sec
                // this is because we are waiting for customOAuthState event
                // which appears AFTER the signIn event,
                // but we need that because the redirect path
                // is set in the customOAuthState event,
                // so we are waiting for it a bit. Usually this comes in 100-200ms
                // after the signIn event
                //
                // another option is to set both signInData and socialProvider data
                // to the element state and then useEffect on them both changed.
                // this way we don't need this ugly timeout
                setTimeout(async () => {
                  await refreshUser(user as User)
                  setLoginWithGoogleInProgress(false)
                  setLoginWithFacebookInProgress(false)
                }, 1000)
              })
            break
          case 'customOAuthState':
            const parsedState = parseState(data)
            setRedirect(parsedState.redirect)
            break
        }
      }
    )

    if (pathname != '/auth' && !storageFacebookInProgress && !storageGoogleInProgress) {
      navigate('/auth')
    }

    if (pathname == '/auth' || pathname == '/auth/') {
      setTimeout(() => {
        setLoginWithGoogleInProgress(false)
        setLoginWithFacebookInProgress(false)
      }, socialLoginTimeout)
    }

    return unsubscribe

  }, [])

  useEffect(() => {
    if (user && !registerSuccess && !sendingCode) {
      // removing double slashes because it leads to the wrong redirection
      navigate(`/${redirect ?? ''}`.replace('//', '/'))
    }
  }, [user])

  useEffect(() => {
    setEmailError(false)
    setError('')
  }, [email])

  useEffect(() => {
    setPasswordError(false)
    setError('')
  }, [password])

  useEffect(() => {
    setConfirmPasswordError(false)
    setError('')
  }, [confirmPassword])

  useEffect(() => {
    setConfirmationCodeError(false)
    setError('')
  }, [confirmationCode])

  useEffect(() =>{
    setRedirect(params.get('redirect') ?? undefined)
  }, [params])

  const switchMode = () => {
    setShowRegister(!showRegister)
  }

  const switchRememberMe = () => {
    setRememberMe(!rememberMe)
  }

  const onLogin = async () => {
    console.log(`onLogin`)
    if (!email || !isEmailValid(email)) {
      setEmailError(true)
    }

    if (!password) {
      setPasswordError(true)
    }

    if (!email || !password || !isEmailValid(email)) {
      return
    }

    try {
      const user = await login({email, password})
      if (user) {
        await refreshUser(user)
      } else {
        await refreshUser()
      }
      // todo add redirect
      navigate(`/${params.get('redirect')}`.replace('//', '/'))
    } catch (e: any) {
      console.log(`onLogin - error: ${e.message}`)
      console.log(`onLogin - error: ${e.toString()}`)
      if (e instanceof UserNotConfirmedError) {
        setShowConfirm(true)
        setUserIsNotActive(true)
      } else {
        setError(e.message)
      }
    }

  }

  const onRegister = async () => {
    if (!email) {
      setEmailError(true)
      setError(t('enter_the_email_please'))
      return
    }

    if (!isEmailValid(email)) {
      setEmailError(true)
      setError(t('email_address_is_wrong_please_check_it'))
      return
    }

    if (!password) {
      setPasswordError(true)
      setError(t('enter_the_password_please'))
      return
    }

    if (password.length < 8) {
      setPasswordError(true)
      setError(t('password_must_be_8_characters_or_longer'))
      return
    }

    if (!passwordAlphaNumeric(password)) {
      setPasswordError(true)
      setError(t('password_must_contain_both_letters_and_numbers'))
      return
    }

    if (password != confirmPassword) {
      setPasswordError(true)
      setConfirmPasswordError(true)
      setError(t('passwords_do_not_match'))
      return
    }

    if (!confirmPassword) {
      setConfirmPasswordError(true)
      setError(t('confirm_the_password_please'))
      return
    }

    try {
      await register({email, password})
      setShowConfirm(true)
    } catch (e: any) {
      console.log(`onRegister - error: ${e}`)
      if (e instanceof UserNotInvitedError) {
        setShowNotInvitedModal(true)
      } else {
        setError(e.message ?? e)
      }
    }
  }

  const onConfirmUser = async () => {
    if (!confirmationCode) {
      showError(t('enter_the_code'))
      setConfirmationCodeError(true)
      return
    }

    if (confirmationCode.length != 6) {
      showError(t('code_should_be_six_digits_length'))
      setConfirmationCodeError(true)
      return
    }

    try {
      await sendCode({
        email,
        password,
        code: confirmationCode
      })
      const user = await login({email, password})
      await refreshUser(user)
      setRegisterSuccess(true)
    } catch (e: any) {
      console.log(`onConfirmUser - ${e}`)
      showError(e.message)
      setConfirmationCodeError(true)
    }

  }

  const onResendCode = async () => {
    // todo check timer

    try {
      await resendCode({email})
      showSuccess(t('code_has_been_sent'))
    } catch (e: any) {
      console.log(`onConfirmUser - ${e}`)
      showError(e.message)
    }

  }

  const handleEnterPress = async () => {
    if (showRegister) {
      await onRegister()
    } else {
      await onLogin()
    }
  }

  const content = () => {

    if (settingsLoading) {
      return <div className={classes.LoginViewWrapper}>
        <div className={classes.SpinnerContainerBig}>
          <Spinner/>
        </div>
      </div>
    }

    if (registerSuccess) {
      return <div className={isMobile ? '' : classes.LoginViewWrapper}>
        <div className={isMobile ? classes.LoginViewMobile : classes.LoginView}>
          <div className={classes.Content}>
            <div className={classes.RegisterSuccessContainer}>
              <CheckIcon/>
              <div className={classnames(classes.RegisterSuccessTitle, 'mt-5')}>
                {t('register_success_message')}
              </div>
              <Spacer height={10}/>
              <Link to={`/${params.get('redirect')}`.replace('//', '/')}>
                <AppButton
                  className={classnames(classes.LoginButton, 'mt-4')}
                  title={t('go_to_the_app')}
                  icon={faDoorOpen}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    }

    if (showConfirm) {
      return <div className={isMobile ? '' : classes.LoginViewWrapper}>
        <div className={isMobile ? classes.LoginViewMobile : classes.LoginView}>
          <div className={classes.Content}>
            <Spacer height={100}/>

            <FontAwesomeIcon icon={faEnvelope} size={'2x'} className={'mb-3'}/>

            <div className={classes.Title}>
                {userIsNotActive ? t('this_account_is_not_active_yet') : t('please_confirm_the_email')}
            </div>

            <div className={classnames(classes.Subtitle, 'mb-4')}>
              {userIsNotActive ? `${t('please_confirm_the_email')}, ${t('enter_the_code_we_sent_to_email', {email: email})}` : t('enter_the_code_we_sent_to_email', {email: email})}
            </div>

            <AppInput
              placeholder={t('confirmation_code')}
              onChange={setConfirmationCode}
              error={confirmationCodeError}
              maxLength={6}/>

            <AppButton
              title={t('confirm')}
              className={classnames(classes.LoginButton, 'mt-4')}
              onClick={onConfirmUser}
              progress={sendingCode}/>

            <AppButton
              transparent
              title={t('resend_code')}
              className={classnames(classes.LoginButton, 'mt-2')}
              onClick={onResendCode}
              progress={resendingCode}/>
          </div>
        </div>
      </div>
    }

    if (settings?.maintenance) {
      return <Navigate to='/maintenance'/>
    }

    return <div className={isMobile ? '' : classes.LoginViewWrapper}>
      <div className={isMobile ? classes.LoginViewMobile : classes.LoginView}>
        <div className={classes.Content}>

          <Spacer height={isDesktop ? 100 : 60}/>

          <div className={classes.Logo} >
            <img src={Logo} className={classes.Logo}/>
          </div>

          <Spacer height={isDesktop ? 80 : 50}/>

          {isSupportedBrowser && <div className={classes.SocialButtonsContainer}>

            {/*<SocialButton*/}
            {/*  type={'facebook'}*/}
            {/*  title={showRegister ? t('sign_up_with') : t('login_with')}*/}
            {/*  onClick={onFacebookLoginClick}*/}
            {/*  progress={storageFacebookInProgress}*/}
            {/*/>*/}

            {/*<Spacer width={10}/>*/}

            <SocialButton
              type={'google'}
              title={showRegister ? t('sign_up_with') : t('login_with')}
              onClick={onGoogleLoginClick}
              progress={storageGoogleInProgress}
            />

          </div>}

          {isSupportedBrowser && !error && <div className={classes.DividerContainer}>
            <Spacer width={10}/>
            <div className={classes.Divider}/>
            <div className={classnames(classes.DividerText)}>
              {t('or')}
            </div>
            <div className={classes.Divider}/>
            <Spacer width={10}/>
          </div>}

          {!!error && <div className={classnames(classes.Error, 'my-4')}>
            {error}
          </div>}

          <div className={classes.InputsContainer}>

            <AppInput
              type={InputType.email}
              placeholder={t('your_email')}
              error={emailError}
              onChange={setEmail}
              onEnterPress={handleEnterPress}/>

            <Spacer height={10}/>

            <AppInput
              type={InputType.password}
              placeholder={t('password')}
              error={passwordError}
              onChange={setPassword}
              onEnterPress={handleEnterPress}
            />

            <Spacer height={10}/>

            {showRegister && <AppInput
              type={InputType.password}
              placeholder={t('confirm_password')}
              error={confirmPasswordError}
              onChange={setConfirmPassword}
              onEnterPress={handleEnterPress}/>}

            {!showRegister && <Checkbox
              title={t('remember_me')}
              checked={rememberMe}
              onChange={switchRememberMe}
            />}

          </div>

          <AppButton
            title={showRegister ? t('sign_up') : t('login')}
            onClick={showRegister ? onRegister : onLogin}
            className={classnames(classes.LoginButton)}
            progress={loginInProgress || registerInProgress}/>

          <AppButton
            transparent
            title={showRegister ? `${t('already_have_an_account')} ${t('login')}` : `${t('dont_have_an_account')} ${t('sign_up')}`}
            onClick={switchMode}
            className={classnames(classes.LoginButton, 'mt-4')}/>

          <Link to={'/reset-password'} style={{margin: '0 auto'}}>
            <AppButton
              transparent
              title={t('forgot_password')}
              className={classnames(classes.LoginButton)}/>
          </Link>

        </div>
      </div>
      <Footer/>
    </div>
  }

  const callbackContent = () => {
    if (error) {
      return <div className={classes.LoginView}>
        <div className={classes.ErrorContainer}>
          <FontAwesomeIcon icon={faWarning} className={classes.Icon}/>
          <Spacer height={40}/>
          {error.split('\n').map(e => <div>{e}</div>)}
          <Spacer height={40}/>
          <AppButton title={t('ok')} onClick={() => Auth.signOut()}/>
        </div>
      </div>
    }

    if (storageGoogleInProgress) {
      return <div className={classes.LoginView}>
        <div className={classes.SpinnerContainer}>
          <Spinner/>
          <Spacer height={10}/>
          <div>{t('logging_in_with_google')}</div>
        </div>
      </div>
    }

    if (storageFacebookInProgress) {
      return <div className={classes.LoginView}>
        <div className={classes.SpinnerContainer}>
          <Spinner/>
          <Spacer height={10}/>
          <div>{t('logging_in_with_facebook')}</div>
        </div>
      </div>
    }

    return <PlaceholderView title={t('page_not_found')}/>

  }

  const onGoogleLoginClick = async () => {
    try {
      setLoginWithGoogleInProgress(true)
      await loginWithGoogle({
        redirect: params.get('redirect'),
      })
    } catch (e: any) {
      setError(e.message)
    }
  }

  const onFacebookLoginClick = async () => {
    try {
      setLoginWithFacebookInProgress(true)
      await loginWithFacebook({
        redirect: params.get('redirect'),
      })
    } catch (e: any) {
      setError(e.message)
    }
  }

  const onNotInvitedModalClose = async () => {
    if (deleteAccountOnNotInvitedModalClose) {
      // this is ugly, but necessary
      // the thing is: If the user is not invited, I should delete auth user
      // I can't do it from the api class, because it makes the app refresh
      // calling Auth.deleteUser() make the app refresh, so all the callbacks
      // are gone and I can not show the modal in the right way
      // so the solution is to return error first, show the modal and delete the user
      // on modal close.
      // Please fix if you can do it better
      await deleteAuthUser()
      setDeleteAccountOnNotInvitedModalClose(false)
    }
    setShowNotInvitedModal(false)
  }

  return <div>

    <Routes>
      <Route path='/callback/*' element={callbackContent()}/>
      <Route path='/' element={content()}/>
    </Routes>

    {showNotInvitedModal && <NotInvitedModal
      show={showNotInvitedModal}
      onClose={onNotInvitedModalClose}/>}

  </div>
}

interface NotInvitedModalProps {
  onClose: VoidFunction
  show: boolean
}

const NotInvitedModal: React.FC<NotInvitedModalProps> = ({show, onClose}) => {
  const{t} = useTranslation()

  return <Modal
    title={t('you_are_not_in_the_invite_list')}
    opened={show}
    onClose={onClose}>

    <div>
      <p>{t('invite_error_explanation')}</p>
      <p className={'mt-4'}>{'we_are_waiting_for_you'}</p>
    </div>

    <AppButton
      className={'mt-5 w-100'}
      title={t('ok')}
      onClick={onClose}/>

  </Modal>
}
