import React from 'react'
import classes from './BottomNavigationBar.module.scss'
import { ReactComponent as Star } from "../../images/star.svg";
import { ReactComponent as StarActive } from "../../images/star_active.svg";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Wristband } from "../../images/wristband.svg";
import { ReactComponent as WristbandActive } from "../../images/wristband_active.svg";
import { ReactComponent as Account } from "../../images/account.svg";
import { ReactComponent as AccountActive } from "../../images/account_active.svg";
import classnames from "classnames";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

export function BottomNavigationBar() {
  const {pathname} = useLocation()
  const {t} = useTranslation()

  return <div className={classes.BottomNavigationBar}>

    <NavigationItem
      title={t('events')}
      icon={Star}
      iconActive={StarActive}
      selected={pathname == '/' || pathname.startsWith('/event')}
      linkTo={'/'}/>

    <NavigationItem
      title={t('wristbands')}
      icon={Wristband}
      iconActive={WristbandActive}
      selected={pathname.startsWith('/wristbands')}
      linkTo={'/wristbands'}/>

    <NavigationItem
      title={t('support')}
      faIcon={faComment}
      selected={pathname.startsWith('/support')}
      linkTo={'/support'}/>

    <NavigationItem
      title={t('account')}
      icon={Account}
      iconActive={AccountActive}
      selected={pathname.startsWith('/account')}
      linkTo={'/account'}/>

  </div>
}

interface NavigationItemProps {
  title: string
  selected: boolean
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  iconActive?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  linkTo: string
  onClick?: () => void
  faIcon?: IconDefinition
}

const NavigationItem: React.FC<NavigationItemProps> = (props) => {

  const {
    title,
    selected,
    icon,
    iconActive,
    linkTo,
    onClick,
    faIcon
  } = props
  const Icon = selected ? iconActive : icon

  return !!onClick
    ? <div
      onClick={onClick}
      className={classnames(
        classes.NavigationItem,
        {[classes.Selected]: selected}
      )}>

      {faIcon && <FontAwesomeIcon
        icon={faIcon}
        className={selected ? classes.NavigationItemIconSelected : classes.NavigationItemIcon}
        style={{opacity: selected ? 1 : 0.25}}/>}

      {Icon && <Icon
        className={classes.NavigationItemIcon}
        style={{opacity: selected ? 1 : 0.25}}/>}

      <div>{title}</div>
    </div>
    : <Link
      to={linkTo}
      className={classnames(
        classes.NavigationItem,
        {[classes.Selected]: selected}
      )}>

      {faIcon && <FontAwesomeIcon
        icon={faIcon}
        className={selected ? classes.NavigationItemIconSelected : classes.NavigationItemIcon}
        style={{opacity: selected ? 1 : 0.25}}/>}

      {Icon && <Icon
        className={classes.NavigationItemIcon}
        style={{opacity: selected ? 1 : 0.25}}/>}

      <div>{title}</div>
    </Link>

}
