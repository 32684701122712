import {getCharity} from "../services/api/api";
import useSWR from "swr";

export const useCharity = (charityId: string) => {
  const {data, error, isLoading} = useSWR(['getCharity', charityId], getCharityFetcher)
  return {
    charity: data,
    charityLoading: isLoading,
    charityError: error,
  }
}

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const getCharityFetcher = async (params: any) => {
  const [,charityId] = params
  return await getCharity(charityId)
}
