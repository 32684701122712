import React, {useEffect, useMemo, useState} from 'react'
import classes from './RegistrationView.module.scss'
import StepIndicator from "../common/StepIndicator"
import PersonInfoPage, {PersonInfo} from "./PersonInfoPage"
import PaymentMethodInfoPage from "./PaymentMethodInfoPage"
import PinCodePage from "./PinCodePage"
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom"
import useEvent from "../../hooks/useEvent"
import Spinner from "../common/Spinner"
import {Wristband, Event, Card} from "../../services/api/models"
import DonePage from "./DonePage"
import {useRegisterWristband} from "../../hooks/useWristband"
import {useWristbands, refreshWristbands} from "../../hooks/useWristbands"
import {useSWRConfig} from "swr"
import {ReactComponent as ErrorIcon} from "../../images/error_big_very.svg"
import {BillfoldError} from "../../services/api/errors"
import {AppButton, ModalProps, Spacer} from "../common"
import {ReactComponent as CloseIcon} from "../../images/close_new.svg"
import {ReactComponent as ArrowLeftIcon} from "../../images/arrow_left_new.svg"
import {faCommentDots} from "@fortawesome/free-regular-svg-icons"
import useUser, {useUpdateUser} from "../../hooks/useUser"
import CheckWristbandPage from "./CheckWristbandPage"
import {formatDate} from "../../util/date_util"
import {qrRegistrationOnly, wristbandRegistrationOnly} from "../../util/event_util"
import WristbandNumberPage from "./WristbandNumberPage"
import {DonePageModal} from "./DonePageModal"
import {ConfirmDetails, ConfirmDetailsPage} from "./ConfirmDetailsPage"
import {isDesktop, isMobile} from "react-device-detect"
import {showError} from "../../util/toast_util"
import {isValidPhoneNumber} from "libphonenumber-js"
import {useTranslation} from "react-i18next"
import {OptInResult} from "../../services/api/api"
import {useOptIns} from "../../hooks/useOptIns"
import {OptInsModal} from "./OptInsModal"
import {isEmpty} from "lodash"
import {getPaymentMethodId} from "../../util/card_util";
import {QrRegistrationInfoModal} from "./QrRegistrationInfoModal";
import {getNotShowQrRegistrationInfoAgain} from "../../services/storage";
import CharityPage from "./CharityPage";

enum CheckInStep {wristbandNumber, personInfo, paymentInfo, charity, pin, confirm}

const wristbandSteps = [
  CheckInStep.wristbandNumber,
  CheckInStep.personInfo,
  CheckInStep.paymentInfo,
  CheckInStep.pin,
  CheckInStep.confirm
]

const wristbandStepsCharity = [
  CheckInStep.wristbandNumber,
  CheckInStep.personInfo,
  CheckInStep.paymentInfo,
  CheckInStep.charity,
  CheckInStep.pin,
  CheckInStep.confirm
]

const qrSteps = [
  CheckInStep.personInfo,
  CheckInStep.paymentInfo,
  CheckInStep.pin,
  CheckInStep.confirm
]

const qrStepsCharity = [
  CheckInStep.personInfo,
  CheckInStep.paymentInfo,
  CheckInStep.charity,
  CheckInStep.pin,
  CheckInStep.confirm
]

const noPinWristbandSteps = [
  CheckInStep.wristbandNumber,
  CheckInStep.personInfo,
  CheckInStep.paymentInfo,
  CheckInStep.confirm
]

const noPinWristbandStepsCharity = [
  CheckInStep.wristbandNumber,
  CheckInStep.personInfo,
  CheckInStep.paymentInfo,
  CheckInStep.charity,
  CheckInStep.confirm
]

export default function RegistrationView() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasWristband, setHasWristband] = useState<boolean>()
  const [step, setStep] = useState<CheckInStep>(CheckInStep.personInfo)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {mutate} = useSWRConfig()
  const {eventId} = useParams() as any
  const {event} = useEvent(eventId)
  const {pinForPurchases} = event ?? {}
  const {wristbands} = useWristbands()
  const {user} = useUser()
  const {updateUser} = useUpdateUser()
  const {registerWristband, registering} = useRegisterWristband()
  const {optIns} = useOptIns(eventId)
  const [wristband, setWristband] = useState<Wristband>()
  const [error, setError] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState<Date>()
  const [optInsResults, setOptInsResults] = useState<OptInResult[]>([])
  const [showDonePageModal, setShowDonePageModal] = useState(false)
  const [pinCode, setPinCode] = useState<string>()
  const [usePinForPurchases, setUsePinForPurchases] = useState(false)
  const [cardId, setCardId] = useState<string>()
  // user can change the event during the registration process
  // if this happens we save the new event here to redirect user there after finish
  const [actualRegisterEvent, setActualRegisterEvent] = useState<Event>()
  const [registeredWristband, setRegisteredWristband] = useState<Wristband>()
  const [success, setSuccess] = useState(false)
  const [showOptInsModal, setShowOptInsModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)

  useEffect(() => {

    if (wristbandRegistrationOnly(event)) {
      setSearchParams({hasWristband: 'true'})
      return
    }

    if (qrRegistrationOnly(event)) {
      setSearchParams({hasWristband: 'false'})
      return
    }

  }, [event])

  useEffect(() => {

    if (!searchParams) {
      return
    }

    if (searchParams.has('wristbandNumber')) {
      setWristband({
        ...wristband,
        wristbandNumber: searchParams.get('wristbandNumber') ?? ''
      })
      setSearchParams({hasWristband: 'true'})
    }

    if (searchParams.has('hasWristband')) {
      const hasWristband = searchParams.get('hasWristband') == 'true'
      setHasWristband(hasWristband)
      setStep(hasWristband ? CheckInStep.wristbandNumber : CheckInStep.personInfo)
    }

  }, [searchParams])

  const previousStep = (): CheckInStep => {
    return step == steps[0] ? step : steps[steps.indexOf(step) - 1];
  }

  const nextStep = (): CheckInStep => {
    return step == steps[steps.length - 1] ? step : steps[steps.indexOf(step) + 1];
  }

  const onWristbandNumberDone = (wristbandNumber: string) => {
    setWristband({
      ...wristband,
      wristbandNumber: wristbandNumber,
    })
    setStep(nextStep())
  }

  const onInfoDone = (info: PersonInfo) => {
    setWristband({
      ...wristband,
      wristbandOwnerName: [info.firstName, info.lastName].join(' '),
      wristbandOwnerFirstName: info.firstName,
      wristbandOwnerLastName: info.lastName,
      wristbandOwnerEmail: info.email,
      wristbandOwnerPhoneNumber: info.phoneNumber?.replace(' ', ''),
      newsSubscription: info.newsSubscription,
    })

    setDateOfBirth(info.dateOfBirth)

    if (isEmpty(optIns) || optIns?.every(o => !o.visible)) {
      setStep(nextStep())
    } else {
      setShowOptInsModal(true)
    }

  }

  const onOptInsDone = (optInsResults: OptInResult[]) => {
    setShowOptInsModal(false)
    setOptInsResults(optInsResults ?? [])
    setStep(nextStep())
  }

  const onPaymentInfoDone = async (card: Card) => {
    setCardId(card.id)
    setWristband({
      ...wristband,
      paymentMethodID: getPaymentMethodId(card, event?.platformAccountBillfoldId, event?.paymentAccountType)
    })
    setStep(nextStep())
  }

  const onPinConfirmed = async (pinCode: string, usePinForPurchases: boolean) => {
    setPinCode(pinCode)
    setUsePinForPurchases(usePinForPurchases)
    setStep(nextStep())
  }

  const onDetailsConfirmed = async (details: ConfirmDetails) => {

    let purchasesWithoutPin = !details.usePinForPurchases
    // if we have no wristband - then we will set the purchasesWithoutPin in a special way
    if (!hasWristband && pinForPurchases == 'optional') {
      purchasesWithoutPin = !details.usePinForPurchases
    }
    setActualRegisterEvent(details.event)
    await startRegistration(
      {
        ...details.wristband,
        pinCode: details.pinCode,
        purchasesWithoutPin: purchasesWithoutPin
      },
      details.event,
    )
  }

  const onContinueWithoutPin = async () => {
    setPinCode('0000')
    setUsePinForPurchases(false)
    setStep(nextStep())
  }

  const onCharityPageDone = (isAgree: boolean) => {
    setWristband({
      ...wristband,
      donor: isAgree
    })
    setStep(nextStep())
  }

  const startRegistration = async (wristband: Wristband, event?: Event) => {
    try {

      if (!user) {
        showError(t('user_not_found'))
        return
      }

      const {phoneNumber} = user
      if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
        showError(t('account_has_wrong_phone_error'))
        return
      }

      // save the birthdate to the user account if it set
      if (dateOfBirth) {
        await updateUser({
          ...user,
          dateOfBirth: formatDate(dateOfBirth)
        })
      }

      if (!event) {
        showError('Event not found')
        return
      }

      if (!cardId) {
        showError('Card not found')
        return
      }

      const registeredWristband = await registerWristband({
        event,
        wristband,
        cardId: cardId,
        optIns: optInsResults
      })
      if (registeredWristband) {
        refreshWristbands([...wristbands ?? [], registeredWristband])
        setRegisteredWristband(registeredWristband)
      }
      if (isMobile) {
        setShowDonePageModal(true)
      } else {
        setSuccess(true)
      }
    } catch (e: any) {
      console.log(`startRegistration - error: `, e)
      if (e.toString().toLowerCase().startsWith('validation error')) {
        setError('Validation error')
      } else {
        setError(e.toString())
      }
    }
  }

  const errorText = useMemo(() => {
    switch (error) {
      case BillfoldError.CUSTOMER_ID_IS_BLANK:
        return t('customer_id_is_blank')
      case BillfoldError.CUSTOMER_NOT_FOUND:
        return t('customer_not_found')
      case BillfoldError.EMAIL_IS_BLANK:
        return t('email_is_blank')
      case BillfoldError.EVENT_ID_IS_BLANK:
        return t('event_id_is_blank')
      case BillfoldError.EVENT_NOT_FOUND:
        return t('event_not_found')
      case BillfoldError.INVALID_DATE_OF_BIRTH_FORMAT:
        return t('invalid_date_of_birth_format')
      case BillfoldError.INVALID_EMAIL_FORMAT:
        return t('invalid_email_format')
      case BillfoldError.INVALID_GENDER:
        return t('invalid_gender')
      case BillfoldError.INVALID_PHONE_NUMBER:
        return t('invalid_phone_number')
      case BillfoldError.INVALID_PIN:
        return t('invalid_pin')
      case BillfoldError.NEWS_SUBSCRIPTION_MUST_BE_FALSE_OR_NULL:
        return t('news_subscription_must_be_false_or_null')
      case BillfoldError.PAYMENT_GATEWAY_ERROR:
        return t('payment_gateway_error')
      case BillfoldError.PAYMENT_METHOD_ID_IS_BLANK:
        return t('payment_method_id_is_blank')
      case BillfoldError.PAYMENT_METHOD_NOT_FOUND:
        return t('payment_method_not_found')
      case BillfoldError.SUBJECT_IS_BLANK:
        return t('subject_is_blank')
      case BillfoldError.SUBJECT_IS_TAKEN:
        return t('subject_is_taken')
      case BillfoldError.VAULT_IS_INVALID:
        return t('vault_is_invalid')
      case BillfoldError.WRISTBAND_IS_BLOCKED:
        return t('wristband_is_blocked')
      case BillfoldError.WRISTBAND_NUMBER_TAKEN:
      case BillfoldError.WRISTBAND_NUMBER_ALREADY_TAKEN:
        return t('wristband_number_is_taken')
      default:
        return error
    }
  }, [error])

  const isSystemProblem = useMemo((): boolean => {
    // All problems are system except these three
    switch (error) {
      case BillfoldError.INVALID_EMAIL_FORMAT:
      case BillfoldError.INVALID_PHONE_NUMBER:
      case BillfoldError.WRISTBAND_NUMBER_TAKEN:
      case BillfoldError.WRISTBAND_NUMBER_ALREADY_TAKEN:
        return false
      default:
        return true
    }
  }, [error])

  const fixProblemButtonTitle = useMemo((): string => {
    // All problems are system except these three
    switch (error) {
      case BillfoldError.INVALID_EMAIL_FORMAT:
        return t('fix_the_email')
      case BillfoldError.INVALID_PHONE_NUMBER:
        return t('fix_the_phone_format')
      case BillfoldError.WRISTBAND_NUMBER_TAKEN:
      case BillfoldError.WRISTBAND_NUMBER_ALREADY_TAKEN:
        return t('change_the_wristband_number')
      default:
        return t('fix_the_problem')
    }
  }, [error])

  const steps = useMemo(() => {

    // for the QR registration
    // we will ALWAYS ask for the PIN
    // https://billfoldpos.youtrack.cloud/issue/DEV-1079
    if (!hasWristband) {
      return event?.charityEnabled ? qrStepsCharity : qrSteps
    }

    switch (pinForPurchases) {
      case'turned_off':
        return event?.charityEnabled ? noPinWristbandStepsCharity : noPinWristbandSteps;
      case'mandatory':
      case'optional':
      default:
        return event?.charityEnabled ? wristbandStepsCharity : wristbandSteps;
    }

  }, [pinForPurchases, hasWristband, event])

  const onFixProblemClick = () => {
    switch (error) {
      case BillfoldError.INVALID_EMAIL_FORMAT:
      case BillfoldError.INVALID_PHONE_NUMBER:
      case BillfoldError.WRISTBAND_NUMBER_TAKEN:
      case BillfoldError.WRISTBAND_NUMBER_ALREADY_TAKEN:
        setError('')
        setStep(CheckInStep.personInfo)
        break
      default:
        // do nothing
        break
    }
  }

  const onAddOneMoreGuestClick = () => {
    setWristband(undefined)

    if (wristbandRegistrationOnly(actualRegisterEvent)) {
      setSearchParams({hasWristband: 'true'})
      setHasWristband(true)
    } else if (qrRegistrationOnly(actualRegisterEvent)) {
      setSearchParams({hasWristband: 'false'})
      setHasWristband(false)
    } else {
      setHasWristband(undefined)
      navigate(`/event/${actualRegisterEvent?.id}/checkin`)
    }

    setSuccess(false)
    setShowDonePageModal(false)
    setError('')
    setStep(steps[0])
  }

  const content = () => {

    if (error) {
      return <div className={classes.ErrorContainer}>
        <ErrorIcon style={{width: 70, height: 70}}/>
        <Spacer height={16}/>
        <div className={'text-center'}>{errorText}</div>

        {isSystemProblem && <div className={'mt-3'}>
          Please contact support
        </div>}

        {!isSystemProblem && <div className={classes.FixButtonsContainer}>
          <AppButton
            title={fixProblemButtonTitle}
            onClick={onFixProblemClick}
          />
          <Spacer height={10}/>
          <Link to={'/support'}>
            <AppButton
              transparent
              icon={faCommentDots}
              title={t('or_contact_support')}
            />
          </Link>
        </div>}
      </div>
    }

    if (!event || registering) {
      return <div className={classes.SpinnerContainer}>
        <Spinner/>
      </div>
    }

    if (hasWristband == undefined) {
      return <CheckWristbandPage
        onHasNoWristbandClick={() => setSearchParams({hasWristband: 'false'})}
        onHasWristbandClick={() => setSearchParams({hasWristband: 'true'})}/>
    }

    if (isDesktop && success) {
      return <DonePage
        event={event}
        wristband={registeredWristband ?? {}}
        onAddWristbandClick={onAddOneMoreGuestClick}
        onDonePressed={() => {
          if (registeredWristband?.registrationType == 'wristband' || getNotShowQrRegistrationInfoAgain()) {
            navigate(`/event/${actualRegisterEvent?.id}`)
          } else {
            setShowInfoModal(true)
          }
        }
        }/>
    }

    switch (step) {
      case CheckInStep.wristbandNumber:
        return <WristbandNumberPage
          preFilledWristband={wristband}
          onContinue={onWristbandNumberDone}/>
      case CheckInStep.personInfo:
        return <PersonInfoPage
          preFilledWristband={wristband}
          onContinue={onInfoDone}/>
      case CheckInStep.paymentInfo:
        return <PaymentMethodInfoPage
          onContinue={onPaymentInfoDone}/>
      case CheckInStep.charity:
        return <CharityPage onContinue={onCharityPageDone}/>
      case CheckInStep.pin:
        return <PinCodePage
          onCodeConfirmed={onPinConfirmed}
          // if QR reg or PIN is mandatory - then hide skip button
          onContinueWithoutPin={(!hasWristband || event.pinForPurchases == 'mandatory') ? undefined : onContinueWithoutPin}
          showUsePinForPurchases={!hasWristband && event.pinForPurchases == 'optional'}/>
      case CheckInStep.confirm:
        return <ConfirmDetailsPage
          event={event}
          wristband={wristband}
          onConfirm={onDetailsConfirmed}
          pinCode={pinCode}
          usePinForPurchases={usePinForPurchases}/>
    }
  }

  const onStepClick = (stepIndex: number) => {
    setStep(steps[Math.min(steps.indexOf(step), stepIndex)])
  }

  const onCloseClick = () => {
    navigate(searchParams.has('hasWristband') ? -2 : -1)
  }

  const onBackClick = () => {
    setError('')
    // setRegisterSuccess(false)
    setStep(previousStep())
  }

  return <div className={classes.RegistrationView}>
    {event && !showDonePageModal && !success && <div>
      <div className={classes.TitleContainer}>
        <ArrowLeftIcon
          onClick={onBackClick}
          className={classes.Icon}
          style={{opacity: step == steps[0] ? 0 : 1}}/>

        <div className={classes.Title}>{event?.name} checkin</div>

        <CloseIcon
          onClick={onCloseClick}
          className={classes.IconClose}/>
      </div>
      {hasWristband != undefined && <div className={'mt-4'}>
        <StepIndicator
          stepsCount={steps.length}
          currentStep={steps.indexOf(step)}
          onStepClick={onStepClick}/>
      </div>}
    </div>}

    {content()}

    {isMobile && event && registeredWristband && <DonePageModal
      event={event}
      opened={showDonePageModal}
      wristband={registeredWristband ?? {}}
      onClose={() => setShowDonePageModal(false)}
      onFinishClick={() => {
        if (registeredWristband?.registrationType == 'wristband' || getNotShowQrRegistrationInfoAgain()) {
          navigate(`/event/${actualRegisterEvent?.id}`)
        } else {
          setShowInfoModal(true)
        }
      }}
      onAddWristbandClick={onAddOneMoreGuestClick}/>}

    <OptInsModal
      opened={showOptInsModal}
      optIns={optIns ?? []}
      onOptInsConfirmed={onOptInsDone}
      onClose={() => setShowOptInsModal(false)}
    />

    <QrRegistrationInfoModal
      actualRegisterEventId={actualRegisterEvent?.id ?? ''}
      opened={showInfoModal}
      onClose={() => setShowInfoModal(false)}/>

  </div>
}
