import React, {useEffect, useMemo, useState} from 'react'
import classes from './ConfirmDetailsPage.module.scss'
import {Wristband, Event, Card} from "../../services/api/models"
import {AppButton, AppInput, Spacer} from "../common"
import {faPen} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import useCards from "../../hooks/useCards"
import {isMobile} from "react-device-detect"
import {ChangePinCodeModal} from "./ChangePinCodeModal"
import PaymentMethodsModal from "../common/PaymentMethodsModal"
import {SelectEventModal} from "../checkin/SelectEventModal"
import {WristbandNumberModal} from "./WristbandNumberModal"
import {showError, showSuccess} from "../../util/toast_util"
import {DateTime} from "luxon"
import {useTranslation} from "react-i18next"
import {parse} from "../../util/object_util"
import {getPaymentMethodId} from "../../util/card_util";
import {SwitchView} from "../common/SwitchView";

export interface ConfirmDetails {
  event: Event
  wristband: Wristband
  pinCode?: string
  usePinForPurchases: boolean
}

interface ConFirmDetailsPageProps {
  event: Event
  wristband?: Wristband
  onConfirm: (details: ConfirmDetails) => void
  pinCode?: string
  usePinForPurchases: boolean
}

export function ConfirmDetailsPage(props: ConFirmDetailsPageProps) {

  const {onConfirm} = props
  const [usePinCode, setUsePinCode] = useState(false)
  const [pinCode, setPinCode] = useState<string>()
  const [usePinForPurchases, setUsePinForPurchases] = useState(false)
  const [showChangePinCodeModal, setShowChangePinCodeModal] = useState(false)
  const [showChooseCardModal, setShowChooseCardModal] = useState(false)
  const [wristband, setWristband] = useState<Wristband>()
  const [event, setEvent] = useState(props.event)
  const [showSelectEventModal, setShowSelectEventModal] = useState(false)
  const [showWristbandNumberModal, setShowWristbandNumberModal] = useState(false)
  const {wristbandNumber, paymentMethodID, stripePaymentMethodID} = wristband ?? {}
  const {name, pinForPurchases} = event ?? {}
  const {t} = useTranslation()
  const {cards} = useCards()

  useEffect(() => {
    setPinCode(props.pinCode)
    setUsePinCode(props.usePinForPurchases)
    setUsePinForPurchases(props.usePinForPurchases)
    setWristband(props.wristband)
    setEvent(props.event)
  }, [])

  // useEffect(() => {
  //   if (!usePinCode) {
  //     setWristband({
  //       ...wristband,
  //       pinCode: '0000',
  //       purchasesWithoutPin: true
  //     })
  //   }
  // }, [usePinCode])

  const card = useMemo<Card | undefined>(() => {

    if (!cards && !wristband) {
      return undefined
    }

    return cards?.find((card, i) => {
      const paymentIds = parse(card.paymentMethodIds ?? '{}')
      const accountId = event.platformAccountBillfoldId ?? ''
      const accountType = event.paymentAccountType
      const methodId = paymentIds[accountType == 'stripe' ? accountId : `custom_${accountId}`]
      return !!methodId && (methodId == paymentMethodID || methodId == stripePaymentMethodID)
    })

  }, [cards, wristband])

  const onEditEventClick = () => {
    // setShowSelectEventModal(true)
  }

  const onEditWristbandNumberClick = () => {
    setShowWristbandNumberModal(true)
  }

  const onEditCardClick = () => {
    setShowChooseCardModal(true)
  }

  const onUsePinCodeClick = () => {
    if (pinForPurchases == 'turned_off') {
      showError(t('pin_code_disabled_for_this_event'))
      return
    }
    setUsePinCode(!usePinCode)
    if (!usePinCode) {
      setShowChangePinCodeModal(true)
    } else {
      setPinCode(undefined)
      setWristband({
        ...wristband,
        pinCode: '0000',
        purchasesWithoutPin: true
      })
    }
  }

  const onUsePinForPurchasesClick = () => {
    setUsePinForPurchases(!usePinForPurchases)
  }

  const onEditPinCodeClick = () => {
    setShowChangePinCodeModal(true)
  }

  const onPinCodeConfirm = (code: string) => {
    setPinCode(code)
    setUsePinCode(true)
    showSuccess(t('pin_code_changed'))

    setWristband({
      ...wristband,
      pinCode: code,
      purchasesWithoutPin: false
    })

  }

  const onCardSelect = (card: Card) => {
    setWristband({
      ...wristband,
      paymentMethodID: getPaymentMethodId(card, event.platformAccountBillfoldId, event.paymentAccountType)
    })
  }

  const onWristbandNumberChanged = (wristbandNumber: string) => {
    setWristband({
      ...wristband,
      wristbandNumber: wristbandNumber
    })
  }

  const onConfirmClick = () => {
    onConfirm({
      event: event,
      wristband: wristband ?? {id: ''},
      // if this is the wristband number we should set pin code to 0000 when not using pin code
      // if this is the qr registration we should have pin code ALWAYS (because it's need to self checkin)
      pinCode: !!wristbandNumber ? (usePinCode ? pinCode : '0000') : pinCode,
      usePinForPurchases: !!wristbandNumber ? usePinCode : usePinForPurchases
    })
  }

  return <div className={classes.ConfirmDetailsPage}>

    <div className={classes.Title}>
      <h2>{t('confirm_details')}</h2>
    </div>

    <AppInput
      placeholder={t('event')}
      initialValue={name}
      disabled/>

    <Spacer height={10}/>

    {wristbandNumber && <AppInput
      placeholder={t('wristband_number')}
      initialValue={wristbandNumber}
      disabled
      uppercase
      trailing={<div className={classes.EditButton} onClick={onEditWristbandNumberClick}>
        <FontAwesomeIcon icon={faPen} className={classes.EditIcon}/>
      </div>}/>}

    {wristbandNumber && <Spacer height={10}/>}

    <AppInput
      placeholder={t('bank_card')}
      initialValue={`**** ${card?.last4}`}
      disabled
      trailing={<div className={classes.EditButton} onClick={onEditCardClick}>
        <FontAwesomeIcon icon={faPen} className={classes.EditIcon}/>
      </div>}/>

    {usePinCode && <Spacer height={10}/>}

    {usePinCode && <AppInput
      placeholder={t('pin_code')}
      initialValue={`****`}
      disabled
      trailing={<div className={classes.EditButton} onClick={onEditPinCodeClick}>
        <FontAwesomeIcon icon={faPen} className={classes.EditIcon}/>
      </div>}/>}

    <Spacer height={10}/>

    {!wristbandNumber && <SwitchView
      title={t('use_this_pin_code_for_purchases')}
      onClick={onUsePinForPurchasesClick}
      checked={usePinForPurchases}/>}

    {wristbandNumber && event.pinForPurchases != 'turned_off' && <SwitchView
      title={t('use_pin_code')}
      onClick={onUsePinCodeClick}
      checked={usePinCode}/>}

    <AppButton
      className={isMobile ? classes.ContinueButtonMobile : classes.ContinueButton}
      title={t('continue')}
      onClick={onConfirmClick}/>

    <ChangePinCodeModal
      onPinCodeConfirm={(code) => {
        onPinCodeConfirm(code)
        setShowChangePinCodeModal(false)
      }}
      opened={showChangePinCodeModal}
      onClose={() => {
        setShowChangePinCodeModal(false)
        setUsePinCode(false)
      }}/>

    <PaymentMethodsModal
      event={event}
      onClose={() => setShowChooseCardModal(false)}
      opened={showChooseCardModal}
      selectedCard={card}
      onChange={onCardSelect}/>

    <SelectEventModal
      opened={showSelectEventModal}
      onClose={() => setShowSelectEventModal(false)}
      onSelected={setEvent}/>

    <WristbandNumberModal
      key={DateTime.now().toString()}
      opened={showWristbandNumberModal}
      onClose={() => setShowWristbandNumberModal(false)}
      onNumberChanged={onWristbandNumberChanged}
      wristbandNumber={wristbandNumber ?? ''}/>

  </div>
}


