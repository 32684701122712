import React, {useEffect, useMemo, useState} from 'react'
import classes from './BatchesView.module.scss'
import {Organizer, WristbandBatch} from "../../services/api/models"
import {AppButton, AppInput, InputType, ModalProps, Spacer} from "../common"
import {faFaceSadCry, faTrashAlt} from "@fortawesome/free-regular-svg-icons"
import {Col, Row} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {isDesktop, isMobile} from "react-device-detect"
import {ViewHeader} from "../common/ViewHeader"
import {isNotEmpty} from "../../util/array_util"
import {useTranslation} from "react-i18next"
import {refreshWristbandBatches, useWristbandBatches} from "../../hooks/useWristbandBatches";
import {useOrganizers} from "../../hooks/useOrganizers";
import {numFormat} from "../../util/format_util";
import {Link} from "react-router-dom";
import {SearchBox} from "../common/SearchBox";
import {faChevronDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import {showError, showSuccess} from "../../util/toast_util";
import Modal from "../common/Modal";
import {createWristbandBatch, deleteWristbandBatch} from "../../services/api/api";
import {useUpdateEvent} from "../../hooks/useEvent";
import Spinner from "../common/Spinner";
import {SelectOrganizerModal} from "../common/SelectOrganizerModal";
import {ConfirmModal} from "../common/ConfirmModal";

export const BatchesView = () => {

  const {batches} = useWristbandBatches()
  const {organizers} = useOrganizers()
  const {t} = useTranslation()
  const [showAddModal, setShowAddModal] = useState(false)
  const [query, setQuery] = useState<string>()

  const filteredBatches = useMemo(() => {
    const q = query?.toLowerCase() ?? ''

    if (!q) {
      return batches
    }

    return batches?.filter((b: WristbandBatch) => b?.code?.toLowerCase()?.includes(q)
      || b?.amount?.toString()?.toLowerCase()?.includes(q)
      || b?.supplier?.toString()?.toLowerCase()?.includes(q)
      || b?.organizerID?.toString()?.toLowerCase()?.includes(q)
      || organizers?.find(o => o.id == b.organizerID)?.name?.toLowerCase()?.includes(q))
  }, [query, batches])

  const onAddClick = () => {
    setShowAddModal(true)
  }

  return <div className={classes.BatchesView}>

    <ViewHeader title={t('wristband_batches')} showBackButton={isMobile}/>

    <Spacer height={20}/>

    <div className={classes.Controls}>

      <SearchBox
        className={classes.SearchBox}
        placeholder={t('find_batch')}
        onSearchChange={setQuery}/>

      <Spacer width={10}/>

      <AppButton
        icon={faPlus}
        title={t('add_batch')}
        onClick={onAddClick}
        className={classes.AddButton}/>
    </div>

    <Spacer height={10}/>

    {isNotEmpty(filteredBatches) && <div className={classes.BatchesTable}>

      {isDesktop && <Row className={classes.Header}>
        <Col xs={4}>{t('code')}</Col>
        <Col xs={4}>{t('organizer')}</Col>
        <Col xs={4}>{t('amount')}</Col>
      </Row>}

      {isMobile && <Row className={classes.Header}>
        <Col xs={12}>{t('code')}</Col>
      </Row>}

      {batches?.map(b => <BatchItem batch={b} organizer={organizers?.find(o => o.id == b.organizerID)}/>)}

    </div>}

    {filteredBatches?.length == 0 && <div className={classes.PlaceholderContainer}>
      <FontAwesomeIcon icon={faFaceSadCry} className={classes.PlaceholderIcon}/>
      <div className={classes.PlaceholderTitle}>{t('no_batches')}</div>
    </div>}

    <AddBatchModal
      opened={showAddModal}
      onClose={() => setShowAddModal(false)}/>

  </div>
}

//
interface BatchItemProps {
  batch: WristbandBatch
  organizer?: Organizer
}

const BatchItem = (props: BatchItemProps) => {
  const {id, amount, code, organizerID, supplier, orderDate} = props.batch
  const {t} = useTranslation()
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const browserContent = () => {
    return <Row className={classes.BatchItem}>
      <Col xs={4}>{code}</Col>
      <Col xs={4}>{props.organizer?.name}</Col>
      <Col xs={3}>{numFormat(amount ?? 0)}</Col>
      <Col xs={1}>
        {showDeleteButton && <FontAwesomeIcon
          icon={faTrashAlt}
          onClick={onDeleteClick}
          className={classes.DeleteButton}/>}
      </Col>

    </Row>
  }

  const mobileContent = () => {
    return <Row className={classes.BatchItem}>
      <Col xs={12}>
        <div className={classes.MobileEmail}>{code}</div>
      </Col>
    </Row>
  }

  const onHover = () => {
    if (showDeleteModal) {
      return
    }
    setShowDeleteButton(true)
  }

  const onLeave = () => {
    setShowDeleteButton(false)
  }

  const onDeleteModalClose = () => {
    setShowDeleteModal(false)
  }

  const onDeleteClick = (e: any) => {
    e.preventDefault()
    setShowDeleteModal(true)
  }

  const onConfirmDeleteClick = async () => {
    setShowDeleteModal(false)
    try {
      await deleteWristbandBatch(id ?? '')
      showSuccess(t('batch_deleted'))
      await refreshWristbandBatches()
    } catch (e) {
      showError(t(`can_not_delete_batch`))
    }
  }

  return <>

    <Link to={id ?? ''} onMouseEnter={onHover} onMouseLeave={onLeave}>
      {isDesktop ? browserContent() : mobileContent()}
    </Link>

    {showDeleteModal && <ConfirmModal
      title={t('are_you_sure')}
      onClose={onDeleteModalClose}
      opened={showDeleteModal}
      onConfirm={onConfirmDeleteClick}
      confirmTitle={t('delete')}
      confirmIcon={faTrashAlt}
      dangerConfirm>
      <div className={classes.DeleteModalText}>{t('are_you_sure_to_delete_batch', {code: code})}</div>
    </ConfirmModal>}

  </>

}

//
const AddBatchModal: React.FC<ModalProps> = (props) => {

  const {t} = useTranslation()
  const [organizer, setOrganizer] = useState<Organizer>()
  const [organizerError, setOrganizerError] = useState(false)
  const [amount, setAmount] = useState<number>()
  const [amountError, setAmountError] = useState(false)
  const [code, setCode] = useState<string>()
  const [codeError, setCodeError] = useState(false)
  const [orderDate, setOrderDate] = useState<Date>()
  const [orderDateError, setOrderDateError] = useState(false)
  const [supplier, setSupplier] = useState<string>()
  const [supplierError, setSupplierError] = useState(false)
  const [error, setError] = useState<string>()
  const [addingBatch, setAddingBatch] = useState(false)

  useEffect(() => {
    setOrganizerError(false)
  }, [organizer])

  useEffect(() => {
    setAmountError(false)
  }, [amount])

  useEffect(() => {
    setCodeError(false)
  }, [code])

  useEffect(() => {
    setOrderDateError(false)
  }, [orderDate])

  useEffect(() => {
    setSupplierError(false)
  }, [supplier])

  const onAddClick = async () => {

    if (!organizer) {
      setOrganizerError(true)
      return
    }

    if (!amount || amount <= 0) {
      setOrganizerError(true)
      return
    }

    if (!code) {
      setCodeError(true)
      return
    }

    if (!orderDate) {
      setOrderDateError(true)
      return
    }

    try {
      setError(undefined)
      setAddingBatch(true)
      await createWristbandBatch({
        amount: amount,
        code: code,
        orderDate: orderDate?.toISOString(),
        organizerID: organizer.id,
        supplier: supplier
      })
      props.onClose()
      showSuccess(t('batch_added'))
      refreshWristbandBatches()
    } catch (e: any) {
      showError(`Can not add batch: ${e}`)
      setError(e.message)
    } finally {
      setAddingBatch(false)
    }
  }

  return <Modal {...props} title={t('add_batch')}>
    <div className={classes.AddBatchModal}>

      <OrganizerSelector organizer={organizer} onSelect={setOrganizer}/>

      <Spacer height={10}/>

      <AppInput
        placeholder={t('code')}
        onChange={setCode}
        error={codeError}
        lowercase/>

      <Spacer height={10}/>

      <AppInput
        placeholder={t('amount')}
        type={InputType.number}
        onChange={v => setAmount(parseInt(v))}
        error={amountError}/>

      <Spacer height={10}/>

      <AppInput
        placeholder={t('order_date')}
        onChange={v => setOrderDate(new Date(v))}
        error={orderDateError}/>

      <Spacer height={10}/>

      <AppInput
        placeholder={t('supplier')}
        onChange={setSupplier}
        error={supplierError}/>

      <Spacer height={10}/>

      <AppButton
        title={t('add_batch')}
        onClick={onAddClick}
        className={classes.AddInvitesButton}
        progress={addingBatch}/>
    </div>

  </Modal>


}

// ---

interface OrganizerSelectorProps {
  onSelect: (organizer: Organizer) => void
  organizer?: Organizer
}

const OrganizerSelector = (props: OrganizerSelectorProps) => {
  const {organizer, onSelect} = props
  const [showModal, setShowModal] = useState(false)
  const {updatingEvent} = useUpdateEvent()
  const {t} = useTranslation()

  return <>
    <div className={updatingEvent ? classes.OrganizerSelectorLoading : classes.OrganizerSelector}
         onClick={() => setShowModal(true)}>
      <div>
        <div className={classes.OrganizerHeader}>{t('organizer')}</div>
        <div className={classes.Title}>{organizer?.name ?? '...'}</div>
      </div>
      <Spacer/>
      {updatingEvent ? <Spinner secondary/> : <FontAwesomeIcon icon={faChevronDown} className={classes.ArrowIcon}/>}
    </div>

    <SelectOrganizerModal
      onSelected={onSelect}
      opened={showModal}
      onClose={() => setShowModal(false)}/>
  </>
}
