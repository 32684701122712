import React, { useState } from "react"
import Modal from "./Modal";
import classes from './PaymentMethodsModal.module.scss'
import {Card, Event} from "../../services/api/models";
import useCards from "../../hooks/useCards";
import { CardItem } from "../account/CardItem";
import { AddButton } from "./AddButton";
import { AddCardForm } from "./AddCardForm";
import {useTranslation} from "react-i18next";

interface PaymentMethodsModalProps {
  event: Event
  selectedCard?: Card
  opened: boolean
  onClose: VoidFunction
  onChange: (card: Card) => void
}

const PaymentMethodsModal: React.FC<PaymentMethodsModalProps> = (props) => {
  const {t} = useTranslation()
  const {opened, onClose, selectedCard, onChange, event} = props
  const {cards} = useCards(event.platformAccountBillfoldId, event.paymentAccountType)
  const [showAddCardInfo, setShowAddCardInfo] = useState(false)

  const onAddCardClick = () => {
    setShowAddCardInfo(true)
  }

  const onCardAdded = (card: Card) => {
    setShowAddCardInfo(false)
    onChange(card)
    onClose()
  }

  const onBackCLick = () => {
    setShowAddCardInfo(false)
  }

  return <Modal
    opened={opened}
    onClose={onClose}
    title={showAddCardInfo ? t('add_new_card') : t('choose_payment_method')}
    onBackClick={showAddCardInfo ? onBackCLick : undefined}>
    {!showAddCardInfo && <div>
      <div className={classes.PaymentMethodsModal}>
        {(cards ?? []).map(c => <div className={'mb-3'}>
          <CardItem
            card={c}
            selected={c.id == selectedCard?.id}
            onClick={() => {
              onChange(c)
              onClose()
            }}/>
        </div>)}

        <AddButton title={t('add_card')} onClick={onAddCardClick}/>

      </div>
    </div>}

    {showAddCardInfo && <div>
      <AddCardForm onCardAdded={onCardAdded} event={event}/>
    </div>}
  </Modal>
}

export default PaymentMethodsModal
