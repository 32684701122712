import React, {PropsWithChildren, useEffect, useState} from 'react'
import classes from "./AppDateInput.module.scss";
import classnames from "classnames";
import {getRandomInt} from "../../util/math_util";
import {DatePickerModal} from "./DatePickerModal";
import "react-datepicker/dist/react-datepicker.css"
import {formatDate} from "../../util/date_util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";
import InfoModal from "./InfoModal";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

interface AppDateInputProps {
  placeholder?: string
  error?: boolean
  warning?: boolean
  onChange?: (date: Date) => void
  initialDate?: Date
  disabled?: boolean
  loading?: boolean
  infoTitle?: string
  infoMessage?: string
  valid?: boolean
  allowFuture?: boolean
}

export const AppDateInput: React.FC<AppDateInputProps & PropsWithChildren> = (props) => {
  const {
    placeholder,
    error,
    warning,
    onChange,
    disabled,
    loading,
    initialDate,
    infoTitle,
    infoMessage,
    valid,
    allowFuture,
  } = props

  const [date, setDate] = useState<Date | undefined>()
  const [showDateModal, setShowDateModal] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  useEffect(() => {
    if (initialDate) {
      setDate(initialDate)
    }
  }, [initialDate])

  const onDateChange = (date: any) => {
    setDate(date)
    setShowDateModal(false)
    onChange && onChange(date)
  }

  const onClick = () => {
    if (!showDateModal) {
      setShowDateModal(true)
    }
  }

  const onQuestionClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation();
    setShowInfo(true)
  }

  const onInfoClose = () => {
    setShowInfo(false)
  }

  return <>
    <div className={classes.AppInput} style={{paddingTop: 0}}>
      <div
        className={disabled ? classes.AppDateInputDisabled : classes.AppDateInput}
        onClick={disabled ? undefined : onClick}>

        {loading && <div className={'placeholder-glow'}>
          <div
            className={classnames(classes.InputShimmer, 'placeholder')}
            style={{width: `${30 + getRandomInt(20)}%`}}/>
        </div>}

        {!loading && <div>
          {date && <div>
            <div className={classes.PlaceholderSmall}>{placeholder}</div>
            <div className={classnames([
              {[classes.Red]: error},
              {[classes.Amber]: warning}
            ])}>{formatDate(date, 'MMMM d, yyyy')}</div>
          </div>}

          {!date && <div className={classes.Placeholder}>{placeholder}</div>}
        </div>}

        {showDateModal && <DatePickerModal
          onClose={() => setShowDateModal(false)}
          opened={showDateModal}
          initialDate={date || new Date()}
          onDateChange={onDateChange}
          allowFuture={allowFuture}/>}

        {valid && <div className={classes.IconContainer}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.IconValid}/>
        </div>}

        {!valid && infoTitle && <div className={classes.IconContainer}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={classnames(
              classes.Icon,
              {[classes.IconError]: error},
              {[classes.IconWarning]: warning}
            )}
            onClick={onQuestionClick}/>
        </div>}

      </div>

    </div>

    {infoTitle && showInfo && <InfoModal
      opened={showInfo}
      onClose={onInfoClose}
      title={infoTitle}
      message={infoMessage}/>}
  </>
}
