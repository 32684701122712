import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () =>  {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(`useEffect`)
    window.scrollTo(0, 0)

    setTimeout(()=>{
      console.log(`useEffect - 1`)
      window.scrollTo(0, 0)
    }, 1000)

  }, [pathname]);

  return null;
}
