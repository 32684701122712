import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import classes from './CheckinExternalView.module.scss'
import {AppButton, Spacer} from "../common";
import EventItem from "../common/EventItem";
import useEvents from "../../hooks/useEvents";
import {DivOrNull} from "../account/AccountView";
import {divLeft, divWidth} from "../../util/div_util";
import {ReactComponent as ListStar} from "../../images/list_star.svg";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faImage, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {debounce} from "@mui/material";
import {isEmpty, isNotEmpty} from "../../util/array_util";
import {Event, Organizer} from "../../services/api/models";
import InfoRow from "../common/InfoRow";
import {dates, locationTitle} from "../../util/event_util";
import {ReactComponent as Calendar} from "../../images/calendar_small.svg";
import {ReactComponent as Location} from "../../images/location_small.svg";
import {SelectEventModal} from "./SelectEventModal";
import {useScreenSize} from "../../hooks/useScreenSize";
import {isDesktop, isMobile} from "react-device-detect";
import useUser from "../../hooks/useUser";
import {useTranslation} from "react-i18next";
import {useOrganizerByCode} from "../../hooks/useOrganizer";
import {useWristbands} from "../../hooks/useWristbands";
import Spinner from "../common/Spinner";
import {useRedirectRules} from "../../hooks/useRedirectRules";
import {useOrganizers} from "../../hooks/useOrganizers";
import {AppImage} from "../common/AppImage";

interface CheckinExternalViewProps {
}

export const CheckinExternalView: React.FC<CheckinExternalViewProps> = (props) => {

  const [searchParams] = useSearchParams()
  const {isClientAdmin, isBillfoldAdmin} = useUser()
  const {organizers} = useOrganizers()
  const {rules} = useRedirectRules()
  const {eventsLoading, closestEvents} = useEvents()
  const {wristbands, wristbandsLoading} = useWristbands()
  const {t} = useTranslation()
  const container = useRef<DivOrNull>(null)
  const firstEventItem = useRef<DivOrNull>(null)
  const [scrolling, setScrolling] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(0)
  const {wristbandNumber} = useParams()
  const [showSelectEventModal, setShowSelectEventModal] = useState(false)
  const [organizer, setOrganizer] = useState<Organizer>()
  const {height} = useScreenSize()
  const navigate = useNavigate()
  const [linkingInitiated, setLinkingInitiated] = useState(false)

  useEffect(() => {
    if (isMobile) {
      const main = document.getElementById('main')
      main?.setAttribute('style', 'background-color: #E0E1EB;')

      const mainContent = document.getElementById('main-content')
      mainContent?.setAttribute('style', 'padding: 0;')

      const root = document.getElementById('checkin-external-root')
      root?.setAttribute('style', `height: ${window.innerHeight}px;`)

      return () => {
        main?.setAttribute('style', 'background-color: white;')
        mainContent?.setAttribute('style', 'padding: 0 1.6rem')
      }
    }

  }, [])

  useEffect(() => {
    if (isMobile) {
      const height = window.innerHeight
      const root = document.getElementById('checkin-external-root')
      root?.setAttribute('style', `height: ${height}px; min-height: ${height}px; max-height: ${height}px;`)
    }
  }, [closestEvents])

  useEffect(() => {
    if (closestEvents && wristbands && isNotEmpty(closestEvents) && isNotEmpty(wristbands)) {
      // check if there are some wristbands with registration type qr for some of closest events
      const hasQrWristbands = wristbands.some(w => w.registrationType == 'qr' && closestEvents?.some(e => e.id == w.eventID))
      if (hasQrWristbands) {
        navigate(`linking`)
      }
      setLinkingInitiated(true)
    }
  }, [closestEvents, wristbands])

  useEffect(() => {
    if (isMobile && height > 0) {
      const root = document.getElementById('checkin-external-root')
      root?.setAttribute('style', `height: ${height}px; min-height: ${height}px; max-height: ${height}px;`)
    }
  }, [height])

  useEffect(() => {
    const eventId = searchParams?.get('eventId') ?? searchParams?.get('eventld') ?? ''
    if (isNotEmpty(closestEvents) && eventId) {
      navigate(`/event/${eventId}?wristbandNumber=${wristbandNumber}`)
    }

    const organizerCode = searchParams?.get('organizer')
    if (organizerCode) {
      const organizer = organizers?.find(o => o.code == organizerCode)
      if (organizer) {
        setOrganizer(organizer)
      }
    }

    const batchCode = searchParams?.get('batchCode') ?? searchParams?.get('batch_code')
    if (batchCode) {
      const rule = rules?.find(r => r.fromBatchCode == batchCode)
      if (rule) {
        if (rule.toEventId) {
          navigate(`/event/${rule.toEventId}?wristbandNumber=${wristbandNumber}`)
        } else if (rule.toOrganizerCode) {
          const organizer = organizers?.find(o => o.code == rule.toOrganizerCode)
          if (organizer) {
            setOrganizer(organizer)
          }
        }
      }
    }

  }, [closestEvents, searchParams, organizers, rules])

  const maxEventItemsCount = useMemo(() => {
    // 100 is the height of the item
    // to make sure event items are not cut off by screen
    // we will show the maximum number of items that fit the screen
    // and for the desktop screen we make it even smaller
    return (height / 100) * (isMobile ? 0.6 : 0.5)
  }, [height])

  const onEventsScroll = useCallback(() => {
    if (!scrolling) {
      setScrolling(true)
    }
    onScrollDebounced()
  }, [])

  const onScrollDebounced = useCallback(debounce((e?: any) => {
    const topOffset = divLeft(firstEventItem) - divLeft(container)
    const position = Math.floor(Math.abs(topOffset) / divWidth(firstEventItem))
    setSelectedEvent(position)
    setScrolling(false)
  }, 100), [])

  const allowedClosestEvents = useMemo(
    () => {
      return closestEvents?.filter((e) => {
        const orgFit = !organizer || e.organizerID == organizer.id
        const isAdmin = isClientAdmin || isBillfoldAdmin
        const visible = e.showToUsers
        return orgFit && (visible || isAdmin)
      })
    },
    [closestEvents, organizer]
  )

  const allowedClosestEventsWithRegistrations = useMemo(
    () => {
      // const organizer = searchParams?.get('organizer') ?? ''
      return closestEvents?.filter((e) => {
        const orgFit = !organizer || e.organizerID == organizer.id
        const isAdmin = isClientAdmin || isBillfoldAdmin
        const visible = e.showToUsers
        const hasQrRegistrations = wristbands?.some(w => w.eventID == e.id && w.registrationType == 'qr')
        return hasQrRegistrations && orgFit && (visible || isAdmin)
      })
    },
    [closestEvents, wristbands]
  )

  const onChangeEventClick = () => {
    if (!closestEvents) {
      return
    }

    if (selectedEvent >= closestEvents.length - 1) {
      container.current?.scrollTo({
        left: 0,
        behavior: 'smooth'
      })
    } else {
      container.current?.scrollBy({
        left: 100,
        behavior: 'smooth'
      })
    }
  }

  const content = () => {

    if (eventsLoading || wristbandsLoading || !linkingInitiated) {
      return <div className={classes.CheckinExternalView} id={'checkin-external-root'}>
        <div className={classes.SpinnerContainer}>
          <Spinner/>
        </div>
      </div>
    }

    return <div className={classes.CheckinExternalView} id={'checkin-external-root'}>

      {(allowedClosestEvents ?? []).length == 1 && <div className={classes.Title}>
        <p>{t('you_are_registering_to')}</p>
        <p className={classes.Accent}>{(allowedClosestEvents ?? [])[0].name}</p>
      </div>}

      {(allowedClosestEvents ?? []).length > 1 && <div className={classes.Title}>
        {t('select_the_event_to_register')}
      </div>}

      <Spacer height={30}/>

      <div className={classes.EventsCardsContainer}>

        {!eventsLoading && allowedClosestEvents?.slice(0, maxEventItemsCount).map((e) => <EventItemSmallOld
          event={e}
          onClick={(e) => {
            navigate(`/event/${e.id}?wristbandNumber=${wristbandNumber}`)
          }}
          darkBackground={isDesktop}/>)}

        {!eventsLoading && isEmpty(allowedClosestEvents) && <div className={classes.Placeholder}>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className={classes.PlaceholderIcon}/>

          <Spacer height={20}/>

          <div className={classes.PlaceholderTitle}>{t('there_are_no_upcoming_events')}</div>

        </div>}

        {eventsLoading && <EventItem shimmer/>}

      </div>

      <Spacer height={20}/>

      {isNotEmpty(allowedClosestEvents) && (allowedClosestEvents??[]).length >= maxEventItemsCount && <AppButton
        transparent
        title={t('select_another_event')}
        iconComponent={<ListStar className={classes.Icon}/>}
        className={classes.ChangeEventButton}
        onClick={() => setShowSelectEventModal(true)}/>}

      <Link to={'/'}>
        <div className={classes.CloseButton}>
          <FontAwesomeIcon
            icon={faClose}
            className={classes.CloseIcon}/>
        </div>
      </Link>

      <SelectEventModal
        opened={showSelectEventModal}
        organizer={organizer}
        onClose={() => setShowSelectEventModal(false)}
        onSelected={(e: any) => {
          setShowSelectEventModal(false)
          navigate(`/event/${e.id}?wristbandNumber=${wristbandNumber}`)
        }}/>

    </div>
  }

  return content()
}

/**
 * @deprecated
 */
interface EventItemSmallOldProps {
  event: Event
  darkBackground?: boolean
  onClick?: (e: Event) => void
}

/**
 * @deprecated
 */
export const EventItemSmallOld = (props: EventItemSmallOldProps) => {
  const {event, darkBackground, onClick} = props
  const {locations, startDate, finishDate} = event

  const platformAccountInfo = () => {
    return <div className={classes.PlatformAccountInfo}>
      {event.platformAccountBillfoldId}
    </div>
  }

  return <div
    className={darkBackground ? classes.ClosestEventDarkBackground : classes.ClosestEvent}
    onClick={() => onClick && onClick(event)}>

    {(event.imageUrl || event.imageStorageKey)
      ? <AppImage src={event.imageUrl} storageKey={event.imageStorageKey} alt={event.name} className={classes.Image}/>
      : <div className={classes.ImagePlaceholder}>
        <FontAwesomeIcon icon={faImage} className={classes.ImagePlaceholderIcon}/>
      </div>}

    <div className={classes.Info}>

      <div className={classes.ClosestTitle}>{event.name}</div>

      <InfoRow
        title={dates(startDate, finishDate)}
        icon={Calendar}/>

      <InfoRow
        title={locationTitle(event)}
        icon={Location}
        grey={!locations || !locations.length}/>

    </div>

    {process.env.REACT_APP_ENV == 'staging' && platformAccountInfo()}
  </div>
}
