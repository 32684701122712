import React, {useEffect, useState} from 'react'
import classes from './ClearAuthView.module.scss'
import {useNavigate, useSearchParams} from "react-router-dom";
import {getClearUserInfo} from "../../services/api/api";
import Spinner from "../common/Spinner";
import useUser, {useUpdateUser} from "../../hooks/useUser";
import {isNotEmpty} from "../../util/string_util";

interface ClearAuthViewProps {
}

// get auth code
// call getUserInfo on server side
// server will return {isOver21: boolean, phoneNumber: string}
// - if success
// get this data, update the user info
// show success to user, redirect to registration page with the given wristband number
// (how to pass the wristband number to the registration page? over the state?)
// - if fail
// show error to user, place two buttons - "try again" and "enter data manually"
// - if cancelled we should not be here

export const ClearAuthView: React.FC<ClearAuthViewProps> = (props) => {

  const {user} = useUser()
  const {updateUser, updatingUser} = useUpdateUser()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [code, setCode] = useState('')

  useEffect(() => {
    if (user && isNotEmpty(code)) {
      refreshUserInfo()
    }
  }, [code, user])

  useEffect(() => {
    if (searchParams && searchParams.has('code')) {
      setCode(searchParams?.get('code') || '')
    }
  }, [searchParams])

  const refreshUserInfo = async () => {

    try {
      setLoading(true)
      // we are calling api directly from the view
      // despite the fact it's not the best practice,
      // but we need to control how often it being called
      // making sure it will never be called twice
      // cause the auth code has one-time use
      const {over21} = await getClearUserInfo(code)

      await updateUser({
        ...user,
        verifiedOver21: over21,
        verifiedOver21Time: new Date().toISOString(),
        dateOfBirth: undefined,
      })

      const redirectToPage = searchParams.get('state') ?? ''
      navigate(redirectToPage)
    } catch (error: any) {
      console.log(`refreshUserInfo - error message: ${error?.message ?? error?.toString()}`)
      console.log(`refreshUserInfo - error data:    ${error?.response?.data ?? error?.data}`)
      console.log(`refreshUserInfo - error stack:   ${error?.stack}`)
      console.log(`refreshUserInfo - ---`)
      const redirectToPage = searchParams.get('state') ?? ''
      navigate(`${redirectToPage}?ageValidationError=${error.toString()}`)
    } finally {
      setLoading(false)
    }
  }

  const progressMessage = (message: string) => {
    return <div className={classes.Container}>
      <Spinner/>
      <div className={classes.ProgressMessage}>{message}</div>
    </div>
  }

  return <div className={classes.ClearAuthView}>

    {loading && !updatingUser && progressMessage('Validating your age...')}

    {updatingUser && progressMessage('Updating your account...')}

  </div>
}
