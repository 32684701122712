import {Location} from './api/models';
const notShowQrRegistrationInfoAgainKey = 'not_show_qr_registration_info_again';
const filterLocationKey = 'filter_location';
const filterCountryKey = 'filter_country';

export const getFilterCountry = () : string | undefined => {
  return localStorage.getItem(filterCountryKey) ?? undefined
}

export const saveFilterCountry = (country: string) => {
  localStorage.setItem(filterCountryKey, country);
}

export const clearFilterCountry = () => {
  localStorage.removeItem(filterCountryKey);
}

export const getFilterLocation = () : Location | undefined => {
  const item = localStorage.getItem(filterLocationKey);
  return !item ? undefined : JSON.parse(item || '{}')
}

export const saveFilterLocation = (location: Location) => {
  localStorage.setItem(filterLocationKey, JSON.stringify(location));
}

export const clearFilterLocation = () => {
  localStorage.removeItem(filterLocationKey);
}

export const getNotShowQrRegistrationInfoAgain = () => {
  return localStorage.getItem(notShowQrRegistrationInfoAgainKey) == 'not_show';
}

export const saveNotShowQrRegistrationInfoAgain = (notShow: boolean) => {
  localStorage.setItem(notShowQrRegistrationInfoAgainKey, notShow ? 'not_show' : 'show');
}

export const clearNotShowQrRegistrationInfoAgain = () => {
  localStorage.removeItem(notShowQrRegistrationInfoAgainKey);
}

export const getToken = (): string | null => {
  const token = localStorage.getItem('token')
  if (!token) {
    return null
  }
  return token;
}

export const saveToken = (token: string) => {
  localStorage.setItem('token', token);
}

export const clearToken = () => {
  localStorage.setItem('token', '');
}

export const getUserLocale = (): string | null => {
  return localStorage.getItem('user_locale')
}

export const setUserLocale = (locale: string): void => {
  localStorage.setItem('user_locale', locale)
}
