import React, {useMemo} from 'react'
import classes from './SelectOrganizerModal.module.scss'
import {useSearchParams} from "react-router-dom";
import {useKeyboardVisibility} from "../../hooks/useKeyboardVisibility";
import Modal, {ModalProps} from "./Modal";
import {Spacer} from "./Spacer";
import {Organizer} from "../../services/api/models";
import {useOrganizers} from "../../hooks/useOrganizers";
import {useTranslation} from "react-i18next";
import {SearchBox} from "./SearchBox";

interface SelectOrganizerModalProps {
  onSelected: (organizer: Organizer) => void
}

export const SelectOrganizerModal: React.FC<SelectOrganizerModalProps & ModalProps> = (props) => {
  const [searchParams] = useSearchParams()
  const {opened, onClose, onSelected} = props
  const {organizersError, organizers, organizersLoading} = useOrganizers()
  const [search, setSearch] = React.useState('')
  const {keyboardVisible} = useKeyboardVisibility()
  const {t} = useTranslation()

  const contains = (text?: string, search?: string) =>{
    return text?.toLowerCase().includes(search?.toLowerCase() ?? '')
  }

  const filteredOrgs = useMemo(() => {
    return organizers?.filter(o => {
      return contains(o.name, search) || contains(o.code, search) || contains(o.description, search)
    })
  }, [organizers, search, searchParams])

  return <Modal
    opened={opened}
    onClose={onClose}
    title={t('select_organizer')}>
    <div className={classes.SelectOrganizerModal}>
      <SearchBox onSearchChange={setSearch}/>
      <Spacer height={10}/>
      <div className={classes.EventsList} style={{maxHeight: keyboardVisible ? 200 : 400}}>
        {filteredOrgs?.map(o => <OrgItem
          organizer={o}
          onClick={(o) => {
            onSelected(o)
            onClose()
          }}/>)}
      </div>
    </div>
  </Modal>

}

interface OrgItemProps {
  organizer: Organizer
  onClick: (organizer: Organizer) => void
}

const OrgItem = (props: OrgItemProps) => {
  const {organizer, onClick} = props
  const {name, code} = organizer ?? {}
  return <div className={classes.OrgItem} onClick={() => onClick && onClick(organizer)}>
    <div className={classes.Name}>{name}</div>
    <div className={classes.Code}>{code}</div>
  </div>
}
