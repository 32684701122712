import {
  EventsPageData,
  getEvents,
  GetEventsParams,
} from '../services/api/api'
import useSWRInfinite from "swr/infinite";
import {last} from "../util/array_util";
import {Event} from "../services/api/models";
import {isBraintree, notFinishedYet} from "../util/event_util";
import {DateTime} from "luxon";

export default function useEvents(params?: GetEventsParams) {
  try {

    const getKey = (pageNumber: number, prevData: EventsPageData | null) => {
      const {events, nextToken} = prevData ?? {}
      if (prevData && !events?.length) {
        return null
      }
      return ['getEvents', {...params, nextToken}]
    }

    const {
      data,
      error,
      isLoading,
      size,
      setSize
    } = useSWRInfinite<EventsPageData>(getKey, getEvents)

    const isEmpty = data?.[0]?.events.length === 0;

    const events: Event[] = data?.flatMap(d => d.events) ?? []
    const closestEvents = events
      ?.filter(e => notFinishedYet(e) && e.registrationAllowed && !isBraintree(e) && e.showToUsers)
      .sort((e1, e2) => {
        const diff1 = DateTime.fromSQL(e1.startDate ?? '').diffNow();
        const diff2 = DateTime.fromSQL(e2.startDate ?? '').diffNow();
        return diff1 < diff2 ? -1 : 1;
      })

    return {
      events: events,
      closestEvents: closestEvents,
      hasMore: !!last(data)?.nextToken,
      isEmpty,
      eventsLoading: isLoading,
      eventsError: error,
      eventsSize: size,
      setSize
    }
  } catch (e) {
    console.log(`useTransactions - e: ${e}`)
    return {
      transactions: [],
      hasMore: false,
      isEmpty: true,
      transactionsLoading: false,
      error: '',
      size: 0,
      setSize: (index: number) => {
      },
    }
  }
}
