import {adyenAddCard} from '../services/api/api'
import useSWRMutation from "swr/mutation";
import {Card} from "../services/api/models";

export type CreateCardRequest = {
  encryptedCardNumber: string
  encryptedExpiryMonth: string
  encryptedExpiryYear: string
  encryptedSecurityCode: string
  holderName: string
  paymentAccountType: string
  platformAccountId: string
  customerId: string
}

export const useAdyenAddCard = () => {
  const {trigger, isMutating, error} = useSWRMutation<Card | undefined, string, string, CreateCardRequest>('adyenAddCard', adyenAddCardFetcher)
  return {
    adyenAddCard: trigger,
    adyenAddingCard: isMutating,
    adyenAddCardError: error
  }
}

const adyenAddCardFetcher = async (url: string, data: { arg: CreateCardRequest }): Promise<Card | undefined> => {
  return adyenAddCard(
    data.arg.encryptedCardNumber,
    data.arg.encryptedExpiryMonth,
    data.arg.encryptedExpiryYear,
    data.arg.encryptedSecurityCode,
    data.arg.holderName,
    data.arg.paymentAccountType,
    data.arg.platformAccountId,
    data.arg.customerId,
  )
}

