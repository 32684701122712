import React, { useEffect, useState } from 'react'
import classes from './InvitesView.module.scss'
// import * as fs from "fs";
// const path = require('path')
// import * as zlib from "zlib";

interface LogsViewProps {
}

interface DataArgument {
  wristband: string;
}

interface LogData {
  timestamp: string;
  requestId: string;
  level: string;
  event: string;
  data: {
    arguments: DataArgument;
  };
}

const regex = /^.*INFO\s+handle - event:(.*)$/gm;

export const LogsView: React.FC<LogsViewProps> = (props) => {

  const [data, setData] = useState<string>("");

  useEffect(() => {

    console.log(`LogsView - fetching the file...`)

    getLogs()
    // const directoryPath = path.join(__dirname, "data");

    /*fs.readdir(directoryPath, (error, files) => {
      if (error) {
        console.error("Error reading directory", error);
        return;
      }

      let combinedContent = "";
      files.forEach((file) => {
        if (file.endsWith(".gz")) {
          const filePath = path.join(directoryPath, file);
          // const content = fs.readFileSync(filePath);
          // const unzippedContent = zlib.gunzipSync(content).toString();
          // combinedContent += unzippedContent;
        }
      });
      setData(combinedContent);
    });*/
  }, []);

  const getLogs = async () => {
    console.log(`getLogs ------------------`)
    try {
      const response = await fetch('/data/test.txt')
      const text = await response.text()
      console.log(`getLogs - text: ${text}`)
    } catch (e) {
      console.log(`getLogs - e: ${e}`)
    }

    // .then(response => response.text())
    // .then(data => {})
    // .catch(error => console.error(error));

  }


  /*useEffect(()=>{
    const parsedLogs: LogData[] = [];
    const logs = data.split("\n");
    logs.forEach((log) => {

      if (log.match(regex)) {
        const jsonStr = '' //log.match(regex)?[0]?.substr(7)
        try {
          const data = JSON.parse(jsonStr).data;
          if (data?.arguments?.wristband) {
            parsedLogs.push({
              timestamp: log.substr(0, 24),
              requestId: log.substr(25, 36),
              level: log.substr(62, 5).trim(),
              event: jsonStr,
              data,
            });
          }
        } catch (error) {
          console.error("Error parsing log", error);
        }
      }

      if (log.startsWith(" 2023-04-28T05:59:06.303Z")) {
        const index = log.indexOf("event: ");
        if (index !== -1) {
          const jsonStr = log.substr(index + 7);
          try {
            const data = JSON.parse(jsonStr).data;
            if (data?.arguments?.wristband) {
              parsedLogs.push({
                timestamp: log.substr(0, 24),
                requestId: log.substr(25, 36),
                level: log.substr(62, 5).trim(),
                event: jsonStr,
                data,
              });
            }
          } catch (error) {
            console.error("Error parsing log", error);
          }
        }
      }
    });
    // setParsedLogs(parsedLogs);
  },[data])

  const stringFromAllFiles = () => {

  }
*/

  return <div className={classes.InvitesView}>


  </div>
}
