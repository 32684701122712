import useSWRMutation from "swr/mutation";
import { login } from "../services/api/api";

export const useLogin = () => {
  const {trigger, isMutating} = useSWRMutation<any, any, any, any>('login', login)
  return {
    login: trigger,
    loginInProgress: isMutating,
  }
}
