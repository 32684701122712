import Modal, {ModalProps} from "./Modal";
import {useTranslation} from "react-i18next";
import {Spacer} from "./Spacer";
import classes from "./WristbandDetailsView.module.scss";
import {Link} from "react-router-dom";
import {AppButton} from "./AppButton";
import React from "react";

interface ActivateSuccessModalProps {
  wristbandId: string
}

export const ActivationOptionModal = (props: ActivateSuccessModalProps & ModalProps) => {
  const {wristbandId, onClose} = props
  const {t} = useTranslation()

  return <Modal {...props}>

    <div className={classes.ActivationOptionModal}>

      <Spacer height={12}/>

      <div className={classes.ModalDrawerLine}/>

      <Spacer height={16}/>

      <Link to={`/wristbands/${wristbandId}/activate`}>
        <AppButton
          title={t('with_my_device')}
          className={'w-100'}
          onClick={onClose}
        />
      </Link>

      <Spacer height={10}/>

      <Link to={`/wristbands/${wristbandId}/activate-kiosk`}>
        <AppButton
          title={t('at_the_event_with_the_kiosk')}
          className={'w-100'}
          secondary
          onClick={onClose}/>

      </Link>

    </div>

  </Modal>
}
