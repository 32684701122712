import {Wristband, Event, Card} from "../../services/api/models"
import React, {useEffect, useMemo, useState} from "react"
import classes from "./RegistrationItem.module.scss"
import useCards from "../../hooks/useCards"
import {AppButton, Spacer} from "../common"
import {ReactComponent as QrIcon} from "../../images/qr_icon.svg"
import {hasPaymentMethodId} from "../../util/card_util"
import {useTranslation} from "react-i18next"
import {PaymentMethodInfo} from "./PaymentMethodInfo"
import {Link} from "react-router-dom"
import {ActivationOptionModal} from "./ActivationOptionModal";

interface RegistrationItemProps {
  wristband?: Wristband
  shimmer?: boolean
  event?: Event
  key?: string
  aspectRatio?: number
}

export const RegistrationItem: React.FC<RegistrationItemProps> = (props: RegistrationItemProps) => {

  const {wristband, shimmer, event, key, aspectRatio} = props
  const {id, wristbandOwnerName, balance, status, registrationType} = wristband ?? {}
  const {cards, cardsLoading} = useCards()
  const {t} = useTranslation()
  const [card, setCard] = useState<Card>()
  const [showActivationOptionModal, setShowActivationOptionModal] = useState(false)

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState == 'hidden') {
        // Hide the modal when the app is switching
        setShowActivationOptionModal(false);
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (cards && cards.length > 0) {
      setCard(
        cards?.find(c => hasPaymentMethodId(c, wristband?.paymentMethodID)) ??
        cards?.find(c => hasPaymentMethodId(c, wristband?.stripePaymentMethodID))
      )
    }
  }, [cards])

  const cardInfo = useMemo(() => {
    return <>

      {cardsLoading && <div className={'placeholder-glow'}>
        <div className={'placeholder'}/>
      </div>}

      {!cardsLoading && card && <PaymentMethodInfo card={card} small/>}

    </>
  }, [card])

  const onLinkWristbandClick = (e: any) => {
    e.preventDefault()
    setShowActivationOptionModal(true)
  }

  // Set link to '#' to prevent navigation when tapping
  // outside the opened modal
  return !shimmer && wristband ?
    <Link
      to={showActivationOptionModal ? '#' : `/wristbands/${wristband?.id}`}
      key={key}
      style={{aspectRatio: aspectRatio}}
      className={classes.RegistrationItem}>

      <div className={classes.TopRow}>
        <div className={classes.IconWrapper}>
          <QrIcon className={classes.Icon}/>
        </div>
        <Spacer/>
        {cardInfo}
      </div>

      <Spacer height={10}/>

      <div className={classes.Name}>{wristbandOwnerName}</div>

      <Spacer/>

      <AppButton
        className={classes.LinkButton}
        title={t('link_wristband')}
        onClick={onLinkWristbandClick}/>

      {showActivationOptionModal && id && <ActivationOptionModal
        opened={showActivationOptionModal}
        onClose={() => setShowActivationOptionModal(false)}
        wristbandId={id}
      />}

    </Link> : <div></div>
}
