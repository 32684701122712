import {getOptIns} from "../services/api/api";
import useSWR from "swr";

export const useOptIns = (eventId: string) => {
  const {data, error, isLoading} = useSWR(['getOptIns', eventId], getOptInsFetcher)
  return {
    optIns: data,
    optInsLoading: isLoading,
    optInsError: error,
  }
}

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const getOptInsFetcher = async (params: any) => {
  const [,eventId] = params
  return await getOptIns(eventId)
}
