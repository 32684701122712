import React from 'react'
import classes from './MenuItem.module.scss'
import classnames from "classnames";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MenuItemProps {
  title: string
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  iconActive?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  faIcon?: IconDefinition
  selected?: boolean
  onClick?: VoidFunction
  noBorder?: boolean
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {title, icon, iconActive, selected, onClick, noBorder, faIcon} = props
  const Icon = selected ? iconActive : icon
  return <div
    onClick={onClick}
    className={classnames(
      classes.MenuItem,
      {
        [classes.MenuItemSelected]: selected,
        [classes.NoBorder]: noBorder
      }
    )}>

    {Icon && <div className={selected ? classes.IconContainerSelected : classes.IconContainer}>
      <Icon className={classes.Icon}/>
    </div>}

    {faIcon && <div>
      <FontAwesomeIcon
      icon={faIcon}
      className={selected ? classes.FaIconSelected : classes.FaIcon}/>
    </div>}

    <div className={classes.Title}>{title}</div>
  </div>
}
