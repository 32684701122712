import {maxSymbolsCutLength} from "../config/constants";

export const capitalize = (value: string | undefined): string => {
  if (!value || value.length == 0) {
    return '';
  }
  return value.substring(0, 1).toUpperCase() + value.substring(1);
}

export const firstTwoLetters = (value: string): string => {
  return value.length == 0 ? '' : value.substring(0, 2);
}

export const trimCommas = (value: string): string => {
  value = value.trim()

  while (value.startsWith(',')) {
    value = value.slice(1, value.length).trim()
  }

  while (value.endsWith(',')) {
    value = value.slice(0, value.length - 1).trim()
  }

  return value
}

export const trimSlash = (value: string): string => {
  value = value.trim()

  while (value.endsWith('/')) {
    value = value.slice(0, value.length - 1).trim()
  }

  return value
}

export const isEmpty = (value?: string | null): boolean => {
  return !value || !value.length
}

export const isNotEmpty = (value?: string | null): boolean => {
  return !isEmpty(value)
}

export const toggleValueInArray = <T>(array?: T[], value?: T): T[] | undefined => {

  if (!array || !value) {
    return array
  }

  if (array.includes(value)) {
    return array.filter(e => e != value)
  } else {
    return [...array, value]
  }
}

export const cut = (input: string, maxSymbols: number = maxSymbolsCutLength): string => {
  return input.length <= maxSymbols ? input : input.substring(0, maxSymbols);
}

export const cutEllipsize = (input: string, maxSymbols: number = maxSymbolsCutLength): string => {
  return input.length <= maxSymbols ? input : input.substring(0, maxSymbols) + '...';
}

export const lowerCaseEqual = (s1: string, s2: string): boolean => {
  return s1?.toLowerCase() == s2?.toLowerCase()
}

export const sameNonNull = (s1: string | undefined, s2: string | undefined) => {
  return !!s1 && !!s2 && s1 == s2
}
