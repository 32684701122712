import { BalanceTransaction, BalanceTransactionType } from "../services/api/models";

export const transactionReason = (transaction: BalanceTransaction) => {
  switch (transaction.type) {
    case BalanceTransactionType.cashTopUp:
    case BalanceTransactionType.bankCardCashTopUp:
    case BalanceTransactionType.promoCashTopUp:
    case BalanceTransactionType.cryptoTopUp:
      return 'Top up'
    case BalanceTransactionType.cashRefund:
    case BalanceTransactionType.bankCardCashRefund:
    case BalanceTransactionType.promoCashRefund:
    case BalanceTransactionType.cryptoRefund:
      return 'Refund'
    default:
      return 'Unknown reason'
  }
}

export const isRefund = (type: string) => {
  return type == BalanceTransactionType.cashRefund
    || type == BalanceTransactionType.bankCardCashRefund
    || type == BalanceTransactionType.promoCashRefund
    || type == BalanceTransactionType.cryptoRefund
}
