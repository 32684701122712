import React from 'react'
import classes from './OrganizersView.module.scss'
import {Spacer} from "../common"
import {isMobile} from "react-device-detect"
import {ViewHeader} from "../common/ViewHeader"
import {useIntegrators} from "../../hooks/useIntegrators"
import {useTranslation} from "react-i18next"
import {TableView} from "../common/TableView";

export const IntegratorsView = () => {

  const {integrators} = useIntegrators()
  const {t} = useTranslation()

  return <div className={classes.OrganizersView}>

    <ViewHeader title={t('integrators')} showBackButton={isMobile}/>

    <Spacer height={20}/>

    <TableView
      columns={[6,6]}
      headers={[t('name'), t('created_at')]}
      mobileHeader={t('name')}
      items={integrators?.map(i => ({
        values: [
          i.name,
          i.createdAt,
        ],
        mobileValue: i.name,
        linkTo: i.id ?? '',
      })) ?? []}
      placeholderText={t('no_integrators')}/>

  </div>
}
