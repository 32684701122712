import React, { useState, useEffect } from 'react'

const defaultDelay = 500

interface LongPressProps {
  onLongPress: VoidFunction
  delay?: number
  children: any
  isActive?: boolean
}

export const LongPress = (props: LongPressProps) => {
  const {onLongPress, delay, children, isActive} = props
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId: any
    if (startLongPress) {
      if (isActive) {
        timerId = setTimeout(onLongPress, delay ?? defaultDelay)
      }
    } else {
      timerId && clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [startLongPress, delay, onLongPress])

  const handleMouseDown = () => {
    setStartLongPress(true)
  }

  const handleMouseUp = () => {
    setStartLongPress(false)
  }

  const handleMouseLeave = () => {
    setStartLongPress(false)
  }

  const handleTouchStart = () => {
    setStartLongPress(true)
  }

  const handleTouchEnd = () => {
    setStartLongPress(false)
  }

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}>
      {children}
    </div>
  )
}

