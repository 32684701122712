export type UserBalanceDetail = {
  id?: string
  amount?: number
  paymentMethodType?: PaymentMethodType
  userID?: string
  paymentMethodID?: string
}

export type EventCategory = {
  id?: string
  name?: string
  events?: [Event]
}

export type AutoTopUpConfig = {
  id?: string
  threshold?: number
  amount?: number
  user?: User
  paymentMethodID?: string
}

export enum BalanceTransactionType {
  cashTopUp = 'cash_top_up',
  bankCardCashTopUp = 'bank_card_cash_top_up',
  cryptoTopUp = 'crypto_top_up',
  promoCashTopUp = 'promo_cash_top_up',
  cashRefund = 'cash_refund',
  bankCardCashRefund = 'bank_card_cash_refund',
  promoCashRefund = 'promo_cash_refund',
  cryptoRefund = 'crypto_refund',
}

export type BalanceTransaction = {
  id: string
  billfoldId: string
  createdAt: string
  updatedAt: string
  createdBy: string
  amount: number
  currency: string
  type: string
  purpose: string
  paymentMethodID: string
  wristbandID: string
  wristband: Wristband
  eventID: string
  event: Event
}

export type WristbandStatus = 'new' | 'active' | 'blocked' | 'not_registered'

export type Wristband = {
  id?: string
  billfoldId?: string
  stripePaymentMethodID?: string
  paymentMethodID?: string
  emailToSendReceipt?: string
  wristbandNumber?: string
  wristbandOwnerName?: string
  wristbandOwnerFirstName?: string
  wristbandOwnerLastName?: string
  wristbandOwnerPhoneNumber?: string
  wristbandOwnerEmail?: string
  topUpAmount?: number
  spendingLimit?: number
  pinCode?: string
  guid?: string
  eventID?: string
  event?: Event
  wristbandUserID?: string
  user?: User
  balance?: number
  createdAt?: string
  updatedAt?: string
  status?: WristbandStatus
  newsSubscription?: boolean
  purchasesWithoutPin?: boolean
  donor?: boolean
  registrationType?: RegistrationType
  balanceTransactions?: [BalanceTransaction]
}

export type Card = {
  id?: string
  owner?: string
  paymentMethodIds?: string
  last4?: string
  brand?: string
  userID?: string
  holderName?: string
  expiryMonth?: number
  expiryYear?: number
}

export type PaymentMethod = {
  id?: string
  type?: PaymentMethodType
  cardNumber?: string
  cardExpiryDate?: string
  cardHolderName?: string
  cardType?: CardType
  userID?: string
  user?: User
  UserBalanceDetails?: [UserBalanceDetail]
  AutoTopUpConfigs?: [AutoTopUpConfig]
}

export type Event = {
  id?: string
  billfoldEventId?: string
  registrationAllowed?: boolean
  startDate?: string
  finishDate?: string
  name?: string
  description?: string
  /**
   * @deprecated
   */
  imageUrl?: string
  imageStorageKey?: string
  category?: string
  facebookUrl?: string
  instagramUrl?: string
  twitterUrl?: string
  featured?: Boolean
  registrationTypes?: RegistrationType[]
  pinForPurchases?: PinForPurchasesType
  locations?: Location[]
  gallery?: GalleryItem[]
  wristbands?: Wristband[]
  categories?: EventCategory[]
  favorers?: User[]
  paymentAccountType?: string
  paymentAccountCurrency?: string
  platformAccountUuid?: string
  platformAccountBillfoldId?: string
  showToUsers?: boolean
  organizerID?: string
  allowedCardFundingTypes?: CardFundingType[]
  wristbandsFileUrl?: string
  checkWristbandNumberInFile?: boolean
  charityEnabled?: boolean
  charityID?: string
}

export type OptIn = {
  id?: string
  billfoldId?: string
  eventId?: string
  billfoldEventId?: string
  text?: string
  version?: number
  required?: boolean
  visible?: boolean
  fileUrl?: string
  createdAt?: string
  updatedAt?: string
}

export type Charity = {
  id?: string
  billfoldId?: string
  description?: string
  createdAt?: string
  updatedAt?: string
}

/**
 * This model is not exists on GraphQL schema
 * This is for local use only.
 */
export type Location = {
  id?: string
  eventID?: string
  latitude: number
  longitude: number
  address: string
  city: string
  country: string
  /**
   * @deprecated
   */
  mapImageUrl?: string
  mapImageStorageKey?: string
}

/**
 * TODO make the same as for locations
 * remove from the server side
 * but keep locally
 */
export type GalleryItem = {
  id?: string
  /**
   * @deprecated
   */
  imageUrl?: string
  imageStorageKey?: string
  /**
   * @deprecated
   */
  videoUrl?: string
  videoStorageKey?: string
  /**
   * @deprecated
   */
  videoThumbnailUrl?: string
  videoThumbnailStorageKey?: string
  eventID?: string
  position?: number
}

export type Invite = {
  id?: string
  name?: string
  email: string
  createdAt?: Date
  accepted: boolean
}

export type Story = {
  id?: string
  active?: boolean
  position?: number
  imageUrl?: string
  title?: string
  backgroundColor?: string
  textColor?: string
  storySlides?: [StorySlide]
  watchers?: [User]
}

export type StorySlide = {
  id?: string
  position?: number
  imageUrl?: string
  title?: string
  story?: Story
  storyID?: string
}

export type User = {
  id?: string
  authUserID?: string
  firstName?: string
  lastName?: string
  address?: string
  phoneNumber?: string
  avatarUrl?: string
  dateOfBirth?: string
  linkedPaymentMethodId?: string
  email?: string
  emailConfirmed?: Boolean
  paymentCustomerIds?: string
  fcmToken?: string
  wristbands?: [Wristband]
  paymentMethods?: [PaymentMethod]
  autoTopUpConfig?: String
  favoriteEvents?: [Event]
  favoriteEventIds?: string[]
  watchedStories?: [Story]
  userBalanceDetails?: [UserBalanceDetail]
  verifiedOver21?: boolean
  verifiedOver21Time?: string
  createdAt?: string
  updatedAt?: string
}

export type FavoriteEvent = {
  id?: string
  eventId: string
  userId: string
}

export type SystemSettings = {
  id?: string
  eventsLastSyncTime?: Date
  paymentTxsLastSyncTime?: string
  balanceTxsLastSyncTime?: string
  wristbandsLastSyncTime?: string
  maintenance: Boolean
  ageVerificationValidTime: number
}

export type SystemHealth = {
  id?: string
  lastCheckTime?: Date
  apiKeysValid?: boolean
  socialUrlsValid?: boolean
}

export type PaymentIntentProduct = {
  title?: string
  price?: number
}

export type PlatformAccount = {
  id?: string
  billfoldId?: string
  uuid?: string
  countryCode?: string
}

export type PaymentIntentInput = {
  stripeCustomerId?: string
  email?: string
  items?: [PaymentIntentProduct]
  currency?: string
  request3DSecure?: PaymentIntentMethodType
  paymentMethodTypes?: [string]
}

export type SetupnumberentInput = {
  stripeCustomerId?: string
}

export type GetTransactionsInput = {
  stripeCustomerId?: string
}

export type StripePaymentMethod = {
  id?: string
  object?: string
  billingDetails?: StripeBillingDetails
  card?: StripeCard
  created?: number
  customer?: string
  livemode?: boolean
  metadata?: Map<String, any>
  type?: string
}

export type StripeBillingDetails = {
  address?: StripeAddress
  email?: string
  name?: string
  phone?: string
}

export type StripeAddress = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postal_code: number
  state?: string
}

export type StripeCardSource = {
  object: 'card';
  number: string;
  exp_month: number;
  exp_year: number | string;
  cvc?: string;
  currency?: string; // Only for CUSTOM CONNECT
  name?: string;
  metadata?: Record<string, any>;
  default_for_currency?: boolean; // Only for CUSTOM CONNECT
  address_line1?: string;
  address_line2?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
  address_country?: string;
}

export type StripeCard = {
  id?: string
  object?: string
  address_city?: string
  address_country?: string
  address_line1?: string
  address_line1_check?: string
  address_line2?: string
  address_state?: string
  address_zip?: string
  address_zip_check?: string
  brand?: string
  country?: string
  customer?: string
  cvc_check?: string
  dynamic_last4?: any
  exp_month?: number
  exp_year?: number
  fingerprint?: string
  funding?: string
  last4?: string
  metadata?: any
  name?: string
  tokenization_method?: any
}

export type StripeCustomer = {
  id?: string
  address?: string
  balance?: number
  created?: number
  currency?: string
  default_source?: any
  delinquent?: boolean
  description?: string
  discount?: any
  email?: string
  invoice_prefix?: string
  invoice_settings?: any
  livemode?: boolean
  metadata?: Map<string, any>
  name?: string
  phone?: string
  preferred_locales?: any[]
  shipping?: any
  tax_exempt?: string
  testClock?: any
}

export type StripeListObject = {
  object?: string
  url?: string
  has_more?: boolean
  data?: any[]
}

export type Organizer = {
  id?: string
  billfoldId?: string
  name?: string
  integratorID?: string
  description?: string
  code?: string
  createdAt?: string
  updatedAt?: string
  discardedAt?: string
}

export type Integrator = {
  id?: string
  billfoldId?: string
  name?: string
  createdAt?: string
  updatedAt?: string
  discardedAt?: string
}

export type WristbandBatch = {
  id?: string
  code?: string
  amount?: number
  orderDate?: string
  supplier?: string
  organizerID?: string
}

export type RedirectRule = {
  id?: string
  fromEventId?: string
  fromBatchCode?: string
  toEventId?: string
  toOrganizerCode?: string
  createdAt?: string
  updatedAt?: string
}

export enum TransactionType {
  DEPOSIT,
  REFUND,
  PURCHASE
}

export enum CardType {
  VISA,

  MASTERCARD
}

export enum PaymentMethodType {
  BILLFOLD_BALANCE,

  CARD,
  CASH,
  PROMO_CASH
}

export enum PaymentIntentMethodType {
  any,
  automatic
}

export type ClearUserInfo = {
  over21: boolean
  phone: string
}

export type PinForPurchasesType = 'mandatory' | 'optional' | 'turned_off'

export type RegistrationType = 'wristband' | 'qr'

export type CardFundingType = 'debit' | 'credit'

export type BFPaymentsCard = {
  brand: string
  customerId: string
  last4: string
  paymentMethodId: string
}
