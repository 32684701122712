import axios from "axios";

const axiosInstance = axios.create({
  baseURL: 'https://noembed.com/'
})

interface NoEmbedResponse {
  html: string
  type: string
  height: number
  provider_url: string
  title: string
  thumbnail_url: string
  author_name: string
  author_url: string
  provider_name: string
  width: number
  thumbnail_width: number
  version: string
  thumbnail_height: number
  error: string
  url: string
}

export const getThumbnail = async (url: string): Promise<string> => {
  console.log(`getThumbnail`)
  try {
    const result = await get(`embed?url=${url}`)
    console.log(`getThumbnail - ${JSON.stringify(result, null, 2)}`)
    return result.error ? '' : result.thumbnail_url
  } catch (e) {
    console.log(`getThumbnail - error: ${e}`)
    return ''
  }
}

//
const get = async (endpoint: string, params?: any): Promise<NoEmbedResponse> => {
  const result = await axiosInstance.get(`${endpoint}`, {params})
  return result.data
}
