import React from "react";
import classes from './SwitchView.module.scss'

import {ReactComponent as SwitchOn} from "../../images/switch_on.svg"
import {ReactComponent as SwitchOff} from "../../images/switch_off.svg"
import Spinner from "./Spinner";

interface SwitchViewProps {
  title: string
  checked: boolean
  onClick: () => void
  progress?: boolean
}

export const SwitchView = (props: SwitchViewProps) => {
  const {onClick, title, checked, progress} = props
  return <div className={classes.SwitchView} onClick={onClick}>
    <div className={classes.CheckBoxTitle}>{title}</div>
    {progress
      ? <Spinner secondary/>
      : (checked
        ? <SwitchOn className={classes.CheckBoxIcon}/>
        : <SwitchOff className={classes.CheckBoxIcon}/>)}
  </div>
}
