import useSWR, {mutate} from 'swr'
import {getCards} from "../services/api/api";
import {Card} from "../services/api/models";
import {hasPlatformId} from "../util/card_util";

export default function useCards(platformAccountId?: string, paymentAccountType?: string) {
  const {data, error, isLoading} = useSWR('getCards', getCards)
  const resultCards = platformAccountId
    ? data?.filter(c => hasPlatformId(c, platformAccountId, paymentAccountType))
    : data

  return {
    cards: resultCards,
    cardsLoading: isLoading,
    cardsError: error
  }
}

export const refreshCards = async (cards?: Card[]) => {
  await mutate('getCards', cards)
}
