import React, {PropsWithChildren, useState} from 'react'
import classes from './UsersView.module.scss'

import {User} from "../../services/api/models";
import {showError, showSuccess} from "../../util/toast_util";
import {ModalProps} from "../common";
import {AppInput, Spacer} from "../common";
import {ConfirmModal, ConfirmModalProps} from "../common/ConfirmModal";
import { faFaceSadCry, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {formatDate} from "../../util/date_util";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import SpinnerSmall from "../common/SpinnerSmall";
import {SearchBox} from "../common/SearchBox";
import {isDesktop, isMobile} from "react-device-detect";
import {ViewHeader} from "../common/ViewHeader";
import {refreshUsers, useDeleteUserData, useUsers} from "../../hooks/useUsers";
import {isNotEmpty} from "../../util/array_util";
import {Checkbox} from "../common/Checkbox";

export const UsersView = () => {

  const [query, setQuery] = useState('')
  const {users} = useUsers(query)

  return <div className={classes.UsersView}>

    <ViewHeader title={'Users'} showBackButton={isMobile}/>

    <Spacer height={20}/>

    <div className={classes.Controls}>

      <SearchBox
        className={classes.SearchBox}
        placeholder={'Find user by full email'}
        onSearchChange={setQuery}/>

    </div>

    <Spacer height={10}/>

    {isNotEmpty(users) && <div className={classes.UsersTable}>

      <Row className={classes.Header}>
        <Col xs={3}>Email</Col>
        <Col xs={3}>First name</Col>
        <Col xs={3}>Last name</Col>
        <Col xs={2}>Date</Col>
        <Col xs={1} className={'d-flex justify-content-end'}></Col>
      </Row>


      {users?.map(u => <UserItem user={u}/>)}

    </div>}

    {query && users?.length === 0 && <div className={classes.PlaceholderContainer}>
      <FontAwesomeIcon icon={faFaceSadCry} className={classes.PlaceholderIcon}/>
      <div className={classes.PlaceholderTitle}>No users for "{query}"</div>
    </div>}

  </div>
}

interface UserItemProps {
  user: User
}

const UserItem: React.FC<UserItemProps> = ({user}) => {
  const {email, createdAt, firstName, lastName, phoneNumber, address, dateOfBirth} = user
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const {deleteUser, deletingUser} = useDeleteUserData()

  const onDeleteClick = async () => {
    setShowDeleteButton(false)
    setShowDeleteModal(true)
  }

  const onConfirmDeleteClick = async () => {
    setShowDeleteModal(false)

    try {
      await deleteUser({
        email: email ?? ''
      })
      showSuccess('User deleted')
      // todo refresh users?
      refreshUsers(email ?? '')
    } catch (e) {
      console.log(`onConfirmDeleteClick - error: `, e)
      showError(`Can not delete user: ${e}`)
    }
  }

  const onHover = () => {
    if (showDeleteModal) {
      return
    }
    setShowDeleteButton(true)
  }

  const onLeave = () => {
    setShowDeleteButton(false)
  }

  const onDeleteModalClose = () => {
    setShowDeleteModal(false)
  }

  const onMobileClick = () => {
    setShowDeleteModal(true)
  }

  const browserContent = () => {
    return <Row className={classes.UserItem} onMouseEnter={onHover} onMouseLeave={onLeave}>
      <Col xs={3}>{email}</Col>
      <Col xs={3}>{firstName}</Col>
      <Col xs={3}>{lastName}</Col>

      <Col xs={2}>{formatDate(createdAt, 'd MMM yyyy')}</Col>

      <Col xs={1} className={'d-flex justify-content-end'}>

        {deletingUser && <SpinnerSmall/>}

        {!deletingUser && showDeleteButton && <FontAwesomeIcon
          icon={faTrashAlt}
          onClick={onDeleteClick}
          className={classes.DeleteButton}/>}
      </Col>

    </Row>
  }

  const mobileContent = () => {
    return <Row className={classes.UserItem} onClick={onMobileClick}>
      <Col xs={9}>
        <div className={classes.MobileEmail}>{email}</div>
        <div className={classes.MobileDate}>{formatDate(createdAt, 'd MMM yyyy')}</div>
      </Col>

      <Col xs={3} className={'d-flex justify-content-center'}>
        <FontAwesomeIcon icon={faCheckCircle} className={classes.AcceptedIcon}/>
      </Col>

    </Row>
  }

  return <>
    {isDesktop ? browserContent() : mobileContent()}

    {showDeleteModal && <DeleteUserModal
      title={'Are you sure?'}
      onClose={onDeleteModalClose}
      opened={showDeleteModal}
      onConfirm={onConfirmDeleteClick}
      confirmTitle={'Delete'}
      confirmIcon={faTrashAlt}
      email={email ?? ''}
      dangerConfirm/>}
  </>

}

interface DeleteUserModalProps extends ConfirmModalProps, ModalProps, PropsWithChildren {
  email: string
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = (props) => {

  const [deleteInput, setDeleteInput] = useState('')
  const [cognitoUserDeleted, setCognitoUserDeleted] = useState(false)

  return <ConfirmModal
    {...props}
    title={'Are you sure?'}
    confirmTitle={'Delete'}
    confirmIcon={faTrashAlt}
    confirmDisabled={deleteInput !== 'Delete' || !cognitoUserDeleted}
    dangerConfirm>

    <div className={classes.DeleteModalText}>
      <div>Are you sure to delete all the data for user <span>{props.email}</span>?</div>
      <div>User and all of his wristbands and transactions will be deleted forever.</div>
      <div>Also you have to <a href={'https://billfoldpos.youtrack.cloud/articles/PRD-A-292/How-to-delete-user-from-Amplify'}>delete user</a> from Amplify user management.</div>

      <Spacer height={10}/>

      <div className={classes.Warning}>
        <FontAwesomeIcon icon={faTriangleExclamation}/> This action can not be undone.
      </div>

      <Spacer height={14}/>

      <AppInput placeholder={'Type "Delete" here'} onChange={setDeleteInput}/>

      <Spacer height={10}/>

      <Checkbox
        title={'I deleted user from Amplify'}
        checked={cognitoUserDeleted}
        onChange={setCognitoUserDeleted}
      />
    </div>
  </ConfirmModal>
}

