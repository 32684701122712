import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Main from './views/main/Main'
import './index.scss'
import AppProvider from "./views/common/AppProvider";
import {Toaster} from "react-hot-toast";
import LoginView from "./views/login/LoginView";
import {Amplify} from "aws-amplify";
import awsconfigStaging from "./aws-exports-staging";
import awsconfigProduction from "./aws-exports-prod";
import awsconfigQa from "./aws-exports-qa";
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from "@mui/x-date-pickers";
import PlaceholderView from "./views/common/PlaceholderView";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {intercomAppId, version} from "./config/constants";
import {isNotEmpty} from "./util/string_util";
import ResetPasswordView from "./views/login/ResetPasswordView";
import {IntercomProvider} from "react-use-intercom";
import {Suspense} from 'react';
import {ScrollToTop} from "./views/common/ScrollToTop";
import MaintenanceView from "./views/maintenance/MaintenanceView";

const bugsnagKey = process.env.REACT_APP_BUGSNAG_API_KEY ?? ''
if (isNotEmpty(bugsnagKey)) {
  Bugsnag.start({
    apiKey: bugsnagKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_ENV,
    appVersion: version
  })
}

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

const configureAmplify = () => {

  let config
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      config = awsconfigStaging
      break;
    case 'prod':
      config = awsconfigProduction
      break;
    case 'qa':
      config = awsconfigQa
      break;
    default:
      console.log(`configureAmplify - error: Env should be "dev" or "staging" or "qa"`)
      return;
  }

  const redirectHost = window.location.origin

  console.log(`configureAmplify - env:           ${process.env.REACT_APP_ENV}`)
  console.log(`configureAmplify - redirect host: ${redirectHost}`)

  Amplify.configure({
    ...config,
    oauth: {
      ...config.oauth,
      redirectSignIn: `${redirectHost}/auth/callback/`,
      redirectSignOut: `${redirectHost}/auth/`,
    }
  });

}

configureAmplify()

export default function App() {

  const env = process.env.REACT_APP_ENV ?? ''
  const unknownEnv = !['draft', 'staging', 'prod', 'qa'].includes(env)

  return <>
    {unknownEnv
      ? <PlaceholderView title={`Env has wrong value: ${env}`}/>
      : <Suspense>
        <ErrorBoundary>
          <IntercomProvider appId={intercomAppId}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <AppProvider>
                <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/maintenance" element={<MaintenanceView/>}/>
                    <Route path="/reset-password/*" element={<ResetPasswordView/>}/>
                    <Route path="/auth/*" element={<LoginView/>}/>
                    <Route path="/*" element={<Main/>}/>
                  </Routes>
                </BrowserRouter>
                <Toaster/>
              </AppProvider>
            </LocalizationProvider>
          </IntercomProvider>
        </ErrorBoundary>
      </Suspense>}
  </>
}
