import React, {useEffect, useState} from 'react'
import classes from './CheckWristbandPage.module.scss'
import {AppButton, Spacer} from "../common";
import WristbandImageNew from "../../images/wristband_serial_new.png";
import useUser from "../../hooks/useUser";
import {faCheck, faClose} from "@fortawesome/free-solid-svg-icons";
import {useWristbands} from "../../hooks/useWristbands";
import {useParams} from "react-router-dom";
import {isDesktop, isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {AppImage} from "../common/AppImage";

interface CheckWristbandPageProps {
  onHasWristbandClick: VoidFunction
  onHasNoWristbandClick: VoidFunction
}

export default function CheckWristbandPage(props: CheckWristbandPageProps) {

  const {eventId} = useParams() as any
  const {onHasWristbandClick, onHasNoWristbandClick} = props
  const {user} = useUser()
  const {wristbands} = useWristbands()
  const {t} = useTranslation()
  const [hasWristbandsForThisEvent, setHasWristbandsForThisEvent] = useState(false)

  useEffect(() => {
    if (wristbands) {
      setHasWristbandsForThisEvent(wristbands.some(w => w.eventID == eventId))
    }
  }, [wristbands])

  const yesButton = () => {
    return <AppButton
      title={t('yes_i_have_wristband')}
      icon={faCheck}
      className={classes.CheckButton}
      onClick={onHasWristbandClick}/>
  }

  const noButton = () => {
    return <AppButton
      title={t('no_i_dont')}
      icon={faClose}
      className={classes.CheckButton}
      onClick={onHasNoWristbandClick}
      secondary/>
  }

  return <div className={classes.CheckWristbandPage}>

    <div className={classes.TitleBig}>
      <p>{t('hello')}!</p>
      <p>{t('lets_check_you_in')}</p>
    </div>

    <Spacer height={30}/>

    <div className={classes.Title}>
      {t('do_you_have_wristband')}
    </div>

    <Spacer height={10}/>

    <div className={classes.ImageContainer}>
      <AppImage src={WristbandImageNew} className={classes.Image}/>
    </div>

    <Spacer height={30}/>

    {isMobile && <div className={classes.ButtonsWrapper}>

      {yesButton()}

      <Spacer height={10}/>

      {noButton()}

    </div>}

    {isDesktop && <div className={classes.ButtonsWrapper}>

      {noButton()}

      <Spacer width={10}/>

      {yesButton()}

    </div>}

  </div>
}
