import { PaymentMethodType, Card } from "../../services/api/models";
import React from "react";
import { ReactComponent as CashIcon } from "../../images/cash.svg";
import { ReactComponent as PromoCashIcon } from "../../images/promo_cash.svg";
import { ReactComponent as BillfoldIcon } from "../../images/billfold.svg";
import { ReactComponent as MastercardGreyIcon } from "../../images/mastercard_grey.svg";
import { ReactComponent as MastercardIcon } from "../../images/mastercard.svg";
import { ReactComponent as VisaGreyIcon } from "../../images/visa_grey.svg";
import { ReactComponent as VisaIcon } from "../../images/visa.svg";
import { ReactComponent as AmexIcon } from "../../images/cc_amex.svg";
import { ReactComponent as AmexGreyIcon } from "../../images/cc_amex_grey.svg";
import { ReactComponent as DiscoverIcon } from "../../images/cc_discover.svg";
import { ReactComponent as DiscoverGreyIcon } from "../../images/cc_discover_grey.svg";
import { ReactComponent as JcbIcon } from "../../images/cc_jcb.svg";
import { ReactComponent as JcbGreyIcon } from "../../images/cc_jcb_grey.svg";
import { ReactComponent as UnionPayIcon } from "../../images/cc_union_pay.svg";
import { ReactComponent as UnionPayGreyIcon } from "../../images/cc_union_pay_grey.svg";
import { ReactComponent as EloIcon } from "../../images/cc_elo.svg";
import { ReactComponent as HiperIcon } from "../../images/cc_hipercard.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import classes from "./PaymentMethodIcon.module.scss";

interface PaymentMethodIconProps {
  card?: Card
  paymentMethodType?: PaymentMethodType
  grey?: boolean
  log?: boolean
}

export const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = (props) => {

  const {card, paymentMethodType, grey} = props

  const cardIcon = () => {
    let Element

    switch (paymentMethodType) {
      case PaymentMethodType.CASH:
        Element = CashIcon
        break;
      case PaymentMethodType.PROMO_CASH:
        Element = PromoCashIcon
        break;
      case PaymentMethodType.BILLFOLD_BALANCE:
        Element = BillfoldIcon
        break;
    }

    const brand = card?.brand

    switch (brand) {
      case 'mc_applepay':
      case 'mc_googlepay':
      case 'mastercard':
        Element = grey ? MastercardGreyIcon : MastercardIcon
        break;
      case 'visa_applepay':
      case 'visa_googlepay':
      case 'visa':
        Element = grey ? VisaGreyIcon : VisaIcon
        break;
      case 'americanexpress':
      case 'amex':
        Element = grey ? AmexGreyIcon : AmexIcon
        break;
      case 'discover':
        Element = grey ? DiscoverGreyIcon : DiscoverIcon
        break;
      case 'jsb':
      case 'jcb':
        Element = grey ? JcbGreyIcon : JcbIcon
        break;
      case 'unionpay':
        Element = grey ? UnionPayGreyIcon : UnionPayIcon
        break;
      case 'elo':
        Element = EloIcon
        break;
      case 'hiper':
        Element = HiperIcon
        break;
      case 'dinersclub':
      case 'diners':
      case 'unknown':
        return <FontAwesomeIcon icon={faCreditCard} className={classes.PaymentMethodIconOther}/>

    }

    if (Element) {
      return <Element
        className={brand == 'mastercard'
          ? classes.PaymentMethodIconMastercard
          : classes.PaymentMethodIconVisa}/>
    }

    return <div/>
  }

  return <div>
    {cardIcon()}
  </div>
}

