import classes from './FavoriteButton.module.scss'
import { Event } from '../../services/api/models'
import {
  refreshFavoriteEvents,
  useAddToFavorite,
  useRemoveFromFavorite
} from "../../hooks/useFavoriteEvents"
import React, { useEffect, useState } from "react"
import { showError } from "../../util/toast_util"
import Spinner from "./Spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons"
import classnames from "classnames"
import useUser, {refreshUser} from "../../hooks/useUser";

interface FavoriteButtonProps {
  event: Event
  small?: boolean
  black?: boolean
}

export default function FavoriteButton(props: FavoriteButtonProps) {
  const {event, small, black} = props

  const {user} = useUser()
  const {addToFavorite} = useAddToFavorite()
  const {removeFromFavorite} = useRemoveFromFavorite()
  const [favorite, setFavorite] = useState<boolean>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (event) {
      setFavorite(user?.favoriteEventIds?.includes(event.id ?? '') ?? false)
    }
  }, [event, user])

  const onFavoriteClick = async (e: any) => {

    e.preventDefault()

    if (loading || !event) {
      return
    }

    try {
      setLoading(true)
      const eventId = event.id ?? ''
      if (favorite) {
        if (user && user.favoriteEventIds?.includes(eventId)) {
          user.favoriteEventIds = (user.favoriteEventIds ?? []).filter(id => id != eventId)
        }
        await removeFromFavorite(event)
      } else {
        if (user && !user.favoriteEventIds?.includes(eventId)) {
          user.favoriteEventIds = [...user.favoriteEventIds ?? [], eventId]
        }
        await addToFavorite(event)
      }
      // don't wait for it to make things faster.
      refreshFavoriteEvents()
      await refreshUser(user)
    } catch (e: any) {
      console.log(`onFavoriteClick - error: ${e}`)
      showError('Can not add event to favorite')
    } finally {
      setLoading(false)
    }
  }


  return <div>
    {favorite != undefined && <div
      className={classnames(
        black ? classes.FavoriteButtonBlack : classes.FavoriteButton,
        {[classes.Red]: favorite},
        {[classes.FavoriteButtonSmall]: small}
      )}
      onClick={onFavoriteClick}>
      {loading ? <Spinner accent/> :
        <FontAwesomeIcon
          icon={favorite ? faHeart : faHeartOutline}
          className={classes.FavoriteButtonIcon}/>}
    </div>}
  </div>
}
