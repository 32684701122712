import React, { useEffect, useState } from 'react'
import classes from './SystemSettingsView.module.scss'
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ViewHeader } from "../common/ViewHeader";
import { isMobile } from "react-device-detect";
import useSystemSettings, {
  refreshSystemSettings,
  useUpdateSystemSettings
} from "../../hooks/useSystemSettings";
import { AppButton, AppInput, InputType, Spacer } from "../common";
import { formatDate } from "../../util/date_util";
import { showSuccess } from "../../util/toast_util";

interface SystemSettingsViewProps {
}

export const SystemSettingsView: React.FC<SystemSettingsViewProps> = (props) => {
  const {updateSystemSettings, updatingSystemSettings} = useUpdateSystemSettings()
  const {settings} = useSystemSettings()
  const {
    ageVerificationValidTime,
    balanceTxsLastSyncTime,
    eventsLastSyncTime,
    paymentTxsLastSyncTime,
    wristbandsLastSyncTime,
    maintenance
  } = settings ?? {}

  const [ageVerificationValidTimeLocal, setAgeVerificationValidTimeLocal] = useState<number>()

  useEffect(() => {
    if (ageVerificationValidTime != undefined) {
      setAgeVerificationValidTimeLocal(ageVerificationValidTime)
    }
  }, [ageVerificationValidTime])

  const onSaveClick = async () => {
    try {
      const updatedSettings = await updateSystemSettings({
        settings: {
          ...settings,
          ageVerificationValidTime: ageVerificationValidTimeLocal
        }
      })
      showSuccess('Settings updated successfully')
      if (updatedSettings) {
        refreshSystemSettings(updatedSettings)
      }
    } catch (e) {
      console.error(e)
    }
  }


  return <div className={classes.SystemSettingsView}>

    <ViewHeader title={'System settings'} showBackButton={isMobile}/>

    <AppInput
      placeholder={'Age verification valid time, mins'}
      initialValue={ageVerificationValidTime?.toString()}
      type={InputType.number}
      onChange={v => setAgeVerificationValidTimeLocal(parseInt(v))}/>

    <Spacer height={10}/>

    <AppInput
      placeholder={'Events last sync time'}
      initialValue={formatDate(eventsLastSyncTime, 'dd MMM yyyy, HH:mm:ss')}
      disabled/>

    <Spacer height={10}/>

    <AppInput
      placeholder={'Wristbands last sync time'}
      initialValue={formatDate(wristbandsLastSyncTime, 'dd MMM yyyy, HH:mm:ss')}
      disabled/>

    <Spacer height={10}/>

    <AppInput
      placeholder={'Balance transactions last sync time'}
      initialValue={formatDate(balanceTxsLastSyncTime, 'dd MMM yyyy, HH:mm:ss')}
      disabled/>

    <Spacer height={10}/>

    <AppInput
      placeholder={'Payment transactions last sync time'}
      initialValue={formatDate(paymentTxsLastSyncTime, 'dd MMM yyyy, HH:mm:ss')}
      disabled/>

    <Spacer height={10}/>

    <AppInput
      placeholder={'Maintenance'}
      initialValue={maintenance ? 'ON' : 'OFF'}
      disabled/>

    <Spacer height={10}/>

    {ageVerificationValidTimeLocal != ageVerificationValidTime && <AppButton
      title={'Save'}
      icon={faCheck}
      onClick={onSaveClick}
      progress={updatingSystemSettings}/>}

  </div>
}

