import React, {useEffect, useMemo, useState} from 'react'
import classes from './EventsView.module.scss'
import useEvents from "../../hooks/useEvents";
import {showError} from "../../util/toast_util";
import EventItem from "../common/EventItem";
import {useFavoriteEvents} from "../../hooks/useFavoriteEvents";
import PlaceholderView from "../common/PlaceholderView";
import {isDesktop, isMobile} from "react-device-detect";
import {ReactComponent as Logo} from '../../images/logo.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSearch, faChevronDown, faClose} from "@fortawesome/free-solid-svg-icons";
import {AppButton, ModalProps, Spacer} from "../common";
import {SearchBox} from "../common/SearchBox";
import {useSearchParams} from "react-router-dom";
import {paramsToObject, setValue} from "../../util/search_params_util";
import {formatDate, sameDay} from "../../util/date_util";
import {useTranslation} from "react-i18next";
import Modal from "../common/Modal";
import useUser from "../../hooks/useUser";
import {countriesFromEvents, locationsFromEvents, notFinishedYet} from "../../util/event_util";
import {Event, Location} from "../../services/api/models";
import {
  clearFilterLocation,
  getFilterCountry,
  getFilterLocation,
  saveFilterCountry,
  saveFilterLocation
} from "../../services/storage";
import {deduplicateLocationsByCity} from "../../util/location_util";
import {getCountryCode} from "../../services/api/geolocation_api";
import {lowerCaseEqual} from "../../util/string_util";
import {faFaceSadTear} from "@fortawesome/free-regular-svg-icons";
import {allCountries} from "../../config/countries";

interface EventsViewProps {
  isFavorite?: boolean
}

export default function EventsView(props: EventsViewProps) {

  const {t} = useTranslation()
  const {user, isClientAdmin, isBillfoldAdmin} = useUser()
  const paramsFromParams = () => {
    return {
      search: search,
      dateFrom: !dateFrom ? undefined : dateFrom,
      dateTo: !dateTo ? undefined : dateTo
    }
  }
  const [params, setParams] = useSearchParams()
  const [showLocationModal, setShowLocationModal] = useState(false)
  const {dateFrom, dateTo, search} = paramsToObject(params) as {
    dateFrom?: string,
    dateTo?: string,
    search?: string
  }
  const selectedLocation = getFilterLocation()
  const selectedCountry = getFilterCountry()
  const {isFavorite} = props
  const {
    events: regularEvents,
    error: regularError,
    eventsLoading: regularLoading
  } = useEvents(paramsFromParams())
  const {
    favoriteEvents,
    favoriteEventsError,
    favoriteEventsLoading
  } = useFavoriteEvents()

  const [showSearchBar, setShowSearchBar] = useState(false)

  const events = useMemo(
    () => (isFavorite ? favoriteEvents : regularEvents)?.filter((e) => {
      const fitCity = !selectedLocation || (e.locations ?? []).some(l => lowerCaseEqual(l.city, selectedLocation?.city) && lowerCaseEqual(l.country, selectedLocation?.country))
      const fitCountry = !selectedCountry || (e.locations ?? []).some(l => lowerCaseEqual(l.country, selectedCountry))
      const fitLocation = selectedLocation ? fitCity : fitCountry
      const isAdmin = isClientAdmin || isBillfoldAdmin
      const isVisible = e.showToUsers
      const isOpen = notFinishedYet(e)

      return fitLocation && (isAdmin || (isVisible && isOpen))
    }),
    [regularEvents, favoriteEvents]
  )

  useEffect(() => {

    if (!user || isClientAdmin || isBillfoldAdmin || !events || selectedCountry != undefined) {
      return
    }

    const countries = [...new Set(events.flatMap(e => e.locations ?? []).map(l => l.country))]
    getCountryCode()
      .then(code => saveFilterCountry(countries.includes(code) ? code : 'US'))
      .catch((e) => {
        console.log(`useEffect - get country code error - ${e}`)
      })

  }, [events, user]);

  const error = useMemo(
    () => isFavorite ? favoriteEventsError : regularError,
    [regularError, favoriteEventsError]
  )

  const isLoading = useMemo(
    () => isFavorite ? favoriteEventsLoading : regularLoading,
    [regularLoading, favoriteEventsLoading]
  )

  useEffect(() => {
    if (error && error.length) {
      showError(error)
    }
  }, [error])

  useEffect(() => {
    if ((search && search.length) || dateFrom || dateTo) {
      setShowSearchBar(true)
    }
  }, [dateFrom, dateTo, search])

  // todo fix it
  const onDatesChanged = (from: Date | undefined, to: Date | undefined) => {

    const fromDifferent = (!from && dateFrom) || (from && !dateFrom) || (from && dateFrom && !sameDay(from, new Date(dateFrom)))
    const toDifferent = (!to && dateTo) || (to && !dateTo) || (to && dateTo && !sameDay(to, new Date(dateTo)))

    if (fromDifferent || toDifferent) {
      let newParams = new URLSearchParams(params.toString());
      newParams = setValue(newParams, 'dateTo', formatDate(to))
      newParams = setValue(newParams, 'dateFrom', formatDate(from))
      setParams(newParams)
    }
  }

  const onSearchChange = (mySearch: string) => {
    if (mySearch != search) {
      let newParams = new URLSearchParams(params.toString());
      newParams = setValue(newParams, 'search', mySearch)
      setParams(newParams)
    }
  }

  const onSearchClose = () => {
    setShowSearchBar(false)
    setParams(new URLSearchParams())
  }

  const locationIcon = () => {
    return <div className={classes.LocationController} onClick={() => setShowLocationModal(true)}>
      <div className={classes.LocationTitle}>{selectedLocation?.city ?? (selectedCountry || t('all_cities'))}</div>
      <Spacer width={4}/>
      <FontAwesomeIcon icon={faChevronDown} className={classes.LocationIcon}/>
    </div>
  }

  const controls = () => {
    return <div className={classes.Controls}>

      <div className={classes.TopContent}>
        {showSearchBar && <div className={classes.SearchBoxWrapper}>
          <SearchBox
            initialValue={search}
            onSearchChange={onSearchChange}
            onCloseClick={onSearchClose}
            placeholder={t('search_by_name_or_description')}/>
        </div>}

        {!showSearchBar && <>
          <Logo className={classes.Logo}/>

          <Spacer/>

          {locationIcon()}

          <div className={classes.IconButton} onClick={() => setShowSearchBar(!showSearchBar)}>
            <FontAwesomeIcon icon={faSearch}/>
          </div>

        </>}

        {/*<div className={classes.IconButton} onClick={() => setShowFilters(!showFilters)}>
          <FontAwesomeIcon icon={faFilter}/>
        </div>*/}

      </div>

      {/*{showSearchBar && <div className={classes.Filters}>
        <DateControl
          dateFrom={dateFrom ? new Date(dateFrom) : undefined}
          dateTo={dateTo ? new Date(dateTo) : undefined}
          onDatesChanged={onDatesChanged}/>
      </div>}*/}

      {!showSearchBar && <Spacer height={16}/>}

    </div>
  }

  return <div className={classes.EventsView}>

    {isMobile && controls()}

    {isDesktop && <div>
      <div className={classes.DesktopControls}>

        <div className={classes.SearchBoxWrapper}>
          <SearchBox
            initialValue={search}
            onSearchChange={onSearchChange}
            placeholder={t('search_by_name_or_description')}/>
        </div>

        {<div className={classes.LocationControl}>
          {locationIcon()}
        </div>}

      </div>

      <Spacer height={10}/>

    </div>}

    {events && !events.length && !isLoading && <PlaceholderView
      title={t('there_are_no_events')}
      icon={faFaceSadTear}/>}

    {!events && isLoading && <div className={classes.EventsGrid}>
      {[...Array(6)].map((i) => <EventItem shimmer key={i}/>)}
    </div>}

    {events && !!events.length && <div className={classes.EventsGrid}>
      {events?.map((e) => <EventItem
        key={e.id}
        event={e}
      />)}
    </div>}

    <Spacer height={100}/>

    <LocationFilterModal
      events={regularEvents ?? []}
      opened={showLocationModal}
      onClose={() => setShowLocationModal(false)}/>

  </div>
}

interface LocationFilterModalProps {
  events: Event[]
}

const LocationFilterModal = (props: ModalProps & LocationFilterModalProps) => {

  const {t} = useTranslation()
  const {events} = props
  const selectedLocation = getFilterLocation()
  const selectedCountry = getFilterCountry()
  const {isClientAdmin, isBillfoldAdmin} = useUser()
  const [visibleEvents, setVisibleEvents] = useState<Event[]>(events)

  useEffect(() => {
    setVisibleEvents(events?.filter((e) => {
      const isAdmin = isClientAdmin || isBillfoldAdmin
      const isVisible = e.showToUsers
      const isOpen = notFinishedYet(e)
      return isAdmin || (isVisible && isOpen)
    }))
  }, [events])

  const locationItem = (location?: Location) => {

    if (!location) {
      return <div/>
    }

    const selected = location.city == selectedLocation?.city && location.country == selectedLocation?.country

    return <div className={selected ? classes.ItemSelected : classes.Item} onClick={() => {
      saveFilterLocation(location)
      props.onClose()
    }}>

      <div className={classes.ItemTitle}>{location.city}<span className={classes.Grey}>, {location.country}</span></div>

      <Spacer/>

      {selected && <FontAwesomeIcon
        icon={faCheckCircle}
        className={classes.Icon}/>}

    </div>
  }

  const countryItem = (country: string) => {
    const selected = !selectedLocation && lowerCaseEqual(country, selectedCountry ?? '')

    return <div className={selected ? classes.ItemSelected : classes.Item} onClick={() => {
      saveFilterCountry(country)
      clearFilterLocation()
      props.onClose()
    }}>

      <div className={classes.ItemTitle}>{allCountries[country]}</div>

      <Spacer/>

      {selected && <FontAwesomeIcon
        icon={faCheckCircle}
        className={classes.Icon}/>}

    </div>
  }

  const onClearLocationClick = () => {
    saveFilterCountry('')
    clearFilterLocation()
    props.onClose()
  }

  return <Modal
    {...props}
    title={t('select_city')}>

    <div className={classes.LocationsList}>

      {countriesFromEvents(visibleEvents).map(countryItem)}

      {locationsFromEvents(visibleEvents).map(locationItem)}

    </div>

    {(selectedLocation || selectedCountry) && <>

      <Spacer height={10}/>

      <AppButton
        title={t('clear_location')}
        icon={faClose}
        className={classes.ClearButton}
        onClick={onClearLocationClick}
        transparent/>
    </>}

  </Modal>
}
