import React, {useEffect, useState} from 'react'
import classes from "./AppImage.module.scss";
import classnames from "classnames";
import 'tippy.js/dist/tippy.css';
import {Storage} from "aws-amplify";
import {DateTime} from "luxon";

interface AppImageProps {
  src?: string
  className?: string
  storageKey?: string
  alt?: string
  onClick?: VoidFunction
}

export const AppImage: React.FC<AppImageProps> = (props) => {
  const {src, className, storageKey, alt, onClick} = props

  const [error, setError] = useState(false)
  const [signedUrl, setSignedUrl] = useState('')

  useEffect(() => {
    refreshUrl()
  }, [])

  useEffect(() => {
    refreshUrl()
  }, [storageKey])

  const refreshUrl = async () => {
    if (storageKey) {
      try {
        const signedUrl = await Storage.get(storageKey, { level: 'public' })
        setSignedUrl(signedUrl)
        setError(false)
      } catch (e) {
        console.log(`refreshUrl - error: ${e}`)
        setError(true)
      }
    }
  }

  return <div className={classnames(classes.AppImage, className)} onClick={onClick}>

    {!error && <>
      {src && <img src={src} alt={alt} className={className} onError={e => setError(true)}/>}
      {(signedUrl && !src) && <img src={signedUrl} alt={alt} className={className} onError={e => setError(true)}/>}
    </>}

  </div>
}
