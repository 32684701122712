import { User } from "../services/api/models";

export type Theme = 'light' | 'dark'
export type Locale = 'en' | 'es' | 'pt'

export interface Action {
  type: string
  value: any
}

export interface AppState {
  theme: Theme
  locale: Locale
  user?: User
  search: string
}

export interface AppActions {
  setTheme: (v: Theme) => void
  setLocale: (v: Locale) => void
  setUser: (v: User) => void
  setSearch: (v: string) => void
}

export type AppContext = AppState & AppActions

export const initialState: AppState = {
  theme: 'light',
  locale: 'en',
  search: '',
}

export const initialActions: AppActions = {
  setTheme: (_) => {
  },
  setLocale: (_) => {
  },
  setUser: (_) => {
  },
  setSearch: (_) => {
  }
}

export const initialContext: AppContext = {
  ...initialState,
  ...initialActions
}

export const actions = {
  SET_THEME: 'SET_THEME',
  SET_LOCALE: 'SET_LOCALE',
  SET_USER: 'SET_USER',
  SET_SEARCH: 'SET_SEARCH',
}

export const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case actions.SET_THEME:
      return {...state, theme: action.value}
    case actions.SET_LOCALE:
      return {...state, locale: action.value}
    case actions.SET_USER:
      return {...state, user: action.value}
    case actions.SET_SEARCH:
      return {...state, search: action.value}
    default:
      return state
  }
}

