import React from 'react'
import classes from './AccountPlaceholderView.module.scss'
import { AddButton, Spacer } from "../common";
import { Link } from "react-router-dom";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AccountPlaceholderViewProps {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  faIcon?: IconDefinition
  title?: string
  buttonTitle?: string
  onClick?: VoidFunction
  linkTo?: string
}

export const AccountPlaceholderView: React.FC<AccountPlaceholderViewProps> = (props) => {
  const {icon, faIcon, buttonTitle, onClick, linkTo, title} = props
  const Icon = icon

  return <div className={classes.AccountPlaceholderView}>
    <Spacer height={10}/>

    {Icon && <Icon className={classes.PlaceholderIcon}/>}

    {faIcon && <FontAwesomeIcon
      icon={faIcon}
      className={classes.PlaceholderFaIcon}/>}

    <Spacer height={5}/>

    {title && <div className={classes.PlaceholderTitle}>{title}</div>}

    {linkTo && <div style={{opacity: 0.6}}>
      <Link to={'/'}>
        <AddButton title={buttonTitle} gray/>
      </Link>
    </div>}

    {onClick && <div style={{opacity: 0.6}}>
      <AddButton title={buttonTitle} onClick={onClick} gray/>
    </div>}
  </div>
}
