import {getIntegrators} from '../services/api/api'
import useSWR, {mutate} from 'swr'

export const useIntegrators = () => {
  const {data, error, isLoading} = useSWR('getIntegrators', getIntegrators)
  return {
    integrators: data,
    integratorsLoading: isLoading,
    integratorsError: error
  }
}

export const refreshIntegrators = async () => {
  await mutate('getIntegrators')
}
