import React, { PropsWithChildren, useEffect, useState } from 'react'
import Modal from "./Modal";
import { DatePicker } from "./DatePicker";

interface DatePickerModalProps {
  opened: boolean
  onClose: () => void
  initialDate: Date
  onDateChange: (date: any) => void
  allowFuture?: boolean
}

export const DatePickerModal: React.FC<DatePickerModalProps & PropsWithChildren> = (props) => {

  const {opened, onClose, initialDate, onDateChange, allowFuture} = props
  const [selectedDate, setSelectedDate] = useState(new Date())

  useEffect(() => {
    setSelectedDate(initialDate)
  }, [])

  const onDateChangeLocal = (date: Date | null) => {
    if (date) {
      setSelectedDate(new Date(date))
      onDateChange(new Date(date))
    }
  }

  return <Modal
    opened={opened}
    onClose={onClose}>
    <div style={{marginTop: 60}}>
      <DatePicker
        onChange={onDateChangeLocal}
        date={selectedDate}
        allowFuture={allowFuture}/>
    </div>
  </Modal>
}
