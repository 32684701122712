import React from 'react'
import classes from './RoadmapView.module.scss'
import {Spacer} from "../common";
import {ViewHeader} from "../common/ViewHeader";
import classnames from "classnames";

export const RoadmapView = () => {

  const line = (percent: number) => {
    return <div className={classes.Line}>
      <div className={percent == 100 ? classes.ProgressSuccess : classes.Progress} style={{width: `${percent}%`}}/>
    </div>
  }

  const item = (title: string, info: string, percent: number, taskNumber?: string) => {
    const success = percent == 100;
    const empty = percent == 0;
    return <a href={`https://billfoldpos.youtrack.cloud/issue/${taskNumber}`} target={'_blank'}>
      <div className={success ? classes.ItemSuccess : classes.Item}>
        <div className={classes.TopInfo}>
          <div className={classes.Title}>{title}</div>

          <div className={classnames({
            [classes.Percent]: true,
            [classes.PercentSuccess]: success,
            [classes.PercentEmpty]: empty,
          })}>
            {percent}%
          </div>
        </div>

        <Spacer height={10}/>
        <div className={classes.Info}>{info}</div>
        {/*<div className={classes.Info}>Helllo</div>*/}

        <Spacer/>

        {line(percent)}

      </div>
    </a>

  }

  return <div className={classes.RoadmapView}>

    <ViewHeader title={'Q3'}/>

    <Spacer height={20}/>

    <div className={classes.ItemsGrid}>

      {item('Billfold Give for Wallet', '', 100, 'DEV-1783')}

      {item('Improve UX & Stability', 'Fixed sync functions', 100, 'PRD-669')}

      {item('QA test environment', 'Almost done, waiting for token', 80, 'DEV-1460')}

      {item('Adyen Integration - additional things', 'Doing Samsung Pay and stuck 🧱', 70, 'DEV-2444')}

      {item('CPF authorization for Brazil', 'Made research', 20, 'PRD-647')}

      {item('QR Payments', 'Discussed everything, waiting for eng review', 10, 'DEV-2482')}

      {item('Admin user groups', '', 0, 'PRD-644')}

      {item('Update design of email messages', '', 0, 'PRD-667')}

      {item('Orders and Payments History', '', 0, 'DEV-828')}

    </div>

    <ViewHeader title={'Q2'}/>

    <Spacer height={20}/>

    <div className={classes.ItemsGrid}>

      {item('QR code activation', '', 100, 'DEV-2138')}

      {item('Adyen integration', '', 100, 'DEV-2300')}

      {item('Validation wristband numbers on the fly', '', 100, 'DEV-1151')}

      {item('Filtering events by region', '', 100, 'DEV-2392')}

      {item('Tool for configuring and management links', '', 100, 'DEV-2279')}

    </div>

  </div>
}
