import React, {useEffect, useRef, useState} from 'react'
import classes from "./AddCardModal.module.scss";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import {loadStripe, Stripe, StripeElements} from "@stripe/stripe-js";
import Spinner from "./Spinner";
import useUser from "../../hooks/useUser";
import {AppButton} from "./AppButton";
import {showError, showSuccess} from "../../util/toast_util";
import useCards, {refreshCards} from "../../hooks/useCards";
import {Event, Card, User} from "../../services/api/models";
import {useAddCard} from "../../hooks/useCard";
import classnames from "classnames";
import {faCheck, faClose, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons";
import {isMobile} from "react-device-detect";
import {Spacer} from "./Spacer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {
  adyenGetCards,
  adyenMakeRefund,
  createCard,
  getCards,
  getCurrentUserOrNull,
  getSetupIntentSecret,
  updateCard
} from "../../services/api/api";
import {SelectEventModal} from "../checkin/SelectEventModal";
import {getPaymentCustomerId} from "../../util/user_util";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {CbObjOnBinLookup} from "@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types";
import {delay} from "../../util/promise_util";
import {useAdyenAddCard} from "../../hooks/useAdyenAddCard";
import {useAppContext} from "./AppProvider";
import Bugsnag from "@bugsnag/js";
import {
  CardTypeNotSupportedError,
  CreditCardNotSupportedError,
  DebitCardNotSupportedError
} from "../../services/api/errors";
import {ReactComponent as ApplePayLogo} from "../../images/apple_pay_logo.svg";
import {getPaymentMethodId, normalizeBrand} from "../../util/card_util";
import {parse, stringify} from "../../util/object_util";
import {ApplePayButton} from "./ApplePayButton";
import {applePayAvailable} from "../../util/apple_pay_util";
import {GooglePayButton} from "./GooglePayButton";
import {googlePayAvailable} from "../../util/google_pay_util";
import {SamsungPayButton} from "./SamsungPayButton";

interface AddCardFormProps {
  buttonTitle?: string
  showCancelButton?: boolean
  shortButton?: boolean
  onCancelClick?: VoidFunction
  buttonsBottomFixed?: boolean
  event?: Event
}

export const AddCardForm: React.FC<AddCardFormProps & AddCardButtonProps> = (props) => {

  const {onCardAdded, shortButton, buttonTitle, showCancelButton, onCancelClick, buttonsBottomFixed, event} = props
  const {user} = useUser()
  const {adyenAddCard, adyenAddingCard} = useAdyenAddCard()
  const {t} = useTranslation()
  const [cardholderName, setCardholderName] = useState('')
  const [cardholderNameError, setCardholderNameError] = useState(false)
  const [formReady, setFormReady] = useState(false)
  const [secret, setSecret] = useState<string>()
  const [loadingSecret, setLoadingSecret] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [showEventsList, setShowEventsList] = useState(false)
  const [paymentCustomerId, setPaymentCustomerId] = useState('')
  const [platformAccountId, setPlatformAccountId] = useState('')
  const [stripe, setStripe] = useState<Stripe | null>()
  const [cardTypeAddError, setCardTypeAddError] = useState('')
  const [isAdyen, setIsAdyen] = useState(false)
  const cardFormRef = useRef<HTMLDivElement>(null)
  const [adyenFormValid, setAdyenFormValid] = useState(false)
  const [adyenPaymentMethod, setAdyenPaymentMethod] = useState<any>()
  const {cards} = useCards()
  const {locale} = useAppContext()
  const [showApplePayWarning, setShowApplePayWarning] = useState(false)
  const [handlingPlatformPayment, setHandlingPlatformPayment] = useState(false)
  const [googlePayCompleting, setGooglePayCompleting] = useState(false)

  useEffect(() => {
    if (event) {
      setSelectedEvent(event)
    }
  }, [event]);

  useEffect(() => {
    if (user && selectedEvent && !secret) {
      initPaymentProcessor(selectedEvent, user)
    }
  }, [user, selectedEvent])

  const initPaymentProcessor = async (selectedEvent: Event, user: User) => {
    const paymentCustomerId = getPaymentCustomerId(user, selectedEvent.platformAccountBillfoldId ?? '') ?? ''
    setPaymentCustomerId(paymentCustomerId)

    const platformAccountId = selectedEvent.platformAccountBillfoldId ?? ''
    setPlatformAccountId(platformAccountId)

    const isAdyen = selectedEvent?.paymentAccountType == 'custom'

    setIsAdyen(isAdyen)

    if (isAdyen) {
      initAdyen()
    } else {
      loadStripe(process.env[`REACT_APP_STRIPE_PUBLISHABLE_KEY_${platformAccountId}`] ?? '')
        .then(setStripe)
        .catch((e: any) => showError(e.toString()))
      initSecret(platformAccountId, paymentCustomerId)
    }
  }

  const onCardholderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[0-9]/g, '');
    setCardholderName(filteredValue.toUpperCase())
    setCardholderNameError(false)
  }

  const onAddCardClick = () => {
    if (cardholderName.length == 0) {
      setCardholderNameError(true)
    }
  }

  const initSecret = async (platformAccountBillfoldId: string, paymentCustomerId: string) => {
    console.log(`initSecret`)
    try {
      setLoadingSecret(true)
      const secret = await getSetupIntentSecret(platformAccountBillfoldId, paymentCustomerId)
      console.log(`initSecret - secret: ${secret}`)
      setSecret(secret)
    } catch (e: any) {
      console.log(`initSecret - error: `, e)
      showError(e.toString())
    } finally {
      setLoadingSecret(false)
    }
  }

  const onSelectedEvent = (event: Event) => {
    setSelectedEvent(event)
  }

  const initAdyen = async () => {

    await delay(1000)

    let adyenLocale
    switch (locale) {
      case 'es':
        adyenLocale = 'es_ES'
        break
      case 'pt':
        adyenLocale = 'pt_BR'
        break
      case 'en':
      default:
        adyenLocale = 'en_US'
        break
    }

    const checkout = await AdyenCheckout({
      locale: adyenLocale,
      environment: process.env.REACT_APP_ENV == 'staging' ? 'test' : 'live',
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
    })

    const cardFormDiv = cardFormRef.current

    if (cardFormDiv) {
      const customCard = checkout.create('card', {
        type: 'card',
        hasHolderName: true,
        holderNameRequired: true,
        brands: ['mc', 'visa', 'amex', 'elo', 'electron', 'hipercard'],
        onChange: (state: any) => {
          console.log(`callback - onChange, state: `, state)
          if (state.isValid != adyenFormValid) {
            setAdyenFormValid(state.isValid)
          }

          if (state.isValid) {
            setAdyenPaymentMethod(state.data.paymentMethod)
          } else {
            setAdyenPaymentMethod(undefined)
          }
        },
        onValid: (v) => {
          console.log(`callback - onValid: `, v)
        },
        onLoad: () => {
          console.log(`callback - onLoad`)
        },
        onConfigSuccess: () => {
          console.log(`callback - onConfigSuccess`)
        },
        onFieldValid: () => {
          console.log(`callback - onFieldValid`)
        },
        onBrand: (brand) => {
          console.log(`callback - onBrand`)
        },
        onError: (e) => {
          showError(e.toString())
          console.log(`callback - onError`)
        },
        onFocus: () => {
          console.log(`callback - onFocus`)
        },
        onBinValue: function (bin: any) {
          console.log(`callback - onBinValue - bin: ${bin}`)
        },
        onBinLookup: function (callbackObj: CbObjOnBinLookup) {
          console.log(`callback - onBinLookup - callbackObj: `, callbackObj)
        },

      }).mount(cardFormDiv);

    }

  }

  const onAddAdyenCardClick = async () => {

    if (!adyenFormValid) {
      return
    }

    const {
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
      holderName
    } = adyenPaymentMethod

    try {

      const newCard = await adyenAddCard({
        encryptedCardNumber,
        encryptedExpiryMonth,
        encryptedExpiryYear,
        encryptedSecurityCode,
        holderName,
        paymentAccountType: selectedEvent?.paymentAccountType ?? '',
        platformAccountId: selectedEvent?.platformAccountBillfoldId ?? '',
        customerId: user?.id ?? '',
      })

      if (newCard) {
        refreshCards([...(cards ?? []), newCard])
        onCardAdded(newCard)
        showSuccess(t('card_added'))
      } else {
        showError(t('can_not_add_card_try_again'))
      }
    } catch (e: any) {
      console.log(`onAddCardClick - error: `, e)
      showError(e.toString())
    }

  }

  const refreshPaymentProcessor = async () => {
    if (!user || !selectedEvent) {
      showError('Close the dialog and try again')
      return
    }

    setLoadingSecret(true)
    setSecret('')
    setCardTypeAddError('')
    await delay(1000)
    await initPaymentProcessor(selectedEvent, user)

  }

  const debitCardNotSupportedBadge = () => {
    return <div className={classes.DebitCardNotSupportedContainer}>
      <FontAwesomeIcon icon={faCreditCard} className={classes.Icon}/>
      {t('debit_cards_not_supported_badge_message_1')}
      <div>{t('debit_cards_not_supported_badge_message_2')}</div>
    </div>
  }

  const onApplePayPaymentComplete = async (transactionId: string, currency: string, amountInCents: number) => {
    return onPlatformPaymentComplete(transactionId, currency, amountInCents, true)
  }

  const onGooglePayPaymentComplete = async (transactionId: string, currency: string, amountInCents: number) => {
    return onPlatformPaymentComplete(transactionId, currency, amountInCents)
  }

  const onPlatformPaymentComplete = async (transactionId: string, currency: string, amountInCents: number, isProd: boolean = false) => {
    try {

      setHandlingPlatformPayment(true)

      // first make a refund
      if (amountInCents > 0) {
        await adyenMakeRefund(
          transactionId,
          currency,
          amountInCents,
          {isProd}
        )
      }

      const adyenCards = await adyenGetCards(
        user?.id ?? '',
        (isProd
          ? process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID_PROD
          : process.env.REACT_APP_PLATFORM_ACCOUNT_ADYEN_INGRESSE_KEY_UUID)
          ?? '',
        isProd
      )

      const myCards = await getCards()

      const paymentAccountType = selectedEvent?.paymentAccountType ?? ''
      const platformAccountId = selectedEvent?.platformAccountBillfoldId ?? ''

      const newCards = []

      for (const c of adyenCards) {

        // if this adyen card already presented in my cards - skip
        if (myCards.some(mc => getPaymentMethodId(mc, platformAccountId, paymentAccountType) == c.paymentMethodId)) {
          continue
        }

        // now looking for the same card from another payment processor
        const existingCard = myCards.find(mc => mc.brand == c.brand && mc.last4 == c.last4)

        if (existingCard) {
          // if we have this wallet card - we will update it
          const oldIds = parse(existingCard.paymentMethodIds ?? '{}')
          const newIds = {
            ...oldIds,
            [`${paymentAccountType}_${platformAccountId}`]: c.paymentMethodId,
          }

          // const newIdsString = stringify(newIds).replaceAll('"', '\\"')
          const newIdsString = stringify(newIds)
          const updatedCard = await updateCard(
            existingCard.id ?? '',
            {
              brand: normalizeBrand(c?.brand),
              last4: c?.last4,
              userID: c?.customerId,
              paymentMethodIds: newIdsString
            }
          )
          newCards.push(updatedCard)
        } else {
          // otherwise we will create the card
          const createdCard = await createCard({
            brand: normalizeBrand(c?.brand),
            last4: c?.last4,
            userID: c?.customerId,
            paymentMethodIds: stringify({[`${paymentAccountType}_${platformAccountId}`]: c.paymentMethodId})
          })
          newCards.push(createdCard)
        }
      }

      refreshCards([...(cards ?? []), ...newCards])
      if (newCards.length >= 0) {
        onCardAdded(newCards[0])
      }

      setShowApplePayWarning(false)
      showSuccess(t('card_added'))

    } catch (e) {
      console.log(`onApplePayPaymentComplete - error: ${e}`)
      showError('Can not update cards')
    } finally {
      setHandlingPlatformPayment(false)
    }

  }

  const content = () => {
    if (cardTypeAddError) {
      return <div className={classes.AddCardModal}>

        <div className={classes.CardTypeErrorContainer}>

          <Spacer height={20}/>

          <FontAwesomeIcon icon={faCreditCard} className={classes.CardTypeErrorIcon}/>

          <Spacer height={20}/>

          <div className={classes.CardTypeErrorMessage}>{cardTypeAddError}</div>

          <Spacer height={50}/>

          <AppButton
            title={t('try_another_card')}
            onClick={refreshPaymentProcessor}
            className={classnames({
                [classes.LongButton]: isMobile
              }
            )}
          />


        </div>

      </div>
    }

    if (showApplePayWarning) {
      return <div className={classes.AddCardModal}>

        <div className={classes.ApplePayWarningContainer}>

          <Spacer height={10}/>

          <div className={classes.ApplePayWarningMessage}>{t('apple_pay_warning')}</div>

          <Spacer height={40}/>

          <ApplePayButton
            title={t('ok')}
            className={classes.PayButton}
            onPaymentComplete={onApplePayPaymentComplete}/>

        </div>
      </div>
    }

    if (handlingPlatformPayment || loadingSecret || googlePayCompleting) {
      return <div className={classes.AddCardModal}>
        <div className={classes.SpinnerContainer}>
          <Spinner/>
        </div>
      </div>
    }

    return <div className={classes.AddCardModal}>

      {!event && !selectedEvent && <div>
        <div className={classes.EventSelector} onClick={() => setShowEventsList(true)}>
          <div>{t('select_an_event')}</div>
          <Spacer/>
          <FontAwesomeIcon icon={faChevronDown} className={classes.Icon}/>
        </div>

        <div className={classes.SelectEventHint}>
          {t('select_event_hint')}
        </div>

      </div>}

      {isAdyen && <div className={classes.AdyenCardForm}>
        <div ref={cardFormRef} className="payment">
          <span data-cse="encryptedCardNumber"></span>
        </div>

        <AddCardButtons
          showCancelButton={showCancelButton}
          onCancelClick={onCancelClick}
          onCardAdded={onCardAdded}
          platformAccountId={platformAccountId}
          paymentCustomerId={paymentCustomerId}
          shortButton={shortButton}
          cardholderName={cardholderName}
          buttonsBottomFixed={buttonsBottomFixed}
          title={buttonTitle ?? t('add_card')}
          onAddAdyenCardClick={onAddAdyenCardClick}
          progress={adyenAddingCard}
          disabled={!adyenFormValid}
          event={selectedEvent}
          onGooglePayPaymentComplete={onGooglePayPaymentComplete}
          onApplePayClick={() => setShowApplePayWarning(true)}
          onCompletingStart={() => setGooglePayCompleting(true)}
          onCompletingEnd={() => setGooglePayCompleting(false)}
          showAppleAndGooglePay
        />

      </div>}

      {!isAdyen && secret && stripe && <div className={'w-100'}>

        {(selectedEvent?.allowedCardFundingTypes?.length ?? 0) > 0 && !selectedEvent?.allowedCardFundingTypes?.includes('debit') && debitCardNotSupportedBadge()}

        <Elements stripe={stripe} options={{clientSecret: secret}}>
          <form className={'w-100'}>
            <div className={classes.StripeFormContainer}>

              {formReady && <div className={'p-Field'}>

                <div className={classnames(classes.StripeLabel, 'Label')}>
                  {t('cardholder_name')}
                </div>

                <input
                  value={cardholderName}
                  autoComplete={"name"}
                  className={cardholderNameError ? classes.StripeInputError : classes.StripeInput}
                  onChange={onCardholderNameChange}
                  placeholder={t('cardholder_name')}/>

              </div>}

              <Spacer height={14}/>

              <PaymentElement
                options={{
                  business: {name: 'Billfold LLC'},
                }}
                onReady={() => setFormReady(true)}
              />
            </div>

            <AddCardButtons
              showCancelButton={showCancelButton}
              onCancelClick={onCancelClick}
              onCardAdded={onCardAdded}
              onCardTypeAddError={setCardTypeAddError}
              platformAccountId={platformAccountId}
              paymentCustomerId={paymentCustomerId}
              shortButton={shortButton}
              title={buttonTitle}
              cardholderName={cardholderName}
              onClick={onAddCardClick}
              buttonsBottomFixed={buttonsBottomFixed}
              event={selectedEvent}
              onGooglePayPaymentComplete={onGooglePayPaymentComplete}
              onApplePayClick={() => setShowApplePayWarning(true)}
            />
          </form>
        </Elements>

      </div>}

      <SelectEventModal
        onSelected={setSelectedEvent}
        opened={showEventsList}
        onClose={() => setShowEventsList(false)}/>

    </div>
  }

  return content()

}

interface AddCardButtonProps {
  onCardAdded: (card: Card) => void
  onCardTypeAddError?: (error: string) => void
  className?: string
  title?: string
  cardholderName?: string
  platformAccountId?: string
  paymentCustomerId?: string
  onClick?: VoidFunction
  onAddAdyenCardClick?: VoidFunction
  expanded?: boolean
  showCancelButton?: boolean
  onCancelClick?: VoidFunction
  shortButton?: boolean
  buttonsBottomFixed?: boolean
  progress?: boolean
  disabled?: boolean
  isStripe?: boolean
  elements?: StripeElements | null
  event?: Event
  showAppleAndGooglePay?: boolean
  onGooglePayPaymentComplete?: (transactionId: string, currency: string, amountInCents: number) => void
  onApplePayClick?: VoidFunction
  onCompletingStart?: VoidFunction
  onCompletingEnd?: VoidFunction
}

/**
 * This component seems like too complex and redundant
 * Let's get rid of this sometime. We can move all this logic to AddCardForm
 */
const AddCardButtons: React.FC<AddCardButtonProps> = (props) => {
  const {t} = useTranslation()
  const {
    title,
    onCardAdded,
    onCardTypeAddError,
    cardholderName,
    platformAccountId,
    paymentCustomerId,
    onClick,
    showCancelButton,
    onCancelClick,
    buttonsBottomFixed,
    progress,
    disabled,
    onAddAdyenCardClick,
    event,
    onGooglePayPaymentComplete,
    onApplePayClick,
    showAppleAndGooglePay,
    onCompletingStart,
    onCompletingEnd,
  } = props
  const {addCard, addingCard} = useAddCard()
  const elements = !onAddAdyenCardClick ? useElements() : null
  const stripe = !onAddAdyenCardClick ? useStripe() : null
  const {cards} = useCards()
  const [error, setError] = useState('')
  const onAddStripeCardClick = async () => {

    onClick && onClick()

    if (!cardholderName) {
      return;
    }

    try {

      setError('')

      const newCard = await addCard({
        elements,
        stripe,
        cardholderName,
        platformAccountId,
        paymentCustomerId,
        allowedCardFundingTypes: event?.allowedCardFundingTypes
      })

      if (newCard) {
        refreshCards([...(cards ?? []), newCard])
        onCardAdded(newCard)
        showSuccess(t('card_added'))
      } else {
        showError(t('something_wrong_added_card_is_empty'))
      }
    } catch (e: any) {
      console.log(`onAddCardClick - error: `, e)

      if (e instanceof DebitCardNotSupportedError) {
        setError(t('debit_cards_not_supported_message'))
        onCardTypeAddError && onCardTypeAddError(t('debit_cards_not_supported_message'))
      } else if (e instanceof CreditCardNotSupportedError) {
        setError(t('credit_cards_not_supported_message'))
        onCardTypeAddError && onCardTypeAddError(t('credit_cards_not_supported_message'))
      } else if (e instanceof CardTypeNotSupportedError) {
        setError(t('this_card_type_is_not_supported'))
        onCardTypeAddError && onCardTypeAddError(t('this_card_type_is_not_supported'))
      } else {
        setError(e.message || t(`can_not_add_card`, {error: e.toString()}))
        // onCardAddError && onCardAddError(t(`can_not_add_card`, {error: e.toString()}))
        Bugsnag.notify(
          e,
          async (event) => {
            event.addMetadata('details', {
              cardholderName,
              platformAccountId,
              paymentCustomerId,
            })
            event.addMetadata('stack', {stack: e.stack})
            const user = await getCurrentUserOrNull()
            if (user) {
              event.addMetadata('user', {
                id: user.id,
                email: user.email,
                name: [user.firstName, user.lastName].join(' '),
                phone: user.phoneNumber ?? '',
              })
            }
          },
        )
      }
    }
  }

  return <div className={'w-100'}>

    <Spacer height={error ? 16 : 40}/>

    {error && <div className={classes.Error}>{error}</div>}

    <div className={buttonsBottomFixed ? classes.ButtonsContainerMobile : classes.ButtonsContainer}>

      <div className={classes.ActionButtons}>

        {showCancelButton && <AppButton
          className={classnames('me-3', {[classes.TwoButtonsMobile]: showCancelButton})}
          title={t('cancel')}
          onClick={onCancelClick}
          icon={faClose}
          secondary/>}

        <AppButton
          icon={faCheck}
          title={title ?? t('add_card')}
          onClick={onAddAdyenCardClick ?? onAddStripeCardClick}
          className={classnames({
            [classes.LongButton]: isMobile && !showCancelButton,
            [classes.TwoButtonsMobile]: showCancelButton
          })}
          progress={progress || addingCard}
          disabled={disabled}/>

      </div>

      {showAppleAndGooglePay && (applePayAvailable() || googlePayAvailable()) && <>

        <div className={classes.DividerContainer}>
          <Spacer width={10}/>
          <div className={classes.Divider}/>
          <div className={classnames(classes.DividerText)}>{t('or')}</div>
          <div className={classes.Divider}/>
          <Spacer width={10}/>
        </div>

        {applePayAvailable() && <>
          <div className={classes.ApplePayButton} onClick={onApplePayClick}>
            <ApplePayLogo className={classes.ApplePayLogo}/>
          </div>
        </>}

        <div className={classes.GooglePayButtonContainer}>
          <GooglePayButton
            onPaymentComplete={(a, b, c) => {
              onGooglePayPaymentComplete && onGooglePayPaymentComplete(a, b, c)
            }}
            onCompletingStart={() => {
              onCompletingStart && onCompletingStart()
            }}
            onCompletingEnd={() => {
              onCompletingEnd && onCompletingEnd()
            }}
          />
        </div>

        <div className={classes.SamsungPayButtonContainer}>
          <SamsungPayButton/>
        </div>

      </>}

    </div>

  </div>

}
