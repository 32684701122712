export const isEmailValid = (email: string): boolean => {
  const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regexp.test(email);
}

export const passwordAlphaNumeric = (password: string): boolean => {
  const alpha = new RegExp("(?=.*[a-zA-Z])")
  const numeric = new RegExp("(?=.*\\d)")
  return alpha.test(password) && numeric.test(password)
}

export const isUrlValid = (url: string): boolean => {
  return url.startsWith('http://') || url.startsWith('https://')
}

export const isImageUrl = (url: string): boolean => {
  return isUrlValid(url) && (url.startsWith('https://images.unsplash.com') || url.match(/\.(jpeg|jpg|gif|png|bmp|ico|tif|webp)/) != null);
}

export const isVideoUrl = (url: string): boolean => {
  return isUrlValid(url) && url.match(/\.(mp4|avi|mov|youtube|youtu|vimeo|tiktok|rutube)/) != null;
}

export const isImageFile = (fileName: string): boolean => {
  return fileName.match(/\.(jpeg|jpg|gif|png|bmp|ico|tif|webp)/) != null;
}

export const isVideoFile = (fileName: string): boolean => {
  return fileName.match(/\.(mp4|avi|mkv|mov|wmv|flv|webm|mpeg|mpg|3gp)/) != null;
}

export const isFacebookUrl = (url: string): boolean => {
  return url.startsWith('http://facebook.com')
    || url.startsWith('https://facebook.com')
    || url.startsWith('http://www.facebook.com')
    || url.startsWith('https://www.facebook.com')
}

export const isTwitterUrl = (url: string): boolean => {
  return url.startsWith('http://twitter.com')
    || url.startsWith('https://twitter.com')
    || url.startsWith('http://www.twitter.com')
    || url.startsWith('https://www.twitter.com')
}

export const isInstagramUrl = (url: string): boolean => {
  return url.startsWith('http://instagram.com')
    || url.startsWith('https://instagram.com')
    || url.startsWith('http://www.instagram.com')
    || url.startsWith('https://www.instagram.com')
}
