import useSWRMutation from "swr/mutation";
import { loginWithFacebook } from "../services/api/api";

export const useFacebookLogin = () => {
  const {trigger, isMutating} = useSWRMutation<any, any, any, any>('loginWithFacebook', loginWithFacebook)
  return {
    loginWithFacebook: trigger,
    loginWithFacebookInProgress: isMutating,
  }
}
