import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import classes from './EventItem.module.scss'
import {Event, Wristband} from "../../services/api/models";
import {Link} from "react-router-dom";
import InfoRow from "./InfoRow";
import {ReactComponent as Calendar} from "../../images/calendar_small.svg";
import {ReactComponent as Location} from "../../images/location_small.svg";
import {Button} from "react-bootstrap";
import FavoriteButton from "./FavoriteButton";
import {Shimmer, Shimmers} from "./Shimmer";
import {useWristbands} from "../../hooks/useWristbands";
import {DivOrNull} from "../account/AccountView";
import {divLeft, divRight} from "../../util/div_util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faImage, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {dates, isBraintree, locationTitle} from "../../util/event_util";
import {useTranslation} from "react-i18next";
import {AppImage} from "./AppImage";

const fullOpacityOffset = 25

interface EventItemProps {
  event?: Event
  shimmer?: boolean
  wristbandNumber?: string
  lightBackground?: boolean
  className?: string
}

const EventItem: React.FC<EventItemProps> = (props) => {
  const {event, shimmer, wristbandNumber, lightBackground, className} = props
  const {t} = useTranslation()
  const {
    name,
    startDate,
    finishDate,
    imageUrl,
    imageStorageKey,
    featured,
    registrationAllowed,
    billfoldEventId,
    showToUsers
  } = event ?? {}
  const {wristbands} = useWristbands()
  const [eventWristbands, setEventWristbands] = useState<Wristband[]>()

  useEffect(() => {
    if (wristbands && wristbands.length && !eventWristbands) {
      setEventWristbands(wristbands.filter(w => w.eventID == event?.id))
    }
  }, [wristbands])

  const platformAccountInfo = () => {
    return <div className={(event?.imageUrl || event?.imageStorageKey)
      ? classes.PlatformAccountInfo
      : classes.PlatformAccountInfoDark}>
      {event?.platformAccountBillfoldId ?? '-'}
    </div>
  }

  return <div style={{overflowX: 'hidden'}} className={className}>
    {event && <Link to={`/event/${event.id}`}>
      <div className={lightBackground ? classes.EventItemLightBackground : classes.EventItem}>
        <div className={classes.ImageContainer}>

          {(imageUrl || imageStorageKey)
            ? <AppImage src={imageUrl} storageKey={imageStorageKey} className={classes.Image}/>
            : <div className={classes.ImagePlaceholder}>
            <FontAwesomeIcon icon={faImage} className={classes.Icon}/>
          </div>}

          {!showToUsers && <div className={classes.HiddenIconContainer}>
            <FontAwesomeIcon icon={faEyeSlash} className={classes.HiddenIcon}/>
          </div>}
        </div>


        <div className={classes.Info}>

          <div className={classes.Title}>{name}</div>

          <InfoRow
            title={dates(startDate, finishDate)}
            icon={Calendar}/>

          <div className={classes.LocationContainer}>
            <InfoRow
              title={locationTitle(event)}
              icon={Location}
              grey={!event.locations || !event.locations.length}/>
          </div>

          <ScrollableContainer wristbands={eventWristbands ?? []}/>

          <div className={classes.ButtonContainer}>
            {billfoldEventId && registrationAllowed && !isBraintree(event) &&
              <Link to={`/event/${event.id}/checkin${wristbandNumber ? `?wristbandNumber=${wristbandNumber}` : ''}`}>
                <Button className={classes.Button}>{t('register')}</Button>
              </Link>}
          </div>

          <div className={classes.FavoriteButtonContainer}>
            <FavoriteButton event={event} small/>
          </div>

        </div>

        {featured && <div className={classes.FeaturedBadge}>
          <FontAwesomeIcon icon={faStar} className={classes.Icon}/>
          Featured
        </div>}

        {process.env.REACT_APP_ENV == 'staging' && platformAccountInfo()}

      </div>
    </Link>}

    {shimmer && <Shimmers>
      <div className={classes.EventItem} style={{width: '100%'}}>
        <div className={classes.ImageContainer}>
          <Shimmer className={classes.Image} expand/>
        </div>
        <div className={classes.Info}>

          <div>
            <Shimmer width={70}/>
          </div>
          <div className={'mt-2'}>
            <Shimmer width={170}/>
          </div>
          <div className={'mt-2'}>
            <Shimmer width={110}/>
          </div>
          <div style={{marginTop: 56}}>
            <Shimmer width={140} height={44}/>
          </div>

          <div className={classes.FavoriteButtonContainer}>
            <Shimmer width={24} height={24} borderRadius={12}/>
          </div>

        </div>
      </div>
    </Shimmers>}
  </div>

}

interface ScrollableContainerProps {
  wristbands: Wristband[]
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = (props) => {
  const {wristbands} = props
  const container = useRef<DivOrNull>(null)
  const firstWristband = useRef<DivOrNull>(null)
  const lastWristband = useRef<DivOrNull>(null)
  const [leftShadowOpacity, setLeftShadowOpacity] = useState(0)
  const [rightShadowOpacity, setRightShadowOpacity] = useState(0)

  useEffect(() => {
    onScroll()
  }, [])

  useEffect(() => {
    onScroll()
  }, [wristbands])

  const onScroll = useCallback(() => {
    const leftOffset = divLeft(firstWristband) - divLeft(container)
    setLeftShadowOpacity(leftOffset >= 0 ? 0 : Math.abs(leftOffset) / fullOpacityOffset)

    const rightOffset = divRight(lastWristband) - divRight(container)
    setRightShadowOpacity(rightOffset <= 0 ? 0 : Math.abs(rightOffset) / fullOpacityOffset)
  }, [])

  return <div className={classes.WristbandsContainerWrapper} ref={container}>
    <div className={classes.WristbandsContainer} onScroll={onScroll}>
      {wristbands?.map((w, i) => <div
        ref={i == 0 ? firstWristband : (i == wristbands.length - 1 ? lastWristband : undefined)}
        key={w.id}>
        <Link to={`wristbands/${w.id}`}>
          <WristbandBadge
            key={w.id}
            name={w.wristbandOwnerName ?? ''}
          />
        </Link>
      </div>)}
    </div>
    <div className={classes.WristbandsContainerOverlayLeft} style={{opacity: leftShadowOpacity}}/>
    <div className={classes.WristbandsContainerOverlayRight} style={{opacity: rightShadowOpacity}}/>
  </div>
}

interface WristbandBadgeProps {
  name: string
}

const WristbandBadge: React.FC<WristbandBadgeProps & ComponentProps<any>> = ({name}) => {
  /* todo show wristband icon or QR icon */
  return <div className={classes.WristbandBadge}>
    {name}
  </div>
}

export default EventItem
