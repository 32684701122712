import {AppButton, ModalProps, Spacer} from "../common"
import {useTranslation} from "react-i18next"
import useUser from "../../hooks/useUser"
import classes from "./QrRegistrationIndoModal.module.scss"
import Modal from "../common/Modal"
import React, {useEffect, useState} from "react"
import {ReactComponent as EnvelopeIcon} from "../../images/envelope.svg"
import { Link } from "react-router-dom"
import {Checkbox} from "../common/Checkbox";
import {getNotShowQrRegistrationInfoAgain, saveNotShowQrRegistrationInfoAgain} from "../../services/storage";

interface QrRegistrationInfoModalProps {
  actualRegisterEventId: string
}

export const QrRegistrationInfoModal = (props: QrRegistrationInfoModalProps & ModalProps) => {
  const {actualRegisterEventId} = props
  const {t} = useTranslation()
  const {user} = useUser()
  const [notShowAgain, setNotShowAgain] = useState(false)

  useEffect(() => {
    setNotShowAgain(getNotShowQrRegistrationInfoAgain())
  }, []);

  const option = (title: string, message: string) => {
    return  <>
      <div className={classes.CardTitle}>
        {title}
      </div>
      <div className={classes.CardText}>
        {message}
      </div>
    </>
  }

  return <Modal {...props}>

    <div className={classes.QrRegistrationInfoModal}>
      <div className={classes.Title}>
        {t('registration_info_title')}
      </div>

      <Spacer height={16}/>

      <div className={classes.Card}>
        <div className={classes.Badge}>{t('online')}</div>
        {option(t('registration_info_option_1_title'), t('registration_info_option_1_message'))}
        <Spacer height={10}/>
        {option(t('registration_info_option_2_title'), t('registration_info_option_2_message'))}
      </div>

      <Spacer height={12}/>

      <div className={classes.Card}>
        <div className={classes.BadgeYellow}>{t('on_site')}</div>
        {option(t('registration_info_option_3_title'), t('registration_info_option_3_message'))}
      </div>

      <Spacer height={12}/>

      <div className={classes.SubMessage}>
        <EnvelopeIcon className={classes.EnvelopeIcon}/>
        <Spacer width={8}/>
        <div className={classes.SubMessageText}>
        <div>{t('we_will_send_instructions_to_your_email', {email: ''})}</div>
        <div className={classes.Email}>{user?.email}</div>
        </div>
      </div>

      <Spacer height={20}/>

      <Checkbox
        title={t('dont_show_again')}
        checked={notShowAgain}
        onChange={(v) => {
        saveNotShowQrRegistrationInfoAgain(v)
        setNotShowAgain(v)
      }}/>

      <Spacer height={20}/>

      <Link to={`/event/${actualRegisterEventId}`}>
        <AppButton className={classes.ActionButton} title={t('got_it')}/>
      </Link>

    </div>

  </Modal>
}
