import React from "react"
import classes from "./Warning.module.scss"
import {ReactComponent as WarningIcon} from "../../images/warning_icon.svg";
import classnames from "classnames";

interface WarningProps {
  title: string
  grey?: boolean
  black?: boolean
  borderless?: boolean
}

export default function Warning(props: WarningProps) {
  const {title, grey, borderless, black} = props
  return <div className={classnames(
    classes.Warning,
    {
      [classes.WarningGrey]: grey,
      [classes.WarningBlack]: black,
      [classes.Borderless]: borderless
    }
  )}>
    {/*<FontAwesomeIcon icon={faTriangleExclamation} className={classes.Icon}/>*/}
    <WarningIcon className={classes.Icon}/>
    <div className={classes.Message}>{title}</div>
  </div>
}
