export class UnauthorizedError extends Error {

}

export class UserNotConfirmedError extends Error {

}

export class UserRegisteredFromOtherChannelError extends Error {

}

export class UserNotInvitedError extends Error {

}

export class NeedAdminPermissionError extends Error {

}

export class BillfoldError {
  public static CUSTOMER_ID_IS_BLANK = 'customer_id_is_blank'
  public static CUSTOMER_NOT_FOUND = 'customer_not_found'
  public static EMAIL_IS_BLANK = 'email_is_blank'
  public static EVENT_ID_IS_BLANK = 'event_id_is_blank'
  public static EVENT_NOT_FOUND = 'event_not_found'
  public static INVALID_DATE_OF_BIRTH_FORMAT = 'invalid_date_of_birth_format'
  public static INVALID_EMAIL_FORMAT = 'invalid_email_format'
  public static INVALID_GENDER = 'invalid_gender'
  public static INVALID_PHONE_NUMBER = 'invalid_phone_number'
  public static INVALID_PIN = 'invalid_pin'
  public static NEWS_SUBSCRIPTION_MUST_BE_FALSE_OR_NULL = 'news_subscription_must_be_false_or_null'
  public static PAYMENT_GATEWAY_ERROR = 'payment_gateway_error'
  public static PAYMENT_METHOD_ID_IS_BLANK = 'payment_method_id_is_blank'
  public static PAYMENT_METHOD_NOT_FOUND = 'payment_method_not_found'
  public static SUBJECT_IS_BLANK = 'subject_is_blank'
  public static SUBJECT_IS_TAKEN = 'subject_is_taken'
  public static VAULT_IS_INVALID = 'vault_is_invalid'
  public static WRISTBAND_IS_BLOCKED = 'wristband_is_blocked'
  public static WRISTBAND_NUMBER_TAKEN = 'wristband_number_taken'
  public static WRISTBAND_NUMBER_ALREADY_TAKEN = 'wristband_number_is_already_taken'
}

export class DebitCardNotSupportedError extends Error {

}

export class CreditCardNotSupportedError extends Error {

}

export class CardTypeNotSupportedError extends Error {

}
