import useSWRMutation from "swr/mutation";
import { confirmUser, register, resendCode } from "../services/api/api";

export const useConfirmEmail = () => {
  const sendCodeMutation = useSWRMutation<any, any, any, any>('confirmUser', confirmUser)
  const resendCodeMutation = useSWRMutation<any, any, any, any>('resendCode', resendCode)

  return {
    sendCode: sendCodeMutation.trigger,
    sendingCode: sendCodeMutation.isMutating,
    resendCode: resendCodeMutation.trigger,
    resendingCode: resendCodeMutation.isMutating,
  }
}
