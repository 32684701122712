import React from "react";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import {cutEllipsize} from "./string_util";

export const showError = (message: any) => {
  toast.error(cutEllipsize(message.toString()), {
    style: {
      padding: '8px 14px',
      color: 'white',
      fontWeight: 600,
      background: '#f44336',
    },
  })
}

export const showSuccess = (message: any) => {
  toast.success(message.toString(), {
    style: {
      padding: '8px 14px',
      color: 'white',
      fontWeight: 600,
      background: '#43a047',
    },
  })
}

export const showInfo = (message: any) => {
  toast(message.toString(), {
    style: {
      padding: '8px 12px 8px 8px',
      color: 'white',
      fontWeight: 600,
      background: '#4451CB',
    },
  })
}

export const showFeatureInDevelopment = () => {
  toast(
    (_) => (
      <div>
        <FontAwesomeIcon icon={faWrench} className={'me-2'}/>
        This feature is in development
      </div>
    ),
    {
      style: {
        padding: '8px 12px 8px 8px',
        color: 'white',
        fontWeight: 600,
        background: '#4451CB',
      }
    }
  )
}

export const showWarning = (message: string) => {
  toast(message, {
    //appearance: 'warning',
    //autoDismiss: true,
  })
}
