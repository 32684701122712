import axios from "axios";
import Bugsnag from "@bugsnag/js";

export const getCountryCode = async (): Promise<string> => {
  console.log(`getCountryCode`)
  try {
    const result = await axios.post(`https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`)
    return result.data.country
  } catch (e: any) {
    Bugsnag.notify(e)
    console.log(`getCountryCode - error: ${e}`)
    return 'US'
  }
}
