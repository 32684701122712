import React from 'react'
import classes from './PaymentMethodInfo.module.scss'
import { PaymentMethodIcon } from "./PaymentMethodIcon";
import { Card } from "../../services/api/models";

interface PaymentMethodInfoProps {
  card?: Card
  small?: boolean
  loading?:boolean
}

export function PaymentMethodInfo(props: PaymentMethodInfoProps) {
  const {small, card, loading} = props

  /*todo make loading shimmer as well*/

  return <div>
    {small && <div className={classes.PaymentMethodInfo}>
      <PaymentMethodIcon card={card}/>
      <div className={classes.CardNumber}>
        {card?.last4}
      </div>
    </div>}

    {!small && <div className={classes.PaymentMethodInfo}>
      <div className={'me-1'}>
        <PaymentMethodIcon card={card}/>
      </div>
      <div className={classes.CardNumberBig}>{card?.last4}</div>
    </div> }

  </div>
}
