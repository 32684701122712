import { DivRef } from "../views/account/AccountView";

export const divLeft = (ref: DivRef): number => ref.current?.getBoundingClientRect().left ?? 0

export const divRight = (ref: DivRef): number => ref.current?.getBoundingClientRect().right ?? 0

export const divTop = (ref: DivRef): number => ref.current?.getBoundingClientRect().top ?? 0

export const divWidth = (ref: DivRef): number => Math.abs(divLeft(ref) - divRight(ref))

export const divBottom = (ref: DivRef): number => ref.current?.getBoundingClientRect().bottom ?? 0
