import React, {useEffect, useState} from 'react'
import {isMobile} from "react-device-detect"
import classes from "./SamsungPayButton.module.scss"
import {adyenAuthAmountInCents, adyenCurrency} from "../../config/constants";
import {showError, showSuccess} from "../../util/toast_util";
import Bugsnag from "@bugsnag/js";
import {getCurrentUserOrNull} from "../../services/api/api";

export const SamsungPayButton = () => {

  const [initialized, setInitialized] = useState(false)
  const [error, setError] = useState('')

  let samsungPayClient: any;


  useEffect(() => {
    console.log(`SamsungPayButton - useEffect`)

    const script = document.createElement('script');
    script.src = 'https://img.mpay.samsung.com/gsmpi/sdk/samsungpay_web_sdk.js';
    script.async = true;
    script.onload = () => {
      console.log(`onload`)
      initializeSamsungPay();
    };
    document.body.appendChild(script);
  }, []);

  const initializeSamsungPay = async () => {
    console.log(`initializeSamsungPay`)

    if (initialized) {
      console.log(`initializeSamsungPay - initialized, exit`)
      return
    }

    setInitialized(true)

    try {


      const paymentMethods = {
        "version": "2",
        "serviceId": process.env.REACT_APP_SAMSUNG_PAY_SERVICE_ID ?? '',
        "protocol": "PROTOCOL_3DS",
        "allowedBrands": ["visa", "mastercard"]
      }

      samsungPayClient = new (window as any).SamsungPay.PaymentClient({environment: "STAGE"});
      console.log(`SamsungPayButton - samsungPayClient: `, samsungPayClient)

      const ready = await samsungPayClient.isReadyToPay(paymentMethods)
      console.log(`initializeSamsungPay - ready: `, ready)

      const button = samsungPayClient.createButton({
        onClick: onSamsungPayButtonClicked,
        buttonColor: "black",
        buttonType: "buy"
      });

      const container = document.getElementById("samsungpay-container")
      if (container && container.children.length == 0)  {
        container.appendChild(button)
        const child = container.firstChild as HTMLElement
        if (child) {
          child.style.width = '100%';
          if (isMobile) {
            child.style.backgroundSize = '60%';
          }
          child.style.borderRadius = '10px !important';
        }
      }

    } catch (e) {
      console.log(`initializeSamsungPay - error: ${e}`)
    }

  }

  const onSamsungPayButtonClicked = async (client: any) => {
    console.log(`onSamsungPayButtonClicked`)

    if (!samsungPayClient) {
      showError(`Samsung Pay client not initialized`)
    }

    try {

      let paymentMethods = {
        version: "2",
        serviceId: process.env.REACT_APP_SAMSUNG_PAY_SERVICE_ID ?? '',
        protocol: "PROTOCOL_3DS",
        allowedBrands: ["visa", "mastercard"]
      }

      const transactionDetail = {
        "orderNumber": "BILLFOLD-APP-CARD-AUTHORIZATION",
        "merchant": {
          "name": "Billfold App",
          "url": process.env.REACT_APP_HOST?.replaceAll('https://','').replaceAll('http://','') ?? '',
          "id": process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT //"xn7qfnd"
        },
        "amount": {
          "option": "FORMAT_TOTAL_ESTIMATED_AMOUNT",
          "currency": adyenCurrency,
          "total": (adyenAuthAmountInCents / 100).toFixed(2)
        }
      }

      // const client = new (window as any).SamsungPay.PaymentClient({environment: "STAGE"});

      console.log(`onSamsungPayButtonClicked - REACT_APP_SAMSUNG_PAY_SERVICE_ID: ${process.env.REACT_APP_SAMSUNG_PAY_SERVICE_ID}`)
      console.log(`onSamsungPayButtonClicked - REACT_APP_HOST:                   ${process.env.REACT_APP_HOST}`)
      console.log(`onSamsungPayButtonClicked - REACT_APP_ADYEN_MERCHANT_ACCOUNT: ${process.env.REACT_APP_ADYEN_MERCHANT_ACCOUNT}`)

      console.log(`onSamsungPayButtonClicked - client: `, samsungPayClient)
      const credentials = await samsungPayClient?.loadPaymentSheet(paymentMethods, transactionDetail)

      console.log(`onSamsungPayButtonClicked - credentials: `, credentials)
      showSuccess(`Got credentials: ${JSON.stringify(credentials)}`)

      //  Process payment with provider

      // const paymentResult = {
      //   "status": "CHARGED",
      //   "provider": "PG Name"
      // }
      // client?.notify(paymentResult);

    } catch (e: any) {
      console.log(`onSamsungPayButtonClicked - error: `, e)
      Bugsnag.notify(`Error processing Samsung Pay payment: - ${e}`);
      showError(`Error processing Samsung Pay payment: ${e}`)
      setError(JSON.stringify(e))
    }

    // samsungPayClient.loadPaymentSheet(paymentMethods, transactionDetail).then(function(paymentCredential) {
    //   Process payment with provider
    // ...
    // ...
    //   const paymentResult = {
    //     "status": "CHARGED",
    //     "provider": "PG Name"
    //   }
    //   samsungPayClient.notify(paymentResult);
    // }).catch(error => {
    //   Show error in developer console for debugging
    // console.error(err);
    // });
  }

  return <div>
    <div className={classes.SamsungPayButton} id="samsungpay-container"></div>
    {error && <div className={classes.Error}>{error}</div>}
  </div>
}
