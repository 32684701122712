import React, {useEffect, useMemo, useState} from 'react'
import classes from './WristbandLinkingView.module.scss'
import {ViewHeader} from "../common/ViewHeader";
import {useTranslation} from "react-i18next";
import {AppButton, Shimmer, Shimmers, Spacer} from "../common";
import {Event} from "../../services/api/models";
import useEvents from "../../hooks/useEvents";
import {useWristbands} from "../../hooks/useWristbands";
import {useOrganizerByCode} from "../../hooks/useOrganizer";
import useUser from "../../hooks/useUser";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {EventItemSmall} from "./EventItemSmall";
import {isNotEmpty} from "../../util/array_util";
import {ReactComponent as ListStar} from "../../images/list_star.svg";
import {SelectEventModal} from "../checkin/SelectEventModal";

interface WristbandLinkingViewProps {
}

export const WristbandLinkingView = (props: WristbandLinkingViewProps) => {
  const {closestEvents, eventsLoading} = useEvents()
  const {wristbands, wristbandsLoading} = useWristbands()
  const {isBillfoldAdmin, isClientAdmin} = useUser()
  const [searchParams] = useSearchParams()
  const {t} = useTranslation()
  const {organizer} = useOrganizerByCode(searchParams?.get('organizer') ?? '')
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [allowedClosestEventsWithRegistrations, setAllowedClosestEventsWithRegistrations] = useState<Event[]>()
  const {wristbandNumber} = useParams()
  const navigate = useNavigate()
  const [showSelectEventModal, setShowSelectEventModal] = useState(false)

  useEffect(() => {
    setAllowedClosestEventsWithRegistrations(closestEvents?.filter((e) => {
      const orgFit = !organizer || e.organizerID == organizer.id
      const isAdmin = isClientAdmin || isBillfoldAdmin
      const visible = e.showToUsers
      const hasQrRegistrations = wristbands?.some(w => w.eventID == e.id && w.registrationType == 'qr')
      return hasQrRegistrations && orgFit && (visible || isAdmin)
    }))
  }, [closestEvents, wristbands]);

  useEffect(() => {
    const events = allowedClosestEventsWithRegistrations
    if (events && events.length == 1) {
      setSelectedEvent(events[0])
    }
  }, [allowedClosestEventsWithRegistrations]);

  const loadingContent = () => {
    return <Shimmers>
      <div className={classes.WristbandLinkingView}>

        <Spacer height={24}/>

        <div className={'d-flex'}>
          <Shimmer width={20} height={20} borderRadius={10}/>
          <Spacer width={20}/>
          <Shimmer width={100} height={20}/>
          <Spacer width={10}/>
          <Shimmer width={60} height={20}/>
        </div>

        <Spacer height={40}/>

        <div className={'d-flex'}>
          <Shimmer width={100} height={20}/>
          <Spacer width={10}/>
          <Shimmer width={60} height={20}/>
          <Spacer width={10}/>
          <Shimmer width={80} height={20}/>
        </div>

        <Spacer height={10}/>

        <div className={'d-flex'}>
          <Shimmer width={40} height={20}/>
          <Spacer width={10}/>
          <Shimmer width={80} height={20}/>
        </div>

        <Spacer height={20}/>

        <EventItemSmall shimmer/>

        <EventItemSmall shimmer/>

      </div>
    </Shimmers>
  }

  const content = () => {
    return <div className={classes.WristbandLinkingView}>

      <ViewHeader title={t('wristband_linking')} showBackButton onBackClick={() => navigate('/')}/>

      <Spacer height={20}/>

      <div className={classes.Title}>{t('select_event_you_want_to_link_wristband')}</div>

      <Spacer height={20}/>

      {allowedClosestEventsWithRegistrations?.map(e => <EventItemSmall
        event={e}
        onClick={setSelectedEvent}
        selected={e == selectedEvent}/>)}

      <Spacer/>

      {isNotEmpty(allowedClosestEventsWithRegistrations) && <AppButton
        transparent
        title={t('select_another_event')}
        iconComponent={<ListStar className={classes.Icon}/>}
        className={classes.ChangeEventButton}
        onClick={() => setShowSelectEventModal(true)}/>}

      <Link
        to={selectedEvent ? `/event/${selectedEvent?.id}?wristbandNumber=${wristbandNumber}` : '#'}
        className={classes.NextButton}>
        <AppButton title={t('next')} disabled={!selectedEvent} className={classes.NextButton}/>
      </Link>

      <SelectEventModal
        opened={showSelectEventModal}
        organizer={organizer}
        onClose={() => setShowSelectEventModal(false)}
        onSelected={(e: any) => {
          setShowSelectEventModal(false)
          navigate(`/event/${e.id}?wristbandNumber=${wristbandNumber}`)
        }}/>

    </div>
  }

  return eventsLoading || wristbandsLoading
    ? loadingContent()
    : content()
}
