import React from 'react'
import PinCodePage from "./PinCodePage";
import Modal, {ModalProps} from "../common/Modal";

interface ChangePinCodeModalProps {
  onPinCodeConfirm: (code: string) => void
}

export const ChangePinCodeModal: React.FC<ChangePinCodeModalProps & ModalProps> = (props) => {
  const {opened, onClose, className, onPinCodeConfirm} = props

  return <Modal
    opened={opened}
    onClose={onClose}
    className={className}>

    <PinCodePage onCodeConfirmed={(code) => {
      onPinCodeConfirm(code)
    }}/>

  </Modal>

}
