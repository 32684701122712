import React from 'react'
import classes from './StepIndicator.module.scss'
import classnames from "classnames";

interface StepIndicatorProps {
  stepsCount: number
  currentStep: number
  onStepClick: (step: number) => void
}

export default function StepIndicator(props: StepIndicatorProps) {
  const {stepsCount, currentStep, onStepClick} = props
  return <div className={classes.StepIndicator}>
    {[...Array(stepsCount).keys()].map(i => <div
      key={i}
      className={classes.StepWrapper}
      onClick={_ => onStepClick(i)}>
      <div
        key={i}
        className={classnames(classes.Step, {[classes.Active]: i <= currentStep})}
      />
    </div>)}
  </div>
}
