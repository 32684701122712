import { DateTime } from "luxon";

const numberFormat = Intl.NumberFormat('en')
const numberFormatTwoDigits = Intl.NumberFormat('en', {minimumFractionDigits: 2})
const currencies : Record<string, string> = {
  'usd': '$',
  'eur': '€',
  'gbp': '£',
  'jpy': '¥',
  'cny': '¥',
  'cad': '$',
  'aud': '$',
  'nzd': '$',
  'chf': 'CHF',
  'brl': 'R$'
}

export const moneyFormat = (value: string | number, currency?: string): string => {
  const formattedValue = (value.toString()).replace(/[^\d.-]/g, '')
  if (formattedValue.length == 0) {
    return ''
  }
  const symbol = currencies[currency?.toLowerCase() ?? 'usd']
  return symbol + numberFormat.format(parseFloat(formattedValue))
}

export const moneyFormatTwoDigits = (value: string | number, currency?: string): string => {
  const formattedValue = (value.toString()).replace(/[^\d.-]/g, '')
  if (formattedValue.length == 0) {
    return ''
  }
  const symbol = currencies[currency?.toLowerCase() ?? 'usd']
  return symbol + numberFormatTwoDigits.format(parseFloat(formattedValue))
}

export const moneyToString = (moneyFormattedString: string): string => {
  return moneyFormattedString.replace(/[^\d.-]/g, '')
}

export const numFormat = (value: string | number): string => {
  const formattedValue = (value.toString()).replace(/[^\d.-]/g, '')
  if (formattedValue.length == 0) {
    return ''
  }
  return numberFormat.format(parseFloat(formattedValue))
}
