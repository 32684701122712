import { getSystemSettings, updateSystemSettings } from '../services/api/api'
import useSWR, { mutate } from 'swr'
import useSWRMutation from "swr/mutation";
import { SystemSettings } from "../services/api/models";

export default function useSystemSettings() {
  const {data, error, isLoading} = useSWR('getSystemSettings', getSystemSettings)
  return {
    settings: data,
    settingsLoading: isLoading,
    settingsLoadingError: error
  }
}

export const useUpdateSystemSettings = () => {
  const {
    trigger,
    isMutating,
    error
  } = useSWRMutation<any, any, any, any>('updateSystemSettings', updateSystemSettings)
  return {
    updateSystemSettings: trigger,
    updatingSystemSettings: isMutating,
    updateSystemSettingsError: error
  }
}

// todo why can't we refresh the settings inside useUpdateSystemSettings?
// we can modify trigger a bit that it calls mutate itself
// let's try that
export const refreshSystemSettings = async (settings: SystemSettings) => {
  await mutate('getSystemSettings', settings)
}
