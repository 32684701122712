import React, {useMemo} from 'react'
import classes from './OrganizerDetailsView.module.scss'
import {AppInput, Spacer} from "../common"
import {ViewHeader} from "../common/ViewHeader"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom"
import {useOrganizer} from "../../hooks/useOrganizer"
import {useWristbandBatch} from "../../hooks/useWristbandBatch"
import {capitalize} from "../../util/string_util"
import {formatDate} from "../../util/date_util"
import {faClipboard} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {showSuccess} from "../../util/toast_util"
import {useIntegrator} from "../../hooks/useIntegrator"
import {numFormat} from "../../util/format_util"

export const BatchDetailsView = () => {

  const {batchId} = useParams()
  const {batch} = useWristbandBatch(batchId ?? '')
  const {organizer} = useOrganizer(batch?.organizerID ?? '')
  const {integrator} = useIntegrator(organizer?.integratorID ?? '')
  const {id, code, amount, supplier, orderDate} = batch ?? {}
  const {t} = useTranslation()

  const link = useMemo(() => {
    return `https://${process.env.REACT_APP_ENV == 'staging' ? 'staging' : 'www'}.billfold.app/wb/xxx?batch_code=${code}`
  }, [code])

  const onCopyClick = async () => {
    await navigator.clipboard.writeText(link)
    showSuccess(t('link_template_copied_to_clipboard'))
  }

  const exampleLinkView = () => {
    return <div className={classes.ExampleLink}>
      <div>
        <div className={classes.ExampleLinkHeader}>{t('link_example_for_wristbands')}</div>
        <div className={classes.ExampleLinkTitle}>{link}</div>
      </div>
      <Spacer/>
      {<FontAwesomeIcon icon={faClipboard} className={classes.CopyIcon} onClick={onCopyClick}/>}
    </div>
  }

  return <div className={classes.OrganizerDetailsView}>

    <ViewHeader title={code ? `${capitalize(t('batch'))} ${code}` : t('loading...')} showBackButton/>

    <Spacer height={10}/>

    <AppInput placeholder={t('id')} initialValue={id} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('code')} initialValue={code} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('amount')} initialValue={numFormat(amount ?? 0)} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('organizer')} initialValue={organizer?.name ?? ' '} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('integrator')} initialValue={integrator?.name ?? ' '} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('supplier')} initialValue={supplier ?? ' '} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('order_date')} initialValue={orderDate ? formatDate(orderDate) : ' '} disabled/>

    <Spacer height={10}/>

    {exampleLinkView()}

  </div>
}
