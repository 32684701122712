import React, {PropsWithChildren, ReactNode, useEffect, useMemo, useRef, useState} from 'react'
import classes from "./AppInput.module.scss";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faClose, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import {getRandomInt} from "../../util/math_util";
import InfoModal from "./InfoModal";
import parsePhoneNumber, {AsYouType} from 'libphonenumber-js'
import {moneyFormat, moneyToString} from "../../util/format_util";
import {useFocus} from "../../hooks/useFocus";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {countryCodeBR, countryCodeRU, countryCodeUS} from "../../config/constants";

export enum InputType {password, phone, money, email, number}

interface AppInputProps {
  placeholder?: string
  error?: boolean
  warning?: boolean
  onChange?: (v: string) => void
  onEnterPress?: () => void
  maxLength?: number
  initialValue?: string
  initialMoneyValue?: number
  disabled?: boolean
  loading?: boolean
  uppercase?: boolean
  lowercase?: boolean
  infoTitle?: string
  infoMessage?: string
  infoChild?: React.ReactNode
  infoIsCenter?: boolean
  valid?: boolean
  onPhoneValid?: VoidFunction
  onPhoneInvalid?: VoidFunction
  big?: boolean
  trailing?: ReactNode
  trailingTexts?: string[]
  type?: InputType
  onFocus?: VoidFunction
  focusedVisible?: boolean
  showClearButton?: boolean
  onClear?: VoidFunction
  colored?: boolean
  multiline?: boolean
  freshStartPrefix?: string
  // a special settings that allows to handle phone autofill
  // it's a workaround for a bug in Safari where phone is pasted without
  // the country code. So we will add it ourselves.
  handlePhoneAutoFill?: boolean
  currency?: string
}

const PHONE_LENGTH_MIN = 10
const PHONE_LENGTH_MAX = 15

/**
 * TODO: "initial values" are bad practice for React
 * components should be controllable or NOT controllable at all.
 * Initial values works good on platforms with controllers (like Dart)
 * but here we probably should use "value" and "moneyValue"
 * and make the component fully controllable in case we have some external value.
 */
export const AppInput: React.FC<AppInputProps & PropsWithChildren> = (props) => {
  const {
    placeholder,
    error,
    warning,
    onChange,
    onEnterPress,
    maxLength,
    initialValue,
    initialMoneyValue,
    disabled,
    loading,
    uppercase,
    lowercase,
    infoTitle,
    infoMessage,
    infoChild,
    infoIsCenter,
    valid,
    onPhoneValid,
    onPhoneInvalid,
    big,
    trailing,
    trailingTexts,
    type,
    onFocus,
    focusedVisible,
    showClearButton,
    onClear,
    colored,
    multiline,
    freshStartPrefix,
    handlePhoneAutoFill,
    currency
  } = props

  const [showPassword, setShowPassword] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [valueLocal, setValueLocal] = useState<string | undefined>()
  const [country, setCountry] = useState<string | undefined>()
  const [inputRef, setInputFocus] = useFocus()

  useEffect(() => {
    if (initialValue) {
      setValueLocal(initialValue)
    } else if (initialMoneyValue) {
      setValueLocal(moneyFormat(initialMoneyValue))
    }
  }, [initialValue, initialMoneyValue])

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress()
    }
  }

  const onQuestionClick = () => {
    setShowInfo(true)
  }

  const onInfoClose = () => {
    setShowInfo(false)
  }

  const onChangeLocal = (e: any) => {
    let value = e.target.value

    switch (type) {
      case InputType.phone:
        if (value.startsWith('8')) {
          value = '7' + value.toString().substring(1)
        }

        if (value.length > 0 && !value.startsWith('+')) {
          value = '+' + value
        }

        const length = value.replaceAll(' ', '').replace('+', '').length

        if (length < PHONE_LENGTH_MIN) {
          if (onPhoneInvalid) {
            onPhoneInvalid()
          }
        }

        if (length > PHONE_LENGTH_MAX) {
          if (onPhoneInvalid) {
            onPhoneInvalid()
          }
        }

        let phoneNumber = new AsYouType().input(value)

        // we handle it only when the value local is pretty short
        // because we have bugs with typing when the phone is almost entered
        // so this case is for the situations where phone is just started or not
        // entered at all and user "fill" it from the browser.
        if (handlePhoneAutoFill && (valueLocal ?? '').length < 4) {
          // checking if the phone number has been autofilled
          // if we think so - we will add the country code in the beginning
          // because Safari not adding the country code to the phone number
          // when autofill it
          const numberSimple = phoneNumber?.replaceAll('+', '')?.replaceAll(' ', '')
          if (numberSimple?.length == 10) {
            if (parsePhoneNumber(`${countryCodeUS}${numberSimple}`)?.isValid()) {
              phoneNumber = new AsYouType().input(`${countryCodeUS}${numberSimple}`)
            } else if (parsePhoneNumber(`${countryCodeRU}${numberSimple}`)?.isValid()) {
              phoneNumber = new AsYouType().input(`${countryCodeRU}${numberSimple}`)
            } else if (parsePhoneNumber(`${countryCodeBR}${numberSimple}`)?.isValid()) {
              phoneNumber = new AsYouType().input(`${countryCodeBR}${numberSimple}`)
            }
          }
        }

        const phoneNumberParsed = parsePhoneNumber(phoneNumber)

        if (phoneNumberParsed) {

          const valid = phoneNumberParsed.isValid()
          if (!valid) {
            onPhoneInvalid && onPhoneInvalid()
          }

          const country = phoneNumberParsed.country
          if (country && valid) {
            onPhoneValid && onPhoneValid()
          }
          setCountry(country)
        } else {
          setCountry(undefined)
        }

        setValueLocal(phoneNumber)
        onChange && onChange(phoneNumber)
        break;
      case InputType.money:
        setValueLocal(moneyFormat(value, currency))
        onChange && onChange(moneyToString(value))
        break;
      case InputType.password:
      default:

        if (uppercase) {
          onChange && onChange(e.target.value.toUpperCase())
        } else if (lowercase) {
          onChange && onChange(e.target.value.toLowerCase())
        } else {
          onChange && onChange(e.target.value)

        }
        setValueLocal(e.target.value)
        break;
    }
  }

  const countryInfo = () => {
    return <div className={classes.CountryInfo}>
      <div className={classes.CountryCode}>{country}</div>
      <img
        className={classes.Flag}
        src={`https://flagsapi.com/${country}/flat/64.png`}
        alt={''}/>
    </div>
  }

  const onClearClick = () => {
    setValueLocal('')
    onClear && onClear()
  }

  const onFocusInner = () => {
    if (!valueLocal?.length && freshStartPrefix && freshStartPrefix.length) {
      setValueLocal(freshStartPrefix)
    }
    onFocus && onFocus()
  }

  const onBlurInner = () => {
    if (freshStartPrefix && freshStartPrefix.length && valueLocal == freshStartPrefix) {
      setValueLocal('')
    }
  }

  const inputMode = useMemo(() => {
    switch (type) {
      case InputType.phone:
      case InputType.money:
        return 'numeric'
      case InputType.password:
        return 'text'
      case InputType.email:
        return 'email'
      case InputType.number:
        return 'numeric'
      default:
        return undefined
    }
  }, [type])

  return <div className={classnames(classes.AppInput)}>
    {loading && <div className={classnames(classes.Input, 'position-relative')}>
      <div className={'placeholder-glow'}>
        <div
          className={classnames(classes.InputShimmer, 'placeholder')}
          style={{width: `${30 + getRandomInt(20)}%`}}/>
      </div>
    </div>}

    {!loading && <div className={classes.InputContainer}>

      {/*
      for some reason I can not put built-in placeholder at the center vertically
      if the main text and placeholder has different font size. Line height doesn't work
      and the margin/padding as well.
      So this is the solution. Please somebody fix it if you can.
      */}

      {big && !valueLocal && <div
        className={classnames(
          classes.BigPlaceholder,
          classes.Unselectable,
          {[classes.BigPlaceholderError]: error}
        )}
        onClick={setInputFocus}>
        {placeholder}
      </div>}

      <div className={classnames('form-floating', {['form-floating-textarea']: multiline})}>

        {multiline
          ? <textarea
            ref={inputRef}
            className={classnames(
              classes.MultilineInput,
              {[classes.InputError]: error && !colored},
              {[classes.InputError]: error && colored},
              {[classes.InputWarning]: warning && !colored},
              {[classes.InputWarningColored]: warning && colored},
              {[classes.InputValid]: valid && !colored},
              {[classes.InputValidColored]: valid && colored},
              {[classes.InputCaps]: uppercase},
              {[classes.InputLowercase]: lowercase},
              {[classes.InputBig]: big},
              {[classes.InputFocused]: !error && focusedVisible},
              "form-control"
            )}
            placeholder={big ? '' : placeholder}
            onChange={onChangeLocal}
            onKeyDown={handleKeyDown}
            maxLength={maxLength}
            value={valueLocal}
            disabled={disabled}
            inputMode={inputMode}
            onBlur={onBlurInner}
            onFocus={onFocusInner}/>
          : <input
            ref={inputRef}
            type={(type == InputType.password && !showPassword) ? 'password' : ''}
            className={classnames(
              classes.Input,
              {[classes.InputError]: error && !colored},
              {[classes.InputError]: error && colored},
              {[classes.InputWarning]: warning && !colored},
              {[classes.InputWarningColored]: warning && colored},
              {[classes.InputValid]: valid && !colored},
              {[classes.InputValidColored]: valid && colored},
              {[classes.InputCaps]: uppercase},
              {[classes.InputLowercase]: lowercase},
              {[classes.InputBig]: big},
              {[classes.InputFocused]: !error && focusedVisible},
              "form-control"
            )}
            placeholder={big ? '' : placeholder}
            onChange={onChangeLocal}
            onKeyDown={handleKeyDown}
            maxLength={maxLength}
            value={valueLocal}
            disabled={disabled}
            inputMode={inputMode}
            onBlur={onBlurInner}
            onFocus={onFocusInner}
            autoComplete={type == InputType.phone ? 'tel' : undefined}
          />}

        <label htmlFor="floatingInput">{placeholder}</label>
      </div>

      <div className={classes.Icons}>

        {type == InputType.password && <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          className={classnames(
            classes.Icon,
            {[classes.IconError]: error},
            {[classes.IconAmber]: warning}
          )}
          onClick={() => setShowPassword(!showPassword)}/>}

        {type == InputType.phone && country && countryInfo()}

        {!valid && infoTitle && <Tippy
          content={infoMessage}
          arrow={false}
          placement={"bottom-end"}
          theme={'billfold'}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={classnames(
              classes.IconQuestion,
              {[classes.IconError]: error},
              {[classes.IconAmber]: warning}
            )}/>
        </Tippy>}

        {trailing}

        {valid && <FontAwesomeIcon
          icon={faCheckCircle}
          className={classes.IconValid}/>}

        {showClearButton && !!valueLocal && <FontAwesomeIcon
          icon={faClose}
          onClick={onClearClick}
          className={classes.ClearIcon}/>}

        {/*
        Yes, It looks ugly, but \n doesn't work for me for some reason,
        and I need to show text ina few lines. Feel free to fix it if you can
        */}
        {trailingTexts && trailingTexts.length && <div className={classnames(
          classes.TrailingText,
          {[classes.Red]: error},
          {[classes.Amber]: warning}
        )}>
          {trailingTexts.map(t => <p>{t}</p>)}
        </div>}

      </div>

    </div>}

    {infoTitle && showInfo && <InfoModal
      opened={showInfo}
      onClose={onInfoClose}
      title={infoTitle}
      child={infoChild}
      message={infoMessage}
      isCenter={infoIsCenter}/>}

  </div>
}
