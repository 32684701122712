import {useEffect, useState} from "react";

const updateTime = 5

export const useKeyboardVisibility = () => {

  const [innerHeightPrev, setInnerHeightPrev] = useState(window.innerHeight)
  const [viewportHeightPrev, setViewportHeightPrev] = useState(window.visualViewport?.height ?? 0)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)
  const [viewportHeight, setViewportHeight] = useState(window.visualViewport?.height ?? 0)
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    updateValues()

    const timer = setInterval(() => {
      updateValues()
    }, updateTime)

    return () => {
      clearInterval(timer)
    };
  }, []);

  useEffect(() => {
    if (innerHeight == innerHeightPrev && viewportHeight == viewportHeightPrev) {
      return
    }

    setInnerHeightPrev(innerHeight)
    setViewportHeightPrev(viewportHeight)
    setKeyboardVisible(viewportHeight < innerHeight)
    setKeyboardHeight(innerHeight - viewportHeight)

  }, [viewportHeight, innerHeight])

  const updateValues = () => {
    setInnerHeight(window.innerHeight)
    setViewportHeight(window.visualViewport?.height ?? 0)
  }

  return {keyboardVisible, keyboardHeight};
};
