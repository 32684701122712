import {Wristband, Event} from "../../services/api/models"
import React from "react"
import classes from './AddRegistratationButton.module.scss'
import {useTranslation} from "react-i18next"
import {ReactComponent as AddIcon} from "../../images/add.svg";
import {Spacer} from "./Spacer";
import {Link} from "react-router-dom";

interface AddRegistrationButtonProps {
  event: Event
  wristband?: Wristband
  shimmer?: boolean
  aspectRatio?: number
}

export const AddRegistrationButton = (props: AddRegistrationButtonProps) => {

  const {t} = useTranslation()

  return <Link to={`/event/${props.event.id}/checkin`}>
    <div className={classes.AddRegistrationButton} style={{aspectRatio: props.aspectRatio}}>

      <AddIcon className={classes.Icon}/>

      <Spacer height={5}/>

      <div className={classes.Title}>{t('add_guest')}</div>

    </div>
  </Link>
}
