import React from 'react'
import classes from './ClearButton.module.scss'
import { Spacer } from "./Spacer";
import { AppButton } from "./AppButton";
import { ReactComponent as ClearIcon } from "../../images/clear_icon.svg";
import { clearUrl } from "../../util/clear_util";

interface ClearButtonProps {
  under21: boolean
  verified: boolean
  expired: boolean
  redirectToPage: string
  title?: string
}

export const ClearButton: React.FC<ClearButtonProps> = (props) => {

  const {under21, verified, expired, redirectToPage, title} = props

  return <a href={clearUrl(redirectToPage)} className={'w-100'}>
    <Spacer height={10}/>
    <AppButton
      title={''}
      className={classes.ClearButton}>
      <ClearIcon className={classes.ClearIcon}/>
      <Spacer width={10}/>
      <div>{title ?? (under21 || (verified && expired) ? 'Re-verify with CLEAR®' : 'Verify age with CLEAR®')}</div>
    </AppButton>
  </a>
}
