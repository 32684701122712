import {getWristbands} from "../services/api/api";
import {Wristband} from "../services/api/models";
import useSWR, {mutate} from "swr";
import useEvents from "./useEvents";

export const useWristbands = () => {
  const {data, error, isLoading} = useSWR('getWristbands', getWristbands)
  const {events, eventsLoading, eventsError} = useEvents()

  const wristbands = (data ?? []).filter(w => {
    const {finishDate} = events?.find(e => e.id === w.eventID) ?? {}
    // return DateTime.fromSQL(finishDate ?? '') >= DateTime.now()
    return true
  })

  return {
    wristbands: !events ? [] : wristbands,
    wristbandsLoading: isLoading || eventsLoading,
    wristbandsError: error || eventsError,
  }
}

export const refreshWristbands = async (wristbands?: Wristband[]) => {
  await mutate('getWristbands', wristbands)
}
