import React, {useEffect} from 'react'
import {AppButton} from "./AppButton";
import {applePayCompletePayment, applePayGetSession} from "../../services/api/api";
import {showError} from "../../util/toast_util";
import Bugsnag from "@bugsnag/js";
import {useTranslation} from "react-i18next";
import {adyenAuthAmountInCents, adyenCurrency} from "../../config/constants";

interface ApplePayButtonProps {
  onPaymentComplete: (transactionId: string, currency: string, amountInCents: number) => void
  title?: string
  className?: string
}

export const ApplePayButton = (props: ApplePayButtonProps) => {

  const {onPaymentComplete, title, className} = props
  const {t} = useTranslation()

  useEffect(() => {

    // Load the Apple Pay JS script
    const script = document.createElement('script')
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js'
    script.async = true
    script.onload = function () {
      if (window.ApplePaySession) {
        console.log('Apple Pay is available.');
      } else {
        showError(t('apple_pay_is_not_available'))
      }
    }
    script.onerror = function () {
      console.error('Script failed to load');
    };

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }

  }, [])

  const onClickLocal = async () => {
    console.log(`onClickLocal - session`)

    if (!window.ApplePaySession) {
      console.log(`onClickLocal - Apple Pay session is not available.`)
      showError(t('apple_pay_session_is_not_available'))
      return
    }

    if (!ApplePaySession.canMakePayments()) {
      console.log(`onClickLocal - Apple Pay is not available or the device cannot make payments.`)
      showError(t('apple_pay_is_not_available_or_device_can_not_make_payments'))
      return
    }

    const reference = t('billfold_card_authorization')

    // Create an instance of ApplePaySession
    const session = new ApplePaySession(3, {
      countryCode: process.env.REACT_APP_ADYEN_COUNTRY_CODE ?? '',
      currencyCode: adyenCurrency,
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: reference,
        amount: (adyenAuthAmountInCents / 100).toString()
      }
    })

    session.onvalidatemerchant = async (event: any) => {
      try {
        const bfSession = await applePayGetSession()
        session.completeMerchantValidation(bfSession)
      } catch (e: any) {
        showError(t('can_not_get_apple_pay_session'))
        session.abort()
      }
    }

    session.onpaymentauthorized = async (event) => {
      try {
        Bugsnag.notify(
          new Error(`Payment authorized - payment: ${JSON.stringify(event.payment ?? {})}`),
          e => e.addMetadata('payment', event.payment)
        )
        const {paymentData} = event.payment?.token ?? {};
        const response = await applePayCompletePayment(paymentData, adyenCurrency, adyenAuthAmountInCents, reference)
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        onPaymentComplete(response.transactionId, adyenCurrency, adyenAuthAmountInCents)
      } catch (e: any) {
        showError(t('can_not_complete_apple_pay_payment', {error: e.toString()}))
        session.completePayment(ApplePaySession.STATUS_FAILURE);
      }
    }

    session.oncancel = (event) => {
      showError(t('payment_cancelled'));
      Bugsnag.notify(new Error(`Payment cancelled by user - ${JSON.stringify(event)}`));
    }

    // Start the payment process
    session.begin();

  }

  return <div>
    <AppButton
      className={className}
      title={title ?? "Apple Pay"}
      onClick={onClickLocal}
    />
  </div>


}
