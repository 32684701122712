import React, {useEffect, useRef, useState} from 'react'
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import classes from './Main.module.scss'
import Header from "./header/Header";
import EventsView from "./EventsView";
import AccountView from "../account/AccountView";
import RegistrationView from "../registration/RegistrationView";
import {EventDetailsView} from "../event/EventDetailsView";
import Footer from "./Footer";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy";
import {WristbandsView} from "../wristbands/WristbandsView";
import {SupportView} from "../support/SupportView";
import {EventEditView} from "../event/EventEditView";
import {isBrowser, isDesktop, isMobile} from 'react-device-detect';
import {BottomNavigationBar} from "./BottomNavigationBar";
import {OrderDetailsView} from "../account/OrderDetailsView";
import {TransactionDetailsView} from "../account/TransactionDetailsView";
import AdminView from "../admin/AdminView";
import useUser from "../../hooks/useUser";
import {ClearAuthView} from "../login/ClearAuthView";
import {useIntercom} from "react-use-intercom";
import {CheckinExternalView} from "../checkin/CheckinExternalView";
import {useTranslation} from "react-i18next"
import {ReactComponent as WifiSlash} from "../../images/wifi_slash.svg"
import {Spacer} from "../common"
import Spinner from "../common/Spinner";
import useSystemSettings from "../../hooks/useSystemSettings";
import {EventImageGuideline} from "../event/EventImageGuideline";
import {WristbandLinkingView} from "../registration/WristbandLinkingView";

export default function Main() {

  const {user, userLoading} = useUser()
  const {settings, settingsLoading} = useSystemSettings()
  const {t} = useTranslation()
  const {pathname, search} = useLocation()
  const {boot} = useIntercom()
  const mainRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    if (location && mainRef.current) {
      mainRef.current.scrollTop = 0;
    }
  }, [location]);

  useEffect(() => {
    if (isDesktop) {
      boot()
    }

    if (isMobile) {
      const root = document.getElementById('main-wrapper')
      // doing it instead of 100vh, because 100vh on mobile browser nav bar and bottom bar
      // eats part of 100vh. so 100vh on mobile browser is actually BIGGER then window height.
      const height = window.innerHeight
      root?.setAttribute('style', `height: ${height}px; min-height: ${height}px; max-height: ${height}px;`)
    }

    const handleOnlineStatus = () => {
      setIsOffline(!navigator.onLine)
    };

    // Add event listeners to detect changes in online/offline status
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    // Clean up event listeners
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    }

  }, [])

  const content = () => {
    if (!user && (userLoading || settingsLoading)) {
      return <div className={classes.MainWrapper} id={'main-wrapper'}>
        <div className={classes.SpinnerContainerBig}>
          <Spinner/>
        </div>
      </div>
    }

    if (settings?.maintenance) {
      return <Navigate to='maintenance'/>
    }

    if (!user && pathname != '/reset-password') {
      let redirect = pathname + search
      if (redirect == '/') {
        return <Navigate to='auth'/>
      } else {
        return <Navigate to={`auth?redirect=${redirect}`}/>
      }
    }

    return <div className={classes.MainWrapper} id={'main-wrapper'}>

      <div className={classes.Main} id={'main'} ref={mainRef} style={{paddingTop: isOffline ? 50 : 0}}>

        {isBrowser && <Header/>}

        <div className={classes.MainContent} id={'main-content'}>
          <Routes>
            <Route path='/support/*' element={<SupportView/>}/>
            <Route path='/account/*' element={<AccountView/>}/>
            <Route path='/admin/*' element={<AdminView/>}/>
            <Route path='/event/:eventId/checkin/*' element={<RegistrationView/>}/>
            <Route path='/event/:eventId/edit/*' element={<EventEditView/>}/>
            <Route path='/event/:eventId/*' element={<EventDetailsView/>}/>
            <Route path='/event' element={<Navigate to='/'/>}/>
            <Route path='/event-image-guideline' element={<EventImageGuideline/>}/>
            <Route path='/order/:orderId/*' element={<OrderDetailsView/>}/>
            <Route path='/transaction/:transactionId/*' element={<TransactionDetailsView/>}/>
            <Route path='/order' element={<Navigate to='/'/>}/>
            <Route path='/wishlist' element={<EventsView isFavorite key={'wishlist'}/>}/>
            <Route path='/wristbands/*' element={<WristbandsView/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/clear/auth/*' element={<ClearAuthView/>}/>
            <Route path='/wb/:wristbandNumber/*' element={<CheckinExternalView/>}/>
            <Route path='/wb/:wristbandNumber/linking' element={<WristbandLinkingView/>}/>
            <Route path='/' element={<EventsView key={'events'}/>}/>
            <Route path='/*' element={<Navigate to={'/'}/>}/>
          </Routes>
        </div>

        {isBrowser && <Footer/>}

      </div>

      {isOffline && <div className={classes.OfflineBadge}>
        <WifiSlash className={classes.Icon}/>
        <Spacer width={10}/>
        {t('you_are_offline')}
      </div>}

      {isMobile && <Routes>
        <Route path='/event' element={<BottomNavigationBar/>}/>
        <Route path='/wishlist' element={<BottomNavigationBar/>}/>
        <Route path='/wristbands' element={<BottomNavigationBar/>}/>
        <Route path='/account' element={<BottomNavigationBar/>}/>
        <Route path='/support' element={<BottomNavigationBar/>}/>
        <Route path='/' element={<BottomNavigationBar/>}/>
      </Routes>}

    </div>
  }

  return content()

}
