import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import classes from "./AdminView.module.scss";
import { isBrowser, isMobile } from "react-device-detect";
import { Spacer } from "../common";
import { InvitesView } from "./InvitesView";
import { SystemSettingsView } from "./SystemSettingsView";
import AdminMenuView from "./AdminMenuView";
import useUser from "../../hooks/useUser";
import { LogsView } from "./LogsView";
import {UsersView} from "./UsersView";
import {AdyenView} from "./AdyenView";
import HealthView from "./HealthView";
import {RedirectRulesView} from "./RedirectRulesView";
import {OrganizersView} from "./OrganizersView";
import {BatchesView} from "./BatchesView";
import {OrganizerDetailsView} from "./OrganizerDetailsView";
import {BatchDetailsView} from "./BatchDetailsView";
import {RoadmapView} from "./RoadmapView";
import {IntegratorsView} from "./IntegratorsView";

export default function AdminView() {

  const {user, isBillfoldAdmin} = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !isBillfoldAdmin) {
      navigate('/')
    }
  }, [user])

  const mobileContent = () => {
    return <div className={classes.AdminView}>
      <Routes>
        <Route path='/health' element={<HealthView/>}/>
        <Route path='/adyen' element={<AdyenView/>}/>
        <Route path='/users' element={<UsersView/>}/>
        <Route path='/invites' element={<InvitesView/>}/>
        <Route path='/system' element={<SystemSettingsView/>}/>
        <Route path='/redirect-rules' element={<RedirectRulesView/>}/>
        <Route path='/organizers' element={<OrganizersView/>}/>
        <Route path='/organizers/:organizerId' element={<OrganizerDetailsView/>}/>
        <Route path='/integrators' element={<IntegratorsView/>}/>
        {/*<Route path='/integrators/:integratorId' element={<IntegratorDetailsView/>}/>*/}
        <Route path='/batches' element={<BatchesView/>}/>
        <Route path='/batches/:batchId' element={<BatchDetailsView/>}/>
        <Route path='/roadmap' element={<RoadmapView/>}/>
        <Route path={'/'} element={<AdminMenuView/>}/>
      </Routes>
    </div>
  }

  const browserContent = () => {
    return <div className={classes.AdminView}>

      <div className={classes.Menu}>
        <AdminMenuView/>
      </div>

      <Spacer width={40}/>

      <div className={classes.AdminViewContent}>
        <Routes>
          <Route path='/health' element={<HealthView/>}/>
          <Route path='/adyen' element={<AdyenView/>}/>
          <Route path='/users' element={<UsersView/>}/>
          <Route path='/invites' element={<InvitesView/>}/>
          <Route path='/system' element={<SystemSettingsView/>}/>
          <Route path='/logs' element={<LogsView/>}/>
          <Route path='/redirect-rules' element={<RedirectRulesView/>}/>
          <Route path='/organizers' element={<OrganizersView/>}/>
          <Route path='/organizers/:organizerId' element={<OrganizerDetailsView/>}/>
          <Route path='/integrators' element={<IntegratorsView/>}/>
          {/*<Route path='/integrators/:integratorId' element={<IntegratorDetailsView/>}/>*/}
          <Route path='/batches' element={<BatchesView/>}/>
          <Route path='/batches/:batchId' element={<BatchDetailsView/>}/>
          <Route path='/roadmap' element={<RoadmapView/>}/>
          <Route path='/*' element={<Navigate to={'health'}/>}/>
        </Routes>
      </div>

    </div>
  }

  return <>
    {isMobile && mobileContent()}

    {isBrowser && browserContent()}
  </>

  // "One or more parameter values are not valid. A value specified for a secondary index key is not supported. The AttributeValue for a key attribute cannot contain an empty string value. IndexName: usersByEmail, IndexKey: email (Service: DynamoDb, Status Code: 400, Request ID: T552S6VBTLREMDF96F58CHAVN7VV4KQNSO5AEMVJF66Q9ASUAAJG)"
}
