import React from 'react'
import classes from './PlaceholderView.module.scss'
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

interface PlaceholderViewProps {
  title: string
  icon?: IconDefinition
  error?: boolean
  height?: number
}

export default function PlaceholderView(props: PlaceholderViewProps) {
  const {title, icon, error, height} = props

  return <div className={classes.PlaceholderView} style={{height: height ? `${height} !important` : undefined}}>

    <FontAwesomeIcon
      icon={icon ?? faTriangleExclamation}
      className={error ? classes.Icon : classes.Icon}/>

    <div className={classes.Title}>
      {title}
    </div>

  </div>
}
