import React, {useMemo, useState} from 'react'
import classes from './PinCodePage.module.scss'
import Warning from "../common/Warning";
import {showError} from "../../util/toast_util";
import classnames from 'classnames';
import {AppButton, Spacer} from "../common";
import {ConfirmModal} from "../common/ConfirmModal";
import {faForward} from "@fortawesome/free-solid-svg-icons/faForward";
import {ReactComponent as DeleteLeftIcon} from '../../images/backspace.svg'
import CheckboxActiveIcon from '../../images/smile_icon.png'
import {Checkbox} from "../common/Checkbox";
import {useScreenSize} from "../../hooks/useScreenSize";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const enum Step {current, create, confirm}

interface PinCodePageProps {
  onCodeConfirmed: (code: string, usePinForPurchases: boolean) => void
  onContinueWithoutPin?: () => void
  showUsePinForPurchases?: boolean
  currentCode?: string
  changePin?: boolean
}

const CODE_LENGTH = 4

export default function PinCodePage(props: PinCodePageProps) {
  const {t} = useTranslation()
  const {onCodeConfirmed, changePin, onContinueWithoutPin, showUsePinForPurchases} = props
  const [currentCode, setCurrentCode] = useState('')
  const [code, setCode] = useState('')
  const [confirmCode, setConfirmCode] = useState('')
  const [step, setStep] = useState<Step>(changePin ? Step.current : Step.create)
  const [showContinueWithoutPinModal, setShowContinueWithoutPinModal] = useState(false)
  const [usePinForPurchases, setUsePinForPurchases] = useState(true)
  const {width, height} = useScreenSize()

  const keyButtonHeight = useMemo(() => Math.min(90, height / (isMobile ? 10 : 12)), [height])

  const onKeyClick = (value: number) => {

    switch (step) {
      case Step.current:
        const newCurrentCode = currentCode + value.toString()
        setCurrentCode(newCurrentCode)
        if (newCurrentCode.length == CODE_LENGTH) {
          if (newCurrentCode != props.currentCode) {
            showError(t('wrong_current_pin_code'))
            setCurrentCode('')
            return
          }
          setStep(Step.create)
        }
        break
      case Step.create:
        const newCode = code + value.toString()
        setCode(newCode)
        if (newCode.length == CODE_LENGTH) {
          setStep(Step.confirm)
        }
        break
      case Step.confirm:
        const newConfirmCode = confirmCode + value.toString()
        setConfirmCode(newConfirmCode)
        if (newConfirmCode.length == CODE_LENGTH) {
          if (code == newConfirmCode) {
            onCodeConfirmed(code, usePinForPurchases)
          } else {
            showError(t('pin_codes_not_match'))
            setConfirmCode('')
            setCode('')
            setStep(Step.create)
          }
        }
        break
    }
  }

  const numberButton = (value: number) => {
    return <div
      className={classes.KeyButton}
      onClick={() => onKeyClick(value)}
      style={{height: keyButtonHeight}}>
      {value}
    </div>
  }

  const onClearClick = () => {
    switch (step) {
      case Step.current:
        setCurrentCode(currentCode.slice(0, -1))
        break
      case Step.create:
        setCode(code.slice(0, -1))
        break;
      case Step.confirm:
        setConfirmCode(confirmCode.slice(0, -1))
        break;
    }
  }

  const title = () => {
    switch (step) {
      case Step.current:
        return t('enter_current_pin_code')
      case Step.create:
        return t('create_a_pin_code')
      case Step.confirm:
        return t('re_enter_the_pin_code_to_confirm')
    }
  }

  const getCode = () => {
    switch (step) {
      case Step.current:
        return currentCode;
      case Step.create:
        return code;
      case Step.confirm:
        return confirmCode;
    }
  }

  return <div className={classes.PinCodePage}>

    <Spacer height={20}/>

    <h2 className={classes.Title}>
      {title()}
    </h2>

    <Spacer height={20}/>

    <Warning title={t('this_is_not_your_credit_card_pin_code')} black borderless/>

    <Spacer/>

    <div className={classes.PinsContainer}>
      {[...Array(CODE_LENGTH).keys()].map((_, i) => <div
        key={i}
        className={classnames(
          classes.Pin,
          {[classes.PinActive]: i < getCode().length}
        )}
      />)}
    </div>

    <div className={classes.Keyboard}>
      {numberButton(1)}
      {numberButton(2)}
      {numberButton(3)}
      {numberButton(4)}
      {numberButton(5)}
      {numberButton(6)}
      {numberButton(7)}
      {numberButton(8)}
      {numberButton(9)}
      <div className={classes.KeyButtonEmpty} style={{height: keyButtonHeight}}/>
      {numberButton(0)}
      <div className={classes.KeyButton} style={{height: keyButtonHeight}} onClick={onClearClick}>
        <DeleteLeftIcon/>
      </div>
    </div>

    <Spacer/>

    {onContinueWithoutPin && <Spacer height={isMobile ? 100 : 60}/>}

    {onContinueWithoutPin && <div className={classes.ButtonsContainer}>
      <div className={classes.SkipText}>
        <div className={classes.SkipTextLine}>
          😉 {t('i_dont_want_to_use_a_pin_code')}
        </div>

      </div>

      <AppButton
        title={t('skip')}
        onClick={_ => setShowContinueWithoutPinModal(true)}
        className={classes.SkipButton}
        secondary/>
    </div>}

    {showUsePinForPurchases && <div className={classes.ButtonsContainer}>
      <div className={classes.UsePinForPurchasesContainer}>
        <Checkbox
          title={t('use_this_pin_code_for_purchases')}
          checked={usePinForPurchases}
          onChange={setUsePinForPurchases}
        />
      </div>
    </div>}

    {onContinueWithoutPin && showContinueWithoutPinModal && <ConfirmModal
      onConfirm={onContinueWithoutPin}
      opened={showContinueWithoutPinModal}
      onClose={() => setShowContinueWithoutPinModal(false)}
      title={t('register_without_pin')}
      confirmTitle={t('go_without_pin')}
      confirmIcon={faForward}>

      <div>{t('register_without_pin_warning')}</div>
    </ConfirmModal>}

  </div>
}
