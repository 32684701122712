import {

  getOrganizers,
} from '../services/api/api'
import useSWR, {mutate} from 'swr'

export const useOrganizers = () => {
  const {data, error, isLoading} = useSWR('getOrganizers', getOrganizers)
  return {
    organizers: data,
    organizersLoading: isLoading,
    organizersError: error
  }
}

export const refreshOrganizers = async () => {
  await mutate('getOrganizers')
}
