import React, { useEffect, useState } from 'react'
import classes from './AccountMenuView.module.scss'
import useUser, { clearUser } from "../../hooks/useUser";
import { initials } from "../../util/user_util";
// using 'active' variants of icon instead of programmatically changing the color
// because one icon (wristband new) has stroke AND fill. So we have to set fill color
// for it. That breaking other icons. TODO: ask designer to create new wristband icon.
// we are using new icon, because the old icon are totally unreadable
import { ReactComponent as BalanceIcon } from "../../images/balance.svg";
import { ReactComponent as BalanceActiveIcon } from "../../images/balance_active.svg";
import { ReactComponent as WristbandIcon } from "../../images/wristband_icon_black.svg";
import { ReactComponent as WristbandIconActive } from "../../images/wristband_icon.svg";
import { ReactComponent as QrIcon } from "../../images/qr_icon.svg";
import { ReactComponent as QrIconActive } from "../../images/qr_icon_active.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as InfoIconActive } from "../../images/info_icon_active.svg";
import { ReactComponent as CardIcon } from "../../images/credit_card_icon.svg";
import { ReactComponent as CardIconActive } from "../../images/credit_card_icon_active.svg";
import { ReactComponent as FileTextIcon } from "../../images/file_text_icon.svg";
import { ReactComponent as FileTextIconActive } from "../../images/file_text_icon_active.svg";
import { ReactComponent as LogoutIcon } from "../../images/logout_icon.svg";
import { ReactComponent as Globe } from "../../images/globe_regular.svg";
import { Link, useLocation } from "react-router-dom";
import { lastSegment } from "../../util/pathname_util";
import { isDesktop, isMobile } from "react-device-detect";
import { MenuItem } from "../common/MenuItem";
import {faCheckCircle, faCircleMinus, faCode, faGlobe, faUserTie} from "@fortawesome/free-solid-svg-icons";
import { minutesAgo } from "../../util/date_util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import useSystemSettings from "../../hooks/useSystemSettings";
import { defaultAgeVerificationValidTime, version } from "../../config/constants";
import { DateTime } from "luxon";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import { formatTime } from "../../util/time_util";
import { ClearButton } from "../common/ClearButton";
import classnames from "classnames";
import {ModalProps, Spacer, useAppContext} from "../common";
import {useTranslation} from "react-i18next";
import {clearNotShowQrRegistrationInfoAgain} from "../../services/storage";
import {Locale} from "../../store/store";
import Modal from "../common/Modal";
import FlagUk from "../../images/flag_uk.svg";
import FlagEs from "../../images/flag_es.svg";
import FlagBr from "../../images/flag_br.svg";
import {AppImage} from "../common/AppImage";

interface AccountMenuViewProps {
  /**
   * Basically we are taking the selected menu item from the path
   * But when we are scrolling the screen - the parent view should tell us
   * what item is selected now, because we have no access to the info body
   */
  selectedItemByScroll?: number
  /**
   * Inform parent view about we click on item
   * used when we click on item which section _already_ chosen, but we scrolled off from here
   * for example, you are sitting on /account/balance and scroll to "cards"
   * then if you click on balance - nothing happens, because pathname hasn't been changed
   * so we inform parent view in thie case.
   */
  onSelectedItemClick?: (index: number) => void
}

export const AccountMenuView: React.FC<AccountMenuViewProps> = (props) => {

  const {t} = useTranslation()
  const {user, isClientAdmin, isBillfoldAdmin} = useUser()
  const {avatarUrl, firstName, email} = user ?? {}
  const {pathname} = useLocation()
  const [showLangModal, setShowLangModal] = useState(false)

  const onLogoutClick = async () => {
    clearNotShowQrRegistrationInfoAgain()
    await clearUser()
  }

  const item = (path: string, title: string, icon: any, iconActive: any) => {
     return <Link to={path}>
       <MenuItem
         selected={lastSegment(pathname) == path}
         title={title}
         icon={icon}
         iconActive={iconActive}/>
     </Link>
  }

  return <div className={classes.AccountMenuView}>

    <h1 className={'mt-2'}>{t('account')}</h1>

    <div className={classes.UserInfoContainer}>
      <div className={classes.Avatar}>
        {avatarUrl && <AppImage
          src={avatarUrl}
          alt={firstName}
          className={classes.Image}/>}

        {!avatarUrl && initials(user)}

      </div>

      <div>

        <div className={classes.Email}>
          {email}
        </div>

        {isClientAdmin && <div className={classes.AdminBadge}>{t('admin')}</div>}

        {isBillfoldAdmin && <div className={classes.AdminBadge} style={{width: 68}}>{t('super_admin')}</div>}

      </div>
    </div>

    {/*hide it for now*/}
    {/*<AgeVerificationBadge/>*/}

    <div className={classes.MenuItems}>

      {/*{item('balance', t('balance'), BalanceIcon, BalanceIconActive)}*/}

      {item('wristbands', t('wristbands'), WristbandIcon, WristbandIconActive)}

      {item('registrations', t('online_registrations'), QrIcon, QrIconActive)}

      {item('info', t('account_info'), InfoIcon, InfoIconActive)}

      {/*{item('age-verification', t('age-verification'), undefined, undefined)}*/}

      {item('cards', t('cards'), CardIcon, CardIconActive)}

      {item('history', t('history'), FileTextIcon, FileTextIconActive)}

      {isBillfoldAdmin && <Link to={'/admin'}>
        <MenuItem
          title={t('admin')}
          faIcon={faUserTie}/>
      </Link>}

      {isBillfoldAdmin && <Link to={process.env.REACT_APP_ADMIN_DOCS_URL ?? ''}>
        <MenuItem
          title={'API'}
          faIcon={faCode}/>
      </Link>}

      <MenuItem
        title={t('language')}
        icon={Globe}
        iconActive={Globe}
        onClick={() => setShowLangModal(true)}/>

      <MenuItem
        title={t('logout')}
        icon={LogoutIcon}
        iconActive={LogoutIcon}
        onClick={onLogoutClick}/>

      {isMobile && <div className={classes.Version}>
        v{version}-{process.env.REACT_APP_ENV}
      </div>}

    </div>

    {isMobile && <Spacer height={80}/>}


    <LangModal
      opened={showLangModal}
      onClose={() => setShowLangModal(false)}
    />

  </div>

}

export const AgeVerificationBadge: React.FC<AccountMenuViewProps> = (props) => {

  const {user} = useUser()
  const {settings} = useSystemSettings()
  const {ageVerificationValidTime} = settings ?? {}
  const {verifiedOver21, verifiedOver21Time} = user ?? {}
  const [secondsBeforeExpiration, setSecondsBeforeExpiration] = useState<number>()

  useEffect(() => {
    if (verifiedOver21Time && settings && user) {
      const validMinutes = ageVerificationValidTime ?? defaultAgeVerificationValidTime
      const expirationTime = DateTime.fromISO(verifiedOver21Time).plus({minutes: validMinutes})
      setSecondsBeforeExpiration(Math.floor(expirationTime.diffNow('seconds').seconds))
    }
  }, [settings, verifiedOver21Time, user])

  useEffect(() => {
    if (!!secondsBeforeExpiration && secondsBeforeExpiration > 0) {
      const intervalId = setInterval(() => {
        if (secondsBeforeExpiration > 0) {
          setSecondsBeforeExpiration(secondsBeforeExpiration - 1);
        } else {
          setSecondsBeforeExpiration(0);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [secondsBeforeExpiration]);

  const verified = verifiedOver21 != undefined

  const expired = minutesAgo(verifiedOver21Time) > (ageVerificationValidTime ?? defaultAgeVerificationValidTime)

  const over21 = verifiedOver21 == true && !expired

  const under21 = verifiedOver21 == false && !expired

  const icon = () => {
    if (!verified) {
      return faCircleMinus
    }

    if (verified && expired) {
      return faClock
    }

    if (over21) {
      return faCircleCheck
    }

    if (under21) {
      return faCircleXmark
    }

    return faCircleMinus

  }

  const title = () => {
    if (!verified) {
      return 'Age not verified'
    }

    if (verified && expired) {
      return 'Age verification expired'
    }

    if (over21) {
      return 'Over 21'
    }

    if (under21) {
      return 'Under 21'
    }

    return ''
  }

  const message = () => {
    if (!verified) {
      return 'You have to show the ID at the entrance'
    }

    if (expired) {
      return 'Please re-verify the age with CLEAR to get the fast pass to the event'
    }

    const expireMessage = !!secondsBeforeExpiration ? `Verification will expire in ${formatTime(secondsBeforeExpiration)}` : ''

    if (over21) {
      return `Show this at the entrance to get the fast pass. ${expireMessage}`
    }

    if (under21) {
      return `You must be over 21 to participate in the event. Please wait for the next event or contact the event organizer to have the special pass. ${expireMessage}`
    }

    return ''
  }

  return <div>
    {user && settings && <div className={classnames({
      [classes.BadgeNeutral]: !verified,
      [classes.BadgeExpired]: expired,
      [classes.BadgeVerified]: over21,
      [classes.BadgeTooYoung]: under21,
    })}>

      <div className={'d-flex'}>
        <FontAwesomeIcon icon={icon()} className={classnames({
          [classes.NotVerifiedIcon]: !verified,
          [classes.ExpiredIcon]: verified && expired,
          [classes.VerifiedIcon]: over21,
          [classes.TooYoungIcon]: under21,
        })}/>
        <div>
          <div className={classes.Title}>{title()}</div>
          <div className={classes.Message}>{message()}</div>
        </div>
      </div>

      {(!verified || expired || under21) && <ClearButton
        under21={under21}
        verified={verified}
        expired={expired}
        title={isDesktop ? 'Verify with CLEAR®' : undefined}
        redirectToPage={'/account'}/>}

    </div>}
  </div>
}

const LangModal = (props: ModalProps) => {

  const {locale, setLocale} = useAppContext()
  const {i18n, t} = useTranslation()

  const item = (iconImage: string, title: string, loc: Locale) => {

    const selected = loc == locale
    return <div className={selected ? classes.ItemSelected : classes.Item} onClick={() => {
      setLocale(loc)
      props.onClose()
      i18n.changeLanguage(loc)
    }}>
      <div className={classes.IconImage}>
        <img src={iconImage}/>
      </div>
      <div className={classes.ItemTitle}>
        {title}
      </div>

      <Spacer/>

      {selected && <FontAwesomeIcon
        icon={faCheckCircle}
        className={classes.Icon}/>}

    </div>
  }

  return <Modal
    {...props}
    title={t('select_language')}>

    {item(FlagUk, 'English', 'en')}
    {item(FlagEs, 'Español', 'es')}
    {item(FlagBr, 'Português', 'pt')}

  </Modal>
}

