import React from 'react'
import classes from './TransactionIcon.module.scss'
import { BalanceTransaction, BalanceTransactionType } from "../../services/api/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoinSign } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CashIcon } from "../../images/cash.svg";
import { ReactComponent as PromoCashIcon } from "../../images/promo_cash.svg";
import { ReactComponent as BankCardIcon } from "../../images/bank_card.svg";
import { ReactComponent as MastercardIcon } from "../../images/mastercard.svg";
import { ReactComponent as VisaIcon } from "../../images/visa.svg";
import { useCard } from "../../hooks/useCard";

interface TransactionSourceIconProps {
  transaction: BalanceTransaction
}

export const TransactionSourceIcon: React.FC<TransactionSourceIconProps> = ({transaction}) => {
  const {card} = useCard(transaction.paymentMethodID)

  const icon = () => {
    switch (transaction.type) {
      case BalanceTransactionType.cashTopUp:
      case BalanceTransactionType.cashRefund:
        return <CashIcon className={classes.Icon}/>
      case BalanceTransactionType.bankCardCashTopUp:
      case BalanceTransactionType.bankCardCashRefund:

        if (card?.brand == 'visa') {
          return <div className={classes.BankCardContainer}>
            <div className={classes.BankCard}>
              <VisaIcon className={classes.BankCardIcon}/>
            </div>
          </div>
        }

        if (card?.brand == 'mastercard') {
          return <div className={classes.BankCardContainer}>
            <div className={classes.BankCard}>
              <MastercardIcon className={classes.BankCardIcon}/>
            </div>
          </div>
        }

        return <div className={classes.BankCardContainer}>
          <div className={classes.BankCard}>
            <BankCardIcon className={classes.BankCardIcon}/>
          </div>
        </div>
      case BalanceTransactionType.promoCashTopUp:
      case BalanceTransactionType.promoCashRefund:
        return <PromoCashIcon className={classes.Icon}/>
      case BalanceTransactionType.cryptoTopUp:
      case BalanceTransactionType.cryptoRefund:
        return <div className={classes.CryptoTopUpContainer}>
          <div className={classes.CryptoTopUp}>
            <FontAwesomeIcon icon={faBitcoinSign} className={classes.BitcoinSign}/>
          </div>
        </div>
      default:
        return <div className={classes.IconPlaceholder}/>
    }
  }

  return <div className={classes.TransactionIcon}>
    {icon()}
  </div>
}
