import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classes from './PeriodModal.module.scss'
import { DOMContainer } from "@restart/ui/useWaitForDOMRef";
import { VirtualElement } from "@restart/ui/usePopper";
import { OverlayProps } from "react-bootstrap/Overlay";
import { Overlay } from "react-bootstrap";
import {
  formatDate,
  minusDay,
  minusMonth,
  minusWeek,
  minusYear,
  periodTitle,
  plusDay,
  plusMonth,
  plusWeek,
  plusYear,
  startOfDay,
  startOfMonth,
  startOfNextMonth,
  startOfNextWeek,
  startOfNextYear,
  startOfWeek,
  startOfYear,
  tomorrow,
  yesterday
} from "../../util/date_util";
import Modal, { ModalProps } from "../common/Modal";
import { AppButton, AppInput, Spacer } from "../common";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faChevronLeft, faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { DivOrNull } from "./AccountView";
import { divTop } from "../../util/div_util";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "@mui/material";
import { DatePicker } from "../common/DatePicker";

const itemHeight = 60

interface PeriodModalProps {
  initialRangeType?: RangeType
  initialFrom?: Date
  initialTo?: Date
  onSave: (type?: RangeType, from?: Date, to?: Date) => void
  onDelete: VoidFunction
  onHide?: (e: any) => void
  show: boolean
}

interface PeriodOverlayProps {
  target: DOMContainer<HTMLElement | VirtualElement>
}

export enum RangeType { today, yesterday, week, month, year, range }

const rangeTypeNames: Record<RangeType, string> = {
  [RangeType.today]: 'Today',
  [RangeType.yesterday]: 'Yesterday',
  [RangeType.week]: 'This week',
  [RangeType.month]: 'This month',
  [RangeType.year]: 'This year',
  [RangeType.range]: 'Select dates',
}

export const PeriodOverlay: React.FC<PeriodModalProps & PeriodOverlayProps & Partial<OverlayProps>> = (props) => {
  const {
    onHide,
    show,
    target,
  } = props

  return <Overlay
    rootClose
    onHide={onHide}
    show={show}
    target={target}
    placement='bottom-start'>
    <div>
      <PeriodModalContent {...props}/>
    </div>
  </Overlay>

}

const PeriodModalContent: React.FC<PeriodModalProps> = (props) => {

  const {
    onSave,
    onDelete,
    onHide,
    initialFrom,
    initialTo,
    initialRangeType
  } = props

  const [showCalendar, setShowCalendar] = useState(initialRangeType == RangeType.range)

  const onDeleteClick = (e: any) => {
    onDelete && onDelete()
    onHide && onHide(e)
  }

  const onPeriodSelect = (type: RangeType) => {
    switch (type) {
      case RangeType.today:
        onSave(type, startOfDay(), startOfDay(tomorrow()))
        break
      case RangeType.yesterday:
        onSave(type, startOfDay(yesterday()), startOfDay())
        break
      case RangeType.week:
        onSave(type, startOfWeek(), startOfNextWeek())
        break
      case RangeType.month:
        onSave(type, startOfMonth(), startOfNextMonth())
        break
      case RangeType.year:
        onSave(type, startOfYear(), startOfNextYear())
        break
      case RangeType.range:
        onSave(type, startOfYear(), startOfNextYear())
        break
      default:
        return ''
    }
  }

  const onRangeSelected = (dateFrom?: Date, dateTo?: Date) => {
    onSave(RangeType.range, dateFrom, dateTo)
    setShowCalendar(false)
  }

  const onRangeClick = () => {
    setShowCalendar(true)
  }

  const onRangeBackClick = () => {
    setShowCalendar(false)
  }

  return <div>
    {showCalendar && <div className={classes.PeriodModalCalendar}>
      <SelectDatesContent
        initialDateFrom={initialFrom}
        initialDateTo={initialTo}
        onSave={onRangeSelected}
        onCancel={onDeleteClick}
      onBackClick={onRangeBackClick}/>
    </div>}

    {!showCalendar && <div className={classes.PeriodModal}>

      <Item type={RangeType.today} onSelect={onPeriodSelect}/>
      <Item type={RangeType.yesterday} onSelect={onPeriodSelect}/>
      <Item type={RangeType.week} onSelect={onPeriodSelect}/>
      <Item type={RangeType.month} onSelect={onPeriodSelect}/>
      <Item type={RangeType.year} onSelect={onPeriodSelect}/>
      <Item type={RangeType.range} onSelect={onRangeClick}/>

      {(initialFrom || initialTo) && <div className={classes.ClearButtonContainer}>
        <AppButton
          danger
          onClick={onDeleteClick}
          title={'Clear'}
          icon={faTrashAlt}
          className={classes.ClearButton}/>
      </div>}

    </div>}
  </div>

}

interface ItemProps {
  type: RangeType
  onSelect: (type: RangeType) => void
}

const Item: React.FC<ItemProps> = ({type, onSelect}) => {

  const onClick = () => {
    onSelect(type)
  }

  return <div className={classes.Item} onClick={onClick}>{rangeTypeNames[type]}</div>
}

export const PeriodModalMobile: React.FC<PeriodModalProps & ModalProps> = (props) => {
  const {
    onHide,
    onSave,
    onDelete,
    initialFrom,
    initialTo,
    initialRangeType
  } = props

  const container = useRef<DivOrNull>(null)
  const firstItem = useRef<DivOrNull>(null)
  const todayRef = useRef<DivOrNull>(null)
  const yesterdayRef = useRef<DivOrNull>(null)
  const weekRef = useRef<DivOrNull>(null)
  const monthRef = useRef<DivOrNull>(null)
  const yearRef = useRef<DivOrNull>(null)
  const rangeRef = useRef<DivOrNull>(null)
  const [rangeType, setRangeType] = useState(initialRangeType)
  const [dateFrom, setDateFrom] = useState(initialFrom)
  const [dateTo, setDateTo] = useState(initialTo)
  const [scrolling, setScrolling] = useState(false)
  const [selectingDateFrom, setSelectingDateFrom] = useState(false)
  const [selectingDateTo, setSelectingDateTo] = useState(false)
  const [blockScrollListener, setBlockScrollListener] = useState(false)

  const rangeTypeRefs = [todayRef, yesterdayRef, weekRef, monthRef, yearRef, rangeRef]

  useEffect(() => {
    onScroll()
  }, [])

  useEffect(() => {
    if (rangeType) {
      rangeTypeRefs[rangeType]?.current?.scrollIntoView()
    }
  }, [selectingDateTo, selectingDateFrom])

  useEffect(() => {
    updateDates(rangeType)
  }, [rangeType])

  useEffect(() => {
    if (initialRangeType) {
      setRangeType(initialRangeType)
    }

    if (initialFrom) {
      setDateFrom(initialFrom)
    }

    if (initialTo) {
      setDateTo(initialTo)
    }
  }, [initialFrom, initialTo, initialRangeType])

  const onSaveClick = (e: any) => {
    onSave(rangeType, dateFrom, dateTo)
    onHide && onHide(e)
  }

  const onDeleteClick = (e: any) => {
    setDateFrom(undefined)
    setDateTo(undefined)
    setRangeType(undefined)
    onDelete && onDelete()
    onHide && onHide(e)
  }

  const onScroll = useCallback(() => {

    if (blockScrollListener) {
      return
    }

    if (!scrolling) {
      setScrolling(true)
      setDateFrom(undefined)
      setDateTo(undefined)
    }
    onScrollDebounced()
  }, [blockScrollListener])

  /**
   * Move this logic to the separated function
   * because reacting on changing the rangeType state is not enough
   * There is a situation
   * - User sit on range type
   * - User do micro-scrolling
   * - Dates set to undefined immediately (this is the current logic)
   * - Scrolling finished, we set the range type
   * - but this is the SAME range type, so useEffect doesn't work and dates are still undefined
   * - This leads to bugs in behavior.
   * - So we call it manually every time we set the range type to prevent dates being undefined
   * @param rangeType
   */
  const updateDates  = (rangeType: RangeType | undefined) => {
    switch (rangeType) {
      case RangeType.today:
        setDateFrom(startOfDay())
        setDateTo(startOfDay(tomorrow()))
        break
      case RangeType.yesterday:
        setDateFrom(startOfDay(yesterday()))
        setDateTo(startOfDay())
        break
      case RangeType.week:
        setDateFrom(startOfWeek())
        setDateTo(startOfNextWeek())
        break
      case RangeType.month:
        setDateFrom(startOfMonth())
        setDateTo(startOfNextMonth())
        break
      case RangeType.year:
        setDateFrom(startOfYear())
        setDateTo(startOfNextYear())
        break
    }
  }

  const onScrollDebounced = useCallback(debounce((e?: any) => {
    const topOffset = divTop(firstItem) - divTop(container)
    const position = Math.floor(Math.abs(topOffset) / itemHeight)
    if (position == 0) {
      setRangeType(undefined)
    } else {
      setRangeType(position - 1)
      updateDates(position - 1)
    }
    setScrolling(false)
  }, 100), [])

  const handleLeftClick = (e: any) => {
    switch (rangeType) {
      case RangeType.today:
        setDateFrom(minusDay(dateFrom))
        setDateTo(minusDay(dateTo))
        break
      case RangeType.yesterday:
        setDateFrom(minusDay(dateFrom))
        setDateTo(minusDay(dateTo))
        break
      case RangeType.week:
        setDateFrom(minusWeek(dateFrom))
        setDateTo(minusWeek(dateTo))
        break
      case RangeType.month:
        setDateFrom(minusMonth(dateFrom))
        setDateTo(minusMonth(dateTo))
        break
      case RangeType.year:
        setDateFrom(minusYear(dateFrom))
        setDateTo(minusYear(dateTo))
        break
      case RangeType.range:
      default:
        // do nothing
        break
    }
  }

  const handleRightClick = (e: any) => {
    console.log(`handleRightClick`)
    if (disableRightButton) {
      return
    }
    switch (rangeType) {
      case RangeType.today:
        setDateFrom(plusDay(dateFrom))
        setDateTo(plusDay(dateTo))
        break
      case RangeType.yesterday:
        setDateFrom(plusDay(dateFrom))
        setDateTo(plusDay(dateTo))
        break
      case RangeType.week:
        setDateFrom(plusWeek(dateFrom))
        setDateTo(plusWeek(dateTo))
        break
      case RangeType.month:
        setDateFrom(plusMonth(dateFrom))
        setDateTo(plusMonth(dateTo))
        break
      case RangeType.year:
        setDateFrom(plusYear(dateFrom))
        setDateTo(plusYear(dateTo))
        break
      case RangeType.range:
      default:
        // do nothing
        break

    }
  }

  const title = (type: RangeType): string | undefined => {
    const useDefault = !dateFrom || !dateTo || type != rangeType || scrolling

    if (useDefault) {
      return rangeTypeNames[type]
    }

    return periodTitle(type, dateFrom, dateTo)
  }

  const onSelectFromDateClick = () => {
    setSelectingDateFrom(true)
  }

  const onSelectToDateClick = () => {
    setSelectingDateTo(true)
  }

  const datesRangeItem = () => {
    return <div className={classes.SelectDatesItem} ref={rangeRef}>
      <div
        className={classes.TextButton}
        onClick={onSelectFromDateClick}>
        {dateFrom ? formatDate(dateFrom, 'd MMM') : 'From'}
      </div>
      <div className={'mx-4'}>-</div>
      <div
        className={classes.TextButton}
        onClick={onSelectToDateClick}>
        {dateTo ? formatDate(dateTo, 'd MMM') : 'To'}
      </div>
    </div>
  }

  const selectorsContent = () => {
    return <div>
      <div className={classes.Selector}>

        <div
          style={{opacity: (rangeType == undefined || rangeType == RangeType.range) ? 0 : 1}}
          className={classnames(classes.ArrowButton, 'me-2')}
          onClick={handleLeftClick}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={classes.Icon}/>
        </div>

        <div className={classes.ScrollableItems} onScroll={onScroll} ref={container}>
          <div
            className={classes.Item}
            ref={firstItem}>
            Select period
          </div>

          <div className={classes.Item} ref={todayRef}>{title(RangeType.today)}</div>
          <div className={classes.Item} ref={yesterdayRef}>{title(RangeType.yesterday)}</div>
          <div className={classes.Item} ref={weekRef}>{title(RangeType.week)}</div>
          <div className={classes.Item} ref={monthRef}>{title(RangeType.month)}</div>
          <div className={classes.Item} ref={yearRef}>{title(RangeType.year)}</div>

          {datesRangeItem()}
        </div>

        <div
          style={{opacity: (rangeType == undefined || rangeType == RangeType.range) ? 0 : 1}}
          className={classnames(disableRightButton ? classes.ArrowButtonDisabled : classes.ArrowButton, 'ms-2')}
          onClick={handleRightClick}>
          <FontAwesomeIcon
            icon={faChevronRight}
            className={classes.Icon}/>
        </div>

      </div>

      <Spacer height={20}/>

      <div className={'d-flex'}>

        <AppButton
          onClick={onDeleteClick}
          title={'Clear'}
          icon={faTrashAlt}
          className={classes.PeriodModalButton}
          danger/>

        <Spacer width={10}/>

        <AppButton
          onClick={onSaveClick}
          title={'Done'}
          icon={faCheck}
          className={classes.PeriodModalButton}/>

      </div>
    </div>
  }

  const disableRightButton = useMemo(() => {
    return !dateTo || (!!dateTo && dateTo >= new Date())
  }, [dateTo])

  return <Modal
    {...props}
    title={'Period'}>

    <div className={classes.PeriodModalMobile}>

      {!selectingDateFrom && !selectingDateTo && selectorsContent()}

      {selectingDateFrom && <DatePicker
        onChange={(date) => {
          setSelectingDateFrom(false)
          setDateFrom(date ?? undefined)
          if (rangeType) {
            // we need to scroll back to needed position after
            // re-render the scrolling view
            setBlockScrollListener(true)
            rangeTypeRefs[rangeType]?.current?.scrollIntoView()
            setTimeout(() => setBlockScrollListener(false), 1000)
          }
        }}
        date={dateFrom}/>}

      {selectingDateTo && <DatePicker
        onChange={(date) => {
          setSelectingDateTo(false)
          setDateTo(date ?? undefined)
          if (rangeType) {
            setBlockScrollListener(true)
            rangeTypeRefs[rangeType]?.current?.scrollIntoView()
            setTimeout(() => setBlockScrollListener(false), 1000)
          }
        }}
        date={dateTo}
        minDate={dateFrom}/>}

    </div>

  </Modal>

}

interface SelectDatesContentProps {
  initialDateFrom?: Date
  initialDateTo?: Date
  onCancel: (e: any) => void
  onSave: (dateFrom?: Date, dateTo?: Date) => void
  onBackClick: VoidFunction
}

const SelectDatesContent: React.FC<SelectDatesContentProps> = (props) => {

  const {
    initialDateFrom,
    initialDateTo,
    onSave,
    onCancel   ,
    onBackClick
  } = props

  console.log(`SelectDatesContent - ${initialDateFrom}, ${initialDateTo}`)

  const [dateFrom, setDateFrom] = useState(initialDateFrom)
  const [dateTo, setDateTo] = useState(initialDateTo)
  const [dateFromFocused, setDateFromFocused] = useState(true)
  const [dateToFocused, setDateToFocused] = useState(false)

  const onDateChanged = (date: Date | null) => {
    //setDateFromError(false)
    if (dateFromFocused) {
      setDateFrom(date ?? undefined)
      if (!!date && !!dateTo && date >= dateTo) {
        setDateTo(undefined)
      }
      setDateFromFocused(false)
      setDateToFocused(true)
    }
    if (dateToFocused) {
      setDateTo(date ?? undefined)
      setDateFromFocused(false)
      setDateToFocused(false)
    }
  }

  const onSavePressed = () => {
    if (!dateFrom && !dateTo) {
      return
    }
    onSave(dateFrom, dateTo)
  }

  const onDateFromFocus = () => {
    setDateFromFocused(true)
    setDateToFocused(false)
  }

  const onDateToFocus = () => {
    setDateFromFocused(false)
    setDateToFocused(true)
  }

  const onCancelClick = (e: any) => {
    setDateFrom(undefined)
    setDateTo(undefined)
    setDateFromFocused(true)
    setDateToFocused(false)
    //setDateFromError(false)
    onCancel(e)
  }

  const onDateFromClear = () => {
    setDateFrom(undefined)
  }

  const onDateToClear = () => {
    setDateTo(undefined)
  }

  return <div className={classes.SelectDatesContent}>

    <div className={classes.Header}>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className={classes.BackButton}
        onClick={onBackClick}/>

      <h2 className={classes.Title}>Select dates</h2>

    </div>

    <Spacer height={16}/>

    <div className={classes.Inputs}>

      <AppInput
        initialValue={formatDate(dateFrom, 'd MMM')}
        onFocus={onDateFromFocus}
        placeholder={'From'}
        focusedVisible={dateFromFocused}
        showClearButton
        onClear={onDateFromClear}/>

      <Spacer width={10}/>

      <AppInput
        initialValue={formatDate(dateTo, 'd MMM')}
        onFocus={onDateToFocus}
        placeholder={'To'}
        focusedVisible={dateToFocused}
        showClearButton
        onClear={onDateToClear}/>

    </div>

    <Spacer height={10}/>

    <DatePicker
      onChange={onDateChanged}
      date={dateFromFocused ? dateFrom : dateTo}
      minDate={dateToFocused ? dateFrom : undefined}/>

    <Spacer height={10}/>

    <div className={classes.Buttons}>
      <AppButton
        title={'Clear'}
        onClick={onCancelClick}
        className={classes.RangeButton}
        icon={faTrashAlt}
        danger
      />

      <Spacer width={10}/>

      <AppButton
        title={'Save'}
        onClick={onSavePressed}
        icon={faCheck}
        className={classes.RangeButton}
        disabled={!dateFrom && !dateTo}/>
    </div>

  </div>
}

