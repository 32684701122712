import React, {MutableRefObject, useState} from 'react'
import {Navigate, Route, Routes} from "react-router-dom";
import classes from "./AccountView.module.scss";
import AccountInfoView from "./AccountInfoView";
import {WristbandDetailsView} from "../common/WristbandDetailsView";
import {isBrowser, isMobile} from "react-device-detect";
import {AccountMenuView} from "./AccountMenuView";
import {AccountWristbandsView} from "./AccountWristbandsView";
import CardsView from "./CardsView";
import HistoryView from "./HistoryView";
import {Spacer} from "../common";
import {AgeVerificationView} from "./AgeVerificationView";

export type DivOrNull = HTMLDivElement | null

export type DivRef = MutableRefObject<DivOrNull>

export default function AccountView() {

  const [selectedMenuItem, setSelectedMenuItem] = useState(0)

  const mobileContent = () => {
    return <div className={classes.AccountView}>

      <Routes>
        <Route path={'/'} element={<AccountMenuView/>}/>
      </Routes>

      <Routes>
        {/*<Route path={'/balance'} element={<BillfoldBalanceView/>}/>*/}
        <Route path={'/wristband/:wristbandId/*'} element={<WristbandDetailsView/>}/>
        <Route path={'/wristbands'} element={<AccountWristbandsView/>}/>
        <Route path={'/registrations'} element={<AccountWristbandsView isQr/>}/>
        <Route path={'/info'} element={<AccountInfoView/>}/>
        <Route path={'/age-verification'} element={<AgeVerificationView/>}/>
        <Route path={'/cards'} element={<CardsView/>}/>
        <Route path={'/history'} element={<HistoryView/>}/>
      </Routes>

    </div>
  }

  const browserContent = () => {
    return <div className={classes.AccountView}>

      <div className={classes.Menu}>
        <AccountMenuView
          selectedItemByScroll={selectedMenuItem}
          onSelectedItemClick={setSelectedMenuItem}/>
      </div>

      <Spacer width={40}/>

      <Routes>

        <Route path='/wristband/:wristbandId' element={
          <div className={classes.AccountViewContent}>
            <WristbandDetailsView/>
          </div>
        }/>

        <Route path='/wristbands' element={
          <div className={classes.AccountViewContent}>
            <AccountWristbandsView key={'wristbands'}/>
          </div>
        }/>

        <Route path='/registrations' element={
          <div className={classes.AccountViewContent}>
            <AccountWristbandsView isQr key={'registrations'}/>
          </div>
        }/>

        <Route path='/info' element={
          <div className={classes.AccountViewContent}>
            <AccountInfoView/>
          </div>
        }/>

        <Route path='/cards' element={
          <div className={classes.AccountViewContent}>
            <CardsView/>
          </div>
        }/>

        <Route path='/history' element={
          <div className={classes.AccountViewContent}>
            <HistoryView/>
          </div>
        }/>

        <Route path='/*' element={
          <Navigate to={'/account/wristbands'}/>
        }/>

      </Routes>

    </div>
  }

  return <>
    {isMobile && mobileContent()}

    {isBrowser && browserContent()}
  </>
}
