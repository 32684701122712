import React, { useEffect } from 'react'
import PlaceholderView from "../common/PlaceholderView";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { useIntercom } from 'react-use-intercom';

export function SupportView() {

  const {boot, shutdown, show} = useIntercom()

  useEffect(() => {
    boot()
    show()

    return () => {
      shutdown()
    }
  }, [])

  return <PlaceholderView title={'Support'} icon={faCommentDots}/>

}
