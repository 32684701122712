import React from 'react'
import classes from './AccountSectionTitle.module.scss'
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface AccountSectionTitleProps {
  title: string
  previousRoute?: string
}

export function AccountSectionTitle({title, previousRoute}: AccountSectionTitleProps) {
  const navigate = useNavigate()

  const onBackClick = () => {
    // noo this is bad
    if (previousRoute) {
      navigate(previousRoute)
    } else {
      navigate(-1)
    }
  }

  return <div className={classes.AccountSectionTitle}>

    {isMobile && <FontAwesomeIcon
      icon={faArrowLeft}
      onClick={onBackClick}
      className={classes.BackButton}/>}

    <h1>{title}</h1>

  </div>
}
