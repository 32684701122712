export const previousVersion = '0.2.49'
export const version = '0.2.50'
export const fallbackLocale = 'en'
export const apiDefaultPageSize = 1
export const defaultAgeVerificationValidTime = 1
// todo move to the env vars
export const intercomAppId = 'xeyid838'
export const wristbandNumberMinLength = 5
export const wristbandNumberMaxLength = 16
export const maxSymbolsCutLength = 100
export const countryCodeBR = '+55'
export const countryCodeUS = '+1'
export const countryCodeRU = '+7'
export const adyenCurrency = 'BRL'
export const adyenCurrencySamsungPay = 'USD'
export const adyenAuthAmountInCents = 10
export const adyenCountryCode = 'BR'
export const maxGalleryImageSizeMb = 2
export const maxRetryCount = 10
