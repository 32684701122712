import classes from "./BounceInView.module.scss";
import React from "react";

interface BounceInViewProps {
  children?: any
}

export const BounceInView = (props: BounceInViewProps) => {
  return <div className={classes.BounceInView}>
    <div className={classes.Child}>
      {props.children}
    </div>
  </div>
}
