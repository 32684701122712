/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "pay",
            "endpoint": "https://00k54y6v5h.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jo7okcvjubev7gnsdfu4jxufza.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jc6ifbjmzra77ijuz2ndnw5cuy",
    "aws_cognito_identity_pool_id": "us-east-2:6bf3f9cf-9957-4298-8750-199ad5d8f378",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qBJCOzGSO",
    "aws_user_pools_web_client_id": "6j6aogq5hd2cs6kacffpn4k5lo",
    "oauth": {
        "domain": "wallet-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.billfold.app/auth/callback/,http://localhost:3000/auth/callback/,myapp://,https://www.billfold.app/auth/callback/",
        "redirectSignOut": "https://www.billfold.app/auth/,http://localhost:3000/auth/,myapp://,https://www.billfold.app/auth/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wallet-storage-bucket151743-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
