import {getProfile, isClientAdmin, isBillfoldAdmin, updateUser} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import { Auth } from "aws-amplify";
import useSWRMutation from "swr/mutation";
import {User} from "../services/api/models";

export default function useUser() {

  const {data, error, isLoading} = useSWR('getProfile', getProfile)
  const {data: isUserClientAdmin, isLoading: isClientAdminLoading} = useSWR('isClientAdmin', isClientAdmin)
  const {data: isUserBillfoldAdmin, isLoading: isBillfoldAdminLoading} = useSWR('isBillfoldAdmin', isBillfoldAdmin)

  return {
    user: data,
    userLoading: isLoading,
    userLoadingError: error,
    isClientAdmin: isUserClientAdmin,
    isBillfoldAdmin: isUserBillfoldAdmin,
    isAdminLoading: isClientAdminLoading || isBillfoldAdminLoading
  }
}

export const clearUser = async () => {
  await Auth.signOut()
  await mutate('getProfile', null)
}

export const refreshUser = async (user?: User) => {
  await mutate('getProfile', user)
}

export const useUpdateUser = () => {
  const {trigger, isMutating, error} = useSWRMutation<User, string, string, User>('updateUser', updateUserFetcher)
  return {
    updateUser: trigger,
    updatingUser: isMutating,
    updateUserError: error
  }
}

const updateUserFetcher = async (url: string, data: { arg: User }):Promise<User> => {
  return updateUser(data.arg)
}
