import React, {useEffect, useState} from 'react'
import classes from './PersonInfoPage.module.scss'
import {AppButton, AppDateInput, AppInput, InputType, Spacer} from "../common";
import {Wristband} from "../../services/api/models";
import {showError} from "../../util/toast_util";
import {isEmailValid} from "../../util/validation_util";
import {useParams, useSearchParams} from "react-router-dom";
import {capitalize, isEmpty, isNotEmpty} from "../../util/string_util";
import {useWristbands} from "../../hooks/useWristbands";
import useUser from "../../hooks/useUser";
import {Checkbox} from "../common/Checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import {isValidPhoneNumber} from "libphonenumber-js";
import {over18} from "../../util/date_util";
import {isMobile} from "react-device-detect";
import {useKeyboardVisibility} from "../../hooks/useKeyboardVisibility";
import {useTranslation} from "react-i18next";
import {getCountryCode} from "../../services/api/geolocation_api";
import {countryCodes} from "../../config/country_codes";

export interface PersonInfo {
  firstName: string
  lastName: string
  email?: string
  phoneNumber?: string
  dateOfBirth?: Date
  newsSubscription?: boolean,
}

interface PersonInfoPageProps {
  onContinue: (info: PersonInfo) => void
  preFilledWristband?: Wristband
}

export default function PersonInfoPage(props: PersonInfoPageProps) {
  const {onContinue, preFilledWristband} = props
  const {t} = useTranslation()
  const {wristbands} = useWristbands()
  const {user} = useUser()
  const {eventId} = useParams() as any
  const [info, setInfo] = useState<PersonInfo>()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [firstNameValid, setFirstNameValid] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [lastNameValid, setLastNameValid] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [phoneNumberValid, setPhoneNumberValid] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState<Date>()
  const [dateOfBirthError, setDateOfBirthError] = useState(false)
  const [dateOfBirthValid, setDateOfBirthValid] = useState(false)
  const [searchParams] = useSearchParams()
  const [country, setCountry] = useState('')
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [agreeToReceiveNews, setAgreeToReceiveNews] = useState(true)
  const [userEffectDone, setUserEffectDone] = useState(false)
  const [wristbandsEffectDone, setWristbandsEffectDone] = useState(false)
  const {keyboardVisible, keyboardHeight} = useKeyboardVisibility()
  const [dialCode, setDialCode] = useState('')

  useEffect(() => {
    if (preFilledWristband) {
      const nameChunks = (preFilledWristband.wristbandOwnerName ?? '').split(' ')
      const firstName = nameChunks[0]
      setFirstName(capitalize(firstName))
      const lastName = nameChunks.length > 1 ? nameChunks[1] : ''
      setLastName(capitalize(lastName))
      setEmail(preFilledWristband.wristbandOwnerEmail ?? '')
      const phone = preFilledWristband.wristbandOwnerPhoneNumber ?? ''
      setPhoneNumber(phone)
      setPhoneNumberValid(isValidPhoneNumber(phone))
    }

    getCountryCode().then((code) => setDialCode(countryCodes[code]))

  }, [])

  useEffect(() => {
    setInfo({
      ...info,
      firstName,
      lastName,
      email,
      phoneNumber,
      dateOfBirth,
      newsSubscription: agreeToReceiveNews,
    } as PersonInfo)
  }, [firstName, lastName, email, phoneNumber, dateOfBirth, agreeToReceiveNews])

  useEffect(() => {
    setFirstNameError(false)
    setFirstNameValid(firstName.length > 0)
  }, [firstName])

  useEffect(() => {
    setLastNameError(false)
    setLastNameValid(lastName.length > 0)
  }, [lastName])

  useEffect(() => {
    setEmailError(false)
    setEmailValid(isEmailValid(email))
  }, [email])

  useEffect(() => {
    setPhoneNumberError(false)
  }, [phoneNumber])

  useEffect(() => {
    if (wristbandsEffectDone || !user) {
      return
    }

    if (isEmpty(email) && !(wristbands ?? []).some(w => w.eventID == eventId)) {
      setEmail(user.email ?? '')
    }

    setWristbandsEffectDone(true)

  }, [wristbands])

  useEffect(() => {
    if (userEffectDone || !user) {
      return
    }

    const {phoneNumber: userPhoneNumber, firstName, lastName} = user

    if (isEmpty(phoneNumber) && isNotEmpty(userPhoneNumber)) {
      setPhoneNumber(userPhoneNumber)
      setPhoneNumberValid(isValidPhoneNumber(userPhoneNumber ?? ''))
    }

    if (isNotEmpty(firstName)) {
      setFirstName(capitalize(firstName))
    }

    if (isNotEmpty(lastName)) {
      setLastName(capitalize(lastName))
    }

    setUserEffectDone(true)
  }, [user])

  useEffect(() => {
    if (agreeToTerms) {
      setTermsError(false)
    }
  }, [agreeToTerms])

  useEffect(() => {
    setDateOfBirthValid(over18(dateOfBirth))
    setDateOfBirthError(!!dateOfBirth && !over18(dateOfBirth))
  }, [dateOfBirth])

  const detectCurrentUserCountry = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const {latitude, longitude} = position.coords;
        const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=YOUR_API_KEY`;

        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            const country = data.results[0].components.country;
            setCountry(country);
          })
          .catch(error => console.error(error));
      }, error => console.error(error));
    } else {
      console.error(t('geolocation_is_not_supported_by_this_browser'));
    }
  }

  const onContinueClick = () => {

    if (isEmpty(firstName)) {
      showError(t('enter_first_name'))
      setFirstNameError(true)
      return
    }

    if (isEmpty(lastName)) {
      showError(t('enter_last_name'))
      setLastNameError(true)
      return
    }

    if (isEmpty(phoneNumber) && isEmpty(email)) {
      showError(t('enter_the_phone_number_and_email'))
      setPhoneNumberError(true)
      setEmailError(true)
      return
    }

    if (isEmpty(phoneNumber)) {
      showError(t('enter_the_phone_number'))
      setPhoneNumberError(true)
      return
    }

    if (isEmpty(email)) {
      showError(t('enter_the_email'))
      setEmailError(true)
      return
    }

    if (!isEmailValid(email)) {
      showError(t('email_is_wrong'))
      setEmailError(true)
      return
    }

    if (!phoneNumberValid) {
      showError(t('phone_number_is_wrong'))
      setPhoneNumberError(true)
      return
    }

    if (!info) {
      showError(t('please_fill_all_the_required_fields'))
      return
    }

    if (!agreeToTerms) {
      setTermsError(true)
      return
    }

    onContinue(info)
  }

  const onPhoneValid = () => {
    setPhoneNumberValid(true)
  }

  const onPhoneInvalid = () => {
    setPhoneNumberValid(false)
  }

  const isEzoo = eventId == process.env.REACT_APP_EZOO_EVENT_ID

  const isKuraGrandBazaar = eventId == process.env.REACT_APP_KURA_GRAND_BAZAAR_EVENT_ID

  const allValidRegular = firstNameValid && lastNameValid && phoneNumberValid && emailValid && agreeToTerms

  const allValidEzoo = allValidRegular && dateOfBirthValid

  const allValid = isEzoo ? allValidEzoo : allValidRegular

  return <div className={classes.PersonInfoPage}>

    <div className={classes.Title}>
      <h2>{t('add_personal_info')}</h2>
    </div>

    <AppInput
      placeholder={t('first_name')}
      onChange={(v) => setFirstName(capitalize(v))}
      error={firstNameError}
      valid={firstNameValid}
      initialValue={firstName}
      infoTitle={t('first_name')}
      infoMessage={t('why_we_need_name_explanation')}
      infoIsCenter/>

    <Spacer height={10}/>

    <AppInput
      placeholder={t('last_name')}
      onChange={(v) => setLastName(capitalize(v))}
      error={lastNameError}
      valid={lastNameValid}
      initialValue={lastName}
      infoTitle={t('last_name')}
      infoMessage={t('why_we_need_name_explanation')}
      infoIsCenter/>

    <Spacer height={10}/>

    <AppInput
      placeholder={t('phone')}
      onChange={setPhoneNumber}
      type={InputType.phone}
      error={phoneNumberError}
      valid={phoneNumberValid}
      initialValue={phoneNumber}
      freshStartPrefix={`${dialCode} `}
      onPhoneValid={onPhoneValid}
      onPhoneInvalid={onPhoneInvalid}
      infoTitle={t('phone')}
      infoMessage={t('why_we_need_phone_explanation')}
      infoIsCenter
      handlePhoneAutoFill/>

    <Spacer height={10}/>

    <AppInput
      placeholder={t('email')}
      onChange={setEmail}
      error={emailError}
      valid={emailValid}
      initialValue={email}
      infoTitle={t('email')}
      type={InputType.email}
      infoMessage={t('why_we_need_email_explanation')}
      infoIsCenter/>

    <Spacer height={10}/>

    {isEzoo && <AppDateInput
      placeholder={t('fate_of_birth')}
      onChange={setDateOfBirth}
      infoTitle={t('fate_of_birth')}
      infoMessage={t('minimum_age_message')}
      error={dateOfBirthError}
      valid={dateOfBirthValid}
    />}

    <Spacer height={16}/>

    <Checkbox
      checked={agreeToTerms}
      onChange={setAgreeToTerms}
      error={termsError}>

      {t('i_accept')} <a
      href={'https://www.billfoldpos.com/webview-pp'}
      target={'_blank'}
      className={classnames(
        classes.TermsLink,
        {
          [classes.TermsLinkError]: termsError,
          [classes.TermsLinkActive]: agreeToTerms
        }
      )}>
      {t('terms_and_conditions')}
    </a>

      <FontAwesomeIcon
        icon={faUpRightFromSquare}
        className={classnames(
          classes.TermsIcon,
          {
            [classes.TermsIconError]: termsError,
            [classes.TermsIconActive]: agreeToTerms
          }
        )}/>
    </Checkbox>

    <Spacer height={4}/>

    <Checkbox
      title={t('receive_news_consent_message')}
      checked={agreeToReceiveNews}
      onChange={setAgreeToReceiveNews}/>

    <Spacer height={20}/>

    {/*<ClearVerificationView redirectToPage={`/event/${eventId}/checkin`}/>*/}

    <AppButton
      title={t('continue')}
      onClick={onContinueClick}
      className={isMobile ? classes.ContinueButtonMobile : classes.ContinueButton}
      disabled={!allValid}
      style={{bottom: keyboardVisible ? keyboardHeight + 16 : 16}}/>

  </div>
}
