import useSWRMutation from "swr/mutation";
import { register } from "../services/api/api";

export const useRegister = () => {
  const {trigger, isMutating} = useSWRMutation<any, any, any, any>('register', register)
  return {
    register: trigger,
    registerInProgress: isMutating,
  }
}
