import React, {useEffect, useState} from 'react'
import classes from './CharityPage.module.scss'
import {AppButton, Spacer} from "../common";
import {useTranslation} from "react-i18next";
import {useCharity} from "../../hooks/useCharity";
import useEvent from "../../hooks/useEvent";
import {useParams} from "react-router-dom";
import {isDesktop, isMobile} from "react-device-detect";
import Lottie from 'lottie-react';
import heartOne from '../../animations/heart_one.json';
import heartTwo from '../../animations/heart_two.json';

interface CharityPageProps {
  onContinue: (isAgree: boolean) => void
}

export default function CharityPage(props: CharityPageProps) {
  const {t} = useTranslation()
  const {onContinue} = props
  const {eventId} = useParams() as any
  const {event} = useEvent(eventId)
  const {charity} = useCharity(event?.charityID ?? '')
  const [showHeartOne, setShowHeartOne] = useState(false)
  const [showHeartTwo, setShowHeartTwo] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowHeartOne(true)
    }, 600)

    setTimeout(() => {
      setShowHeartTwo(true)
    }, 300)
  }, []);

  const onYesClick = () => {
    onContinue(true)
  }

  const onNoClick = () => {
    onContinue(false)
  }

  const yesButton = () => {
    return <AppButton
      className={classes.YesButton}
      onClick={onYesClick}
      title={t('yes')}/>
  }

  const noButton = () => {
    return <AppButton
      secondary
      className={classes.ContinueButton}
      onClick={onNoClick}
      title={t('no')}/>
  }

  const heartsContainer = () => {
    return <div className={classes.HeartsContainer}>

      {showHeartOne && <Lottie
        loop={false}
        autoplay
        animationData={heartOne}
        className={classes.HeartOne}/>}

      {showHeartTwo && <Lottie
        loop={false}
        autoplay
        animationData={heartTwo}
        className={classes.HeartTwo}/>}

    </div>
  }

  return <div className={classes.CharityPage}>

    <Spacer height={40}/>

    {heartsContainer()}

    <Spacer height={30}/>

    <div className={classes.Title}>{t('charity_page_title')}</div>

    <Spacer height={30}/>

    <div className={classes.Description}>{charity?.description}</div>

    {isMobile && <Spacer height={260}/>}

    {isMobile && <div className={classes.ButtonsBackground}/>}

    {isMobile && <div className={classes.ButtonsContainer}>

      {yesButton()}

      <Spacer height={10}/>

      {noButton()}

    </div>}

    {isDesktop && <div className={classes.ButtonsContainer}>

      {noButton()}

      <Spacer width={10}/>

      {yesButton()}

    </div>}

  </div>
}
