import useSWR from 'swr'
import useSWRMutation from "swr/mutation";
import {stripeAddCard, getCard} from "../services/api/api";
import {Card, CardFundingType} from "../services/api/models";
import {Stripe, StripeElements} from "@stripe/stripe-js";

export const useCard = (cardId: string) => {
  const {
    data,
    error,
    isLoading
  } = useSWR(['getCard', cardId], getCard)
  return {
    card: data,
    cardLoading: isLoading,
    cardError: error
  }
}

export const useAddCard = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, any>('stripeAddCard', stripeAddCardFetcher)
  return {
    addCard: trigger,
    addingCard: isMutating,
    error
  }
}

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const stripeAddCardFetcher = async (url: string, data: {arg: {
    elements: StripeElements,
    stripe: Stripe,
    cardholderName: string,
    paymentCustomerId: string,
    platformAccountId: string,
    allowedCardFundingTypes?: CardFundingType[]
  }}): Promise<Card> => {
  const {elements, stripe, cardholderName, paymentCustomerId,platformAccountId, allowedCardFundingTypes } = data.arg
  return stripeAddCard(elements, stripe, cardholderName, platformAccountId, paymentCustomerId, allowedCardFundingTypes)
}
