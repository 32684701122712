import { RangeType } from "../account/PeriodModal";
import React, { useMemo } from "react";
import classes from "./DateFilterButton.module.scss";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { periodTitle } from "../../util/date_util";

interface DateFilterButtonProps {
  dateFrom?: Date
  dateTo?: Date
  onClick?: (e: any) => void
  opened?: boolean
  rangeType?: RangeType
  onLeftClick?: VoidFunction
  onRightClick?: VoidFunction
}

export const DateFilterButton: React.FC<DateFilterButtonProps> = (props) => {
  const {onClick, opened, dateFrom, dateTo, rangeType, onLeftClick, onRightClick} = props

  const handleLeftClick = (e: any) => {
    e.stopPropagation()
    onLeftClick && onLeftClick()
  }

  const handleRightClick = (e: any) => {
    e.stopPropagation()
    if (disableRightButton) {
      return
    }
    onRightClick && onRightClick()
  }

  const disableRightButton = useMemo(() => {
    return !!dateTo && dateTo >= new Date()
  }, [dateTo])

  const showArrowButtons = useMemo(() => {
    return rangeType != undefined && rangeType != RangeType.range
  }, [rangeType])

  return <div
    className={!showArrowButtons ? classes.DateFilterButtonNoRange : classes.DateFilterButton}
    onClick={onClick}>

    {showArrowButtons && <div
      className={classnames(classes.ArrowButton, 'me-2')}
      onClick={handleLeftClick}>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className={classes.Icon}/>
    </div>}

    {periodTitle(rangeType, dateFrom, dateTo)}

    {showArrowButtons && <div
      className={classnames(disableRightButton ? classes.ArrowButtonDisabled : classes.ArrowButton, 'ms-2')}
      onClick={handleRightClick}>
      <FontAwesomeIcon
        icon={faChevronRight}
        className={classes.Icon}/>
    </div>}

    {!showArrowButtons && <FontAwesomeIcon
      icon={!opened ? faChevronDown : faChevronUp}
      className={classes.FilterButtonIcon}/>}

  </div>
}
