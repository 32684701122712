import React, {useEffect, useMemo, useRef, useState} from 'react'
import classes from './GalleryModal.module.scss'
import {Modal as BModal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faPlay} from "@fortawesome/free-solid-svg-icons";
import {ModalProps} from "./Modal";
import {Event, GalleryItem} from "../../services/api/models";
import {Spacer} from "./Spacer";
import {ReactComponent as CloseButton} from "../../images/video_modal_close_button.svg";
import {ReactComponent as RightButton} from "../../images/video_modal_right_button.svg";
import {ReactComponent as LeftButton} from "../../images/video_modal_left_button.svg";
import {refreshEvent, useUpdateEvent} from "../../hooks/useEvent";
import {showError} from "../../util/toast_util";
import useUser from "../../hooks/useUser";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {AppButton} from "./AppButton";
import Spinner from "./Spinner";
import {isDesktop, isMobile} from "react-device-detect";
import classnames from "classnames";
import {arraySameSize} from "../../util/array_util";
import {useSwipeable} from "react-swipeable";
import {AppImage} from "./AppImage";
import {AppVideoPlayer} from "./AppVideoPlayer"

interface GalleryModalProps {
  event?: Event
  itemIndex: number
}

export const GalleryModal: React.FC<GalleryModalProps & ModalProps> = (props) => {
  const {opened, onClose, className, event, itemIndex} = props
  const {isClientAdmin, isBillfoldAdmin} = useUser()
  const {updateEvent, updatingEvent} = useUpdateEvent()
  const [selectedItem, setSelectedItem] = useState(itemIndex)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [gallery, setGallery] = useState<GalleryItem[]>()
  const childRefs = useRef(arraySameSize(gallery))

  const handlers = useSwipeable({
    // onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedLeft: () => onRightClick(),
    onSwipedRight: () => onLeftClick(),
  })

  const item: GalleryItem | undefined = useMemo(() => {
    if (!gallery || gallery?.length == 0) {
      return undefined
    }
    return (gallery ?? [])[selectedItem]
  }, [gallery, selectedItem])

  useEffect(() => {
    if (event) {
      setGallery(event.gallery)
    }
  }, [event])

  useEffect(() => {
    const backdrop = document.getElementsByClassName('modal-backdrop')[0]
    if (backdrop) {
      backdrop.setAttribute('style', 'opacity: 0.85 !important; background-color: #2C2E3E !important; touch-action: none !important;')
    }

    const maxSizeStyle = 'height: 100% !important; max-height: 100% !important; width: 100% !important; max-width: 100% !important;'
    const modalDialog = document.getElementsByClassName('modal-dialog')[0]
    if (modalDialog) {
      modalDialog.setAttribute('style', maxSizeStyle)
    }

    const modalContent = document.getElementsByClassName('modal-content')[0]
    if (modalContent) {
      modalContent.setAttribute('style', maxSizeStyle)
    }

    if (isMobile) {
      const modal = document.getElementsByClassName('modal')[0]
      if (modal) {
        console.log(`useEffect - modal found`)
        modal.setAttribute('style', 'display: block; height: 100% !important;')
      }
    }
  }, [opened])

  useEffect(() => {
    childRefs.current[selectedItem]?.scrollIntoView({block: 'end'});
  }, [selectedItem])

  const onCloseInner = () => {
    refreshEvent(event?.id ?? '', {
      ...event,
      gallery,
    })
    onClose()
  }

  const slideImage = (item: GalleryItem, index: number) => {
    return <div
      className={index == selectedItem ? classes.SlideImageSelected : classes.SlideImage}
      key={item.id}
      ref={(e) => (childRefs.current[index] = e)}>
      {(item.imageUrl || item.imageStorageKey) && <AppImage
        src={item.imageUrl}
        storageKey={item.imageStorageKey}
        className={classes.Image}
        onClick={() => setSelectedItem(index)}/>}

      {(item.videoThumbnailUrl || item.videoThumbnailStorageKey) && <AppImage
        src={item.videoThumbnailUrl}
        storageKey={item.videoThumbnailStorageKey}
        className={classes.Image}
        onClick={() => setSelectedItem(index)}/>}

      {(item.videoThumbnailUrl || item.videoThumbnailStorageKey) && <FontAwesomeIcon
        icon={faPlay}
        className={classes.PlayButton}
        onClick={() => setSelectedItem(index)}/>}

    </div>
  }

  const onLeftClick = () => {
    if (selectedItem == 0) {
      return
    }
    setSelectedItem(selectedItem - 1)
  }

  const onRightClick = () => {
    if (selectedItem == (gallery?.length ?? 0) - 1) {
      return
    }
    setSelectedItem(selectedItem + 1)
  }

  const onDeleteClick = async () => {

    try {

      setShowDeleteConfirmation(false)

      const newGallery = gallery
        ?.filter(g => g.id != item?.id)
        ?.map((item, index) => ({...item, position: index})) ?? []

      const updatedEvent = {
        ...event,
        gallery: newGallery
      }

      await updateEvent(updatedEvent)

      setGallery(newGallery)

      if (updatedEvent.gallery?.length == 0) {
        onCloseInner()
      } else {
        setSelectedItem(0)
      }

    } catch (e) {
      showError('Error deleting gallery item')
    }

  }

  const closeButton = () => {
    return <CloseButton className={classes.CloseButton} onClick={onCloseInner}/>
  }

  const deleteButton = () => {
    return <div className={classes.DeleteButton} onClick={() => setShowDeleteConfirmation(true)}>
      <FontAwesomeIcon icon={faTrashAlt}/>
    </div>
  }

  return <BModal
    show={opened}
    onHide={onCloseInner}
    className={className}
    animation={false}>

    <div className={classes.GalleryModal}>

      {isMobile && closeButton()}

      {isMobile && (isClientAdmin || isBillfoldAdmin) && deleteButton()}

      {isMobile && <Spacer/>}

      <div className={classes.MainFrame} {...handlers}>

        {(item?.imageUrl || item?.imageStorageKey) && <AppImage
          src={item?.imageUrl}
          storageKey={item?.imageStorageKey}
          className={classes.Image}/>}

        {(item?.videoUrl || item?.videoStorageKey) && <div className={classes.PlayerWrapper}>
          <AppVideoPlayer key={item.id} url={item.videoUrl} storageKey={item.videoStorageKey}/>
        </div>}

        {isDesktop && closeButton()}

        {isDesktop && (isClientAdmin || isBillfoldAdmin) && deleteButton()}

        <RightButton
          className={classnames(
            classes.RightButton,
            {[classes.SlideButtonDisabled]: selectedItem == (gallery?.length ?? 0) - 1}
          )}
          onClick={onRightClick}/>

        <LeftButton
          className={classnames(
            classes.LeftButton,
            {[classes.SlideButtonDisabled]: selectedItem == 0}
          )}
          onClick={onLeftClick}/>

        {showDeleteConfirmation && !updatingEvent && <div className={classes.DeleteConfirmationModal}>
          <div>Delete item?</div>
          <Spacer height={10}/>

          <AppButton
            title={'Yes'}
            onClick={onDeleteClick}
            icon={faCheck}/>

          <Spacer height={10}/>

          <AppButton
            title={'No'}
            onClick={() => setShowDeleteConfirmation(false)}
            icon={faCancel}
            secondary/>

        </div>}

        {updatingEvent && <div className={classes.DeletingCover}>
          <Spinner/>
        </div>}

      </div>

      {isDesktop && <Spacer height={16}/>}

      {isMobile && <Spacer/>}

      <div className={classes.BottomSlidesWrapper}>
        <div className={classes.BottomSlides}>
          {gallery?.map(slideImage)}
        </div>
      </div>

    </div>

  </BModal>

}
