import {getOrganizer, getOrganizerByCode} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import {Organizer} from "../services/api/models";

export const useOrganizer = (organizerId: string) => {
  const {data, error, isLoading} = useSWR(['getOrganizer', organizerId], getOrganizerFetcher)
  return {
    organizer: data,
    organizerLoading: isLoading,
    organizerError: error
  }
}

export const useOrganizerByCode = (code: string) => {
  const {data, error, isLoading} = useSWR(['getOrganizerByCode', code], getOrganizerByCodeFetcher)
  return {
    organizer: data,
    organizerLoading: isLoading,
    organizerError: error
  }
}

export const refreshOrganizer = async (organizerId: string, organizer?: Organizer) => {
  await mutate(['getOrganizer', organizerId], organizer)
}

// ---

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const getOrganizerFetcher = async (params: any) => {
  const [,organizerId] = params
  return await getOrganizer(organizerId)
}

const getOrganizerByCodeFetcher = async (params: any) => {
  const [,code] = params
  return await getOrganizerByCode(code)
}

