import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import {Storage} from "aws-amplify";

interface AppVideoPlayerProps {
  key?: string
  url?: string
  storageKey?: string
}

export const AppVideoPlayer = (props: AppVideoPlayerProps) => {
  const {key, url, storageKey} = props

  const [signedUrl, setSignedUrl] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    refreshUrl()
  }, [])

  useEffect(() => {
    refreshUrl()
  }, [storageKey])

  const refreshUrl = async () => {
    if (storageKey) {
      try {
        const signedUrl = await Storage.get(storageKey, { level: 'public' })
        setSignedUrl(signedUrl)
        setError(false)
      } catch (e) {
        console.log(`refreshUrl - error: ${e}`)
        setError(true)
      }
    }
  }

  return <ReactPlayer key={key} url={url ?? signedUrl} width={'100%'} height={'100%'} controls playing/>
}
