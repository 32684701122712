import React from 'react'
import classes from './OrganizerDetailsView.module.scss'
import {AppInput, Spacer} from "../common"
import {ViewHeader} from "../common/ViewHeader"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom";
import {useOrganizer} from "../../hooks/useOrganizer";
import {useIntegrator} from "../../hooks/useIntegrator";

export const OrganizerDetailsView = () => {

  const {organizerId} = useParams()
  const {organizer} = useOrganizer(organizerId ?? '')
  const {id, billfoldId, code, description, name, integratorID} = organizer ?? {}
  const {t} = useTranslation()
  const {integrator} = useIntegrator(organizer?.integratorID ?? '')

  return <div className={classes.OrganizerDetailsView}>

    <ViewHeader title={name ?? t('loading...')} showBackButton/>

    <Spacer height={10}/>

    <AppInput placeholder={t('id')} initialValue={id ?? ' '} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('code')} initialValue={code ?? ' '} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('description')} initialValue={description || '-'} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('integrator')} initialValue={integrator?.name ?? ' '} disabled/>

    <Spacer height={10}/>

    <AppInput placeholder={t('billfoldId')} initialValue={billfoldId ?? ' '} disabled/>

  </div>
}
