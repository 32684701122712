import {
  getWristband,
  getWristbandByNumberForEvent,
  OptInResult,
  registerWristband,
  registerWristbandByQr,
  updateWristband
} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import {Wristband, Event} from "../services/api/models";
import useSWRMutation from "swr/mutation";

export type UpdateWristbandRequest = {
  event: Event
  cardId: string
  wristband: Wristband
}

export type RegisterWristbandRequest = {
  event: Event
  cardId: string
  wristband: Wristband
  optIns: OptInResult[]
}

export type RegisterWristbandByQrRequest = {
  wristbandId: string
  wristbandNumber: string
  eventId: string
}

export default function useWristband(wristbandId: string) {
  const {data, error, isLoading} = useSWR(['getWristband', wristbandId], getWristband)
  return {
    wristband: data,
    isLoading,
    error
  }
}

export const useWristbandByNumberForEvent = (wristbandNumber: string, eventId: string) => {
  const {
    data,
    error,
    isLoading
  } = useSWR(['getWristbandByNumber', wristbandNumber, eventId], getWristbandByNumberForEvent)
  return {
    wristband: data,
    wristbandLoading: isLoading,
    wristbandError: error
  }
}

// todo make all POST calls to api the same way.
// no need to useSWRMutation in views. Do like this one.
export const useUpdateWristband = () => {
  const {
    trigger,
    isMutating,
    error
  } = useSWRMutation<Wristband | undefined, string, string, UpdateWristbandRequest>('updateWristband', updateWristbandFetcher)
  return {
    updateWristband: trigger,
    updatingWristband: isMutating,
    error
  }
}

export const refreshWristband = async (wristbandId: string, wristband?: Wristband) => {
  await mutate(['getWristband', wristbandId], wristband)
}

export const useRegisterWristband = () => {
  const {trigger, isMutating, error, data} = useSWRMutation<Wristband | undefined, any, any, RegisterWristbandRequest>('registerWristband', registerWristbandFetcher)
  return {
    registerWristband: trigger,
    registeredWristband: data,
    registering: isMutating,
    registerWristbandError: error,
  }
}

export const useRegisterWristbandByQr  = () => {
  const {trigger, isMutating, error, data} = useSWRMutation<Wristband | undefined, any, any, RegisterWristbandByQrRequest>('registerWristbandByQr', registerWristbandByQrFetcher)
  return {
    registerWristbandByQr: trigger,
    registeredWristbandByQr: data,
    registeringWristbandByQr: isMutating,
    registerWristbandByQrError: error,
  }
}

//
const updateWristbandFetcher = async (url: string, data: { arg: UpdateWristbandRequest }): Promise<Wristband | undefined> => {
  const {event, wristband, cardId} = data.arg
  return updateWristband(event, wristband, cardId)
}

const registerWristbandFetcher = async (url: string, data: { arg: RegisterWristbandRequest }): Promise<Wristband | undefined> => {
  const {event, wristband, cardId, optIns} = data.arg
  return registerWristband(event, wristband, cardId, optIns)
}

const registerWristbandByQrFetcher = async (url: string, data: { arg: RegisterWristbandByQrRequest }): Promise<Wristband | undefined> => {
  const {wristbandId, wristbandNumber, eventId} = data.arg
  return registerWristbandByQr(wristbandId, wristbandNumber, eventId)
}
