import React from 'react'
import classes from './Menu.module.scss'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface MenuItem {
  title: string
  linkTo?: string
  icon?: IconDefinition
  iconImage?: string
  onClick?: VoidFunction
}

interface MenuProps {
  items: MenuItem[]
}

export const Menu: React.FC<MenuProps> = (props) => {

  return <div className={classes.Menu}>
    {props.items.map(item => {
      const {title, linkTo, icon, iconImage, onClick} = item
      let iconComponent

      if (icon) {
        iconComponent = <FontAwesomeIcon icon={icon} className={classes.Icon}/>
      }

      if (iconImage) {
        iconComponent = <img src={iconImage} className={classes.IconImage}/>
      }

      if (linkTo && linkTo.length) {
        return <Link to={linkTo}>
          <div className={classes.Item}>
            {iconComponent}
            <div className={classes.ItemTitle}>
              {title}
            </div>
          </div>
        </Link>
      } else {
        return <div className={classes.Item} onClick={onClick}>
          {iconComponent}
          <div className={classes.ItemTitle}>
            {title}
          </div>
        </div>
      }
    })}
  </div>
}
