import React from 'react'
import classes from './MaintenanceView.module.scss'
import {Spacer} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonDigging} from "@fortawesome/free-solid-svg-icons";
import useSystemSettings from "../../hooks/useSystemSettings";
import {Navigate} from "react-router-dom";

export default function MaintenanceView() {

  const {settings} = useSystemSettings()

  return <div>

    {settings && !settings?.maintenance && <Navigate to='/'/>}

    {settings?.maintenance && <div className={classes.MaintenanceView}>

      <Spacer height={100}/>

      <FontAwesomeIcon icon={faPersonDigging} className={classes.Icon}/>

      <Spacer height={60}/>

      <div className={classes.Title}>
        We  are updating the Billfold App
      </div>

      <Spacer height={6}/>

      <div className={classes.Subtitle}>
        Be right back in a while
      </div>
    </div>}
  </div>
}
