import React, { useMemo, useState } from 'react'
import classes from './InvitesView.module.scss'
import useInvites, {
  refreshInvites,
  useAddInvites,
  useDeleteInvite
} from "../../hooks/useInvites";
import { Invite } from "../../services/api/models";
import { showError, showSuccess } from "../../util/toast_util";
import Modal, { ModalProps } from "../common/Modal";
import { AppButton, Spacer } from "../common";
import { ConfirmModal } from "../common/ConfirmModal";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { formatDate } from "../../util/date_util";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import SpinnerSmall from "../common/SpinnerSmall";
import { SearchBox } from "../common/SearchBox";
import { isEmailValid } from "../../util/validation_util";
import { isDesktop, isMobile } from "react-device-detect";
import { ViewHeader } from "../common/ViewHeader";

interface InvitesViewProps {
}

export const InvitesView: React.FC<InvitesViewProps> = (props) => {

  const {invites} = useInvites()
  const [showAddModal, setShowAddModal] = useState(false)
  const [query, setQuery] = useState<string>()

  const onAddClick = () => {
    setShowAddModal(true)
  }

  const onAddModalClose = () => {
    setShowAddModal(false)
  }

  const filteredInvites = useMemo(() => {
    if (!invites || !invites.length || !query || !query.length) {
      return invites
    }
    return invites.filter(i => i.email.toLowerCase().includes(query.toLowerCase()))
  }, [invites, query])

  return <div className={classes.InvitesView}>

    <ViewHeader title={'Invites'} showBackButton={isMobile}/>

    <Spacer height={20}/>

    <div className={classes.Controls}>

      <SearchBox
        className={classes.SearchBox}
        placeholder={'Find by email'}
        onSearchChange={setQuery}/>

      <Spacer width={10}/>

      <AppButton
        icon={faPlus}
        title={'Add invite'}
        onClick={onAddClick}
        className={classes.AddButton}/>
    </div>

    <Spacer height={10}/>

    <div className={classes.InvitesTable}>

      <Row className={classes.Header}>
        <Col xs={6}>Email</Col>
        <Col xs={3}>Date</Col>
        <Col xs={2} className={'d-flex justify-content-center'}>
          Accepted
        </Col>
        <Col xs={1} className={'d-flex justify-content-end'}>
        </Col>
      </Row>
      {filteredInvites?.map(i => <InviteItem invite={i}/>)}
    </div>

    {showAddModal && <AddInviteModal
      opened={showAddModal}
      onClose={onAddModalClose}/>}

  </div>
}

interface InviteItemProps {
  invite: Invite
}

const InviteItem: React.FC<InviteItemProps> = ({invite}) => {
  const {name, email, createdAt, accepted} = invite
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const {deleteInvite, deletingInvite} = useDeleteInvite()

  const onDeleteClick = async () => {
    setShowDeleteButton(false)
    setShowDeleteModal(true)
  }

  const onConfirmDeleteClick = async () => {
    setShowDeleteModal(false)
    try {
      await deleteInvite(invite.id ?? '')
      showSuccess('Invite deleted')
      refreshInvites()
    } catch (e) {
      showError(`Can not delete invite: ${e}`)
    }
  }

  const onHover = () => {
    if (showDeleteModal) {
      return
    }
    setShowDeleteButton(true)
  }

  const onLeave = () => {
    setShowDeleteButton(false)
  }

  const onDeleteModalClose = () => {
    setShowDeleteModal(false)
  }

  const onMobileClick = () => {
     setShowDeleteModal(true)
  }

  const browserContent = () => {
    return <Row className={classes.InviteItem} onMouseEnter={onHover} onMouseLeave={onLeave}>
      <Col xs={6}>{email}</Col>
      <Col xs={3}>{formatDate(createdAt, 'd MMM yyyy')}</Col>
      <Col xs={2} className={'d-flex justify-content-center'}>
        {accepted
          ? <FontAwesomeIcon icon={faCheckCircle} className={classes.AcceptedIcon}/>
          : <FontAwesomeIcon icon={faCircle} className={classes.NotAcceptedIcon}/>}
      </Col>
      <Col xs={1} className={'d-flex justify-content-end'}>

        {deletingInvite && <SpinnerSmall/>}

        {!deletingInvite && showDeleteButton && <FontAwesomeIcon
          icon={faTrashAlt}
          onClick={onDeleteClick}
          className={classes.DeleteButton}/>}
      </Col>
    </Row>
  }

  const mobileContent = () => {
    return <Row className={classes.InviteItem} onClick={onMobileClick}>
      <Col xs={9}>
        <div className={classes.MobileEmail}>{email}</div>
        <div className={classes.MobileDate}>{formatDate(createdAt, 'd MMM yyyy')}</div>
      </Col>

      <Col xs={3} className={'d-flex justify-content-center'}>
        {accepted
          ? <FontAwesomeIcon icon={faCheckCircle} className={classes.AcceptedIcon}/>
          : <FontAwesomeIcon icon={faCircle} className={classes.NotAcceptedIcon}/>}
      </Col>

    </Row>
  }

  return <>
    {isDesktop ? browserContent() : mobileContent()}

    {showDeleteModal && <ConfirmModal
      title={'Are you sure?'}
      onClose={onDeleteModalClose}
      opened={showDeleteModal}
      onConfirm={onConfirmDeleteClick}
      confirmTitle={'Delete'}
      confirmIcon={faTrashAlt}
      dangerConfirm>
      <div className={classes.DeleteModalText}>Are you sure to delete an invite for <b>{email}</b>?
      </div>
    </ConfirmModal>}
  </>

}

const AddInviteModal: React.FC<ModalProps> = (props) => {

  const {addInvites, addingInvites} = useAddInvites()
  const [text, setText] = useState<string>()
  const [textError, setTextError] = useState(false)

  const onTextChange = (e: any) => {
    setTextError(false)
    setText(e.target.value)
  }

  const onAddClick = async () => {

    if (!text || !text.length) {
      setTextError(true)
      return
    }

    const emails = text?.split(',').map(e => e.trim())
    for (const e of emails) {
      if (!isEmailValid(e)) {
        showError(`Email "${e}" is not valid`)
        return
      }
    }

    try {
      await addInvites(emails)
      props.onClose()
      showSuccess('Users invited')
      refreshInvites()
      //setSuccess(true)
    } catch (e) {
      showError(`Can not add invites: ${e}`)
    }
  }

  return <Modal {...props} title={'Add invites'}>
    <div className={classes.AddInvitesModal}>
      <textarea
        className={textError ? classes.TextInputError : classes.TextInput}
        onChange={onTextChange}
        disabled={addingInvites}
        placeholder={'Enter emails separated by commas. Already invited users will be skipped.'}/>
      <Spacer height={10}/>
      <AppButton
        title={'Add invites'}
        onClick={onAddClick}
        className={classes.AddInvitesButton}
        progress={addingInvites}/>
    </div>
  </Modal>


}
