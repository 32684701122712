import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import classes from './EventDetailsView.module.scss'
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import useEvent from "../../hooks/useEvent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faImage,
  faChevronDown,
  faChevronUp,
  faPlay,
  faMapLocationDot
} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as CalendarIcon} from "../../images/calendar_small.svg";
import {ReactComponent as LocationIcon} from "../../images/location_small.svg";
import {ReactComponent as FacebookIcon} from "../../images/facebook_icon.svg";
import {ReactComponent as InstagramIcon} from "../../images/instagram_icon.svg";
import {ReactComponent as TwitterIcon} from "../../images/twitter_icon.svg";
import {AppButton, Shimmer, Shimmers, Spacer} from "../common";
import {Card, Event, Location, Wristband} from "../../services/api/models";
import InfoRow from "../common/InfoRow";
import {capitalize} from "../../util/string_util";
import FavoriteButton from "../common/FavoriteButton";
import useUser from "../../hooks/useUser";
import {isDesktop, isMobile} from "react-device-detect";
import {refreshWristbands, useWristbands} from "../../hooks/useWristbands";
import {WristbandItem} from "../common/WristbandItem";
import {dates, isBraintree, locationTitle} from "../../util/event_util";
import {DivOrNull} from "../account/AccountView";
import {ViewHeader} from "../common/ViewHeader";
import {isEmpty} from "../../util/array_util";
import {GalleryModal} from "../common/GalleryModal";
import {DateTime} from "luxon";
import PlaceholderView from "../common/PlaceholderView";
import {useTranslation} from "react-i18next";
import {isQrType} from "../../util/wristband_util";
import {refreshWristband, useRegisterWristbandByQr} from "../../hooks/useWristband";
import {showError, showInfo} from "../../util/toast_util";
import {ActivateSuccessPage} from "../common/ActivateSuccessPage";
import {PaymentMethodInfo} from "../common/PaymentMethodInfo";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons";
import useCards from "../../hooks/useCards";
import {hasPaymentMethodId} from "../../util/card_util";
import {ReactComponent as QrIcon} from "../../images/qr_icon.svg";
import {RegistrationItem} from "../common/RegistrationItem";
import {AddRegistrationButton} from "../common/AddRegistrationButton";
import {useRedirectRules} from "../../hooks/useRedirectRules";
import {isWristbandNumberAllowed} from "../../services/api/api";
import {AppImage} from "../common/AppImage";

export const EventDetailsView = () => {

  const {t} = useTranslation()
  const navigate = useNavigate()
  const {eventId} = useParams() as any
  const [searchParams] = useSearchParams()
  const {event, eventLoading} = useEvent(eventId)
  const {rules} = useRedirectRules()
  const {isClientAdmin, isBillfoldAdmin} = useUser()
  const {
    name,
    startDate,
    finishDate,
    imageUrl,
    imageStorageKey,
    description,
  } = event ?? {}
  const {wristbands, wristbandsLoading} = useWristbands()
  const firstLocationItem = useRef<DivOrNull>(null)
  const [openedLocation, setOpenedLocation] = useState(0)
  const [wristbandNumber, setWristbandNumber] = useState('')
  const [eventNotFound, setEventNotFound] = useState(false)

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    if (!searchParams) {
      return
    }
    setWristbandNumber(searchParams?.get('wristbandNumber') ?? '')
  }, [searchParams])

  useEffect(() => {
    setEventNotFound(event == null)
  }, [event])

  useEffect(() => {
    if (eventId) {
      window.scrollTo(0, -100);
      const suffix = searchParams ? `?${searchParams}` : ''

      // add redirection because of DEV-2234, DEV-2280 etc
      if (eventId == process.env.REACT_APP_TACOS_AND_TEQUILA_DFW
        || eventId == process.env.REACT_APP_TACOS_AND_TEQUILA_KC_NEW
        || eventId == process.env.REACT_APP_PHASE_FEST_NEW) {
        navigate(`/event/${process.env.REACT_APP_TACOS_AND_TEQUILA_MKE}${suffix}`)
      } else if (eventId == process.env.REACT_APP_LIGHTNING_IN_A_BOTTLE_2024_EVENT_ID_OLD) {
        navigate(`/event/${process.env.REACT_APP_LIGHTNING_IN_A_BOTTLE_2024_EVENT_ID_NEW}${suffix}`)
      } else if (eventId == process.env.REACT_APP_SUMMER_CLUB_2024_OLD) {
        navigate(`/event/${process.env.REACT_APP_SUMMER_CLUB_2024_NEW}${suffix}`)
      } else if (eventId == process.env.REACT_APP_OPENING_DAY_LAUBERGE_OLD) {
        navigate(`/event/${process.env.REACT_APP_OPENING_DAY_LAUBERGE_NEW}${suffix}`)
      } else if (eventId == process.env.REACT_APP_TACOS_AND_TEQUILA_KC_OLD) {
        navigate(`/event/${process.env.REACT_APP_TACOS_AND_TEQUILA_KC_NEW}${suffix}`)
      } else if (eventId == process.env.REACT_APP_PHASE_FEST_OLD) {
        navigate(`/event/${process.env.REACT_APP_PHASE_FEST_NEW}${suffix}`)
      } else if (eventId == process.env.REACT_APP_HIGH_SIERRA_MUSIC_FESTIVAL_OLD) {
        navigate(`/event/${process.env.REACT_APP_HIGH_SIERRA_MUSIC_FESTIVAL_NEW}${suffix}`)
      }
    }
  }, [eventId])

  useEffect(() => {
    if (rules && rules.length > 0) {
      const rule = rules.find(r => r.fromEventId == eventId)
      if (rule) {
        const suffix = searchParams ? `?${searchParams}` : ''
        const {toEventId} = rule
        if (toEventId) {
          navigate(`/event/${toEventId}${suffix}`)
        }
      }

      // todo move all this stuff to the dynamo db rules
      // and after that remove this code
      // add redirection because of DEV-2234, DEV-2280 etc
      // if (eventId == process.env.REACT_APP_LIGHTNING_IN_A_BOTTLE_2024_EVENT_ID_OLD){
      //   navigate(`/event/${process.env.REACT_APP_LIGHTNING_IN_A_BOTTLE_2024_EVENT_ID_NEW}${suffix}`)
      // } else if (eventId == process.env.REACT_APP_SUMMER_CLUB_2024_OLD){
      //   navigate(`/event/${process.env.REACT_APP_SUMMER_CLUB_2024_NEW}${suffix}`)
      // } else if (eventId == process.env.REACT_APP_OPENING_DAY_LAUBERGE_OLD){
      //   navigate(`/event/${process.env.REACT_APP_OPENING_DAY_LAUBERGE_NEW}${suffix}`)
      // }
    }
  }, [rules])

  const eventWristbands = useMemo(() => {
    return wristbands?.filter(w => w.eventID == eventId) ?? []
  }, [wristbands])

  const activeWristbands = useMemo(() => {
    return eventWristbands.filter(w => !isQrType(w))
  }, [eventWristbands])

  const registrations = useMemo(() => {
    return eventWristbands.filter(w => isQrType(w))
  }, [eventWristbands])

  const onLocationClick = (index: number) => {
    setOpenedLocation(index)
  }

  const notFoundContent = () => {
    return <div>

      <ViewHeader
        title={t('event_not_found')}
        showBackButton
        onBackClick={() => navigate('/')}/>

      <PlaceholderView
        title={t('event_not_found')}
        icon={faImage}/>

    </div>
  }

  const loadingContent = () => {
    return <Shimmers className={'mt-4 mb-5'}>
      <div className={'w-100'}>
        <ViewHeader loading/>

        <div className={'mt-2'}>
          <div className={classes.Image}>
            <Shimmer expand/>
          </div>
        </div>

        <div className={'d-flex mt-4'}>
          <div className={classes.InfosContainer}>
            <div>
              <Shimmer width={280}/>
            </div>
            <div className={'mt-1'}>
              <Shimmer width={230}/>
            </div>
          </div>

          {isDesktop && <Shimmer width={150} height={44}/>}

        </div>

        <div className={'mt-5'}>
          <Shimmer width={200}/>
        </div>

        <div className={'mt-3'}>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
        </div>

        <div className={'mt-5'}>
          <Shimmer width={200}/>
        </div>

        <div className={'mt-3'}>
          <div className={classes.Image}>
            <Shimmer expand/>
          </div>
        </div>

      </div>
    </Shimmers>
  }

  const wristbandsSection = (title: string, wristbands: Wristband[], subtitle?: string) => {

    if (wristbands.length == 0) {
      return <div/>
    }

    return <div>
      <div className={subtitle ? classes.SectionHeaderTwoLines : classes.SectionHeader}>{title}</div>
      <div className={classes.SectionSubheader}>{subtitle}</div>
      {!!wristbands.length && <div className={'mt-3'}>
        {wristbands.map(w => <div className={'mb-3'} key={w.id}>
          <Link to={`/wristbands/${w.id}`}>
            <WristbandItem wristband={w} event={event}/>
          </Link>
        </div>)}
      </div>}
    </div>
  }

  const registrationsSection = (wristbands: Wristband[]) => {
    if (wristbands.length == 0) {
      return <div/>
    }

    return <div>
      <div className={classes.SectionHeaderTwoLines}>{t('online_registrations')}</div>
      <div className={classes.SectionSubheader}>{t('registration_section_subtitle')}</div>

      <div className={classes.RegistrationItemsGrid}>
        {wristbands.map(w =>
          <RegistrationItem wristband={w} event={event} key={w.id}/>
        )}
        {event && <AddRegistrationButton event={event}/>}
      </div>

    </div>
  }

  const descriptionSection = () => {

    return <>

      <Spacer height={10}/>

      {!!description && <DescriptionView description={description} eventName={event?.name ?? ''}/>}

    </>
  }

  const gallerySection = () => {

    if (!event) {
      return <div/>
    }

    return <>
      <div className={classes.SectionHeader}>{t('gallery')}</div>

      <Gallery eventId={event.id ?? ''}/>
    </>

  }

  const socialUrlsSection = () => {

    if (!event) {
      return <div/>
    }

    return <>
      <Spacer height={10}/>

      <SocialUrls event={event}/>
    </>

  }

  const venueSection = () => {

    if (!event) {
      return <div/>
    }

    return <>
      <div className={classes.SectionHeader}>{t('venue')}</div>

      {event.locations?.map((l, i) => <LocationView
        location={l}
        ref={i == 0 ? firstLocationItem : undefined}
        index={i}
        key={i}
        opened={openedLocation == i}
        hideArrow={event.locations?.length == 1}
        onClick={() => onLocationClick(i)}
        hideBorder={i == (event?.locations?.length ?? 0) - 1}/>)}
    </>
  }

  const eventContent = (event: Event) => {
    if (wristbandNumber && registrations.length > 0) {
      return <EventDetailsActivationView
        event={event}
        registrations={registrations}
      />
    }

    return <div className={'mt-2'}>
      <ViewHeader
        title={name}
        trailingItems={[
          (isClientAdmin || isBillfoldAdmin) ? <Link to={'edit'} key={'edit_button'}>
            <FontAwesomeIcon icon={faPencilAlt} className={classes.EditIcon}/>
          </Link> : undefined,
          <FavoriteButton event={event} black key={'favorite_button'}/>
        ]}
        showBackButton
        onBackClick={() => navigate('/')}
      />

      {(imageUrl || imageStorageKey)
        ? <AppImage src={imageUrl} storageKey={imageStorageKey} className={classes.Image} alt={event?.name}/>
        : <div className={classes.Image}>
          <FontAwesomeIcon icon={faImage} className={classes.Icon}/>
        </div>}

      <div className={'d-flex mt-4'}>
        <div className={classes.InfosContainer}>
          <InfoRow
            fontBig
            title={dates(startDate, finishDate)}
            icon={CalendarIcon}/>

          <div className={classes.LocationContainer}>
            <InfoRow
              fontBig
              title={locationTitle(event)}
              icon={LocationIcon}/>
          </div>
        </div>

        {event.registrationAllowed && isDesktop && <Link
          to={wristbandNumber ? `checkin?wristbandNumber=${wristbandNumber}` : 'checkin'}>
          <AppButton
            title={isEmpty(eventWristbands) ? t('register') : t('add_guest')}
            className={classes.RegisterButton}/>
        </Link>}
      </div>

      {wristbandsLoading && !eventWristbands.length && <div>
        <Spacer height={10}/>
        <WristbandItem shimmer/>
        <Spacer height={10}/>
        <WristbandItem shimmer/>
        <Spacer height={10}/>
        <WristbandItem shimmer/>
        <Spacer height={10}/>
      </div>}

      {wristbandsSection(t('activated_wristbands'), activeWristbands)}

      {registrationsSection(registrations)}

      {descriptionSection()}

      {gallerySection()}

      {socialUrlsSection()}

      {venueSection()}

      <Spacer height={100}/>

      {event.registrationAllowed && !isBraintree(event) && isMobile && isEmpty(registrations) &&
        <div className={classes.BottomButtonContainer}>
          <Link to={wristbandNumber ? `checkin?wristbandNumber=${wristbandNumber}` : 'checkin'}>
            <AppButton
              title={isEmpty(eventWristbands) ? t('register') : t('add_guest')}
              className={classes.RegisterButton}/>
          </Link>
        </div>}

    </div>
  }

  const content = () => {

    if (eventLoading) {
      return loadingContent()
    }

    if (!event && eventNotFound) {
      return notFoundContent()
    }

    if (!event) {
      return <div/>
    }

    return eventContent(event)

  }

  return <div className={classes.EventDetailsView}>

    {content()}

  </div>
}

//
interface EventDetailsActivationViewProps {
  registrations: Wristband[]
  event: Event
}

const EventDetailsActivationView = (props: EventDetailsActivationViewProps) => {
  const {registrations, event} = props
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    name,
    startDate,
    finishDate,
    imageUrl,
    imageStorageKey
  } = event ?? {}
  const [wristbandNumber, setWristbandNumber] = useState('')
  const {registerWristbandByQr, registeringWristbandByQr} = useRegisterWristbandByQr()
  const [showActivateSuccess, setShowActivateSuccess] = useState(false)
  const [selectedRegistration, setSelectedRegistration] = useState<Wristband>()
  const {cards, cardsLoading} = useCards()
  const [checkingWristbandNumber, setCheckingWristbandNumber] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!searchParams) {
      return
    }
    setWristbandNumber(searchParams?.get('wristbandNumber') ?? '')
  }, [searchParams])

  useEffect(() => {
    if (registrations && registrations.length > 0 && !selectedRegistration) {
      setSelectedRegistration(registrations[0])
    }
  }, [registrations])

  const onActivateClick = async (wristband: Wristband | undefined, newWristbandNumber: string) => {

    if (!wristband) {
      showInfo('Please select a registration to activate the wristband')
      return
    }

    if (event.checkWristbandNumberInFile && event.wristbandsFileUrl) {
      try {
        setCheckingWristbandNumber(true)
        const allowed = await isWristbandNumberAllowed(event.wristbandsFileUrl, wristbandNumber)
        if (!allowed) {
          showError(t('wristband_number_not_allowed_message'))
          return
        }
      } catch (e: any) {
        showError(t('can_not_check_wristband_number'))
        return
      } finally {
        setCheckingWristbandNumber(false)
      }
    }

    try {
      await registerWristbandByQr({
        wristbandNumber: newWristbandNumber,
        eventId: event?.id ?? '',
        wristbandId: wristband?.id ?? '',
      })
      setShowActivateSuccess(true)
    } catch (e: any) {
      console.log(`onActivateClick - ${e}, ${e.stack}`)
      showError(e)
    }
  }

  const registrationsSection = (wristbands: Wristband[]) => {

    if (wristbands.length == 0) {
      return <div/>
    }

    return <div>
      <div className={classes.SectionHeader}>{t('select_registration_you_want_to_link_wristband')}</div>
      {wristbands.map(w => <div className={'mb-3'} key={w.id}>
        {registrationItem(w)}
      </div>)}
    </div>
  }

  const cardInfo = (card?: Card) => {

    if (cardsLoading) {
      return <div/>
    }

    if (!card) {
      return <div className={classes.NoCard}>
        <FontAwesomeIcon icon={faCreditCard} className={classes.Icon}/>
        <div>No card</div>
      </div>
    }

    return <PaymentMethodInfo card={card} small/>

  }

  const registrationItem = (wristband?: Wristband) => {

    if (!wristband) {
      return <div/>
    }

    const {paymentMethodID, stripePaymentMethodID, wristbandOwnerName} = wristband
    const card = cards?.find(c => hasPaymentMethodId(c, paymentMethodID)) ??
      cards?.find(c => hasPaymentMethodId(c, stripePaymentMethodID))

    return <div
      className={selectedRegistration == wristband ? classes.RegistrationItemActive : classes.RegistrationItem}
      onClick={() => setSelectedRegistration(wristband)}>
      <div className={classes.IconWrapper}>
        <QrIcon className={classes.WristbandIcon}/>
      </div>
      <Spacer width={10}/>
      <div>{wristbandOwnerName}</div>
      <Spacer/>
      {cardInfo(card)}
    </div>
  }

  const activateButton = () => {
    return <AppButton
      title={t('link')}
      className={classes.ActivateButton}
      progress={registeringWristbandByQr || checkingWristbandNumber}
      onClick={() => onActivateClick(selectedRegistration, wristbandNumber)}
    />
  }

  const content = () => {
    if (showActivateSuccess) {
      return <div className={classes.EventDetailsView}>
        <ActivateSuccessPage onOkClick={() => {
          setShowActivateSuccess(false)
          navigate(`/event/${event?.id}`)
          refreshWristbands()
        }}/>
      </div>
    }

    return <div className={classes.EventDetailsView}>

      <Spacer height={6}/>

      <ViewHeader
        title={name}
        showBackButton
        onBackClick={() => navigate('/')}
      />

      {(imageUrl || imageStorageKey)
        ? <AppImage src={imageUrl} storageKey={imageStorageKey} className={classes.Image} alt={event?.name}/>
        : <div className={classes.Image}>
          <FontAwesomeIcon icon={faImage} className={classes.Icon}/>
        </div>}

      <div className={'d-flex mt-4'}>

        <div className={classes.InfosContainer}>
          <InfoRow
            fontBig
            title={dates(startDate, finishDate)}
            icon={CalendarIcon}/>

          <div className={classes.LocationContainer}>
            <InfoRow
              fontBig
              title={locationTitle(event)}
              icon={LocationIcon}/>
          </div>
        </div>

      </div>

      {registrationsSection(registrations)}

      {isDesktop && <div>
        <Spacer height={10}/>
        {activateButton()}
      </div>}

      <Spacer height={100}/>

      {event.registrationAllowed && !isBraintree(event) && isMobile && <div className={classes.BottomButtonContainer}>
        {activateButton()}
      </div>}

    </div>
  }

  return content()
}

//
interface SocialUrlsProps {
  event: Event
}

const SocialUrls = (props: SocialUrlsProps) => {
  const {t} = useTranslation()
  const {event} = props
  const {facebookUrl, twitterUrl, instagramUrl} = event

  const noSocialUrls = () => {
    return !facebookUrl && !twitterUrl && !instagramUrl
  }

  return noSocialUrls()
    ? <div></div>
    : <div className={classes.SocialUrls}>
      <div>{t('more_info_on')}</div>

      <Spacer width={8}/>

      {facebookUrl && <a href={facebookUrl} target={'_blank'} className={classes.SocialButton}>
        <FacebookIcon className={classes.SocialIcon}/>
      </a>}

      {instagramUrl && <a href={instagramUrl} target={'_blank'} className={classes.SocialButton}>
        <InstagramIcon className={classes.SocialIcon}/>
      </a>}

      {twitterUrl && <a href={twitterUrl} target={'_blank'} className={classes.SocialButton}>
        <TwitterIcon className={classes.SocialIcon}/>
      </a>}

    </div>

}

//
interface GalleryProps {
  eventId: string
}

const Gallery = (props: GalleryProps) => {
  const {eventId} = props
  const {event} = useEvent(eventId)
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [pressedItem, setPressedItem] = useState<number>()

  return <div className={classes.Gallery}>
    <div className={classes.GalleryWrapper}>
      <div className={classes.GalleryScrollView}>
        {event?.gallery?.map((item, i) => <div className={classes.GalleryItem} key={i}>
          {(item.imageUrl || item.imageStorageKey) && <AppImage
            src={item.imageUrl}
            storageKey={item.imageStorageKey}
            className={classes.GalleryImage}
            onClick={() => {
              setPressedItem(i)
              setShowGalleryModal(true)
            }}
          />}

          {(item.videoThumbnailUrl || item.videoThumbnailStorageKey) && <div onClick={() => {
            setPressedItem(i)
            setShowGalleryModal(true)
          }}>
            <AppImage
              src={item.videoThumbnailUrl}
              storageKey={item.videoThumbnailStorageKey}
              className={classes.GalleryImage}/>
          </div>}

          {(item.videoThumbnailUrl || item.videoThumbnailStorageKey) && <FontAwesomeIcon
            icon={faPlay}
            className={classes.PlayButton}
            onClick={() => {
              setPressedItem(i)
              setShowGalleryModal(true)
            }}/>}

        </div>)}
      </div>
    </div>

    <GalleryModal
      key={DateTime.now().toString()}
      opened={showGalleryModal}
      onClose={() => setShowGalleryModal(false)}
      event={event}
      itemIndex={pressedItem ?? 0}/>

  </div>
}

//
interface LocationViewProps {
  location: Location
  index: number
  opened: boolean
  onClick: () => void
  hideBorder?: boolean
  hideArrow?: boolean
}

const LocationView = forwardRef((props: LocationViewProps, ref: ForwardedRef<DivOrNull>) => {
  const {location, index, opened, onClick, hideBorder, hideArrow} = props
  const {latitude, longitude, address, mapImageUrl, mapImageStorageKey} = location

  return <div className={classes.LocationCard} ref={ref}>

    <div className={opened ? classes.LocationHeaderSelected : classes.LocationHeader} onClick={onClick}>

      {index != undefined && <div className={opened ? classes.NumberSelected : classes.Number}>
        {index + 1}
      </div>}

      <Spacer width={6}/>

      <div className={classes.LocationTitle}>{capitalize(address ?? '')}</div>

      <Spacer/>

      {!hideArrow && <FontAwesomeIcon
        icon={opened ? faChevronUp : faChevronDown}
        className={opened ? classes.IconSelected : classes.Icon}/>}

      <Spacer width={10}/>

    </div>

    <div className={classes.MapWrapper} style={{height: opened ? 250 : 0, opacity: opened ? 1 : 0}}>
      <div className={classes.MapImageWrapper}>
        {mapImageUrl || mapImageStorageKey
          ? <AppImage className={classes.MapImage} src={mapImageUrl} storageKey={mapImageStorageKey}/>
          : <div className={classes.MapImagePlaceholder}>
            <FontAwesomeIcon icon={faMapLocationDot} className={classes.MapIcon}/>
          </div>}
      </div>

      <a
        href={`https://maps.google.com/?q=${address}`}
        target='_blank'
        className={classes.MapCover}/>
    </div>

    {!opened && !hideBorder && <div className={classes.Border}/>}
  </div>
});

//
interface DescriptionViewProps {
  description: string
  eventName: string
}

const DescriptionView = (props: DescriptionViewProps) => {

  const {t} = useTranslation()
  const {description, eventName} = props
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(false)
  const [contentWrapperHeight, setContentWrapperHeight] = useState(0)
  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [descShort, setDescShort] = useState(false)
  const [hideCoverFully, setHideCoverFully] = useState(false)

  useEffect(() => {

    setContentWrapperHeight(contentWrapperRef.current?.clientHeight ?? 0)
    setDescriptionCollapsed(true)

    if (250 >= (contentRef.current?.clientHeight ?? 0)) {
      setDescShort(true)
      setDescriptionCollapsed(false)
    }

  }, [])

  const wrapperHeight = (): number | string => {
    if (descriptionCollapsed) {
      return 250
    }

    if (contentWrapperHeight == 0) {
      return 'auto'
    }

    return contentWrapperHeight

  }

  return <div>

    {/*{!!description && <h2 className={classes.SectionHeader}>{t('about')} {eventName}</h2>}*/}

    <div
      className={classes.ContentWrapper}
      style={{height: wrapperHeight()}}
      ref={contentWrapperRef}>

      <div
        className={classes.Content}
        ref={contentRef}
        dangerouslySetInnerHTML={{__html: description.replaceAll('\\n', '\n')}}/>

      {!descShort && !hideCoverFully && <div
        className={classes.ContentCover}
        style={{opacity: descriptionCollapsed ? 1 : 0}}/>}

    </div>

    {!descShort &&
      <div className={classes.HeightSwitcher} onClick={() => {
        setDescriptionCollapsed(!descriptionCollapsed)
        if (descriptionCollapsed) {
          // first we will smoothly make opacity of the cover from 0 to 1 (doing it in CSS)
          // and after that hide it fully to not prevent click links in the text
          setTimeout(() => {
            setHideCoverFully(true)
          }, 1000)
        } else {
          setHideCoverFully(false)
        }
      }}>
        {descriptionCollapsed ? t('show_more') : t('show_less')}
      </div>}

  </div>
}
