import {getWristbandBatch, getWristbandBatchByCode} from '../services/api/api'
import useSWR, {mutate} from 'swr'
import {WristbandBatch} from "../services/api/models";

export const useWristbandBatch = (batchId: string) => {
  const {data, error, isLoading} = useSWR(['getWristbandBatch', batchId], getBatchFetcher)
  return {
    batch: data,
    batchLoading: isLoading,
    batchError: error
  }
}

export const useWristbandBatchByCode = (code: string) => {
  const {data, error, isLoading} = useSWR(['getWristbandBatchByCode', code], getBatchByCodeFetcher)
  return {
    batch: data,
    batchLoading: isLoading,
    batchError: error
  }
}

export const refreshWristbandBatch = async (batchId: string, batch?: WristbandBatch) => {
  await mutate(['getWristbandBatch', batchId], batch)
}

// ---

/**
 * Fetcher for useSWR
 * Using separated function to keep api signatures clean
 */
const getBatchFetcher = async (params: any) => {
  const [, batchId] = params
  return await getWristbandBatch(batchId)
}

const getBatchByCodeFetcher = async (params: any) => {
  const [, code] = params
  return await getWristbandBatchByCode(code)
}

