import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import classes from "./ActivateSuccessPage.module.scss";
import {Spacer} from "./Spacer";
import {AppButton} from "./AppButton";
import React, {useEffect, useState} from "react";
import {ReactComponent as WristbandBigIcon} from "../../images/wristband_big_icon.svg";
import {BounceInView} from "./BounceInView";
import {isMobile} from "react-device-detect";

interface ActivateSuccessPageProps {
  onOkClick?: () => void;
}

export const ActivateSuccessPage = (props: ActivateSuccessPageProps) => {

  const {onOkClick} = props
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [showElements, setShowElements] = useState(false)
  const [showTextAndButton, setShowTextAndButton] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowElements(true)
    }, 500)

    setTimeout(() => {
      setShowTextAndButton(true)
    }, 1000)
  }, [])

  return <div className={classes.ActivateSuccessPage}>

    {showElements && <>

      <Spacer height={isMobile ? 1 : 80}/>

      <BounceInView>
        <WristbandBigIcon className={classes.Icon}/>
      </BounceInView>

      <Spacer height={isMobile ? 40 : 32}/>

      <div className={classes.Title} style={{opacity: showTextAndButton ? 1 : 0}}>
        {t('wristband_linked_successfully')}
      </div>

      <Spacer height={isMobile ? 60 : 100}/>

      <AppButton
        title={t('ok')}
        className={classes.OkButton}
        style={{opacity: showTextAndButton ? 1 : 0}}
        onClick={() => {
        if (onOkClick) {
          onOkClick()
        } else {
          navigate(-1)
        }
      }}/>

    </>}

  </div>
}
